<template>
  <div class="main-content">
    <mdc-card class="signup-card">
      <mdc-card-header>
        <img style="width: 80px; text-align: center;" src="/static/img/duomeU.png" alt="DuoMe" />
      </mdc-card-header>
      <mdc-text>
        {{message}}
        <br>
        <mdc-button v-if="start" unelevated to="/dash">Let's Go</mdc-button>
        <mdc-button v-else unelevated to="/signin">Login</mdc-button>
      </mdc-text>
    </mdc-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import fb from '../store/firebase'
export default {
  data () {
    return {
      message: '',
      start: false
    }
  },
  methods: {
    ...mapActions([
      'setPageLoad'
    ])
  },
  mounted () {
    const actionCode = this.$route.query.oobCode
    fb.auth.applyActionCode(actionCode).then((resp) => {
      console.log('Verification OK')
      this.message = 'Your email has been verified. Please proceed into the app.'
      this.start = true
    }).catch((error) => {
      console.log(error)
      this.message = 'Your request to verify your email has expired or the link has already been used. Try verifying your email again.'
    })
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  }
}
</script>

<style scoped>
.main-content {
  text-align: center;
}
.signup-card {
  max-width: 350px;
  top: calc(50% - 300px);
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  position:fixed;
  z-index:999;
}
.mdc-button {
  width: 230px;
  margin-top:16px;
}
</style>
