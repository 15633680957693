import MdcBody from 'mdc/dist/mdc/Body.vue'
import MdcButton from 'mdc/dist/mdc/Button.vue'
import MdcAnimatedIcon from 'mdc/dist/mdc/AnimatedIcon.vue'
import MdcButtonIcon from 'mdc/dist/mdc/ButtonIcon.vue'
import MdcCard from 'mdc/dist/mdc/Card.vue'
import MdcCardActions from 'mdc/dist/mdc/CardActions.vue'
import MdcCardActionIcons from 'mdc/dist/mdc/CardActionIcons.vue'
import MdcCardActionIcon from 'mdc/dist/mdc/CardActionIcon.vue'
import MdcCardHeader from 'mdc/dist/mdc/CardHeader.vue'
import MdcCardMedia from 'mdc/dist/mdc/CardMedia.vue'
import MdcCardPrimaryAction from 'mdc/dist/mdc/CardPrimaryAction.vue'
import MdcCardText from 'mdc/dist/mdc/CardText.vue'
import MdcCardTitle from 'mdc/dist/mdc/CardTitle.vue'
import MdcCardSubtitle from 'mdc/dist/mdc/CardSubtitle.vue'
import MdcCheckbox from 'mdc/dist/mdc/Checkbox.vue'
import MdcChip from 'mdc/dist/mdc/Chip.vue'
import MdcChipSet from 'mdc/dist/mdc/ChipSet.vue'
import MdcDialog from 'mdc/dist/mdc/Dialog.vue'
import MdcFab from 'mdc/dist/mdc/Fab.vue'
import MdcIcon from 'mdc/dist/mdc/Icon.vue'
import MdcIconButton from 'mdc/dist/mdc/IconButton.vue'
import MdcIconToggle from 'mdc/dist/mdc/IconToggle.vue'
import MdcLayoutCell from 'mdc/dist/mdc/LayoutCell.vue'
import MdcLayoutGrid from 'mdc/dist/mdc/LayoutGrid.vue'
import MdcLinearProgress from 'mdc/dist/mdc/LinearProgress.vue'
import MdcList from 'mdc/dist/mdc/List.vue'
import MdcListDivider from 'mdc/dist/mdc/ListDivider.vue'
import MdcListItem from 'mdc/dist/mdc/ListItem.vue'
import MdcMenu from 'mdc/dist/mdc/Menu.vue'
import MdcMenuItem from 'mdc/dist/mdc/MenuItem.vue'
import MdcMenuDivider from 'mdc/dist/mdc/MenuDivider.vue'
import MdcRadio from 'mdc/dist/mdc/Radio.vue'
import MdcSelect from 'mdc/dist/mdc/Select.vue'
import SelectItem from 'mdc/dist/mdc/SelectItem.vue'
import MdcSnackbar from 'mdc/dist/mdc/Snackbar.vue'
import MdcSwitch from 'mdc/dist/mdc/Switch.vue'
import MdcTab from 'mdc/dist/mdc/Tab.vue'
import MdcTabBar from 'mdc/dist/mdc/TabBar.vue'
import MdcText from 'mdc/dist/mdc/Text.vue'
import MdcTextArea from 'mdc/dist/mdc/TextArea.vue'

import MdcTextField from 'mdc/dist/mdc/TextField.vue'
import MdcTextFieldCounter from 'mdc/dist/mdc/TextFieldCounter.vue'
import MdcTitle from 'mdc/dist/mdc/Title.vue'
import MdcSubheading from 'mdc/dist/mdc/Subheading.vue'

import RoundButton from './components/RoundButton.vue'
import TextAreaAutosize from './components/TextAreaAutosize'

const components = {
  MdcBody,
  MdcButton,
  MdcAnimatedIcon,
  MdcButtonIcon,
  MdcCard,
  MdcCardActions,
  MdcCardActionIcons,
  MdcCardActionIcon,
  MdcCardHeader,
  MdcCardMedia,
  MdcCardPrimaryAction,
  MdcCardText,
  MdcCardTitle,
  MdcCardSubtitle,
  MdcCheckbox,
  MdcChip,
  MdcChipSet,
  MdcDialog,
  MdcFab,
  MdcIcon,
  MdcIconButton,
  MdcIconToggle,
  MdcLayoutCell,
  MdcLayoutGrid,
  MdcLinearProgress,
  MdcList,
  MdcListDivider,
  MdcListItem,
  MdcMenu,
  MdcMenuItem,
  MdcMenuDivider,
  MdcRadio,
  MdcSelect,
  SelectItem,
  MdcSnackbar,
  MdcSwitch,
  MdcTab,
  MdcTabBar,
  MdcText,
  MdcTextArea,
  MdcTextAreaAutosize: TextAreaAutosize,
  MdcTextField,
  MdcTextfield: MdcTextField,
  MdcTextFieldCounter,
  MdcTitle,
  MdcSubheading,
  RoundButton
}

export default components
