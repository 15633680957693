<template>
  <div class="update-section-wrapper">
      <div v-for="(update, index) in updates" :key="index">

        <div class="dateline" v-if="showDateLines && !sameday(update.created, previousUpdate(index).created)">
            <hr>
            <span v-if="update.created">{{getDate(update.date) | dateDisplayFormat}}</span>
        </div>

        <div class="update">
        <span v-if="update.fromId !== previousUpdate(index).fromId" :title="getProfile(update.fromId).displayName" class="avatar avatar-link">
            <router-link class="update-avatar" v-if="update.fromId !== 'bot'" :to="'/user/' + update.fromId">
                <img v-if="hasPhoto(getProfile(update.fromId))" :src="getPhoto(getProfile(update.fromId))"/>
                <default-avatar v-else fontSize="16px" :profile="getProfile(update.fromId)"/>
            </router-link>
            <span class="update-avatar" v-else>
                <img v-if="hasPhoto(getProfile(update.fromId))" :src="getPhoto(getProfile(update.fromId))"/>
                <default-avatar v-else fontSize="16px" :profile="getProfile(update.fromId)"/>
            </span>
        </span>
        <div @click="$emit('showUpdate', update)" :class="{'has-link': hasLink}" class="update-text">

          <mdc-icon class="update-icon" outlined v-if="icons[update.type]" :icon="icons[update.type]"/>
          <div class="update-text-wrapper">
            <span class="update-ts">
              <time-ago refresh long :datetime="getDate(update.created)"></time-ago>
              <span class="update-from" style="margin-left:16px;" v-if="update.fromId !== previousUpdate(index).fromId">{{getProfile(update.fromId).displayName}}</span>
            </span>
            <mdc-body tag="div" typo="body2" class="update-message" v-html="update.message"></mdc-body>
          </div>
          <span v-if="update.commentCount && showReplyCount" class="update-reply-count-wrapper">
            <span class="update-reply-count">{{commentCount(update)}}</span>
          </span>

        </div>
        </div>

      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { dateFormat } from '../utils/time'
import DefaultAvatar from '../components/DefaultAvatar'
import TimeAgo from '../components/TimeAgo.vue'

export default {
  name: 'UpdateSection',
  props: {
    team: {},
    updates: {},
    week: {},
    profiles: {},
    hasLink: {
      type: Boolean,
      default: false
    },
    showDateLines: {
      type: Boolean,
      default: false
    },
    showReplyCount: {
      type: Boolean,
      default: true
    }
  },
  components: {
    DefaultAvatar,
    TimeAgo
  },
  computed: {
    ...mapState([
      'user',
      'profile'
    ])
  },
  data () {
    return {
      icons: {
        note: 'lens',
        update: 'trending_flat',
        fyi: 'info',
        broadcast: 'record_voice_over',
        achievement: 'emoji_events',
        reply: 'forum'
      }
    }
  },
  methods: {
    commentCount (update) {
      if (update.commentCount) {
        return update.commentCount
      }
      return null
    },
    previousUpdate: function (indx) {
      if (this.updates) {
        if (indx >= 1) {
          return this.updates[indx - 1]
        }
      }
      return {}
    },
    getDate (day) {
      let date = day
      if (date.toDate) {
        date = date.toDate()
      }
      return date
    },
    sameday (day1, day2) {
      if (day2) {
        if (day1) {
          let date1 = day1
          let date2 = day2
          if (date1.toDate) {
            date1 = date1.toDate()
          }
          if (date2.toDate) {
            date2 = date2.toDate()
          }
          return dateFormat(date1) === dateFormat(date2)
        }
      }
      return false
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      if (profileId === 'bot') {
        return {
          displayName: 'DuoMe Bot',
          photoURL: '/static/img/chat-bubble-circle.svg'
        }
      }
      let profileMap
      let prof = {}
      if (this.profiles) {
        profileMap = new Map(this.profiles.map(i => [i.id, i]))
        prof = profileMap.get(profileId)
        if (!prof) {
          prof = {}
        }
      }
      return prof
    },
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    },
    getPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return profile.photoURL
        }
      }
      return '/static/img/user.jpg'
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
.update-text-wrapper {
  display: flex;
  flex-flow: column;
  max-width:100%;
  width:100%
}
.update-text-wrapper.withCount {
  width: calc(100% - 86px);
}
.update-from, .update-ts {
  font-style: italic;
  font-size: 10px;
  height: 14px;
}
ul[data-type="todo_list"] {
  padding-left: 0;
}

li[data-type="todo_item"] {
  display: flex;
  flex-direction: row;
}

.todo-checkbox {
  border: 2px solid black;
  height: 0.9em;
  width: 0.9em;
  box-sizing: border-box;
  margin-right: 10px;
  margin-top: 0.3rem;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  border-radius: 0.2em;
  background-color: transparent;
  transition: 0.4s background;
}

.todo-content {
  flex: 1;
}

.todo-content p {
  margin:2px;
}

ul[data-type="todo_list"] li[data-done="true"] {
  text-decoration: line-through;
}

ul[data-type="todo_list"] li[data-done="true"] .todo-checkbox {
  background-color: black;
}

ul[data-type="todo_list"] li[data-done="false"] {
  text-decoration: none;
}

.dateline {
  width:100%;
  font-size:0.8em;
  color:#999;
  text-align: center;
}
.dateline hr {
  position:relative;
  top:19px;
  border-color: #ccc;
  border-top: 0px solid #ccc;
}
.dateline span {
  display:inline-block;
  padding-left:12px;
  padding-right:12px;
  background-color: #FFFFFF;
  position: relative;
}

.update > .avatar {
  width:0;
  z-index: 2;
}
.update .avatar .update-avatar  {
    width:31px;
    height: 31px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: block;
    overflow: hidden;
    position: relative;
}

.update .update-text .avatar .update-avatar  {
    width:21px;
    height: 21px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: block;
    overflow: hidden;
}
.avatar .update-avatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.update {
  display:flex;
  align-items: flex-start;
  margin-top:6px;
  margin-bottom:6px;
  position: relative;
}
.update .avatar .update-avatar {
  margin-right:-10px;
  margin-top:-6px;
  z-index:2;
}
.update-icon {
  color:#999999;
  margin-left:6px;
  margin-right:6px;
}
.update-reply-count {
  border-radius:16px;
  width:26px;
  height:26px;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
  background-color:#3560af;
  color:white;
}
.update-reply-count-wrapper {
  display:flex;
  align-items: center;
  border-left: 1px solid #999999;
  margin-left:6px;
  padding-left:6px;
  align-self: stretch;
}
.update-text {
  display:flex;
  align-items: center;
  padding:6px;
  margin:2px 0;
  color:black;
  background-color:#F2F2F2;
  border: 0.25px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0.503638px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 0px 5px;
  position: relative;
  line-height: 1.2em;
  max-width:calc(100%);
}
.update-text.has-link {
  cursor:pointer;
}
.update-text.has-link:hover {
  background-color:#E2E2E2;
  border: 0.25px solid #E2E2E2;
}
.update-text .update-message {
  max-width: calc(100%);
  position: relative;
  flex:1;
  display:block;
  width:100%;
  white-space: pre-wrap;
  overflow-wrap: normal;
}
</style>
<style>

.profiletag .avatar {
    height: 22px;
    width: 22px;
    position: absolute;
    margin-left: -24px;
    margin-top: -4px;
}
.profiletag  {
    margin-left:4px;
    padding-bottom: 1px;
    padding-left: 24px;
    padding-right: 6px;
    height: 16px;
    line-height: 14px;
    font-size: 14px;
    letter-spacing: 0.3px;
    border-radius: 16px;
    text-decoration: inherit;
    overflow: visible;
}

.update-text .update-message p {
  margin:4px;
}

.update .avatar .update-avatar {
  margin-left: -16px;
}

.update .update-text {
  padding-left:16px;
  padding-right:6px;
}

.update.update-right .update-text {
  padding-left:6px;
  padding-right:16px;
}

.update.update-right .avatar .update-avatar {
  position: absolute;
  right: -20px;
  top: -5px;
  margin: 0!important;
}

.update.update-right {
  align-items: flex-start;
  flex-flow: row-reverse;
  text-align: left;
  margin-left: auto;
  background-color:white
}
.update.update-right .update-text {
  padding-right:16px;
}

.update-text .update-message > span {
  display:inline;
}
.update-text .update-message > .tag {
  background-color:#fbd303;
  border-radius:16px;
  padding:2px;
  padding-left:6px;
  padding-right:6px;
  margin-bottom: -6px;
  flex:0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display:inline-block;
  max-width: calc(100% - 18px);
}
</style>
