<template>
  <div class="tempo-card">
    <div class="tempo-card-wrapper">
      <mdc-card class="tempo-wrapper">
        <div class="tempo"
        v-bind:class="{ active: isActive }"
        @click="cardActive = !cardActive"
        >

          <div class="tempo-image-wrapper" style="flex-flow:column;">
            <mdc-body typo="body2" class="tag-header" style="background-color:#FEF2B4;">
                {{tempo.title}}
            </mdc-body>

            <h5 style="font-size:1.2em;margin-top:16px;margin-bottom:16px;" class="mdc-typography--headline5">{{tempo.name}}</h5>
          </div>

          <div class="tempo-text">
            <div class="tempo-detail">
              <mdc-icon outlined icon="people" style="margin-right:16px;"/>
              <span v-for="member in tempo.members" :key="member" :title="getProfile(member).displayName" class="avatar avatar-link">
                <router-link class="update-avatar" v-if="member !== 'bot'" :to="'/user/' + member">
                    <img v-if="hasPhoto(getProfile(member))" :src="getPhoto(getProfile(member))"/>
                    <default-avatar v-else fontSize="16px" :profile="getProfile(member)"/>
                </router-link>
                <span class="update-avatar" v-else>
                    <img v-if="hasPhoto(getProfile(member))" :src="getPhoto(getProfile(member))"/>
                    <default-avatar v-else fontSize="16px" :profile="getProfile(member)"/>
                </span>
              </span>
            </div>
            <div class="tempo-detail">
              <mdc-icon outlined icon="calendar_today" style="margin-right:16px;"/>
              <mdc-body class="tempo-date">
                {{tempo.frequency}}  <span v-if="tempo.frequency !== 'Daily'">{{tempo.day}}</span> {{tempo.time}} <span v-if="tempo.condition">when {{tempo.condition}}</span>
              </mdc-body>
            </div>
            <div class="">
                <mdc-body style="margin-top:18px;">
                    <div><strong>Question</strong></div>
                    <div>{{tempo.question}}</div>
                </mdc-body>
            </div>
          </div>

          <div style="display:flex;align-items:center;width: 100%;margin-top: auto;">
          <div class="expand-btn">
            <mdc-button @click="$emit('edit')">
              EDIT
            </mdc-button>
          </div>
          <div class="active-btn">
              <mdc-switch  @click.native.stop.prevent @change="onSaveTempo" v-model="tempo.active" style="display:flex;flex-flow:row-reverse;margin-right:24px;">
                <span style="padding-right:12px;" v-if="tempo.active">
                  Active
                </span>
                <span style="padding-right:12px;" v-else>
                  Inactive
                </span>
              </mdc-switch>
          </div>
          </div>

        </div>
      </mdc-card>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DefaultAvatar from '../../components/DefaultAvatar'

export default {
  name: 'EventCard',
  components: {
    DefaultAvatar
  },
  computed: {
    ...mapState([
      'profile',
      'profiles'
    ]),
    isActive () {
      return this.cardActive === true
    }
  },
  data () {
    return {
      cardActive: {}
    }
  },
  mounted () {
    this.cardActive = this.active
  },
  methods: {
    ...mapActions([
      'saveTempo'
    ]),
    onSaveTempo () {
      console.log('TempoCard.onSaveTempo', this.tempo)
      this.saveTempo({ teamId: this.profile.team, tempoId: this.tempo.id, tempo: this.tempo })
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      if (profileId === 'bot') {
        return {
          displayName: 'DuoMe Bot',
          photoURL: '/static/img/chat-bubble-circle.svg'
        }
      }
      const profileMap = new Map(this.profiles.map(i => [i.id, i]))
      let prof = profileMap.get(profileId)
      if (!prof) {
        prof = {}
      }
      return prof
    },
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    },
    getPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return profile.photoURL
        }
      }
      return '/static/img/user.jpg'
    }
  },
  watch: {
    active () {
      this.cardActive = this.active
    }
  },
  props: {
    tempo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
em.text-highlight {
  font-style: normal;
  border-radius: 1em 0 1em 0;
  text-shadow: 1px 1px 1px #fff;
  background-image: linear-gradient(-100deg, rgba(255,250,150,0.15), rgba(248, 181, 23, 0.65) 100%, rgba(255,250,150,0.25) );
  padding:2px;
}
</style>
<style scoped>
.tempo .avatar {
  position: relative;
  width:21px !important;
  height: 21px !important;
  margin-left:2px;
  margin-right:2px;
}

.tempo .avatar .update-avatar  {
    width:21px !important;
    height: 21px !important;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: block;
    overflow: hidden;
}
.avatar .update-avatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.tag-header {
    margin:0;
    margin-left:-16px;
    margin-top:-12px;
    width: calc(100% + 32px);
    text-align: center;
    font-size: 0.9em !important;
    padding: 10px;
    margin-bottom: 12px !important;
}
.tempo-wrapper {
  overflow: hidden;
  background-color: white;
  position: relative;
  border-radius: 13px;
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}
.tempo-card {
  min-width: 255px;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}
.tempo {
  display:flex;
  flex-flow: column;
  top: 0;
  left: 0;
  width: 101%;
  height: auto;
  align-items: center;
  min-height:300px;
}
.tempo .icon {
  color: #828282;
}

.tempo .tempo-headline {
  margin-left:12px !important;
}
.tempo .notification-dot {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 100%;
  background-color: #FFAB40;
  font-size: 9px;
  z-index: 1;
  text-align: center;
  position: absolute;
  margin-left:14px;
  margin-top:6px;
}

.tempo-image-wrapper {
  width: calc(100% - 32px);
  border-bottom:1px solid silver;
  display:flex;
  justify-content: center;
  align-items: center;
}
.tempo-headline {
  max-height: 23px;
  overflow: hidden;
}
.tempo-image {
  margin-top:16px;
  margin-bottom:16px;
  width: 80%;
  max-height:54px;
  margin-bottom:8px;
}
.tempo-text {
  width: calc(100% - 32px);
  text-align: left;
  margin-top:8px;
  padding-bottom:8px;
  overflow: hidden;
}
.tempo-text .tempo-detail {
  display:flex;
  width:100%;
  margin-left:0px;
  margin-right:0px;
  height:24px;
  align-items: center;
}
.tempo-text .tempo-detail img {
  padding-right:16px;
}
.tempo-desc {
  border-top:1px solid silver;
  width: calc(100% - 32px);
  margin:16px;
  margin-top:0;
  padding-top: 8px;
  margin-bottom: 8px;
  max-height: 36px;
  overflow: hidden;
  transition: all 0.8s ease-out;
}
.expand-btn {
  width: calc(100% - 16px);
  padding-top:4px;
  padding-bottom:4px;
  margin-top: auto;
}
.expand-btn .mdc-button{
  color: rgb(130, 130, 130);
}
.tempo-desc.expanded {
  max-height: 1200px;
}
.link-row {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.link-row > div {
  flex: 1;
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
}
.link-row-top > div {
  flex: 0;
}
.link-row .mdc-icon.duome-icon {
    margin-right: 3px;
}
.link-row-bottom > div:nth-of-type(2),
.link-row-bottom > div:nth-of-type(3) {
    flex: 0 0 20%;
    min-width: 30px;
    justify-content: flex-end;
}
.tempo-stat {
    display: flex;
    flex-direction: column;
    line-height: 0;
    flex: 1;
}
.tempo-card .mdc-subheading {
  margin:0;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.tempo-card .mdc-body {
  margin:0;
  font-size: 12px;
  line-height: 1;
}
.tempo-status-bar {
  display:flex;
  width:100%
}
.tempo-status-bar {
  display:flex;
  width:100%
}
.tempo-details {
  padding: 5% 2% 0 2%;
  font-family: 'Roboto', sans-serif;
  background: none;
  box-shadow: none;
}
.tempo-details .value {
    display: block;
    line-height: 1;
    color: #333333;
    min-height: 9px;
}
.tempo-details .field {
  font-style: italic;
  margin-top: 3px;
}
.tempo-details .duome-icon img {
  width:20px;
  height: 20px;
}

.tempo-logo-wrapper,
.tempo-logo-wrapper img {
    max-width: 100%;
    max-height: 44px;
}
.tempo-logo-wrapper {
    padding: 0 10%;
    display:flex;
    justify-content: center;
}
</style>
