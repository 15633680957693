<template>
  <!-- Preferences -->
  <app-section class="prefs"
  :editable="editable"
  animationTime="0ms"
  @change="setEditing"
  @cancel="cancel"
  @save="updateProfilePreferences"
  title="Preferences"
  >

    <div v-show="editingPrefs">

        <div class="profile-section">
          <auto-complete-location
            label="Location"
            prompt="Where do you live?"
            helptext="- E.g. London, this helps us match opportunities with a reasonable commute"
            :mobileOverlay="true"
            :value="profilecopy.location"
            :isMobile="isMobile"
            @updateAddress="updateAddress">
          </auto-complete-location>
        </div>

        <div class="profile-section">
            <mdc-body>How many days are you looking to work?</mdc-body>
            <div class="input-wrapper">
              <day-slider-range modal @change="setFlexSchedule" v-model="profilecopy.flexschedule"/>
            </div>
            <mdc-body class="sub-label nomargin">
              This helps us match you opportunities offering similar hours
            </mdc-body>
        </div>

        <div class="profile-section">
          <mdc-body>Are you looking for remote working?</mdc-body>
          <mdc-select style="width:100%;margin-bottom:12px;" outlined v-model="profilecopy.workstyle" label="Work Style">
            <img style="padding: 0px;" slot="leadingIcon" src="/static/img/icons/home-work-outline-light.svg">
            <select-item value="Office"/>
            <select-item value="Mixed"/>
            <select-item value="Remote"/>
          </mdc-select>
          <mdc-body class="sub-label">
              This helps match positions matching the workstyle you want
          </mdc-body>
        </div>

        <mdc-button @click="toggleStatusOptions" class="status-button" :class="statusClasses[profilecopy.journeyStage]">
          <span slot="leading-icon">
            <i tabindex="-1" class="material-icons mdc-button__icon" aria-hidden="true">{{statusIcons[profilecopy.journeyStage]}}</i>
          </span>
          <span class="status-button-content" >{{profilecopy.journeyStage}}</span>
          <span slot="trailing-icon">
            <i tabindex="-1" class="material-icons mdc-button__icon" :class="{'rotate': statusOptionsOpen}" aria-hidden="true">arrow_drop_down</i>
          </span>
        </mdc-button>
        <div :class="{'hidden': !statusOptionsOpen, 'without-share': profile.companyId }" class="status-button-dropdown-wrapper">
        <div :class="{'hidden': !statusOptionsOpen }" class="status-button-dropdown">
          <mdc-list  outlined twoLine interactive>
            <mdc-list-item @click="statusSelect('Learning More')">
              <span slot="leading-icon" class="learning-more-icon material-icons mdc-list-item__graphic">chrome_reader_mode</span>
              <span class="border" slot="secondary">You're here to learn more about flexible working but not open to offers</span>
              Learning More
            </mdc-list-item>
            <mdc-list-item @click="statusSelect('Starting to Look')">
              <span slot="leading-icon" class="starting-to-look-icon material-icons mdc-list-item__graphic">directions_run</span>
              <span class="border" slot="secondary">You're actively looking for new work and ready to interview</span>
              Starting to Look
            </mdc-list-item>
            <mdc-list-item @click="statusSelect('Open to Offers')">
              <span slot="leading-icon" class="open-to-offers-icon material-icons mdc-list-item__graphic">input</span>
              <span class="border" slot="secondary">You're not looking but open to hear about new opportunities</span>
              Open to Offers
            </mdc-list-item>
            <mdc-list-item @click="statusSelect('Working Flexibly')">
              <span slot="leading-icon" class="working-flexibly-icon material-icons mdc-list-item__graphic">flash_on</span>
              <span slot="secondary">You're currently in a flexibly arrangement and interested in the community</span>
              Working Flexibly
            </mdc-list-item>
          </mdc-list>
        </div>

        <div style="padding-top:12px;">
          <div class="button-row">
            <a class="link" target="_blank" :href="`${publicProfilesUrl}/me/${profile.id}`">
              <round-button style="margin-left:0" background="transparent" class="view" title="View" iconOutline icon="remove_red_eye" label="View"/>
            </a>
            <span>View your Public Profile</span>
          </div>

          <div class="button-row" v-if="!profile.emailVerified">
            <round-button style="margin-left:0" @click="sendVerification" :disabled="verificationSent" background="transparent" class="verify" title="Verify" iconOutline icon="verified_user" label="Verify"/>
            <span v-if="!verificationSent">Verify your account</span>
            <span v-else>Verification Sent</span>
          </div>
          <div class="button-row" v-else>
            <round-button style="margin-left:0" background="transparent" noHover disabled class="verifed" title="Verifed" iconOutline icon="verified_user" label="Verifed"/>
            <span>Account is <span class="emphasis">Verified</span></span>
          </div>
        </div>

        </div>

    </div>
    <div v-show="!editingPrefs">

        <div class="link-row link-row-top">
          <div>
            <span class="mdc-icon duome-icon">
              <img src="/static/img/icons/location_outline_light.svg">
            </span>
            <div class="profile-stat">
              <mdc-body class="value">{{profile.location}}</mdc-body>
              <div v-if="profile.locationFull" class="hover-info">{{profile.locationFull}}</div>
              <span><img src="/static/img/separator.svg"></span>
              <mdc-body tag="span" class="field" typo="body2">Location</mdc-body>

            </div>
          </div>
          <div>
            <mdc-icon>
              <img src="/static/img/icons/watch_outline_light.svg">
            </mdc-icon>
            <div class="profile-stat">
              <mdc-body tag="span" class="value">{{flexDisplay}}</mdc-body>
              <span :class="'days-' + flexStyle">
                <span class="day"></span>
                <span class="day"></span>
                <span class="day"></span>
                <span class="day"></span>
                <span class="day"></span>
              </span>
              <mdc-body tag="span" class="field" typo="body2">Flex Schedule</mdc-body>
            </div>
          </div>
          <div>
            <mdc-icon>
              <img src="/static/img/icons/home-work-outline-light.svg">
            </mdc-icon>
            <div class="profile-stat">
              <mdc-body tag="span" class="value">{{profile.workstyle}}</mdc-body>
              <span><img src="/static/img/separator.svg"></span>
              <mdc-body tag="span" class="field" typo="body2">Work Style</mdc-body>
            </div>
          </div>
        </div>

        <hr/>

        <mdc-button @click="toggleStatusOptions" class="status-button" :class="statusClasses[profile.journeyStage]">
          <span slot="leading-icon">
            <i tabindex="-1" class="material-icons mdc-button__icon" aria-hidden="true">{{statusIcons[profile.journeyStage]}}</i>
          </span>
          <span class="status-button-content" >{{profile.journeyStage}}</span>
          <span slot="trailing-icon">
            <i tabindex="-1" class="material-icons mdc-button__icon" :class="{'rotate': statusOptionsOpen}" aria-hidden="true">arrow_drop_down</i>
          </span>
        </mdc-button>
        <div :class="{'hidden': !statusOptionsOpen, 'without-share': profile.companyId }" class="status-button-dropdown-wrapper">
        <div :class="{'hidden': !statusOptionsOpen }" class="status-button-dropdown">
          <mdc-list  outlined twoLine interactive>
            <mdc-list-item @click="statusSelect('Learning More')">
              <span slot="leading-icon" class="learning-more-icon material-icons mdc-list-item__graphic">chrome_reader_mode</span>
              <span class="border" slot="secondary">You're here to learn more about flexible working but not open to offers</span>
              Learning More
            </mdc-list-item>
            <mdc-list-item @click="statusSelect('Starting to Look')">
              <span slot="leading-icon" class="starting-to-look-icon material-icons mdc-list-item__graphic">directions_run</span>
              <span class="border" slot="secondary">You're actively looking for new work and ready to interview</span>
              Starting to Look
            </mdc-list-item>
            <mdc-list-item @click="statusSelect('Open to Offers')">
              <span slot="leading-icon" class="open-to-offers-icon material-icons mdc-list-item__graphic">input</span>
              <span class="border" slot="secondary">You're not looking but open to hear about new opportunities</span>
              Open to Offers
            </mdc-list-item>
            <mdc-list-item @click="statusSelect('Working Flexibly')">
              <span slot="leading-icon" class="working-flexibly-icon material-icons mdc-list-item__graphic">flash_on</span>
              <span slot="secondary">You're currently in a flexibly arrangement and interested in the community</span>
              Working Flexibly
            </mdc-list-item>
          </mdc-list>
        </div>

      <div>
        <div v-if="!profile.companyId" style="padding-top:12px;">
          <div v-if="profile.profilePublic && profile.experienceCompleted" class="button-row">
            <share-section v-if="user.uid === profile.id" @change="shareopen = $event" :shareopen="shareopen" :profile="profile" />
          </div>
          <div v-else class="button-row">
            <round-button style="margin-left:0;color:#BBBBBB" background="transparent" class="share" title="Share" iconOutline icon="share" label="Share"/>
            <span>Enable <span @click="$router.push({ name: 'Settings', hash: '#privacy' })" class="emphasis" style="cursor:pointer;">Public Profile</span> to Share</span>
          </div>
        </div>

        <div class="button-row" v-if="!profile.emailVerified">
            <round-button style="margin-left:0" @click="sendVerification" background="transparent" class="verify" title="Not Verified" iconOutline icon="verified_user" label="Verify"/>
            <span>Account is <span class="emphasis">Not Verified</span></span>
          </div>
          <div class="button-row" v-else>
            <round-button style="margin-left:0" background="transparent" class="verifed" title="Verifed" iconOutline icon="verified_user" label="Verifed"/>
            <span>Account is <span class="emphasis">Verified</span></span>
          </div>
        </div>
      </div>
    </div>

    <app-dialog v-model="verifyOpen"
      title="Verification Email"
      accept="Okay"
      class="fullsize-mobile"
      scrollable>
      <div style="padding:20px;" class="mdc-dialog__body">
        We have sent you a new verification email to {{user.email}}, please check both your inbox and spam folder.
      </div>
    </app-dialog>

  </app-section>
</template>

<script>
import AppDialog from '../../components/AppDialog'
import RoundButton from '../../components/RoundButton'
import AutoCompleteLocation from '../../components/AutoCompleteLocation'
import DaySliderRange from '../../components/DaySliderRange'
import AppSection from '../../components/profile-v2/AppSection.vue'
import ShareSection from '../../components/profile-v2/ShareSection.vue'
import { mapState } from 'vuex'
import { publicProfilesUrl, firebaseSettings } from '../../settings'
import { mail } from '../../utils/backend'

export default {
  name: 'PreferencesSection',
  components: {
    RoundButton,
    AutoCompleteLocation,
    AppSection,
    ShareSection,
    DaySliderRange,
    AppDialog
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    profile: Object,
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      profilecopy: {
        workstyle: this.profile.workstyle,
        flexschedule: this.profile.flexschedule,
        journeyStage: this.profile.journeyStage,
        location: this.profile.location,
        locationFull: this.profile.locationFull,
        locationLat: this.profile.locationLat,
        locationLon: this.profile.locationLon
      },
      publicProfilesUrl: publicProfilesUrl,
      elementId: null,
      verificationSent: false,
      shareopen: false,
      editingPrefs: false,
      statusOptionsOpen: false,
      statusIcons: {
        'Learning More': 'chrome_reader_mode',
        'Starting to Look': 'directions_run',
        'Open to Offers': 'input',
        'Working Flexibly': 'flash_on'
      },
      statusClasses: {
        'Learning More': 'learning-more',
        'Starting to Look': 'starting-to-look',
        'Open to Offers': 'open-to-offers',
        'Working Flexibly': 'working-flexibly'
      },
      locationFocused: false,
      locationInputVal: '',
      verifyOpen: false
    }
  },
  computed: {
    ...mapState([
      'user'
    ]),
    flexDisplay () {
      const flexschedule = '' + (this.profile.flexschedule || '3')
      if (flexschedule.indexOf('Days') === -1) {
        return flexschedule + ' Days'
      }
      return flexschedule
    },
    flexStyle () {
      const flexschedule = this.profile.flexschedule || '3'
      return ('' + flexschedule).replace(' Days', '').replace('.', '-')
    }
  },
  methods: {
    editProfilePrefs () {
      this.editingPrefs = true
    },
    cancel () {
      this.profilecopy = this.profile
    },
    updateProfilePreferences () {
      this.$emit('updateProfileSection', this.profilecopy)
      this.editingPrefs = false
    },
    setEditing (val) {
      if (!val) {
        this.$emit('editing', false)
      } else {
        this.$emit('editing', 'preferences')
      }
      this.editingPrefs = val
    },
    toggleStatusOptions () {
      this.statusOptionsOpen = !this.statusOptionsOpen
    },
    statusSelect (val) {
      this.statusOptionsOpen = false
      this.profilecopy.journeyStage = val
      if (!this.editingPrefs) {
        const profileUpdates = {
          journeyStage: this.profilecopy.journeyStage
        }
        this.$emit('updateProfileSection', profileUpdates)
      }
    },
    updateAddress (addressData) {
      // console.log('updateAddress', this.profilecopy, addressData)
      this.profilecopy.location = addressData.location
      this.profilecopy.locationFull = addressData.locationFull
      this.profilecopy.locationLat = addressData.locationLat
      this.profilecopy.locationLon = addressData.locationLon
    },
    updateLocation (val) {
      this.locationInputVal = val.newVal
    },
    setFlexSchedule: function (flexschedule) {
      this.profile.flexschedule = flexschedule
    },
    locationFocus () {
      this.locationFocused = true
      this.movePac()
    },
    locationBlur () {
      this.locationFocused = false
    },
    sendVerification () {
      this.verificationSent = true
      if (this.user) {
        this.verifyOpen = true
        const mailBody = {
          email: this.profile.email,
          url: `https://${firebaseSettings.authDomain}`,
          displayName: this.profile.displayName
        }
        mail('verify-email', mailBody)
      }
    }
  },
  mounted () {
    this.elementId = Math.floor(Math.random() * (99999999 - 10000000 + 1) + 10000000).toString()
  }
}
</script>

<style scoped>

.prefs .button-row {
  display: flex;
  align-items:center;
  margin-left: 0px;
  overflow: hidden;
  height: 41px;
}

.prefs .button-row .emphasis {
  font-weight:500;
  text-decoration: underline;
}

.prefs .button-row .verify {
  color:#b00020;
}

.prefs .button-row .verifed {
  color:#00b020;
}

.prefs hr {
  border:none;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.prefs .status-button .material-icons {
  transition: transform 0.6s ease-in-out;
  transform: rotate(0deg);
}

.prefs .status-button .material-icons.rotate {
  transform: rotate(180deg);
  transition: transform 0.6s ease-in-out;
}

.prefs .status-button {
  color:white;
  width:100%;
  display:flex;
}

.prefs .status-button .material-icons.mdc-button__icon {
  outline: none !important;
}

.prefs .status-button-content {
  flex:1;
  text-align: left
}
.prefs .status-button-dropdown {
  margin-left: 2px;
  margin-right: 2px;
}
.prefs .status-button-dropdown {
  margin-left: 2px;
  margin-right: 2px;
  transition: max-height 1s;
  transition-delay: 300ms;
  overflow: hidden;
  max-height:400px;
}

.prefs .status-button-dropdown-wrapper {
  transition: height 1s;
  transition-delay: 0;
  height: calc(100% + 100px);
  overflow: hidden;
  margin:2px;
}

.prefs .status-button-dropdown.hidden {
  max-height: 0;
  transition-delay: 0;
}
.prefs .status-button-dropdown-wrapper.hidden {
  height: calc(100% + 100px);
  transition-delay: 500ms;
}
.prefs .status-button-dropdown-wrapper.without-share.hidden {
  height: calc(100% + 60px);
  transition-delay: 500ms;
}

.prefs .status-button-dropdown .mdc-list-item__secondary-text span {
  display: inline-block;
  margin: 0;
  font-size: 12px;
}
.prefs .status-button-dropdown .mdc-list-item__secondary-text::before {
  display:none;
}

.prefs-location-autocomplete {
  width:100%
}

.prefs-location-autocomplete .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label {
  background: white;
  padding-left: 2px;
  padding-right: 2px;
}

.prefs-location-autocomplete .mdc-text-field--focused  .mdc-notched-outline__notch,
.prefs-location-autocomplete .mdc-text-field--focused  .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #6200ee) !important;
}

.prefs-location-autocomplete .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__input {
    padding-left: 6px;
    padding-right: 6px;
    background-image: none !important;
}

.prefs .location-autocomplete input::placeholder {
  opacity: 1 !important;
}

.prefs .mdc-list .mdc-list-item__graphic {
  margin-top: 8px;
  margin-bottom: auto;
}
.learning-more {
  background-color: #880E4F;
}
.starting-to-look {
  background-color: #008080;
}
.open-to-offers {
  background-color: #FF6B05;
}
.working-flexibly {
  background-color: #3560AF;
}

.learning-more-icon {
  color: #880E4F;
}
.starting-to-look-icon {
  color: #008080;
}
.open-to-offers-icon {
  color: #FF6B05;
}
.working-flexibly-icon {
  color: #3560AF;
}

.prefs p.sub-label {
  font-size:0.8em;
  margin-top:0;
  margin-bottom:16px;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
}
.prefs p.sub-label.nomargin {
  margin-bottom:0;
}

.prefs .profile-stat .hover-info {
  display:none;
  position:absolute;
  color:#fff;
  height:20px;
  background-color: rgba(0,0,0,1);
  align-items: center;
  padding: 2px 12px;
  margin-top: 40px;
  border-radius:5px;
  font-size:12px;
  z-index:9999;
}
.prefs .profile-stat:hover .hover-info {
  display:flex;
}
.prefs .profile-stat {
    display: flex;
    flex-direction: column;
    line-height: 0;
}
.prefs .location {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.prefs .location .profile-stat .value {
  font-style:italic;
  font-size: 14px;
}
.prefs .link {
  text-decoration: none;
}
.prefs .link-row {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
}
.prefs .link-row > div {
  flex: 1;
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.prefs .link-row .mdc-icon {
    margin-right: 5px;
}
.prefs .link-row .mdc-icon img{
    width: 20px;
    height: 20px;
}
.prefs .link-row-top > div {
  flex: 1;
}
.prefs .link-row-bottom > div:nth-of-type(3) {
    flex: 0 0 30%;
    min-width: 30px;
    justify-content: flex-end;
}
.prefs .link-row-bottom > div:nth-of-type(4) {
    flex: 0 0 15%;
    min-width: 30px;
    justify-content: flex-end;
}
.prefs .link-row-bottom .mdc-button .mdc-icon {
  margin: 0 10px 5px 0;
}
.prefs {
  margin-bottom:16px;
}

.prefs .profile-stat .value {
    display: block;
    line-height: 1;
    color: #333333;
    min-height: 9px;
    margin: 0;
    font-size: 12px;
}
.prefs .profile-stat .field {
  font-style: italic;
  margin: 0;
  margin-top: 3px;
  font-size: 12px;
}

span[class^="days-"] {
  margin-top: 3px;
  width: 55px;
  white-space: nowrap;
}
span[class^="days-"] .day {
  height: 2px;
  background: #E0E0E0;
  display: inline-block;
  width: 8px;
  margin-left: 1.5px;
  margin-right: 1.5px;
}
span.days-1 .day:nth-of-type(-n+1) {
  background: #AD1457;
}
span.days-2 .day:nth-of-type(-n+2) {
  background: #AD1457;
}
span.days-3 .day:nth-of-type(-n+3) {
  background: #AD1457;
}
span.days-4 .day:nth-of-type(-n+4) {
  background: #AD1457;
}
span.days-5 .day:nth-of-type(-n+5) {
  background: #AD1457;
}

span.days-1-5 .day:nth-of-type(-n+1) {
  background: #AD1457;
}
span.days-2-5 .day:nth-of-type(-n+2) {
  background: #AD1457;
}
span.days-3-5 .day:nth-of-type(-n+3) {
  background: #AD1457;
}
span.days-4-5 .day:nth-of-type(-n+4) {
  background: #AD1457;
}

span.days-0-5 .day:nth-of-type(1) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-1-5 .day:nth-of-type(2) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-2-5 .day:nth-of-type(3) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-3-5 .day:nth-of-type(4) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-4-5 .day:nth-of-type(5) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
</style>
<style>
.prefs .autocomplete .autocomplete.mdc-text-field.mdc-text-field--upgraded.outlined {
  margin-bottom: 0px;
}

</style>
