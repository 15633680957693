export const flexFilter = [
  'Job Share',
  'Job Sharing',
  'Job Sharer',
  'Job-share',
  'Job-sharing',
  'Job-sharer',
  'Work life balance',
  'Work-life balance',
  'Work shifting',
  'Working flexibly',
  'Work flexibly',
  'Location flexibility',
  'Smart working',
  'Telecommuting',
  'Variable hours',
  'Flexible hours',
  'Flexible working',
  'Flexible work arrangements',
  'Flexible working arrangements',
  'Flexible work arrangement',
  'Flexible working arrangement',
  'Flexible work',
  'Flexible worker',
  'Flex for all',
  'Flexitime',
  'Agile working',
  'Compressed working week',
  'Annualised hours',
  'Family friendly',
  'Home working',
  'Remote working',
  'Remote worker',
  'Remote work',
  'Remote',
  'Part time',
  'Part-time',
  'Part-time working',
  'Part-time hours',
  'Part time hours',
  'Compressed hours',
  'Compressed time',
  'Working from home',
  'Work from home',
  'Work remote',
  'Work remotely',
  'Work remotely from home',
  'Term hours',
  'Term time hours',
  'Staggered hours',
  'Staggered return'
]

export const strongFlexFilter = [
  'Remote working',
  'Remote worker',
  'Remote work',
  'Home working',
  'Working from home',
  'Work from home',
  'Work remote',
  'Work remotely',
  'Working flexibly',
  'Work flexibly',
  'Flexible hours',
  'Flexible working',
  'Flexible work',
  'Flex for all',
  'Flexitime',
  'Flexible work arrangements',
  'Flexible working arrangements',
  'Flexible work arrangement',
  'Flexible working arrangement',
  'Job Share',
  'Job Sharing',
  'Job Sharer',
  'Job-share',
  'Job-sharing',
  'Job-sharer'
]

export const jobShareFilter = [
  'Job Share',
  'Job Sharing',
  'Job Sharer',
  'Job-share',
  'Job-sharing',
  'Job-sharer'
]

export const remoteWorkFilter = [
  'Remote Work Filter',
  'Remote working',
  'Remote worker',
  'Remote work',
  'Home working',
  'Working from home',
  'Work from home',
  'Work remote',
  'Work remotely from home',
  'Work remotely'
]

export const flexHoursFilter = [
  'Compressed working week',
  'Annualised hours',
  'Part-time hours',
  'Part time hours',
  'Compressed hours',
  'Compressed time',
  'Flexible hours',
  'Flexitime',
  'Term hours',
  'Term time hours',
  'Staggered hours'
]
