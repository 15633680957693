<template>

  <app-dialog
    class="first-modal-teams fullsize-mobile"
    style="z-index:11;"
    accept=""
    required
    cancel=""
    v-model="open"
    title=""
    :subtitle="subtitle"
    >
      <div style="display:flex; justify-content: center; align-items: center;" slot="title">
        <mdc-body typo="headline5" v-if="screen === 1">Welcome to DuoMe</mdc-body>
        <div style="display:flex; align-items:center; flex:1" v-if="screen > 1 && screen < 7">
           <round-button noHover style="margin-left:-6px;" background="transparent" @click="screen--" icon="arrow_back">
           </round-button>
           <div style="margin-left:auto; margin-right:auto;">
             <span class="dot" :class="{active: screen >= 1}"/>
             <span class="dot" :class="{active: screen >= 2}"/>
             <span class="dot" :class="{active: screen >= 3}"/>
             <span class="dot" :class="{active: screen >= 4}"/>
             <span v-if="!joinMode" class="dot" :class="{active: screen >= 5}"/>
           </div>
           <div style="width:59px;height:31px;" class="spacer"></div>
        </div>
        <mdc-body typo="headline5" v-if="screen === 7">Thats it you are all done!</mdc-body>
      </div>

      <profile-card v-if="screen !== 3 && screen !== 4 && screen !== 5" style="margin-top:16px;margin-bottom:16px;max-width:255px;min-width:150px;" :detail="false" @avatar="openAvatarDialog" @cardDesign="updateCardDesign" @openCardDesign="openCardDesign = true" :user="cardUser" hoverHigh :medium="true" hideShare hideBookmark hideMore :profile="profile"></profile-card>

      <mdc-body tag="div" style="margin-bottom:16px;" v-if="text">{{text}}</mdc-body>

      <div v-if="screen === 2" class="profile-section">
          <auto-complete-outline
          label="Role"
          prompt="What type of role best describes what you do?"
          :required="true"
          :value="profile.role"
          autocompleteId="'role'"
          :field="'role'"
          :scrollFocus="false"
          @set="setRole"
          @input="setRoleValue"
          :items="roles"
          ariaLabelledBy="roleLabel"
          style="z-index:99;"
          maxlength="120"
          helptext = "- E.g. Project Manager"
          :valid="roleValid || !saveFailed"
          >
            <mdc-text-field-counter slot="counter">0 / 120</mdc-text-field-counter>
          </auto-complete-outline>
      </div>

      <div v-if="screen === 3 && !joinMode" class="profile-section">
          <div class="profile-section">
            <mdc-body style="margin-top:16px;margin-bottom:0;color:rgba(0,0,0,.6);" typo="">
              <span>Set-up your team or <span class="link" @click="joinMode = true">join existing team</span></span>
            </mdc-body>
            <div class="team-tree-wrapper">
              <div class="team-tree">
                <div class="top-row">
                  <profile-card-mobile :bookmarkEnabled="false" :detail="false" :profile="profile"></profile-card-mobile>
                </div>
                <div class="tree-row"></div>
                <div class="bottom-row">
                  <profile-card-mobile :bookmarkEnabled="false" :detail="false" :profile="bottomProfileFirst"></profile-card-mobile>
                  <profile-card-mobile :bookmarkEnabled="false" :detail="false" :profile="bottomProfileSecond"></profile-card-mobile>
                </div>
              </div>
            </div>
          </div>
          <div class="profile-section">
            <app-text-field
            type="text"
            style="width:100%;"
            class="team-name"
            label="Team Name"
            prompt="Give your team a name"
            promptMobileOverlay="Give your team a name"
            helptextMobileOverlay=""
            outlined
            v-model="teamName"
            @change="setTeamName"
            :required="true"
            autocomplete="new-password"
            autofill="off"
            ariaLabelledBy="teamNameLabel"
            :disabled="teamId"
            maxlength="100"
            ref="teamName"
            helptext = "- E.g. My Team"
            :valid="managerValid || !saveFailed"
            >
            <mdc-text-field-counter slot="counter">0 / 100</mdc-text-field-counter>
            </app-text-field>
          </div>
      </div>

      <div v-if="screen === 3 && joinMode" class="profile-section">
          <div class="profile-section">
            <mdc-body style="margin-top:16px;margin-bottom:0;color:rgba(0,0,0,.6);" typo="">
              <span>Join or <span class="link" @click="joinMode = false">set-up your team</span></span>
            </mdc-body>
            <div class="team-tree-wrapper">
              <div class="team-tree">
                <div class="top-row">
                  <profile-card-mobile :bookmarkEnabled="false" :detail="false" :profile="topProfile"></profile-card-mobile>
                </div>
                <div class="tree-row"></div>
                <div class="bottom-row">
                  <profile-card-mobile :bookmarkEnabled="false" :detail="false" :profile="profile"></profile-card-mobile>
                  <profile-card-mobile :bookmarkEnabled="false" :detail="false" :profile="bottomProfileSecond"></profile-card-mobile>
                </div>
              </div>
            </div>
          </div>
          <div class="profile-section">
            <div v-if="teams" class="invite-section">
                <div v-for="(invite, index) in teams" :key="index">
                    <mdc-radio :picked="join === invite.id" @change="inviteSelected = invite" :value="invite.id" name="radios" :label="'Join ' + invite.name"  />
                    <mdc-body style="margin-top:-6px;margin-bottom:16px;color:rgba(0,0,0,.6);" typo="body2">You were invited to join by {{invite.ownerName}}</mdc-body>
                </div>
            </div>
            <mdc-body v-else typo="">
                You do not currently have any team invites.
            </mdc-body>
          </div>
      </div>

      <div v-if="screen === 4 && !joinMode" class="profile-section">
          <mdc-body style="margin-top:16px;margin-bottom:0" typo="body1">
            Automate asking you team for key information to reduce calls, optimise office time, and support people working remotely
          </mdc-body>
          <div class="profile-section">
              <div style="display:flex;margin-bottom: 16px;margin-top: 16px;">
              <img style="width:120px;" src="/static/img/teams/remote.svg"/>
              <div style="margin-left:16px;margin-top:16px;width: 100%;">
              <div style="width:100%" class="toggle-row">
              <h4>Remote Schedule</h4>
              <mdc-switch @change="saveRemoteSchedule" v-model="remoteSchedule" class="switch-row">
                <span style="padding-left:24px;" v-if="remoteSchedule">
                  On
                </span>
                <span style="padding-left:24px;" v-else>
                  Off
                </span>
              </mdc-switch>
              </div>
              <mdc-body typo="body2">Plan people and activities in the office and engage remote workers</mdc-body>
              </div>
              </div>
          </div>
          <div class="profile-section">
              <div style="display:flex;margin-bottom: 16px;margin-top: 16px;">
              <img style="width:120px;" src="/static/img/teams/tempo.svg"/>
              <div style="margin-left:16px;margin-top:16px;width: 100%;">
              <div style="width:100%" class="toggle-row">
              <h4>Team Tempo</h4>
              <mdc-switch @change="saveTeamTempo" v-model="teamTempo" class="switch-row">
                <span style="padding-left:24px;" v-if="teamTempo">
                  On
                </span>
                <span style="padding-left:24px;" v-else>
                  Off
                </span>
              </mdc-switch>
            </div>
            <mdc-body typo="body2">Reduce check in calls, ask your team what they plan to work on, and how they got on</mdc-body>
            </div>
            </div>
          </div>
      </div>

      <div v-if="screen === 4 && joinMode" class="profile-section">
          <mdc-body style="margin-top:16px;margin-bottom:16px" typo="body1">
            The following question provides annoymous guidance to help the company improve remote working.
          </mdc-body>
          <div class="profile-section">
            <mdc-body style="margin-top:0;margin-bottom:0" typo="">
              <span>How comfortable are you working remotely?</span>
            </mdc-body>
            <number-selector :value="comfortIndex" @change="setComfort" style="margin-bottom:24px;" :optionLabels="['Very Uncomfortable', 'Uncomfortable', 'Neither', 'Comfortable', 'Very Comfortable']" />
          </div>
          <div class="profile-section">
            <mdc-body style="margin-top:0;margin-bottom:0" typo="">
              <span>Do you feel you can be as productive remote as in the office?</span>
            </mdc-body>
            <number-selector :value="productiveIndex" @change="setProductive" style="margin-bottom:24px;" :optionLabels="['Strongly Disagree', 'Disagree', 'Neither', 'Agree', 'Strongly Agree']" />
          </div>
      </div>

      <div v-if="screen === 5 && !joinMode" class="profile-section">
          <div class="profile-section">
            <div class="team-tree-wrapper">
              <div class="team-tree">
                <div class="top-row">
                  <profile-card-mobile :bookmarkEnabled="false" :detail="false" :profile="profile"></profile-card-mobile>
                </div>
                <div class="tree-row"></div>
                <div class="bottom-row">
                  <profile-card-mobile :bookmarkEnabled="false" :detail="false" :profile="bottomProfileFirst"></profile-card-mobile>
                  <profile-card-mobile :bookmarkEnabled="false" :detail="false" :profile="bottomProfileSecond"></profile-card-mobile>
                </div>
              </div>
            </div>
          </div>
          <div class="profile-section mobile-column" style="display:flex">
            <app-text-field
            type="text"
            style="width:100%;"
            class="team-invite-email"
            label="Email Address"
            prompt="Invite your team to join"
            promptMobileOverlay="Invite your team to join"
            helptextMobileOverlay=""
            outlined
            v-model="teamInviteEmail"
            @change="setTeamInviteEmail"
            :required="true"
            autocomplete="new-password"
            autofill="off"
            ariaLabelledBy="teamInviteEmail"
            ref="teamName"
            :helptext="inviteMessage || '- E.g. someone@domain.com'"
            :valid="teamInviteValid || !saveFailed"
            >
            </app-text-field>
            <round-button :disabled="!teamId" @click="addInvite" style="padding-left:16px;margin-top: 68px;" icon="send" hasSlot medium>
              <span>Send</span>
            </round-button>
          </div>
      </div>

      <round-button noHover fullWidth high :disabled="acceptDisabled" @disabled-click="validate" @click="accept" hasSlot icon="arrow_forward">
        <span v-if="screen > 3 && joinMode">Finish</span>
        <span v-else-if="screen < 5">Next</span>
        <span v-else>Finished</span>
      </round-button>

      <!-- Avatar photo -->
      <photo-modal :openPhoto="avatarmodalopen" @upload="uploadAvatarFile" @change="changeAvatarModalOpen" :profile="profile" :accept-disabled="saveAvatarDisabled" />

      <!-- Card design -->
      <card-design-modal v-show="openCardDesign" :advancedEnabled="tasksCompleted" @openPhoto="avatarmodalopen = true;openCardDesign = false" @openTasks="profileNotification = true" :openCardDesign="openCardDesign" @change="changeCardDesignModalOpen" @cardDesign="updateCardDesign" :profile="profile" />
  </app-dialog>

</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import AppDialog from '../components/AppDialog'
import ProfileCard from '../components/profile-v2/ProfileCard.vue'
import ProfileCardMobile from '../components/profile-v2/ProfileCardMobile.vue'

import PhotoModal from '../components/profile-v2/PhotoModal.vue'
import CardDesignModal from '../components/profile-v2/CardDesignModal.vue'
import AutoCompleteOutline from '../components/AutoCompleteOutline.vue'
import AppTextField from '../components/AppTextField'
import NumberSelector from '../components/NumberSelector'

export default {
  name: 'FirstLoginTeams',
  props: {
    firstModalOpen: {
      type: Boolean
    }
  },
  components: {
    AppDialog,
    AppTextField,
    AutoCompleteOutline,
    ProfileCard,
    ProfileCardMobile,
    CardDesignModal,
    PhotoModal,
    NumberSelector
  },
  async mounted () {
    if (this.profile) {
      this.teams = await this.getTeams()
      this.role = this.profile.role
      if (this.teams.count > 0) {
        this.joinMode = true
      }
      this.completed = 1
      if (this.profile.basicCompleted) {
        this.basic = true
        this.completed++
      }
      if (this.profile.experienceCompleted) {
        this.experience = true
        this.completed++
      }
      if (this.profile.photoURL) {
        this.personalise = true
        this.completed++
      }
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'roles'
    ]),
    ...mapGetters([
      'getTeams'
    ]),
    cardUser () {
      if (this.screen === 6) {
        return this.user
      }
      return {}
    },
    subtitle () {
      return this.screenData[this.screen] ? this.screenData[this.screen].subtitle : ''
    },
    text () {
      return this.screenData[this.screen] ? this.screenData[this.screen].text : ''
    },
    helpText () {
      return this.screenData[this.screen] ? this.screenData[this.screen].helpText : ''
    },
    acceptCloses () {
      if (this.joinMode && this.screen > 3) {
        return true
      }
      if (this.screen > 4) {
        return true
      }
      return false
    },
    acceptDisabled () {
      if (this.screen === 1) {
        return false
      }
      if (this.screen === 2 && this.role) {
        return false
      }
      if (this.screen === 3 && this.joinMode && this.join) {
        return false
      }
      if (this.screen === 3 && !this.joinMode && this.teamName) {
        return false
      }
      if (this.screen === 4) {
        return false
      }
      if (this.screen === 5) {
        return false
      }
      return true
    },
    tasksCompleted () {
      return this.completed === 4
    }
  },
  data () {
    return {
      open: false,
      openCardDesign: false,
      avatarmodalopen: false,
      saveAvatarDisabled: true,
      profileNotification: false,
      completed: 1,
      basic: false,
      experience: false,
      personalise: false,
      completeIntro: false,
      saveFailed: false,
      role: '',
      roleValid: true,
      managerEmail: '',
      managerValid: true,
      comfort: 'Neither',
      comfortIndex: 3,
      productive: 'Neither',
      productiveIndex: 3,
      location: '',
      locationValid: true,
      flexscheduleValid: true,
      flexschedule: '',
      remoteOnly: false,
      teamName: '',
      joinMode: false,
      join: false,
      teamTempo: true,
      remoteSchedule: true,
      teams: null,
      teamInviteEmail: '',
      teamInviteValid: true,
      inviteMessage: '',
      inviteSelected: null,
      teamId: null,
      screen: 1,
      screenData: {
        1: {
          text: 'The next steps create your business card and let you join or create a team'
        },
        2: {
        },
        3: {
        },
        4: {
        },
        5: {
        }
      },
      topProfile: { displayName: '?', cardDesign: 'profile-colour-cyan', cardDesignBackground: 'profile-bg-bottom' },
      bottomProfileFirst: { displayName: '?', cardDesign: 'profile-colour-cyan', cardDesignBackground: 'profile-bg-bottom' },
      bottomProfileSecond: { displayName: '?', cardDesign: 'profile-colour-cyan', cardDesignBackground: 'profile-bg-bottom' }
    }
  },
  methods: {
    ...mapActions([
      'setAction',
      'updateProfile',
      'uploadAvatar',
      'createTeam',
      'inviteToTeam',
      'joinTeam',
      'updateTeam'
    ]),
    validate () {
      this.saveFailed = true
      if (this.screen === 2) {
        this.roleValid = false
      }
      if (this.screen === 4) {
      }
      if (this.screen === 5) {
      }
    },
    async addInvite () {
      if (this.teamId && this.teamInviteEmail) {
        this.inviteMessage = await this.inviteToTeam({ teamId: this.teamId, email: this.teamInviteEmail })
        this.$nextTick(() => {
          this.teamInviteEmail = ''
        })
      }
    },
    uploadAvatarFile (file, filename) {
      const self = this
      this.uploadAvatar({ file, filename }).then(() => {
        self.avatarmodalopen = false
      })
    },
    changeAvatarModalOpen ($event) {
      this.avatarmodalopen = $event
      console.log('reapply scroll lock')
      setTimeout(() => {
        document.getElementsByClassName('body')[0].classList.add('mdc-dialog-scroll-lock')
      }, 0)
    },
    changeCardDesignModalOpen ($event) {
      this.openCardDesign = $event
      console.log('reapply scroll lock')
      setTimeout(() => {
        document.getElementsByClassName('body')[0].classList.add('mdc-dialog-scroll-lock')
      }, 0)
    },
    async accept () {
      this.saveFailed = false
      this.roleValid = true
      this.$set(this, 'locationValid', true)
      this.flexscheduleValid = true

      if (this.screen === 2) {
        this.profile.role = this.role
        this.updateProfile({ profile: { role: this.profile.role }, noSnack: true })
        this.topProfile.displayName = '?'
        this.join = null
        this.inviteSelected = null
      }
      if (this.screen === 3 && !this.joinMode) {
        // Create Team
        if (!this.teamId) {
          const teamResult = await this.createTeam({ teamName: this.teamName })
          this.updateProfile({ profile: { seenIntro: true }, noSnack: true })
          this.teamId = teamResult.id
        }
      }
      if (this.screen === 3 && this.joinMode) {
        // Join Team
        this.joinTeam({ teamId: this.join })
        this.updateProfile({ profile: { seenIntro: true }, noSnack: true })
      }
      if (this.screen === 4 && !this.joinMode) {
      }
      if (this.screen === 4 && this.joinMode) {
        this.open = false
        window.scrollTo({
          top: 0
        })
        this.$emit('close')
      }
      if (this.screen === 5) {
        this.open = false
        window.scrollTo({
          top: 0
        })
        this.$emit('close')
      }
      this.screen++
    },
    setTeamName: function (val) {
      this.teamName = val
      this.teamNameValid = true
    },
    setTeamInviteEmail: function (val) {
      this.teamInviteEmail = val
    },
    saveTeamTempo: function (val) {
      this.updateTeam({ teamId: this.teamId, updates: { tempo: val } })
    },
    saveRemoteSchedule: function (val) {
      this.updateTeam({ teamId: this.teamId, updates: { remoteSchedule: val } })
    },
    setRoleValue: function (val) {
      this.role = val
      this.roleValid = true
    },
    setRemote (val) {
      this.remoteOnly = val
    },
    setComfort (val, option) {
      this.comfortIndex = val
      this.comfort = option
    },
    setProductive (val, option) {
      this.productiveIndex = val
      this.productive = option
    },
    updateCardDesign: function (design, layout) {
      this.profile.cardDesign = design
      this.profile.cardDesignBackground = layout
      this.updateProfile({ profile: { cardDesign: this.profile.cardDesign, cardDesignBackground: this.profile.cardDesignBackground } })
    },
    setRole (role) {
      if (typeof role.role !== 'undefined') {
        this.role = role.role
      } else {
        this.role = role
      }
    },
    setFlexSchedule (fs) {
      this.flexschedule = fs
    },
    updateAddress (addressData) {
      this.location = addressData.location
      this.locationFull = addressData.locationFull
      this.locationLat = addressData.locationLat
      this.locationLon = addressData.locationLon
      this.$set(this, 'locationValid', true)
    },
    updateLocation (val) {
      this.locationInputVal = val.newVal
      this.$set(this, 'locationValid', true)
    },
    dashAction (action) {
      this.setAction(action)
      this.open = false
    },
    openAvatarDialog: function () {
      this.avatarmodalopen = true
    },
    setAvatarSaveDisabled (loaded) {
      this.saveAvatarDisabled = !loaded
    },
    routeTo (url) {
      this.open = false
      const self = this
      this.$nextTick(() => {
        setTimeout(function () {
          self.$router.push(url)
        }, 300)
      })
    }
  },
  watch: {
    firstModalOpen: {
      handler: function (newo, oldo) {
        if (this.firstModalOpen) {
          this.open = true
        } else {
          this.open = false
        }
      },
      immediate: true
    },
    inviteSelected () {
      if (this.inviteSelected) {
        this.topProfile.displayName = this.inviteSelected.ownerName
        this.join = this.inviteSelected.id
      }
    },
    joinMode () {
      this.topProfile.displayName = '?'
      this.join = null
      this.inviteSelected = null
    },
    open (newo, oldo) {
      if (!newo) {
        if (!this.completeIntro) {
          this.$emit('modalClosed')
        }
      }
    }
  }
}
</script>

<style>
.first-modal-teams .mdc-body {
  color: rgba(0,0,0,.6);
}
.first-modal-teams .team-tree-wrapper {
  display:flex;
}
.first-modal-teams .team-tree {
  flex-flow: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
  margin-left: auto;
  margin-right: auto;
}
.first-modal-teams .tree-row {
  height: 1px;
  background-color: #666;
  width: 50%;
  margin: 16px;
  position: relative;
}
.first-modal-teams .tree-row:before {
  content: '|';
  color:#666;
  position:absolute;
  top:-18px;
  left:50%;
}
.first-modal-teams .tree-row:after {
  content: '';
  border-left:1px solid #666;
  border-right:1px solid #666;
  height:18px;
  display:block;
}
.first-modal-teams .bottom-row {
  display:flex;
  min-width:290px;
}
.team-name .app-text-field.mdc-textfield-wrapper {
  width:100%;
}
.team-invite-email .app-text-field.mdc-textfield-wrapper {
  width:100%;
}
.first-modal-teams .toggle-row {
  display:flex;
  width:100%;
  justify-content: center;
}
.first-modal-teams .toggle-row h4 {
  flex:1;
  margin-bottom:12px;
  margin-top:0px;
}
.first-modal-teams .toggle-row .mdc-switch {
  margin-bottom:0px;
  margin-top:-2px;
  order:2;
  margin-right:16px;
}
.first-modal-teams .toggle-row label {
  margin-right:16px;
  font-weight:600;
  order:1;
}
.first-modal-teams .switch-row {
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top: -12px;
}
</style>

<style scoped>
.first-modal-teams .link-button {
  display:block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden;
  padding-left:16px;
  max-width:100%;
  margin-left: auto;
  margin-right: auto;
}
.first-modal-teams .item {
  align-items: flex-start;
  display: flex;
  flex:0;
  flex-direction: column;
  margin-top:auto;
  margin-top:12px;
  min-height:65px;
  padding:12px;
  cursor:normal;
  border-radius: 2px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: all 600ms ease-in-out;
  max-height: 100px;
}
.first-modal-teams .item .item-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  width:100%;
}
.item img, .item .image-spacer {
    height:80px;
    width:80px;
    min-width:80px;
  }
p.mdc-body{
  margin-bottom:0;
}
.item .info {
  flex: 1;
  padding-left:24px;
  padding-right:24px;
}
.first-modal-teams .item.expanded {
  max-height: 600px;
  overflow: hidden;
}
.item .info .mdc-title {
  font-size:16px;
}
.first-modal-teams .tick {
  border-radius:50%;
  padding:10px;
  background-color:transparent;
  border-color:transparent;
  border-width: 2px;
  border-style: solid;
  width:10px;
  height:10px;
  text-align: center;
  font-style: normal;
  display: block;
  font-weight:500;
  font-size:22px;
  line-height: 10px;
  text-indent: 1px;
}
.first-modal-teams .tick.material-icons {
  text-indent: -6px;
}

.tick.blue {
  border-color: #3560af;
}
.tick.pink {
  border-color: #C10457;
}
.tick.orange {
  border-color: #f4854d;
}
.tick.green {
  border-color: #2cbcb6;
}
.tick.done.blue {
  background-color: #3560AF;
  color:white;
}
.tick.done.pink {
  background-color: #C10457;
  color:white;
}
.tick.done.orange {
  background-color: #f4854d;
  color:white;
}
.tick.done.green {
  background-color: #2cbcb6;
  color:white;
}
p, h1, h2, h3 {
  color: #24292e;
  margin-bottom:6px;
  margin-top:6px;
}
.modal-title-wrapper {
  display:flex;
  flex-flow:row;
  align-items: flex-end;
  padding-top: 12px;
}
.modal-title-wrapper .modal-title {
  flex:1
}
.modal-title-wrapper .modal-progression {
  flex:0;
  min-width: 100px;
}
@media all and (max-width: 670px), all and (max-height: 670px){
  .item {
    min-height:65px;
  }
  .modal-title-wrapper .modal-progression {
    font-size: 2.4em;
    min-width: 90px;
  }
  h2 {
    font-size: 1.2em;
  }
  p.mdc-typography--body2 {
    font-size: 0.8em;
    line-height: 1.4em;
  }
  .progress-container {
    padding-top: 12px;
    padding-bottom: 6px;
  }
  .item {
    padding:8px;
  }
  .item img, .item .image-spacer {
    height:65px;
    width:65px;
    min-width:65px;
  }
  .item .info {
    padding-left:16px;
    padding-right:0px;
  }
  .item .info .mdc-title {
    font-size:0.9em;
    line-height:1.4em;
  }
}
@media all and (max-width: 570px), all and (max-height: 570px){
  .mobile-column {
    flex-flow:column;
  }
  .mobile-column > * {
    flex:1;
  }
  .mobile-column > .round-button {
    padding-bottom: 2px;
    width: calc(100% - 12px) !important;
    margin: 0 !important;
    max-width: unset;
    padding-top: 2px;
  }
  .item {
    min-height:50px;
  }
  .modal-title-wrapper .modal-progression {
    font-size: 2.2em;
    min-width: 80px;
  }
  h2 {
    font-size: 1em;
  }
  p.mdc-typography--body2 {
    font-size: 0.7em;
    line-height: 1.4em;
  }
  .progress-container {
    padding-top: 12px;
    padding-bottom: 6px;
  }
  .item {
    padding:6px;
  }
  .item img, .item .image-spacer  {
    height:55px;
    width:55px;
    min-width:55px;
  }
  .item .info {
    padding-left:16px;
    padding-right:0px;
  }
  .item .info .mdc-title {
    font-size:0.8em;
    line-height:1.4em;
  }
  .first-modal-teams .tick {
    width:6px;
    height:6px;
    font-size:20px;
    line-height: 6px;
    text-indent: -2px;
  }
  .first-modal-teams .tick.material-icons {
    text-indent: -7px;
  }
}

.close-modal {
  position: absolute;
  top: -10px;
  right: 16px;
  min-width: 0px;
  color:gray;
}
.switch-wrapper {
  display:flex;
  flex-flow:row;
  height:30px;
  z-index:9;
  align-items: center;
  width:99%;
  justify-content:flex-end;
  padding-top: 6px;
}
</style>

<style lang="scss">
.first-modal-teams .profile-card .default-span {
  padding-top:0%;
}
.first-modal-teams .autocomplete .input-bottom {
  display: none;
}
.first-modal-teams .mdc-dialog__surface {
  max-width: 560px !important;
  min-width: unset !important;
}
.first-modal-teams .round-button.disabled {
  background-color: #F2F2F2;
  color: rgba(0, 0, 0, 0.54);
}
.first-modal-teams .mdc-dialog__body {
  position:relative;
  padding:0px;
  margin-top:16px;
  overflow:visible;
  max-height:calc(100vh - 80px);
}
@media all and (max-width: 570px), all and (max-height: 700px){
  .first-modal-teams .mdc-dialog__body {
    max-height:calc(100vh - 65px);
  }
}
</style>
