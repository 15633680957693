<template>
  <div class="main-content">
    <mdc-textfield fullwidth v-model="talentQuery"/>
    <mdc-button raised @click="talentSearch">search</mdc-button>
    results found {{totalSize}}
    <ul class="mdc-list mdc-list--two-line">
      <li class="mdc-list-item" v-for="job in talentResults" :key="job.job.requisitionId">
        <span @click="$router.open(`/job-detail/${job.job.requisitionId}`)" class="mdc-list-item__text">
          <span class="mdc-list-item__primary-text" v-if="job.jobTitleSnippet" v-html="job.jobTitleSnippet"></span>
          <span class="mdc-list-item__primary-text" v-else v-html="job.job.title"></span>
          <span class="mdc-list-item__secondary-text" v-if="job.searchTextSnippet" v-html="job.searchTextSnippet"></span>
          <span class="mdc-list-item__secondary-text" v-else v-html="job.job.description"></span>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
// import fb from '../store/firebase'
import * as backend from '../utils/backend'
export default {
  data () {
    return {
      talentQuery: '',
      talentResults: [],
      totalSize: 0
    }
  },
  methods: {
    async talentSearch () {
      this.talentResults = []
      this.totalSize = 0

      const res = await backend.search('talent', {
        query: { query: `${this.talentQuery}` }
      })
      if (res.error) {
        console.log(res.error)
        return
      }
      console.log(this.talentQuery)
      console.log(res)
      this.talentResults = res.result.matchingJobs
      this.totalSize = res.result.totalSize
      // const doJobSearch = fb.functions.httpsCallable('doJobSearch')
      // ron const startLocation = {'latitude': 51.424473, 'longitude': 0.068296}
      // const startLocation = { 'latitude': 51.445168, 'longitude': -0.140616 }
      // const commutePreference = {
      //   roadTraffic: 'TRAFFIC_FREE',
      //   commuteMethod: 'TRANSIT',
      //   travelDuration: '3600s',
      //   allowImpreciseAddresses: true,
      //   startCoordinates: startLocation
      // }
      // const res = await doJobSearch({
      //   query: {
      //     // commuteFilter: commutePreference,
      //     query: this.talentQuery
      //   }
      // })
      // if (res.data.error) {
      //   console.log(res.data.error)
      //   return
      // }
      // const result = res.data.result
      // console.log(this.talentQuery)
      // console.log(result)
      // this.talentResults = result.matchingJobs
      // this.totalSize = result.totalSize
    }
  }
}
</script>
