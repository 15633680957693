<template>
  <mdc-list v-if="profile" class="connectmenu">
    <span>
      <mdc-list-item class="side-menu-item" v-for="(channelGroup, index) in channelGroups" :key="index">
        <div style="width:100%" v-if="channelGroup.filter==='Inbox'">
          <router-link style="display:flex;" v-on:click.native="toggleSidebar" :to="'/connect'">
            <div class="side-menu-heading sublist1">
              <span class="mdc-list-item__graphic">
                <mdc-icon slot="start-detail" :outlined="channelGroup.iconOutline" :icon="channelGroup.icon"></mdc-icon>
              </span>
              <mdc-text typo='body2' tag="div">{{channelGroup.title}}</mdc-text>
            </div>
          </router-link>
          <mdc-list style="width:100%" interactive>
            <mdc-list-item
              v-for="channel in getChannelsForGroup(channelGroup.filter)" :key="channel.id">
              <router-link v-on:click.native="toggleSidebar" :to="`/channels/${channel.channelType}/${channel.id}`">
                <div class="side-menu-item connectionrow sublist2">
                  #{{channel.channelName}}
                </div>
              </router-link>
            </mdc-list-item>
          </mdc-list>
        </div>
        <div style="width:100%" v-else-if="channelGroup.filter==='Community' || (channelGroup.filter==='Company' && profile.companyId)">
          <router-link style="display:flex;" v-on:click.native="toggleSidebar" :to="'/channel-group/' + channelGroup.link">
            <div class="side-menu-heading sublist1">
              <span class="mdc-list-item__graphic">
                <mdc-icon slot="start-detail" :outlined="channelGroup.iconOutline" :icon="channelGroup.icon"></mdc-icon>
              </span>
              <mdc-text typo='body2' tag="div">{{channelGroup.title}}</mdc-text>
            </div>
          </router-link>
          <mdc-list style="width:100%" interactive>
            <mdc-list-item
              v-for="channel in getChannelsForGroup(channelGroup.filter)" :key="channel.id">
              <router-link v-on:click.native="toggleSidebar" :to="`/channels/${channel.channelType}/${channel.id}`">
                <div class="side-menu-item connectionrow sublist2">
                  #{{channel.channelName}}
                </div>
              </router-link>
            </mdc-list-item>
          </mdc-list>
        </div>
        <div style="width:100%" v-else-if="showTeam && channelGroup.filter==='Team'">
          <router-link style="display:flex;" v-on:click.native="toggleSidebar" :to="'/channel-group/' + channelGroup.link">
            <div class="side-menu-heading sublist1">
              <span class="mdc-list-item__graphic">
                <mdc-icon slot="start-detail" :outlined="channelGroup.iconOutline" :icon="channelGroup.icon"></mdc-icon>
              </span>
              <mdc-text typo='body2' tag="div">{{channelGroup.title}}</mdc-text>
            </div>
          </router-link>
        </div>
        <div style="width:100%" v-else-if="channelGroup.filter==='Connections'">
          <router-link style="display:flex;"  v-on:click.native="toggleSidebar" to="/connections">
            <div class="side-menu-heading sublist1">
              <span class="mdc-list-item__graphic">
                <mdc-icon slot="start-detail" :outlined="channelGroup.iconOutline" icon="person_outline"></mdc-icon>
              </span>
              <mdc-text typo='body2' tag="div">Connections</mdc-text>
            </div>
          </router-link>
          <mdc-list  v-if="profile && currentRoute.name === 'Connection'" interactive>
            <mdc-list-item
              v-for="con in connections"
              :key="con.id"
              >
              <router-link v-on:click.native="toggleSidebar" :to="'/connection/' + con.id">
                <div class="side-menu-item connectionrow sublist2">
                  <img v-if="profiles && hasPhoto(otherUser(con.users))" class="avatar" :src="getPhoto(otherUser(con.users))"/>
                  <default-avatar v-else class="avatar" fontSize="12px" :profile="otherUser(con.users)"/>
                  <div v-if="profiles">
                    <span v-if="otherUser(con.users)">{{otherUser(con.users).displayName}}</span>
                  </div>
                  &middot;
                  <time-ago v-if="con.lastUpdate" refresh short :datetime="con.lastUpdate.toDate ? con.lastUpdate.toDate() : con.lastUpdate"></time-ago>
                </div>
              </router-link>
            </mdc-list-item>
          </mdc-list>
        </div>
        <div style="width:100%" v-else-if="!(channelGroup.filter === 'Company' && !profile.companyId)">
          <router-link style="display:flex;" v-on:click.native="toggleSidebar" :to="'/connections-' + channelGroup.link">
            <div class="side-menu-heading sublist1">
              <span class="mdc-list-item__graphic">
                <mdc-icon slot="start-detail" :outlined="channelGroup.iconOutline" :icon="channelGroup.icon"></mdc-icon>
              </span>
              <mdc-text typo='body2' tag="div">{{channelGroup.title}}</mdc-text>
            </div>
          </router-link>
        </div>
      </mdc-list-item>
    </span>

  </mdc-list>
</template>

<script>
import { appName } from '../settings'
import { mapState } from 'vuex'
import TimeAgo from '../components/TimeAgo.vue'
import DefaultAvatar from '../components/DefaultAvatar'
export default {
  name: 'ConnectMenuRemote',
  props: {
    currentRoute: {}
  },
  data () {
    return {
      showCommunity: appName === 'duome-demo' || appName === 'duome-testing',
      showTeam: appName === 'duome-demo' || appName === 'duome-testing',
      channelGroups: [
        {
          title: 'Inbox',
          link: 'Inbox',
          filter: 'Inbox',
          icon: 'inbox',
          iconOutline: true
        },
        {
          title: 'Tempo',
          link: 'Team',
          filter: 'Team',
          icon: 'people_outline',
          iconOutline: true
        },
        {
          title: 'Connections',
          link: 'Community',
          filter: 'Connections',
          icon: 'public',
          iconOutline: true
        },
        {
          title: 'Assigned',
          link: 'assigned',
          filter: 'Assigned',
          icon: 'assignment',
          iconOutline: true
        },
        {
          title: 'Mentioned',
          link: 'Mentioned',
          filter: 'mentioned',
          icon: 'alternate_email',
          iconOutline: true
        },
        {
          title: 'Subscribed',
          link: 'subscribed',
          filter: 'Subscribed',
          icon: 'notification_important',
          iconOutline: true
        },
        {
          title: 'My Company',
          link: 'Company',
          filter: 'Company',
          icon: 'work_outline',
          iconOutline: true
        }
      ],
      updates: [
        { displayName: 'Check Ins' },
        { displayName: 'Achievements' }
      ]
    }
  },
  components: {
    TimeAgo,
    DefaultAvatar
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'profiles',
      'connections'
    ])
  },
  methods: {
    otherUser: function (users) {
      const usersCopy = Object.assign({}, users)
      delete usersCopy[this.profile.id]
      if (Object.keys(usersCopy).length === 1) {
        const profileId = Object.keys(usersCopy)[0]
        const profileMap = new Map(this.profiles.map(i => [i.id, i]))
        return profileMap.get(profileId)
      }
      return {}
    },
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    },
    getPhoto: function (profile) {
      if (profile) {
        if (profile.photoURL) {
          return profile.photoURL
        }
      }
      return '/static/img/user.jpg'
    },
    getChannelsForGroupPriority: function (channelType) {
      if (channelType === 'Community') {
        const publicChannels = this.$store.state.channelMessages.publicChannels.filter(channel => channel.channelType === 'Community').filter(channel => channel.channelPriority >= 1)
        return [...publicChannels]
      } else if (channelType === 'Team') {
        const teamChannels = this.$store.state.channelMessages.teamChannels.filter(channel => channel.channelPriority >= 1)
        return [...teamChannels]
      } else {
        return this.$store.state.channelMessages.companyChannels.filter(channel => channel.channelPriority >= 1)
      }
    },
    getChannelsForGroup: function (channelType) {
      if (channelType === 'Community') {
        const publicChannels = this.$store.state.channelMessages.publicChannels.filter(channel => channel.channelType === 'Community').filter(channel => !channel.channelPriority)
        return [...publicChannels]
      } else if (channelType === 'Team') {
        const teamChannels = this.$store.state.channelMessages.teamChannels.filter(channel => !channel.channelPriority)
        return [...teamChannels]
      } else {
        return this.$store.state.channelMessages.companyChannels.filter(channel => !channel.channelPriority)
      }
    },
    toggleSidebar: function () {
      this.$root.$emit('toggleSidebar')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.connectmenu .avatar {
  width:20px;
  height: 20px;
  margin-right: 6px;
  position: relative;
}

.connectmenu .connectionrow, .connectionrow > div {
  display:flex;
  font-size:12px;
  flex-flow: row nowrap;
  white-space: nowrap;
  margin-right:6px;
  height: 24px;
  line-height: 24px;
  overflow: hidden;
}
.connectmenu a {
  color: #333333;
  text-decoration: none;
}
.connectmenu .mdc-list-item {
  height:24px;
}
.connectmenu .side-menu-item > a {
  margin-bottom: 5px;
}
</style>
