<template>
  <portal to="destination" :disabled="!(focused && isMobile)">

  <round-button style="position:fixed; top:0;left:0" v-if="focused && isMobile && mobileOverlay" noHover icon="close" background="transparent" @click="closePortal"></round-button>

  <mdc-body v-if="promptMobileOverlay" typo="body1" class="padded-label prompt-mobile-overlay">{{promptMobileOverlay}}</mdc-body>

  <div class="mdc-text-field mdc-text-field--outlined mdc-text-field--textarea" :class="classes">
    <textarea-autosize
    style="margin-top: 16px !important; padding-left:4px;"
    class="mdc-text-field__input"
    :placeholder="placeholder"
    :ref="id"
    @keypress.native.stop="checkKey"
    @input="onInput($event)"
    @blur.native="handleBlur"
    @focus.native="handleFocus"
    v-model="text"
    v-bind="$attrs"
    rows="1"
    :maxlength="maxlength"
    :minHeight="20"
    :maxHeight="maximumHeight"
    ></textarea-autosize>
    <div class="mdc-notched-outline mdc-notched-outline--upgraded">
      <div class="mdc-notched-outline__leading"></div>
      <div class="mdc-notched-outline__notch">
        <label for="textarea" class="mdc-floating-label" :class="{'mdc-floating-label--float-above': value || focused}">{{label}}</label>
      </div>
      <div class="mdc-notched-outline__trailing"></div>
    </div>
  </div>
  <div v-if="hasCounter" ref="counter" class="text-area-counter mdc-text-field-character-counter">{{this.text.length}} / {{this.maxlength}}</div>
  <div style="" class="mdc-text-field-helper-line textarea-helper-line">
    <mdc-body typo="body2" v-if="helptext" class="sub-label" :class="{prompt: helptextMobileOverlay}">{{helptext}}</mdc-body>
    <mdc-body typo="body2" v-if="helptextMobileOverlay" class="sub-label prompt-mobile-overlay">{{helptextMobileOverlay}}</mdc-body>
  </div>

  <div v-if="focused && isMobile && mobileOverlay" style="display:flex; width:100%; position:relative;left:0;z-index:-1;">
    <round-button :disabled="!prev" class="prev" noHover style="margin-right:auto"  @click="triggerPrev" hasSlot icon="arrow_back">
      <span>Previous</span>
    </round-button>
    <round-button v-if="!finish" :disabled="!next" class="next" noHover style="margin-left:auto"  @click="triggerNext" hasSlot trailingIcon="arrow_forward">
      <span>Next</span>
    </round-button>
    <round-button v-if="finish" class="next" noHover style="margin-left:auto"  @click="closePortalFinish" hasSlot icon="done">
      <span>Done</span>
    </round-button>
  </div>

  </portal>
</template>

<script>
import { MDCTextField } from '@material/textfield'

export default {
  name: 'mdc-text-area',
  model: {
    prop: 'value',
    event: 'input'
  },
  components: {
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    rows: {
      default: '2'
    },
    outlined: {
      default: true
    },
    disabled: {
      default: false
    },
    resize: {
      type: String,
      default: 'none'
    },
    placeholder: {
      type: String,
      default: ''
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    mobileOverlay: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: Number,
      default: 200
    },
    maxlength: {},
    prompt: {
      type: String
    },
    promptMobileOverlay: {
      type: String
    },
    helptext: {
      type: String
    },
    helptextMobileOverlay: {
      type: String
    },
    focusedOn: {
      type: Boolean,
      default: false
    },
    next: {
    },
    prev: {
    },
    finish: {
    },
    blurOnEnterMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      id: null,
      text: '',
      focused: false,
      refocus: false
    }
  },
  computed: {
    classes () {
      return {
        'mdc-text-field--focused': this.focused,
        'mdc-text-field--outlined': this.outlined,
        'mdc-text-field--disabled': this.disabled,
        'mdc-text-field--no-label': !(this.Label === undefined)
      }
    },
    maximumHeight () {
      if (this.focused && this.isMobile && this.mobileOverlay) {
        return 100
      }
      return this.maxHeight
    }
  },
  mounted () {
    if (!this.id) {
      this.getRndId()
    }
    this.textField = MDCTextField.attachTo(this.$el)
  },
  methods: {
    getRndId () {
      this.id = 'textarea-autosize-' + Math.floor(100000 + Math.random() * 900000)
    },
    triggerNext () {
      console.log('AutocompleteOutline.TriggerNext', this.next)
      this.refocus = false
      this.focused = false
      this.$emit('focusOn', this.next)
    },
    triggerPrev () {
      console.log('AutocompleteOutline.TriggerPrev', this.prev)
      this.refocus = false
      this.focused = false
      this.$emit('focusOn', this.prev, 'previous')
    },
    closePortal () {
      this.$emit('blur')
      this.$root.$children[0].$refs.view.$emit('inputBlurred')
      this.focused = false
    },
    closePortalFinish () {
      this.closePortal()
      this.$emit('finish')
    },
    hasCounter () {
      if (this.$slots.counter) return true
      return false
    },
    onInput ($event) {
      this.$emit('input', $event)
    },
    checkKey (event) {
      // console.log('TextAreaAutosize.checkKey', event)
      if (event.key === 'Escape') {
        this.handleBlur()
      }
      if (event.key === 'Enter' && this.blurOnEnterMobile && this.isMobile && !event.shiftKey) {
        event.preventDefault()
        if (this.finish) {
          this.closePortal()
        } else if (this.next) {
          this.triggerNext()
        } else {
          this.handleBlur()
        }
      }
    },
    handleFocus () {
      // console.log('focus', this.isMobile, this.mobileOverlay , this.refocus)
      this.focused = true
      this.$emit('focus')
      if (this.isMobile && this.mobileOverlay) {
        if (!this.refocus) {
          this.refocus = true
          this.$root.$children[0].$refs.view.$emit('inputFocused')
        } else {
          this.refocus = false
        }
      }
    },
    handleBlur () {
      if (!this.refocus) {
        // console.log('blur', this.isMobile, this.mobileOverlay, this.refocus)
        const self = this
        this.$emit('change', this.value)
        if (!(this.isMobile && this.mobileOverlay)) {
          self.$root.$children[0].$refs.view.$emit('inputBlurred')
          self.focused = false
          self.$emit('blur')
        }
      } else {
        if (this.isMobile && this.mobileOverlay) {
          // console.log('blur refocus', this.isMobile, this.mobileOverlay)
          const self = this
          setTimeout(function () {
            self.$refs[self.id].$el.focus()
          }, 300)
        }
      }
    }
  },
  watch: {
    value: {
      handler (newv, oldv) {
        if (this.value !== this.text) {
          this.text = this.value
        }
      },
      immediate: true
    },
    isMobile: function () {
      if (this.focused && this.mobileOverlay) {
        this.refocus = false
        this.handleFocus()
      }
    },
    focusedOn: {
      handler (newv, oldv) {
        if (this.focusedOn) {
          const self = this
          setTimeout(function () {
            console.log('setting focus for textareaautosize')
            if (!self.id) {
              self.getRndId()
            }
            self.$refs[self.id].$el.focus()
          }, 400)
        }
      },
      immediate: true
    }
  }
}
</script>

<style>

.mdc-text-field-character-counter.text-area-counter {
  position:relative;
  right:10px;
  margin-top:-4px;
  text-align:right;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
}
.round-button.next:not(.disabled), .round-button.prev:not(.disabled) {
  color: var(--mdc-theme-primary, #6200ee);
}

.mdc-text-field--textarea {
  width:100%;
  padding-bottom:0;
  padding-right:12px;
  margin-bottom:6px;
  height:auto !important;
  padding-left: 0;
  overflow:visible;
}

.mdc-text-field--textarea .mdc-notched-outline__notch {
  min-width:unset;
  margin-right: -1px;
}

.mdc-text-field--textarea textarea.mdc-text-field__input {
  width:100%;
  margin-left: 16px;
  padding-left: 0px;
  margin-bottom: -20px;
  margin-top: 16px !important;
  z-index: 1;
  min-height: 36px;
}

.mdc-text-field--textarea .mdc-floating-label.mdc-floating-label--float-above {
  background-color: white;
}

.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color:rgba(45,156,219,0.87);
  background-color: white;
  border-color: rgba(45,156,219,0.87);
  padding-right:2px;
}

.textarea-helper-line.mdc-text-field-helper-line {
  max-width: calc(100% - 90px);
  margin-right: auto;
  margin-top:-20px;
  margin-bottom:4px;
  text-align: left;
}

.textarea-helper-line.mdc-text-field-helper-line .sub-label{
  line-height:1.5em;
}

.mdc-text-field--textarea .mdc-notched-outline__notch {
  border-color:rgba(0,0,0,.24);
}

.mdc-text-field--textarea .mdc-notched-outline__leading,
.mdc-text-field--textarea .mdc-notched-outline__trailing {
  border-color:rgba(0,0,0,.24);
  border-radius:4px;
}

.mdc-text-field--textarea:hover .mdc-notched-outline__notch,
.mdc-text-field--textarea:hover .mdc-notched-outline__leading,
.mdc-text-field--textarea:hover .mdc-notched-outline__trailing {
  border-color:rgba(0,0,0,.4);
}

.mdc-text-field--textarea.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #2D9CDB);
  border-width: 2px;
}
</style>
