<template>
  <div class="profile-card">
    <div class="profile-card-wrapper">
      <mdc-card class="profile-wrapper">
        <div :class="'profile ' + profile.cardDesign">
          <div :class="'profile-bg ' + profile.cardDesignBackground">
            <div class="profile-image">
              <span v-if="profile" class="avatar">
                <img v-if="hasPhoto(profile)" :src="profile.photoURL">
                <default-avatar fontSize="49.5px" v-else :profile="profile"/>
              </span>
            </div>
            <div class="profile-text">
              <mdc-subheading>{{profile.displayName}}</mdc-subheading>
              <mdc-body class="profile-status" typo="body2"></mdc-body>
            </div>
          </div>
        </div>
      </mdc-card>
    </div>
  </div>
</template>

<script>
import DefaultAvatar from '../components/DefaultAvatar'

export default {
  name: 'MyProfileCard',
  props: {
    profile: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DefaultAvatar
  },
  methods: {
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
.profile-wrapper {
  overflow: hidden;
  background-color:white;
  position: relative;
  border-radius: 13px;
  width:100%;
  overflow: hidden;
  padding-top: calc(154 / 238 * 100%);
  position: relative;
}
.profile, .profile-bg {
  display:flex;
  flex-flow: row;
  position: absolute;
  top: 0;
  left: 0;
  width: 101%;
  height: 100%;
  align-items: center;
}

.profile {
  top:-8px;
  background-size: cover;
  height:calc(100% + 16px)
}

/* Background position this goes on the same div as profile-bg class */
div[class*="profile-bg-"] .profile-text {
  padding-bottom: 0px;
}

div[class*="profile-bg-"] .profile-text img {
  display: none;
}

.profile-bg-diagonal {
  background: linear-gradient(70deg, transparent 35%, #F2EEE6 0%);
}
.profile-card:hover .profile-bg-diagonal {
  background: linear-gradient(70deg, transparent 35%, #DEDBD6 0%);
}
.profile-bg-top {
  background: linear-gradient(#F2EEE6 85%, transparent 0%);
}
.profile-bg-top .profile-text {
  padding-top: 0;
  padding-bottom: 20px;
}
.profile-card:hover .profile-bg-top {
  background: linear-gradient(#DEDBD6 85%, transparent 0%);
}
.profile-bg-bottom {
  background: linear-gradient(transparent 40%, #F2EEE6 0%);
}
.profile-card:hover .profile-bg-bottom {
  background: linear-gradient(transparent 40%, #DEDBD6 0%);
}
.profile-bg-left {
  background: linear-gradient(90deg, transparent 22%, #F2EEE6 0%);
}
.profile-card:hover .profile-bg-left {
  background: linear-gradient(90deg, transparent 22%, #DEDBD6 0%);
}

/* Background colour/bg options this goes on the same div a profile class */
.profile-colour-cyan {
  background-color: #2cbcb6;
}
.profile-colour-magenta {
  background-color: #C10457;
}
.profile-colour-orange {
  background-color: #f4854d;
}
.profile-colour-yellow {
  background-color: #ffd12b;
}
.profile-colour-blue {
  background-color: #3560AF;
}
.profile-design-1-cyan {
  background-image: url('/static/img/card-designs/profile-design-1-cyan.svg');;
}
.profile-design-1-magenta {
  background-image: url('/static/img/card-designs/profile-design-1-magenta.svg');
}
.profile-design-1-orange {
  background-image: url('/static/img/card-designs/profile-design-1-orange.svg');
}
.profile-design-1-yellow {
  background-image: url('/static/img/card-designs/profile-design-1-yellow.svg');
}
.profile-design-2-cyan {
  background-image: url('/static/img/card-designs/profile-design-2-cyan.svg');
}
.profile-design-2-white {
  background-image: url('/static/img/card-designs/profile-design-2-white.svg');
}
.profile-design-2-magenta {
  background-image: url('/static/img/card-designs/profile-design-2-magenta.svg');
}
.profile-design-2-orange {
  background-image: url('/static/img/card-designs/profile-design-2-orange.svg');
}
.profile-design-2-yellow {
  background-image: url('/static/img/card-designs/profile-design-2-yellow.svg');
}
.profile-design-3-cyan {
  background-image: url('/static/img/card-designs/profile-design-3-cyan.svg');
}
.profile-design-3-magenta {
  background-image: url('/static/img/card-designs/profile-design-3-magenta.svg');
}
.profile-design-3-orange {
  background-image: url('/static/img/card-designs/profile-design-3-orange.svg');
}
.profile-design-3-yellow {
  background-image: url('/static/img/card-designs/profile-design-3-yellow.svg');
}
.profile-design-4-cyan {
  background-image: url('/static/img/card-designs/profile-design-4-cyan.svg');
}
.profile-design-4-magenta {
  background-image: url('/static/img/card-designs/profile-design-4-magenta.svg');
}
.profile-design-4-orange {
  background-image: url('/static/img/card-designs/profile-design-4-orange.svg');
}
.profile-design-4-yellow {
  background-image: url('/static/img/card-designs/profile-design-4-yellow.svg');
}
.profile-design-5-cyan {
  background-image: url('/static/img/card-designs/profile-design-5-cyan.svg');
}
.profile-design-5-white {
  background-image: url('/static/img/card-designs/profile-design-5-white.svg');
}
.profile-design-5-magenta {
  background-image: url('/static/img/card-designs/profile-design-5-magenta.svg');
}
.profile-design-5-orange {
  background-image: url('/static/img/card-designs/profile-design-5-orange.svg');
}
.profile-design-5-yellow {
  background-image: url('/static/img/card-designs/profile-design-5-yellow.svg');
}
.profile-design-6-cyan {
  background-image: url('/static/img/card-designs/profile-design-6-cyan.svg');
}
.profile-design-6-white {
  background-image: url('/static/img/card-designs/profile-design-6-white.svg');
}
.profile-design-6-magenta {
  background-image: url('/static/img/card-designs/profile-design-6-magenta.svg');
}
.profile-design-6-orange {
  background-image: url('/static/img/card-designs/profile-design-6-orange.svg');
}
.profile-design-6-yellow {
  background-image: url('/static/img/card-designs/profile-design-6-yellow.svg');
}
.profile-design-6-multi {
  background-image: url('/static/img/card-designs/profile-design-6-multi.svg');
}
.profile-design-7-cyan {
  background-image: url('/static/img/card-designs/profile-design-7-cyan.svg');
}
.profile-design-7-white {
  background-image: url('/static/img/card-designs/profile-design-7-white.svg');
}
.profile-design-7-magenta {
  background-image: url('/static/img/card-designs/profile-design-7-magenta.svg');
}
.profile-design-7-orange {
  background-image: url('/static/img/card-designs/profile-design-7-orange.svg');
}
.profile-design-7-yellow {
  background-image: url('/static/img/card-designs/profile-design-7-yellow.svg');
}

.profile-image {
  width: 50%;
}
.avatar {
  width: 80%;
  margin: 10%;
  padding-top: 80%;
  position: relative;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  display: block;
  overflow: hidden;
}
.avatar img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;;
    left: 0;
    object-fit: cover;
}
.profile-text {
  width: 50%;
  height: 50%;
  text-align: center;
  padding: 5%;
  padding-top: 30% !important;
  overflow: hidden;
}
.link-row {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.link-row > div {
  flex: 1;
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
}
.link-row-top > div {
  flex: 0;
}
.link-row-bottom > div:nth-of-type(3) {
    flex: 0 0 30%;
    min-width: 30px;
    justify-content: flex-end;
}
.link-row-bottom > div:nth-of-type(4) {
    flex: 0 0 15%;
    min-width: 30px;
    justify-content: flex-end;
}
.profile-stat {
    display: flex;
    flex-direction: column;
    line-height: 0;
}
.profile-headline {
  font-style:italic;
  font-size: 14px;
  color: #333333;
}
.profile-card .profile-image .profile-status {
  color:gray;
}
.profile-card .mdc-subheading {
  margin:0;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.profile-card .mdc-body {
  margin:0;
  font-size: 12px;
  line-height: 1;
}
span[class^="days-"] {
  margin-top: 3px;
}
span[class^="days-"] .day {
  height: 2px;
  background: #E0E0E0;
  display: inline-block;
  width: 13%;
  margin: 0 0.1%;
}

span.days-1 .day:nth-of-type(-n+1) {
  background: #AD1457;
}
span.days-2 .day:nth-of-type(-n+2) {
  background: #AD1457;
}
span.days-3 .day:nth-of-type(-n+3) {
  background: #AD1457;
}
span.days-4 .day:nth-of-type(-n+4) {
  background: #AD1457;
}
span.days-5 .day:nth-of-type(-n+5) {
  background: #AD1457;
}
.profile-status-bar {
  display:flex;
  width:100%
}
.profile-details {
  padding: 5% 2% 0 2%;
  font-family: 'Roboto', sans-serif;
  background: none;
  box-shadow: none;
}
.profile-details .duome-icon img {
  width:20px;
  height: 20px;
}
.profile-details .value {
    display: block;
    line-height: 1;
    color: #333333;
    min-height: 9px;
}
.profile-details .field {
  font-style: italic;
  margin-top: 3px;
}
.location {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.location .profile-stat .value {
  font-style:italic;
  font-size: 14px;
}
.role {
  font-weight: 500;
  font-style:italic;
  font-size: 14px;
}
.mdc-icon {
  margin-right: 5px;
}

.profile-details .mdc-button {
  color: black;
}
.profile-details .mdc-button img {
  margin-right:6px;
}
.profile-details .mdc-button img.icon-active {
  display:none;
}
.profile-card:not(:hover) .link-row-bottom .mdc-button {
  background-color: #ffffff;
  box-shadow: none;
  color: #333333;
}
.profile-card .link-row-bottom .mdc-button .mdc-icon {
  margin: 0 10px 5px 0;
}
.profile-details:hover .profile-card {
  background-color:#f6f6f6;
}
.profile-details:hover .mdc-button {
  background-color: rgb(45, 156, 219);
  color:white;
}
.profile-details:hover .mdc-button img.icon-active{
  display:block;
}
.profile-details:hover .mdc-button img.icon{
  display:none;
}
.about > div {
  font-size: 14px;
  margin: 10px 0;
}
.bookmark {
  display:none;
  cursor: pointer;
}
.bookmark .icon {
  display: block;
}
.bookmark .icon-active {
  display:none;
}
.active .bookmark .icon-active {
  display: block;
}
.active .bookmark .icon {
  display: none;
}
</style>
