import fb from './firebase'

const connectionsRef = fb.firestore.collection('connections')
let connectMessagesListener

const resetConnectMessageListener = () => {
  if (connectMessagesListener) { connectMessagesListener() }
}

const stripButtons = (botMessage) => {
  if (botMessage.buttons) {
    for (const btn of botMessage.buttons) {
      if (btn.match) {
        botMessage.text = botMessage.text.replace(btn.match, '')
      }
    }
  }
  return botMessage
}

const actions = {
  updateConnectionMessage ({ commit }, { messageId, message }) {
    const connectionId = message.connectionId
    const messageRef = connectionsRef
      .doc(connectionId)
      .collection('messages')
      .doc(messageId)
    return messageRef.update(message)
  },
  addConnectionMessage ({ commit }, { message }) {
    message.created = fb.serverTime()
    const connectionId = message.connectionId
    const lastSender = message.fromId
    const messageCopy = stripButtons({ ...message })
    const lastMessage =
      (messageCopy.text.length > 150)
        ? messageCopy.text.substr(0, 150)
        : messageCopy.text
    const connection = {
      lastMessage,
      lastUpdate: fb.serverTime(),
      lastSender
    }
    connectionsRef.doc(connectionId).get().then(doc => {
      const con = doc.data()
      connection.unread = con.unread
      if (!connection.unread) {
        connection.unread = {}
        Object.keys(con.users).forEach(function (key) {
          connection.unread[key] = 0
        })
      }
      Object.keys(connection.unread).forEach(function (key) {
        if (key !== lastSender) {
          connection.unread[key] += 1
        } else {
          connection.unread[lastSender] = 0
        }
      })
      doc.ref.update(connection)
    })
    const messagesRef = connectionsRef
      .doc(connectionId)
      .collection('messages')
    return messagesRef.add(message)
  },
  loadConnectionMessages ({ commit }, connectionId) {
    if (connectMessagesListener) { connectMessagesListener() }
    connectMessagesListener = connectionsRef
      .doc(connectionId)
      .collection('messages')
      .orderBy('created', 'asc')
      .onSnapshot(querySnapshot => {
        const results = []
        querySnapshot.forEach((doc) => {
          if (doc.id !== this.state.user.uid) {
            results.push({ id: doc.id, ...doc.data() })
          }
        })
        commit('setMessages', results)
      }, error => {
        console.log(error)
      })
  }
}

export default { actions, resetConnectMessageListener }
