<template>
  <div class="main-content">
    <mdc-textfield fullwidth v-model="talentQuery"/>
    <mdc-button raised @click="complete">search</mdc-button>
    <ul class="mdc-list">
      <li class="mdc-list-item" v-for="result in completionResults" :key="result.suggestion">
        <span class="mdc-list-item__primary-text" v-html="result.suggestion"></span>
      </li>
    </ul>
  </div>
</template>

<script>
// import fb from '../store/firebase'
import * as backend from '../utils/backend'
export default {
  data () {
    return {
      talentQuery: '',
      completionResults: []
    }
  },
  methods: {
    async complete () {
      this.completionResults = []
      const res = await backend.search('talent-complete', {
        query: {
          query: `${this.talentQuery}`,
          numResults: 30
        }
      })
      if (res.error) {
        console.log(res.error)
        return
      }
      console.log(this.talentQuery)
      console.log(res)
      this.completionResults = res.result
    }
  }
}
</script>
