<template>
  <div>
    <div class="highlight-padding mobileonly"></div>
    <div v-if="!asSection" class="highlight-row desktoponly">
    <div class="highlight-wrapper">
        <div class="highlight">
        <router-link class="highlight-link" to="/my-profile">
          <img class="highlight-img" style="min-height:100px;min-width:100px" src="/static/img/highlights/resume.svg"/>
        </router-link>
        <span class="highlight-link-wrapper desktoponly">
            <router-link class="highlight-link" to="/my-profile"><b>Complete your profile</b></router-link>
            to improve your chance of success
        </span>
        <span class="highlight-link-wrapper mobileonly">
            <router-link class="highlight-link" to="/my-profile"><b>Update your Profile</b></router-link>
        </span>
        </div>
    </div>
    <div class="highlight-wrapper">
        <div class="highlight">
        <router-link class="highlight-link" to="/learn">
          <img class="highlight-img" style="min-height:100px;min-width:100px" src="/static/img/highlights/webinar.svg"/>
        </router-link>
        <span class="highlight-link-wrapper desktoponly">
            <router-link class="highlight-link" to="/learn"><b>Learn more</b></router-link>
            about your Flexible Working options
        </span>
        <span class="highlight-link-wrapper mobileonly">
            <router-link class="highlight-link" to="/learn"><b>Learn about Flexi Work</b></router-link>
        </span>
        </div>
    </div>
    <div class="highlight-wrapper">
        <div class="highlight">
        <router-link class="highlight-link" to="/connect">
          <img class="highlight-img" style="min-height:100px;min-width:100px" src="/static/img/highlights/community.svg"/>
        </router-link>
        <span class="highlight-link-wrapper desktoponly">
            <router-link class="highlight-link" to="/connect"><b>Connect with others</b></router-link>
            looking for Job Share partners
        </span>
        <span class="highlight-link-wrapper mobileonly">
            <router-link class="highlight-link" to="/connect"><b>Connect to Partners</b></router-link>
        </span>
        </div>
    </div>
    </div>

    <div v-if="!asSection" class="highlight-row mobileonly">
      <carousel :per-page="1" mouse-drag
          navigationEnabled
          navigationPrevLabel="<i data-mdc-ripple-is-unbounded class='material-icons mdc-ripple-surface demo-surface'>chevron_left</i>"
          navigationNextLabel="<i data-mdc-ripple-is-unbounded class='material-icons mdc-ripple-surface demo-surface'>chevron_right</i>"
          autoplay autoplayHoverPause loop
          :speed="500"
          :autoplayTimeout="10000">
        <slide>
          <div class="highlight-wrapper">
            <div class="highlight">
              <router-link class="highlight-link" to="/my-profile">
                <img class="highlight-img" src="/static/img/highlights/resume.svg"/>
              </router-link>
              <span class="highlight-link-wrapper">
                <router-link class="highlight-link" to="/my-profile"><b>Update your Profile</b></router-link>
                <mdc-body typo="body2">Improve your chance of success</mdc-body>
              </span>
            </div>
          </div>
        </slide>

        <slide>
          <div class="highlight-wrapper">
            <div class="highlight">
              <router-link class="highlight-link" to="/learn">
                <img class="highlight-img" src="/static/img/highlights/webinar.svg"/>
              </router-link>
              <span class="highlight-link-wrapper">
                <router-link class="highlight-link" to="/learn"><b>Learn More</b></router-link>
                <mdc-body typo="body2">Understand your Flexible Working options</mdc-body>
              </span>
            </div>
          </div>
        </slide>

        <slide>
          <div class="highlight-wrapper">
            <div class="highlight">
              <router-link class="highlight-link" to="/connect">
                <img class="highlight-img" src="/static/img/highlights/community.svg"/>
              </router-link>
              <span class="highlight-link-wrapper">
                <router-link class="highlight-link" to="/connect"><b>Message Others</b></router-link>
                <mdc-body typo="body2">Connect with potenital Job Share partners</mdc-body>
              </span>
            </div>
          </div>
        </slide>

      </carousel>
    </div>

    <div v-else class="highlight-row">
      <app-section class="highlight-section"
      :editable="false"
      title="How DuoMe Helps"
      >
        <carousel :per-page="1" mouse-drag
          navigationEnabled
          navigationPrevLabel="<i data-mdc-ripple-is-unbounded class='material-icons mdc-ripple-surface demo-surface'>chevron_left</i>"
          navigationNextLabel="<i data-mdc-ripple-is-unbounded class='material-icons mdc-ripple-surface demo-surface'>chevron_right</i>"
          autoplay autoplayHoverPause loop
          :speed="500"
          :autoplayTimeout="10000">
          <slide>
            <div class="highlight-wrapper">
              <div class="highlight">
                <router-link class="highlight-link" to="/my-profile">
                  <img class="highlight-img" src="/static/img/highlights/resume.svg"/>
                </router-link>
                <span class="highlight-link-wrapper">
                  <router-link class="highlight-link" to="/my-profile"><b>Update your Profile</b></router-link>
                  <mdc-body typo="body2">Complete your profile to improve your chance of success</mdc-body>
                </span>
              </div>
            </div>
          </slide>

          <slide>
            <div class="highlight-wrapper">
              <div class="highlight">
                <router-link class="highlight-link" to="/my-profile">
                  <img class="highlight-img" src="/static/img/highlights/webinar.svg"/>
                </router-link>
                <span class="highlight-link-wrapper">
                  <router-link class="highlight-link" to="/my-profile"><b>Learn More</b></router-link>
                  <mdc-body typo="body2">Understand your Flexible Working options</mdc-body>
                </span>
              </div>
            </div>
          </slide>

          <slide>
            <div class="highlight-wrapper">
              <div class="highlight">
                <router-link class="highlight-link" to="/my-profile">
                  <img class="highlight-img" src="/static/img/highlights/community.svg"/>
                </router-link>
                <span class="highlight-link-wrapper">
                  <router-link class="highlight-link" to="/my-profile"><b>Connect to Others</b></router-link>
                  <mdc-body typo="body2">Connect with Job Share partners looking for flexible working</mdc-body>
                </span>
              </div>
            </div>
          </slide>

        </carousel>
      </app-section>
    </div>

  </div>
</template>

<script>
import AppSection from '../../components/profile-v2/AppSection.vue'

export default {
  name: 'HighlightSection',
  components: {
    AppSection
  },
  props: {
    asSection: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.highlight-img {
  height:100px;
  width:100px;
}
.highlight-row {
  display:flex;
  margin-top:10px;
}
.highlight-row.desktoponly {
  margin-left:var(--mdc-layout-grid-margin-desktop, 24px);
  margin-right:var(--mdc-layout-grid-margin-desktop, 24px);
}
.highlight-row.mobileonly {
  margin-left:var(--mdc-layout-grid-margin-tablet, 16px);
  margin-right:var(--mdc-layout-grid-margin-tablet, 16px);
}
.highlight-wrapper {
  width:33%;
}
.VueCarousel .highlight-wrapper {
  width:100%;
}
.highlight {
  display:flex;
  flex-flow:row wrap;
  align-items:center;
}
.highlight img {
  flex:1;
}
.highlight-link-wrapper {
  padding:6px;
  flex:1;
  text-align:left;
  padding-left:12px;
}
.highlight-padding.mobileonly {
  margin-top:26px;
}
.highlight-link {
  color:inherit;
  text-decoration:none;
}
.highlight-link-wrapper p {
  margin-bottom:0;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54))
}
@media (max-width: 480px) {
  .highlight-row.mobileonly {
    margin-left:0;
    margin-right:0;
  }
}
</style>

<style>
.highlight-row .VueCarousel {
  width:100%;
}
.highlight-row .VueCarousel .highlight .highlight-img {
  margin-left:18px;
}
.highlight-row .VueCarousel .highlight  .highlight-link-wrapper {
  margin-right: 16px;
  margin-left: 18px;
}

@media (min-width: 480px) {
  .highlight-row.mobileonly {
    margin-left:0;
    margin-right:0;
  }
  .highlight-row .VueCarousel .highlight .highlight-img {
    margin-left:26px;
  }
  .highlight-row .VueCarousel .highlight  .highlight-link-wrapper {
    margin-left: 26px;
  }
}

.highlight-row .VueCarousel .highlight .highlight-link-wrapper  p {
  font-size: 12px;
}

.highlight-row .VueCarousel-navigation-button:focus {
  outline: none !important;
}
.highlight-row .VueCarousel-navigation-prev {
  margin:0;
  left:40px !important;
  margin-top:-15px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.highlight-row .VueCarousel-navigation-next {
  margin:0;
  right:40px !important;
  margin-top:-15px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.highlight-row .VueCarousel-navigation-button:focus{
  outline: none !important;
}
.highlight-row .VueCarousel-dot-container {
  margin-top:0 !important;
}
.highlight-row .VueCarousel-dot-container .VueCarousel-dot {
  margin-top:0 !important;
  outline: none !important;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.highlight-row .mdc-ripple-surface::before, .mdc-ripple-surface::after {
    top: calc(50% - 100%);
    left: calc(50% - 100%);
    width: 200%;
    height: 200%;
}
.highlight-row .mdc-ripple-surface[data-mdc-ripple-is-unbounded]::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded]::after {
    top: calc(50% - 50%);
    left: calc(50% - 50%);
    width: 100%;
    height: 100%;
}

.highlight-row .mdc-ripple-surface::before, .mdc-ripple-surface::after {
    background-color: #000;
}
.highlight-row .mdc-ripple-surface::before {
    transition: opacity 15ms linear;
    z-index: 1;
}
.highlight-row .mdc-ripple-surface::before, .mdc-ripple-surface::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: "";
}
.highlight-row .VueCarousel-dot::before, .highlight-row .VueCarousel-dot::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: "";
    background-color: #000;
    top: calc(50% - 50%);
    left: calc(50% - 50%);
    width: 100%;
    height: 100%;
}
.highlight-row .VueCarousel-dot:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: .12;
}
.highlight-row .demo-surface {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 100px;
    padding: 1rem;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
}
.highlight-row .demo-surface[data-mdc-ripple-is-unbounded] {
    width: 22px;
    height: 22px;
    padding: 10px;
}
.highlight-row .mdc-ripple-surface[data-mdc-ripple-is-unbounded], .highlight-row .VueCarousel-dot {
    overflow: visible;
}
</style>
