<template>
  <div class="bottom-bar-wrapper" :class="{'fixed':!relative, 'no-bottom-nav': inputFocus}">
    <div class="bottom-bar">
        <div class="bottom-message-bar message-bar" @click="checkDisabled">
        <mdc-layout-grid >
            <mdc-layout-cell span="12">
            <div class="flexrow">
                <div style="padding-left:6px;background-color: #F5F5F5;" class="left messagebar_field mdc-text-field mdc-text-field--upgraded mdc-text-field--dense mdc-text-field--box">
                <textarea-autosize
                style="width:100%; margin-top: 4px;margin-bottom:0px; padding-left:4px;margin-left:0"
                :disabled="disabled"
                @focus.native="setFocus(true)"
                @blur.native="setFocus(false)"
                @keydown.native.enter.exact.capture.prevent.stop="sendMessage"
                class="mdc-text-field__input mobile-only"
                placeholder="Type here..."
                ref="messageInput"
                v-model="newMessage"
                rows="1"
                :minHeight="20"
                :maxHeight="90"
                ></textarea-autosize>
                <textarea-autosize
                :disabled="disabled"
                @focus.native="setFocus(true)"
                @blur.native="setFocus(false)"
                style="width:100%; margin-top: 4px;margin-bottom:0px; padding-left:4px;margin-left:0"
                @keydown.native.enter.exact.capture.prevent.stop="sendMessage"
                class="mdc-text-field__input desktop-only"
                placeholder="Type here...  (shift-enter for new line)"
                ref="messageInput"
                v-model="newMessage"
                rows="1"
                :minHeight="20"
                :maxHeight="90"
                ></textarea-autosize>
                </div>

                <span class="after-wrapper desktop-only" style="margin-left:6px;">
                  <span
                    v-for="(item, index) in afterFiltered"
                    :key="index">
                    <mdc-icon v-if="item.icon" :icon="item.icon" />
                    <mdc-select @change="$emit('filter' + item.filter.label, $event)" v-else-if="item.filter" :label="item.filter.label">
                      <select-item v-for="(opt, index) in item.filter.options" :key="index" :value="opt" />
                    </mdc-select>
                    <span class="profile" v-else-if="item.profile">
                      <img style="margin-right:0" v-if="hasPhoto(item.profile)" class="avatar" :src="item.profile.photoURL">
                      <img style="margin-right:0" v-else class="avatar" src="/static/img/user.jpg">
                      <mdc-chip-set v-if="item.profile.status">
                        <mdc-chip class="status">{{item.profile.status}}</mdc-chip>
                      </mdc-chip-set>
                    </span>
                    <component
                    v-else-if="item.component"
                    v-bind:is="item.component"
                    v-bind="item.props"
                    >
                    </component>
                  </span>
                </span>

                <div class="right" :class="{ 'desktop-only': !oneline }">
                <mdc-button :disabled="disabled" @click="sendMessage"><mdc-icon icon="send"></mdc-icon></mdc-button>
                </div>
            </div>
              <div v-if="!oneline" style="margin-top:4px;" class="flexrow mobile-only">
                <span class="after-wrapper" style="margin-right:auto;margin-left:0;">
                  <span
                    v-for="(item, index) in afterFiltered"
                    :key="index">
                    <mdc-icon v-if="item.icon" :icon="item.icon" />
                    <mdc-select @change="$emit('filter' + item.filter.label, $event)" v-else-if="item.filter" :label="item.filter.label">
                      <select-item v-for="(opt, index) in item.filter.options" :key="index" :value="opt" />
                    </mdc-select>
                    <span class="profile" v-else-if="item.profile">
                      <img v-if="hasPhoto(item.profile)" class="avatar" :src="item.profile.photoURL">
                      <img v-else class="avatar" src="/static/img/user.jpg">
                      <mdc-chip-set>
                        <mdc-chip v-if="item.profile.status" class="status">{{item.profile.status}}</mdc-chip>
                      </mdc-chip-set>
                    </span>
                    <component
                    v-else-if="item.component"
                    v-bind:is="item.component"
                    v-bind="item.props"
                    >
                    </component>
                  </span>
                </span>

                <div class="right">
                <mdc-button :disabled="disabled" @click="sendMessage"><mdc-icon icon="send"></mdc-icon></mdc-button>
                </div>
            </div>
            </mdc-layout-cell>
        </mdc-layout-grid>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageBar',
  components: {
  },
  props: {
    connectionId: {},
    profile: {},
    disabled: { type: Boolean },
    disabledClick: {},
    after: Array,
    relative: {
      type: Boolean,
      default: false
    },
    oneline: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      newMessage: '',
      inputFocus: false
    }
  },
  computed: {
    afterFiltered () {
      if (this.after) {
        return this.after.filter(after => after)
      }
      return []
    }
  },
  methods: {
    setFocus (val) {
      this.inputFocus = val
      this.$emit('focus', val)
    },
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    },
    sendMessage () {
      const self = this
      setTimeout(() => {
        if (self.newMessage !== '') {
          self.$nextTick(() => {
            const message = {
              fromId: this.profile.id,
              text: this.newMessage
            }
            if (this.connectionId) {
              message.connectionId = this.connectionId
            }
            self.$emit('send', message)
            self.newMessage = ''
          })
        }
      }, 200)
    },
    checkDisabled () {
      if (this.disabled) {
        this.disabledClick()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.message-bar .messagebar_field {
  border:none;
  height:auto !important;
  margin:0;
}

.messagebar_field textarea.mdc-text-field__input {
    background-color: #F5F5F5;
}

.messagebar_field textarea.mdc-text-field__input::placeholder {
  opacity: 1 !important;
  color: #979797;
  font-size: .813rem;
  line-height: 20px;
}

.after-wrapper {
  margin-left:6px;
}

.bottom-bar-wrapper {
  position:absolute;
  width:100%;
  min-height:45px;
  height:auto;
  bottom:0;
  left:0;
}

.bottom-bar-wrapper.fixed {
  position:fixed;
  width: calc(100% - 294px);
  margin-left: 288px;
  padding-left: 0;
}

.bottom-bar {
  margin:6px;
  box-sizing: border-box;
  margin: 0;
  width:100%;
  max-width:1200px;
  margin-left:auto;
  margin-right:auto;
  padding-right:32px;
  z-index: 4;
}
.bottom-message-bar .mdc-textfield {
    margin: 0;
}

.mobile-only {
  display:none !important;
}

/* Removes width of grid padding, wrapper padding and collapsed sidebar */
@media (min-width: 571px) and (max-width: 840px) {
  .bottom-bar-wrapper, .bottom-bar-wrapper.fixed {
    margin-left: 0;
    padding-left: calc(24px + var(--mdc-layout-grid-margin-phone, 16px));
    width: calc(100% - 16px - 2*(var(--mdc-layout-grid-margin-phone, 16px)));
  }
  .mobile-only {
    display:flex !important;
  }
  .desktop-only {
    display:none !important;
  }
}

@media all and (max-width: 570px) {
  .bottom-bar-wrapper, .bottom-bar-wrapper.fixed {
    margin-left: 0;
    width: calc(100% - 2px);
    padding-left: 1px;
    bottom: 0px;
  }
  .bottom-bar-wrapper.no-bottom-nav {
    bottom: 0px;
  }
  .bottom-bar {
    padding-right:0px;
  }
  .mobile-only {
    display:flex !important;
  }
  .desktop-only {
    display:none !important;
  }
}

.bottom-message-bar {
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  background-color:#FCFCFC;
  width:100%;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bottom-message-bar .mdc-button {
  min-width: 37px;
  width: 37px;
  height: 30px;
}
.bottom-bar .mdc-layout-grid {
  padding: 4px 12px;
}
.bottom-bar .mdc-textfield {
  height:48px;
  overflow: hidden;
  width:100%;
}
.bottom-bar .mdc-icon.mdc-icon--material.material-icons {
  width: 24px;
  height: 24px;
  margin-right: 0;
}

.flexrow {
  display:flex;
  flex-flow: row;
  align-items: center !important;
}
.flexrow .left{
  flex-grow: 1;
  min-width: 0;
  align-items: center;
}
.flexrow .right{
  min-width: 0;
  flex: none;
  align-items: center;
}
.flexrow .left > div {
  width: 100%;
  margin: 0;
  height: 30px;
}
.bottom-bar .mdc-textfield-wrapper.inline .mdc-text-field {
  margin: 0;
}
</style>
<style>
.mdc-textfield-wrapper.inline {
  width:100%;
}
.mdc-textfield-wrapper.inline .mdc-text-field {
  min-height:30px !important;
  height:auto;
  margin-top:0;
  margin-bottom: 0;
}
.mdc-textfield-wrapper.inline .mdc-text-field .mdc-text-field__input {
  padding:0;
}
.mdc-dialog__content .bottom-bar-wrapper {
    margin-left: 0;
    padding-left: 0 !important;
    width: 100% !important;
}
.mdc-dialog__content .message-bar .messagebar_field {
  padding: 0 !important;
  margin-top: 4px !important;
}
</style>
