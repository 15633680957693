<template>
  <div class="update-input">

    <div v-if="groupMonth">
      <template v-for="(updatesGroup, groupIndex) in updatesGrouped">

        <div v-if="updatesGroup.current" :key="groupIndex">
          <div v-for="(updateDay, dayIndex) in updatesGroup.updates" :key="dayIndex">

            <div v-if="updateDay[0].old">
              <div v-if="updateDay[0].show" @click="updateDay[0].show = false" class="blank-group-title">
                  {{blankGroups[updateDay[0].date].title}}
                  <mdc-icon class="update-icon" outlined icon="indeterminate_check_box"/>
              </div>
              <div v-else @click="updateDay[0].show = true" class="blank-group-title">
                  {{blankGroups[updateDay[0].date].title}}
                  <mdc-icon class="update-icon" outlined icon="add_box"/>
              </div>
            </div>
            <div v-if="blankGroups[updateDay[0].date]">
              <div v-if="isBlankGroupShowing(updateDay[0].date, 'current.blank-group-title')" @click="hideBlankGroup(updateDay[0].date)" class="blank-group-title">
                  {{blankGroups[updateDay[0].date].title}}
                  <mdc-icon class="update-icon" outlined icon="indeterminate_check_box"/>
              </div>
              <div v-else @click="showBlankGroup(updateDay[0].date)" class="blank-group-title">
                  {{blankGroups[updateDay[0].date].title}}
                  <mdc-icon class="update-icon" outlined icon="add_box"/>
              </div>
            </div>

            <div v-for="(update, index) in updateDay" :class="{'update-blank' : (updateDay.old && !updateDay.show) || (groupBlank && update.date <= twoDaysAgo && update.message === '' && !isBlankGroupShowing(update.date))}" :key="index">
              <div>
                <div class="dateline" v-if="showDateLines && index === 0">
                  <hr>
                  <span v-if="update.date">{{getDate(update.date) | dateDisplayFormat}}</span>
                </div>
              </div>

              <div class="update">
                <div class="update-text" @click="selectedUpdate(update, update.id, dayIndex, index + 1)" :class="{'has-link': hasLink, 'active': selected === update}">
                  <mdc-icon :class="{'small': update.type === 'note'}" outlined @click.native="openTypeMenu(update, $event)" class="update-icon" :icon="icons[update.type]" />
                  <div class="new-update-row" style="position:relative" v-if="target && selected === update">
                    <div class="mdc-menu-surface--anchor" style="position:absolute;margin-top: 6px;">
                    <mdc-menu class="type-select-menu" style="width:auto;position:absolute" v-model="newTypeOpen" @selected="onTypeSelect(selected, $event)">
                      <template v-for="(icon, key) in icons" >
                        <mdc-menu-item v-if="key !== '' && info[key] !== '' && !(disableNotes && key === 'note')" :key="key">
                          <mdc-icon :class="{'small': key === 'note'}" outlined :icon="icons[key]"/>
                          <mdc-body typo="body2">
                            <div>{{key}} {{shortcuts[key]}}</div>
                            <div class="sub-label" typo="body2">{{info[key]}}</div>
                          </mdc-body>
                        </mdc-menu-item>
                      </template>
                    </mdc-menu>
                    </div>
                  </div>
                  <tiptap-editor :ref="'editors' + dayIndex" emitEnter @editorEnter="addUpdate" style="flex:1" :customCommands="editorCommands" :hashtags="initiatives" :mentionSuggestions="teamMentions" @onCommand="onCommand" @onTextUpdate="onUpdateUpdate" :content="update.message" :title="'Description'" :contentKey="'idx-' + index">
                  </tiptap-editor>
                  <mdc-icon @click.native="openUpdateMenu(update)" class="update-icon more-icon" icon="more_horiz"/>
                  <div class="mdc-menu-surface--anchor" style="position: absolute; right: 200px;margin-top:10px">
                  <mdc-menu v-if="selected === update" class="type-select-menu" style="width:auto;" v-model="moreOpen" @selected="onDelete(selected.id, index)">
                    <mdc-menu-item>
                        <mdc-body typo="body2">
                          <div>Delete</div>
                          <div class="sub-label" typo="body2">Update permanently removed</div>
                        </mdc-body>
                    </mdc-menu-item>
                  </mdc-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else :key="groupIndex">

            <div>
              <div v-if="updatesGroup.show" @click="updatesGroup.show = !updatesGroup.show" class="group-month">
                  <div class="group-text">{{ updatesGroup.title }}</div>
                  <mdc-icon class="update-icon" outlined icon="indeterminate_check_box"/>
              </div>
              <div v-else @click="updatesGroup.show = !updatesGroup.show"  class="group-month">
                  <div class="group-text">{{ updatesGroup.title }}</div>
                  <mdc-icon class="update-icon" outlined icon="add_box"/>
              </div>
            </div>

          <div v-show="updatesGroup.show">
            <div v-for="(updateDay, dayIndex) in updatesGroup.updates" :key="dayIndex">

              <div class="blank-group-title" v-if="blankGroups[updateDay[0].date]">
                {{blankGroups[updateDay[0].date].title}}
                <mdc-icon v-if="isBlankGroupShowing(updateDay[0].date)" @click.native="hideBlankGroup(updateDay[0].date)" class="update-icon" outlined icon="indeterminate_check_box"/>
                <mdc-icon v-else @click.native="showBlankGroup(updateDay[0].date)" class="update-icon" outlined icon="add_box"/>
              </div>

              <div v-for="(update, index) in updateDay" :key="index" :class="{'update-blank' : groupBlank && update.date <= twoDaysAgo && update.message === '' && !isBlankGroupShowing(update.date)}">

                <div class="dateline" v-if="showDateLines && index === 0">
                  <hr>
                  <span v-if="update.date">{{getDate(update.date) | dateDisplayFormat}}</span>
                </div>

                <div class="update">
                  <div class="update-text" @click="selectedUpdate(update, update.id, dayIndex, index + 1)" :class="{'has-link': hasLink, 'active': selected === update}">
                    <mdc-icon :class="{'small': update.type === 'note'}" outlined @click.native="openTypeMenu(update, $event)" class="update-icon" :icon="icons[update.type]" />
                    <div class="new-update-row" style="position:relative" v-if="target && selected === update">
                    <div class="mdc-menu-surface--anchor" style="position:absolute;margin-top: 6px;">
                      <mdc-menu class="type-select-menu" style="width:auto;position:absolute" v-model="newTypeOpen" @selected="onTypeSelect(selected, $event)">
                        <template v-for="(icon, key) in icons" >
                          <mdc-menu-item v-if="key !== '' && info[key] !== '' && !(disableNotes && key === 'note')" :key="key">
                            <mdc-icon :class="{'small': key === 'note'}" outlined :icon="icons[key]"/>
                            <mdc-body typo="body2">
                              <div>{{key}} {{shortcuts[key]}}</div>
                              <div class="sub-label" typo="body2">{{info[key]}}</div>
                            </mdc-body>
                          </mdc-menu-item>
                        </template>
                      </mdc-menu>
                      </div>
                    </div>
                    <tiptap-editor :ref="'editors' + dayIndex" emitEnter @editorEnter="addUpdate" style="flex:1" :customCommands="editorCommands" :hashtags="initiatives" :mentionSuggestions="teamMentions" @onCommand="onCommand" @onTextUpdate="onUpdateUpdate" :content="update.message" :title="'Description'" :contentKey="'idx-' + index">
                    </tiptap-editor>
                    <mdc-icon @click.native="openUpdateMenu(update)" class="update-icon more-icon" icon="more_horiz"/>
                    <div class="mdc-menu-surface--anchor" style="position: absolute; right: 200px;margin-top:10px">
                    <mdc-menu v-if="selected === update" class="type-select-menu" style="width:auto;" v-model="moreOpen" @selected="onDelete(selected.id, index)">
                      <mdc-menu-item>
                          <mdc-body typo="body2">
                            <div>Delete</div>
                            <div class="sub-label" typo="body2">Update permanently removed</div>
                          </mdc-body>
                      </mdc-menu-item>
                    </mdc-menu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </template>
    </div>
    <div v-else>
      <div v-for="(updateDay, dayIndex) in updates" :key="dayIndex">
        <div v-for="(update, index) in updateDay" :key="index">

          <div class="dateline" v-if="showDateLines && index === 0">
            <hr>
            <span v-if="update.date">{{getDate(update.date) | dateDisplayFormat}}</span>
          </div>

          <div class="update">
            <div class="update-text" @click="selectedUpdate(update, update.id, dayIndex, index + 1)" :class="{'has-link': hasLink, 'active': selected === update}">
              <mdc-icon :class="{'small': update.type === 'note'}" outlined @click.native="openTypeMenu(update, $event)" class="update-icon" :icon="icons[update.type]" />
              <div class="new-update-row" style="position:relative" v-if="target && selected === update">
                <div class="mdc-menu-surface--anchor" style="position:absolute;margin-top: 6px;">
                <mdc-menu class="type-select-menu" style="width:auto;position:absolute" v-model="newTypeOpen" @selected="onTypeSelect(selected, $event)">
                  <template v-for="(icon, key) in icons" >
                    <mdc-menu-item v-if="key !== '' && info[key] !== '' && !(disableNotes && key === 'note')" :key="key">
                      <mdc-icon :class="{'small': key === 'note'}" outlined :icon="icons[key]"/>
                      <mdc-body typo="body2">
                        <div>{{key}} {{shortcuts[key]}}</div>
                        <div class="sub-label" typo="body2">{{info[key]}}</div>
                      </mdc-body>
                    </mdc-menu-item>
                  </template>
                </mdc-menu>
                </div>
              </div>
              <tiptap-editor :ref="'editors' + dayIndex" emitEnter @editorEnter="addUpdate" style="flex:1" :customCommands="editorCommands" :hashtags="initiatives" :mentionSuggestions="teamMentions" @onCommand="onCommand" @onTextUpdate="onUpdateUpdate" :content="update.message" :title="'Description'" :contentKey="'idx-' + index">
              </tiptap-editor>
              <mdc-icon @click.native="openUpdateMenu(update)" class="update-icon more-icon" icon="more_horiz"/>
              <div class="mdc-menu-surface--anchor" style="position: absolute; right: 200px;margin-top:10px">
              <mdc-menu v-if="selected === update" class="type-select-menu" style="width:auto;" v-model="moreOpen" @selected="onDelete(selected.id, index)">
                <mdc-menu-item>
                    <mdc-body typo="body2">
                      <div>Delete</div>
                      <div class="sub-label" typo="body2">Update permanently removed</div>
                    </mdc-body>
                </mdc-menu-item>
              </mdc-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="copyNotes && notes">
      <hr style="color:#999">
      <div v-if="notesexpanded">
        <div style="display:flex;align-items:center;cursor:pointer;color:#999" @click="notesexpanded = false">
          <mdc-icon icon="indeterminate_check_box" outlined style="margin:0;margin-right:12px;margin-left:22px;"  title="Hide notes" />
          <mdc-body style="margin:0;">Add from Notes</mdc-body>
        </div>
        <div v-for="(update, index) in notes" :key="index">
          <div class="update">
            <div style="color:#999" class="update-text" @click="selectedUpdate(update, update.id, 'notes', index + 1)" :class="{'has-link': hasLink, 'active': selected === update}">
              <mdc-icon :class="{'small': update.type === 'note'}" outlined @click.native="openTypeMenu(update, $event, index + 1)" class="update-icon" :icon="icons[update.type]" />
              <div class="new-update-row" style="position:relative" v-if="target && selected === update">
                <div class="mdc-menu-surface--anchor" style="position:absolute;margin-top: 6px;">
                <mdc-menu class="type-select-menu" style="width:auto;position:absolute" v-model="newTypeOpen" @selected="onTypeSelect(selected, $event, index + 1)">
                  <template v-for="(icon, key) in icons" >
                    <mdc-menu-item v-if="key !== '' && info[key] !== '' && !(disableNotes && key === 'note')" :key="key">
                      <mdc-icon :class="{'small': key === 'note'}" outlined :icon="icons[key]"/>
                      <mdc-body typo="body2">
                        <div>{{key}} {{shortcuts[key]}}</div>
                        <div class="sub-label" typo="body2">{{info[key]}}</div>
                      </mdc-body>
                    </mdc-menu-item>
                  </template>
                </mdc-menu>
                </div>
              </div>
              <tiptap-editor :ref="'editorsnotes'" emitEnter @editorEnter="addUpdate" style="flex:1" :customCommands="editorCommands" :hashtags="initiatives" :mentionSuggestions="teamMentions" @onCommand="onCommand" @onTextUpdate="onUpdateUpdate" :content="update.message" :title="'Description'" :contentKey="'idx-' + index">
              </tiptap-editor>
              <mdc-icon @click.native="copyNote(update)" class="update-icon more-icon" icon="post_add"/>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div style="display:flex;align-items:center;cursor:pointer;color:#999" @click="notesexpanded = true" >
          <mdc-icon icon="add_box" outlined style="cursor:pointer;margin:0;margin-right:12px;margin-left:22px;"  title="Add from notes" />
          <mdc-body style="margin:0">Add from Notes</mdc-body>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import { mapState, mapActions } from 'vuex'
import { dateFormat, dateDisplayFormat, parseDateFormat, datesBetween } from '../utils/time'

export default {
  name: 'UpdateInput',
  props: {
    updateGroup: {},
    dateDesc: {
      type: Boolean,
      default: true
    },
    groupByDate: {
      type: Boolean,
      default: true
    },
    showDateLines: {
      type: Boolean,
      default: false
    },
    showBlankDates: {
      type: Boolean,
      default: false
    },
    autosave: {
      type: Boolean,
      default: false
    },
    checkId: {},
    disableNotes: {
      type: Boolean,
      default: false
    },
    groupBlank: {
      type: Boolean,
      default: false
    },
    groupMonth: {
      type: Boolean,
      default: false
    },
    copyNotes: {
      type: Boolean,
      default: false
    },
    defaultType: {},
    notes: {},
    group: {}
  },
  components: {
    TiptapEditor: () => import('../components/Tiptap')
  },
  data () {
    return {
      notesexpanded: false,
      target: {},
      updates: [],
      updatesGrouped: [],
      blankGroupShowing: {},
      blankGroups: {},
      moreOpen: false,
      hasLink: true,
      selected: null,
      selectedId: null,
      selectedIndex: null,
      newIcon: 'add',
      newType: '',
      newMessage: '',
      newUpdateValue: '',
      newTypeOpen: false,
      shortcuts: {
        note: '/n',
        update: '/u',
        fyi: '/f',
        broadcast: '/b',
        achievement: '/a'
      },
      editorCommands: [
        '/n',
        '/u ',
        '/f ',
        '/b ',
        '/a '
      ],
      icons: {
        '': 'add',
        note: 'lens',
        update: 'trending_flat',
        fyi: 'info',
        broadcast: 'record_voice_over',
        achievement: 'emoji_events'
      },
      info: {
        note: 'Capture private notes',
        update: 'Update the team or initiative',
        fyi: 'General info only for team',
        broadcast: 'Announcements across the org',
        achievement: 'Celebrate wins across the org'
      },
      days: [
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri'
      ],
      today: '',
      selectedDay: ''
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'initiatives',
      'teamProfiles'
    ]),
    teamMentions () {
      if (this.teamProfiles) {
        return this.teamProfiles.map((prof) => {
          return { ...prof, name: prof.displayName, initials: this.initials(prof) }
        })
      }
      return []
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    ...mapActions([
      'saveUpdate'
    ]),
    initialize () {
      const updateMap = {}
      const defaultType = this.defaultType || ''
      // updates ordered by date asc
      console.log('UpdateInput.init', this.groupByDate, this.showBlankDates)

      const now = new Date()
      let startDate = new Date()
      // console.log(this.updateGroup)
      if (this.updateGroup) {
        if (this.updateGroup[0]) {
          startDate = this.updateGroup[0].date.toDate()
        }
      }

      let updates = []
      if (this.groupByDate) {
        // map updates by date
        for (const update of this.updateGroup) {
          const fdate = dateFormat(update.date.toDate())
          // console.log('UpdateInput.mounted', update, fdate)
          if (updateMap[fdate]) {
            updateMap[fdate].push(update)
          } else {
            updateMap[fdate] = [update]
          }
        }
        // console.log('UpdateInput.updateMap', updateMap)

        // get all dates between start and now
        const dates = datesBetween(startDate, now).reverse()
        let fdates = dates.map(function (date) {
          return dateFormat(date)
        })
        if (!fdates.includes(dateFormat(now))) {
          fdates = [dateFormat(now), ...fdates]
        }
        if (!this.dateDesc) {
          fdates = fdates.reverse()
        }
        // console.log('UpdateInput.mounted.dates', this.dateDesc, startDate, now, fdates)
        updates = []

        for (const fdate of fdates) {
          const date = parseDateFormat(fdate)
          if (updateMap[fdate]) {
            const maplength = updateMap[fdate].length
            const highestorder = updateMap[fdate][maplength - 1].order
            // console.log('UpdateInput.init highestorder', updateMap[fdate][maplength], highestorder)
            if (this.showBlankDates) {
              updates.push([
                ...updateMap[fdate],
                {
                  fromId: this.profile.id,
                  message: '',
                  type: defaultType,
                  date: date,
                  order: highestorder + 1,
                  createdTimestamp: Date.now(),
                  created: date,
                  checkId: this.checkId ? this.checkId : false,
                  group: this.group,
                  changed: true
                }
              ])
            } else {
              updates.push([
                ...updateMap[fdate]
              ])
            }
          } else {
            // day with blank update
            if (this.showBlankDates) {
              updates.push([
                {
                  fromId: this.profile.id,
                  message: '',
                  type: defaultType,
                  date: date,
                  order: 0,
                  createdTimestamp: Date.now(),
                  created: date,
                  checkId: this.checkId ? this.checkId : false,
                  group: this.group,
                  changed: true
                }
              ])
            }
          }
        }

        if (!this.showBlankDates) {
          const date = new Date()
          updates.push([
            {
              fromId: this.profile.id,
              message: '',
              type: defaultType,
              date: date,
              order: 0,
              createdTimestamp: Date.now(),
              created: date,
              checkId: this.checkId ? this.checkId : false,
              group: this.group,
              changed: true
            }
          ])
        }
      } else {
        updates = [[...this.updateGroup]]
        const date = new Date()
        updates[0].push(
          {
            fromId: this.profile.id,
            message: '',
            type: defaultType,
            date: date,
            order: updates[0].length,
            createdTimestamp: Date.now(),
            created: date,
            checkId: this.checkId ? this.checkId : false,
            group: this.group,
            changed: true
          }
        )
        // console.log('NonDayGroup:', updates)
      }

      // updates order sort
      function compareOrder (a, b) {
        if (a.order < b.order) {
          return -1
        }
        return 1
      }

      for (const updateDay of updates) {
        updateDay.sort(compareOrder)
        // console.log('UpdateInput.Sorting', updateDay)
      }

      this.updates = updates
      // console.log('UpdateInput.updates', this.updates)

      this.blankGroups = {}
      this.twoDaysAgo = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
      this.twoDaysAgo.setDate(this.twoDaysAgo.getDate() - 2)
      if (this.groupBlank) {
        let currentBlank = {}
        for (const up of updates) {
          if (up[0]) {
            // console.log(up[0].date, this.twoDaysAgo, up[0].date >= this.twoDaysAgo)
            if (up[0].date <= this.twoDaysAgo) {
              if (up[0].message === '') {
                // console.log('UpdateInput.init groupBlank add to currentBlank', currentBlank, up)
                // message is blank update the current blank group
                if (!currentBlank.from) {
                  currentBlank.from = up[0].date
                } else {
                  currentBlank.to = up[0].date
                }
              } else {
                if (currentBlank.from && currentBlank.to) {
                  // console.log('UpdateInput.init groupBlank end of currentBlank', currentBlank, up)
                  const fromStr = dateDisplayFormat(currentBlank.from)
                  const toStr = dateDisplayFormat(currentBlank.to)
                  this.blankGroups[currentBlank.from] = { ...currentBlank, title: toStr + ' to ' + fromStr }
                }
                currentBlank = {}
              }
            }
          }
        }
        if (currentBlank.from && currentBlank.to) {
          // console.log('UpdateInput.init groupBlank end of currentBlank', currentBlank, up)
          const fromStr = dateDisplayFormat(currentBlank.from)
          const toStr = dateDisplayFormat(currentBlank.to)
          this.blankGroups[currentBlank.from] = { ...currentBlank, title: toStr + ' to ' + fromStr }
        }
      }
      this.groupUpdates()
    },
    groupUpdates () {
      const updates = this.updates
      const twoMonthsAgo = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
      if (this.groupMonth) {
        this.updatesGrouped = []
        const currentUps = []
        for (const up of updates) {
          // console.log('updateInput.initalize groupMonth current', up)
          if (this.getDate(up[0].date) > twoMonthsAgo) {
            currentUps.push(up)
          }
        }
        const currentMonths = { current: true, title: 'current', updates: currentUps, show: true, old: false }
        this.updatesGrouped.push(currentMonths)

        let currentMonth = ''
        for (const up of updates) {
          if (this.getDate(up[0].date) < twoMonthsAgo) {
            const month = this.getMonthTitle(up[0].date)
            // console.log('updateInput.initalize groupMonth prev', month, up[0])
            if (currentMonth !== month) {
              const prevMonth = { current: false, title: month, updates: [up], show: false, old: true }
              this.updatesGrouped.push(prevMonth)
            } else {
              const updatesMonth = this.updatesGrouped[this.updatesGrouped.length - 1]
              updatesMonth.updates.push(up)
            }
            currentMonth = month
          }
        }
      }
    },
    initials (profile) {
      let initialFirst = ''
      let initialLast = ''
      let displayNameParts = ['', '']
      displayNameParts = profile.displayName.split(' ')
      if (profile.firstName) {
        initialFirst = profile.firstName.trim().substring(0, 1)
      } else {
        initialFirst = displayNameParts[0].substring(0, 1)
      }
      if (profile.lastName) {
        initialLast = profile.lastName.substring(0, 1)
      } else {
        if (displayNameParts.length > 1) {
          initialLast = displayNameParts[1].substring(0, 1)
        }
      }
      return `${initialFirst}${initialLast}`.toUpperCase()
    },
    isBlankGroupShowing (date, where) {
      // get blank group for this date
      if (this.groupBlank) {
        for (const bgkey in this.blankGroups) {
          const bg = this.blankGroups[bgkey]
          if (bg.from >= date && bg.to <= date) {
            return this.blankGroupShowing[bg.from]
          }
        }
      }
      return false
    },
    showBlankGroup (date) {
      const blankGroupShowing = {}
      blankGroupShowing[date] = true
      this.blankGroupShowing = { ...blankGroupShowing }
    },
    hideBlankGroup (date) {
      this.blankGroupShowing = {}
    },
    copyNote (note) {
      note.copied = true
      const update = {
        fromId: this.profile.id,
        type: note.type,
        message: note.message,
        date: new Date(),
        createdTimestamp: Date.now(),
        created: new Date(),
        copiedFrom: note.id,
        checkId: this.checkId ? this.checkId : false,
        group: this.group,
        changed: true
      }
      const lastDayUpdate = this.updates[this.updates.length - 1]
      this.updates[this.updates.length - 1].splice(lastDayUpdate.length, 0, update)
      let index = 0
      for (const update of this.updates[0]) {
        update.order = index
        update.changed = true
        index++
      }
      this.$emit('updatesChanged')
    },
    addUpdate () {
      console.log('updateInput.addUpdate INDEX:', this.dayIndex, this.selectedIndex, ' selectedId:', this.selectedId)
      const editorContent = this.$refs['editors' + this.dayIndex][this.selectedIndex - 1].getContent()
      console.log('updateInput.addUpdate order', this.selectedIndex, editorContent)

      const defaultType = this.defaultType || ''

      // if current editor content not blank
      if (editorContent !== '<p></p>') {
        const insertAt = this.selectedIndex
        this.selected = null
        this.selectedIndex = -1

        const previous = this.updates[this.dayIndex][insertAt - 1]
        let previousDate = new Date()
        if (previous) {
          previousDate = previous.date
          if (previousDate.toDate) {
            previousDate = previousDate.toDate()
          }
        }

        const self = this
        const newUpdate = {
          id: null,
          fromId: this.profile.id,
          type: defaultType,
          message: '',
          date: previousDate,
          createdTimestamp: Date.now(),
          created: new Date(),
          checkId: this.checkId ? this.checkId : false,
          group: this.group,
          changed: true
        }
        this.$nextTick(() => {
          const newUpdates = [...this.updates[this.dayIndex]]
          newUpdates.splice(insertAt, 0, newUpdate)
          console.log('addUpdate - updates', newUpdates)
          this.updates[this.dayIndex] = newUpdates
          this.updates = [...this.updates]
          // updating ordering for that day
          let index = 0
          for (const update of this.updates[this.dayIndex]) {
            update.order = index
            update.changed = true
            index++
          }
          this.groupUpdates()
          setTimeout(() => {
            console.log('UpdateInput.focusAt', this.dayIndex, insertAt + 1)
            this.focusAt(this.dayIndex, insertAt + 1)
          }, 300)
          setTimeout(() => {
            self.selected = newUpdate
            self.selectedIndex = insertAt + 1
            self.selectedId = null
          }, 600)
        })
      }
    },
    getDate (day) {
      let date = day
      if (date.toDate) {
        date = date.toDate()
      }
      return date
    },
    getMonthTitle (day) {
      const date = this.getDate(day)
      const month = date.toLocaleString('default', { month: 'long' })
      const year = date.getFullYear()
      return `${month} ${year}`
    },
    onDelete (id, index) {
      console.log('onDelete', this.profile.teamId, id, index, this.selected, this.selected.id)
      if (id) {
        this.$emit('deleteUpdate', this.profile.team, id)
      }
      this.updates[this.dayIndex].splice(index, 1)
      this.moreOpen = false
    },
    focusAt (dayIndex, index) {
      console.log('updateInput.focusAt', this.$refs['editors' + dayIndex], dayIndex, index - 1)
      this.$refs['editors' + dayIndex][index - 1].focus()
    },
    selectedUpdate (update, updateId, dayindex, index) {
      this.selected = update
      this.selectedCopy = { ...this.selected }
      this.selectedId = updateId
      this.dayIndex = dayindex
      this.selectedIndex = index
      console.log('selectedUpdate', update, updateId, dayindex, index)
    },
    onTypeSelect (update, event) {
      let indexAdd = 1
      if (this.disableNotes) {
        indexAdd = 2
      }
      const iconKey = Object.keys(this.icons)[event.detail.index + indexAdd]
      console.log('onTypeSelect', this.selected, event.detail.index + 2, iconKey, this.icons)
      this.selectedCopy.type = iconKey
      this.selected.type = iconKey
      this.selectedCopy.changed = true
      this.selected.changed = true
      console.log('this.selected.type', this.selected.type)
    },
    onCommand (command) {
      if (this.selected) {
        if (!(this.disableNotes && command === '/n')) {
          const iconIndex = this.editorCommands.indexOf(command)
          const iconKey = Object.keys(this.icons)[iconIndex]
          this.selectedCopy.type = iconKey
          this.selected.type = iconKey
        }
      }
    },
    setUpdated () {
      for (const update of this.updates) {
        update.changed = false
      }
    },
    getContent () {
      console.log('UpdateInput.getContent - selected', this.selected, this.selectedIndex)
      if (this.selected) {
        console.log('UpdateInput.getContent - selectedIndex update', { ...this.updates[this.selectedIndex - 1] })
        // this.updates[this.selectedIndex - 1] = { ...this.selected }
      }
      console.log('UpdateInput.getContent - updates', this.updates)
      const allUpdates = [].concat.apply([], this.updates)
      console.log('UpdateInput.getContent - allUpdates', allUpdates)

      // Filter empty updates
      const content = [
        ...allUpdates.filter((update) => {
          if (!update.message) {
            return false
          }
          return update.message !== '<p></p>' && update.message !== ''
        })
      ]
      console.log('UpdateInput.getContent', content)
      return content
    },
    openUpdateMenu (update, index) {
      console.log('openUpdateMenu', update)
      this.moreOpen = true
      this.selected = update
      this.selectedIndex = index
    },
    openTypeMenu (update, event, index) {
      // console.log('openTypeMenu')
      this.selected = update
      this.selectedIndex = index
      const x = 20
      const y = event.clientY
      console.log('openTypeMenu update:', update, 'event:', event)
      this.target = { x, y }
      this.newTypeOpen = true
    },
    onUpdateUpdate (value, key) {
      if (this.selectedIndex) {
        console.log('onUpdateUpdate', value, key, { ...this.selectedCopy }, { ...this.selected })
        if (this.selected) {
          this.selected.message = value
          this.selected.changed = true
          this.selected.tags = this.$refs['editors' + this.dayIndex][this.selectedIndex - 1].getTags()
          this.selected.mentions = this.$refs['editors' + this.dayIndex][this.selectedIndex - 1].getMentions()
          if (value !== '' && value !== '<p></p>') {
            if (!this.selected.type) {
              if (this.disableNotes) {
                this.selectedCopy.type = 'update'
                this.selected.type = 'update'
              } else {
                this.selectedCopy.type = 'note'
                this.selected.type = 'note'
              }
            }
          }
          this.$emit('updatesChanged')
        }
      }
    }
  },
  watch: {
    updateGroup (oldVal, newVal) {
      if (newVal && oldVal) {
        // this.initialize()
      }
    },
    selected: {
      handler (newVal, oldVal) {
        console.log('watch.selected new', newVal)
        console.log('watch.selected old', oldVal)
        if (oldVal) {
          if (this.selectedCopy && (newVal !== oldVal)) {
            const oldSelected = oldVal
            oldSelected.updated = new Date()
            oldSelected.changed = true
            this.$emit('selectedChanged')
            console.log('selectedChanged', oldSelected, '=>', newVal)
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.blank-group-title {
  background: linear-gradient(-135deg, #f2f2f2 5px, transparent 0) 0 5px, linear-gradient(135deg, #f2f2f2 5px, #fff 0) 0 5px;
  background-color: #f2f2f2;
  background-position: left bottom;
  background-repeat: repeat-x;
  background-size: 10px 10px;
  padding: 4px 0px 8px 0px;
  display:flex;
  align-items: center;
  justify-content: center;
  font-size:0.8em;
  color:#C4C4C4;
  cursor:pointer;
}

.blank-group-title  .update-icon {
  font-size: 1.2em;
  color: #C4C4C4;
  line-height: 1.2em;
  color: #C4C4C4;
}

.group-month {
  display:flex;
  align-items: center;
  justify-content: center;
  margin:6px;
  font-size:0.8em;
  color:#999;
  cursor:pointer;
}

.group-month .update-icon {
  font-size: 1.2em;
  color: #C4C4C4;
  line-height: 1.2em;
  color: #C4C4C4;
}
.update-blank {
  display:none;
}
.more-icon {
  opacity:0;
}
.update:hover .more-icon {
  opacity:1;
}
@media all and (max-width: 570px) {
  .update .more-icon {
    opacity:1;
  }
}
.mdc-icon.small {
  font-size:12px;
  padding-left: 6px;
  padding-right: 6px;
}
ul[data-type="todo_list"] {
  padding-left: 0;
}

li[data-type="todo_item"] {
  display: flex;
  flex-direction: row;
}

.todo-checkbox {
  border: 2px solid black;
  height: 0.9em;
  width: 0.9em;
  box-sizing: border-box;
  margin-right: 10px;
  margin-top: 0.3rem;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  border-radius: 0.2em;
  background-color: transparent;
  transition: 0.4s background;
}

.todo-content {
  flex: 1;
}

.todo-content p {
  margin:2px;
}

ul[data-type="todo_list"] li[data-done="true"] {
  text-decoration: line-through;
}

ul[data-type="todo_list"] li[data-done="true"] .todo-checkbox {
  background-color: black;
}

ul[data-type="todo_list"] li[data-done="false"] {
  text-decoration: none;
}

.dateline {
  width:100%;
  font-size:0.8em;
  color:#999;
  text-align: center;
}
.dateline hr {
  position:relative;
  top:19px;
  border-color: #ccc;
  border-top: 0px solid #ccc;
}
.dateline span {
  display:inline-block;
  padding-left:12px;
  padding-right:12px;
  background-color: #FFFFFF;
  position: relative;
}

.avatar {
    width:35px;
    height: 35px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: block;
    overflow: hidden;
}
.avatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.update {
  display:flex;
  align-items: center;
  margin-top:0;
  margin-bottom:0;
}
.update .avatar {
  margin-right:-10px;
  margin-top:-16px;
  z-index:2;
}
.update-icon {
  color:#999999;
  margin-left:6px;
  margin-right:12px;
  cursor: pointer;
}
.update-reply-count {
  border-radius:16px;
  width:26px;
  height:26px;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
  background-color:#3560af;
  color:white;
}
.update-text.active {
  background-color:#f9f9f9;
}
.update-reply-count-wrapper {
  display:flex;
  align-items: center;
  border-left: 1px solid #999999;
  margin-left:6px;
  padding-left:6px;
  align-self: stretch;
}
.update-text {
  display:flex;
  align-items: center;
  padding:6px;
  margin:0;
  color:black;
  position: relative;
  line-height: 1.2em;
  width: calc(100% - 12px);
  max-width:calc(100% - 12px);
}
.new-update-row {
  position: relative;
  width: 0px;
}
.update-text.has-link {
  cursor:pointer;
}
.update-text .update-more {
  opacity:0;
}
.update-text.has-link:hover {
  background-color:#F2F2F2;
}
.update-text.has-link:hover .update-more {
  opacity:1;
}
.update-text .update-message {
  max-width: calc(100%);
  position: relative;
  flex:1;
  display:block;
  width: calc(100% - 86px);
  white-space: normal;
  overflow-wrap: normal;
}
.new-update-icon {
  cursor:pointer;
  color:#999999;
  margin-left:6px;
  margin-right:16px;
}
</style>
<style>
.update-input .ProseMirror p {
  line-height: 19px;
  font-size: 14px;
  margin:0;
}
.new-update-row .type-select-menu.open {
  display: block;
  z-index: 9;
  opacity: 1;
  position: fixed;
  left: unset !important;
  top: unset !important;
  transform-origin: unset !important;
  max-height: 250px !important;
}
.new-update-row .type-select-menu .mdc-list-item__text {
    display:flex;
    align-items: center;
}
.new-update-row .type-select-menu .mdc-list-item__text .sub-label{
  line-height: 12px;
}
.new-update-row .type-select-menu .mdc-list-item__text .mdc-icon{
    margin-right:16px;
}
.new-update-row .type-select-menu .mdc-list-item__text .mdc-body {
    margin:0;
}
.new-update-row .editor .menubar {
    position: absolute;
    margin-top: -40px;
    background: rgba(255,255,255,0.8);
    margin-left: -8px;
}
.update-text .update-message > span {
  display:inline;
}
.update-text .update-message > .tag, .hashtag.suggestion, .hashtag {
  background-color:#fbd303;
  border-radius:16px;
  padding:2px;
  padding-left:20px;
  padding-right:6px;
  margin-bottom: -6px;
  flex:0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display:inline-block;
  max-width: calc(100% - 18px);
  font-size: 14px;
  height: 18px;
  line-height:18px;
  align-items: center;
  text-decoration: inherit;
}
.hashtag .avatar {
  height: 18px;
  width: 18px;
  margin-left:-18px;
  position: absolute;
}
.hashtag.suggestion {
  padding-left:6px;
}
.mention {
  background-color:transparent;
  border-radius:16px;
  padding:2px;
  padding-left:6px;
  padding-right:6px;
  margin-bottom: -6px;
  flex:0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display:inline-block;
  max-width: calc(100% - 18px);
  font-size: 14px;
  height: 18px;
  line-height:18px;
}
</style>
