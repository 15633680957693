<template>
  <div class="schedule">
    <template v-if="currentSchedule && profile">
    <mdc-body v-if="getDate(currentSchedule.from)" style="padding-left:16px;margin:0;margin-top:12px;" typo="body2">
      Week {{getDate(currentSchedule.from) | dateShortDisplayFormat }}
    </mdc-body>
    <div class="button-row weekly-buttons" v-if="currentSchedule.dates">

      <div v-if="currentSchedule.newType">
      <table class="schedule-table" cell-spacing="4">
        <tr>
          <th></th>
          <th v-for="(day, index) in weekdays" :class="{'weekend': isWeekend(day)}" :key="index">
            {{day}}
          </th>
        </tr>
        <tr>
          <th><mdc-icon style="position: relative;top: 6px;" outlined icon="house"/><span class="row-title" style="line-height:24px;"> Remote</span></th>
          <td v-for="(day, index) in weekdays" :class="{'weekend': isWeekend(day)}" :key="index">
            <div class="wrapper">
              <span v-if="isSelectedOffice(index + 1)">
                <img v-if="hasPhoto(profile)" :src="profile.photoURL" class="avatar schedule-slot-background">
                <default-avatar v-else fontSize="16px" :profile="profile"/>
              </span>
              <mdc-icon-toggle primary
                :value="!isSelectedOffice(index + 1)"
                @input="toggleScheduleDayOffice(index + 1)"
                :toggle-on="{cssClass:'schedule-slot-active' }"
                :toggle-off="{cssClass: 'schedule-slot'}">
              </mdc-icon-toggle>
            </div>
          </td>
        </tr>
         <tr>
          <th><mdc-icon style="position: relative;top: 6px;" outlined icon="corporate_fare"/> <span class="row-title" style="line-height:24px;"> Office</span></th>
          <td v-for="(day, index) in weekdays" :class="{'weekend': isWeekend(day)}" :key="index">
            <div class="wrapper">
              <span v-if="isSelectedRemote(index + 1)">
                <img v-if="hasPhoto(profile)" :src="profile.photoURL" class="avatar schedule-slot-background">
                <default-avatar v-else fontSize="16px" :profile="profile"/>
              </span>
              <mdc-icon-toggle primary
                :value="isSelectedRemote(index + 1)"
                @input="toggleScheduleDayRemote(index + 1)"
                :toggle-on="{cssClass:'schedule-slot-active' }"
                :toggle-off="{cssClass: 'schedule-slot'}">
              </mdc-icon-toggle>
            </div>
          </td>
        </tr>
      </table>
      <table v-if="schedule.type === 'Every other week'" class="schedule-table" cell-spacing="4">
        <tr>
          <th></th>
          <th v-for="(day, index) in weekdays" :class="{'weekend': isWeekend(day)}" :key="index">
            {{day}}
          </th>
        </tr>
        <tr>
          <th><mdc-icon style="position: relative;top: 6px;" outlined icon="house"/><span class="row-title" style="line-height:24px;"> Remote</span></th>
          <td v-for="(day, index) in weekdays" :class="{'weekend': isWeekend(day)}" :key="index">
            <div class="wrapper">
              <span v-if="isSelectedOffice(index + 8)">
                <img v-if="hasPhoto(profile)" :src="profile.photoURL" class="avatar schedule-slot-background">
                <default-avatar v-else fontSize="16px" :profile="profile"/>
              </span>
              <mdc-icon-toggle primary
                :value="!isSelectedOffice(index + 8)"
                @input="toggleScheduleDayOffice(index + 8)"
                :toggle-on="{cssClass:'schedule-slot-active' }"
                :toggle-off="{cssClass: 'schedule-slot'}">
              </mdc-icon-toggle>
            </div>
          </td>
        </tr>
         <tr>
          <th><mdc-icon style="position: relative;top: 6px;" outlined icon="corporate_fare"/> <span class="row-title" style="line-height:24px;"> Office</span></th>
          <td v-for="(day, index) in weekdays" :class="{'weekend': isWeekend(day)}" :key="index">
            <div class="wrapper">
              <span v-if="isSelectedRemote(index + 8)">
                <img v-if="hasPhoto(profile)" :src="profile.photoURL" class="avatar schedule-slot-background">
                <default-avatar v-else fontSize="16px" :profile="profile"/>
              </span>
              <mdc-icon-toggle primary
                :value="isSelectedRemote(index + 8)"
                @input="toggleScheduleDayRemote(index + 8)"
                :toggle-on="{cssClass:'schedule-slot-active' }"
                :toggle-off="{cssClass: 'schedule-slot'}">
              </mdc-icon-toggle>
            </div>
          </td>
        </tr>
      </table>
      </div>

      <div v-else>
      <round-button style="padding-left:16px;border:1px solid" :medium="isSelected(1)" @click="toggleScheduleDay(1)" hasSlot>MON</round-button>
      <round-button style="padding-left:16px;border:1px solid" :medium="isSelected(2)" @click="toggleScheduleDay(2)" hasSlot>TUE</round-button>
      <round-button style="padding-left:16px;border:1px solid" :medium="isSelected(3)" @click="toggleScheduleDay(3)" hasSlot>WED</round-button>
      <round-button style="padding-left:16px;border:1px solid" :medium="isSelected(4)" @click="toggleScheduleDay(4)" hasSlot>THU</round-button>
      <round-button style="padding-left:16px;border:1px solid" :medium="isSelected(5)" @click="toggleScheduleDay(5)" hasSlot>FRI</round-button>
      </div>
    </div>

    <mdc-checkbox v-if="active" style="padding-left:8px;" @change="setDefault"  label="Set as your default schedule" v-model="isDefault"/>

    <div class="button-row submit-schedule" style="display:flex; align-items:center;">
        <round-button v-if="active" noHover icon="exit_to_app" high @click="submitSchedule" :disabled="scheduleSubmitted && !editSchedule" style="padding-left:16px;" hasSlot>
        <span v-if="!scheduleSubmitted || editSchedule">Submit</span>
        <span v-else>Submitted</span>
        </round-button>
        <mdc-body v-if="scheduleSubmitted && active" style="padding-left:16px;margin:0;" typo="body2">
        <span v-if="scheduleSubmitted && !editSchedule" style="color:black" @click="editSchedule = true" class="link">Edit your schedule</span>
        <span v-else style="color:black" @click="cancelSchedule" class="link">Cancel</span>
        </mdc-body>
    </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DefaultAvatar from '../components/DefaultAvatar'

export default {
  name: 'Schedule',
  props: {
    teamId: {},
    scheduleId: {},
    lastScheduleId: {}
  },
  components: {
    DefaultAvatar
  },
  data () {
    return {
      scheduleSubmitted: false,
      editSchedule: false,
      currentSchedule: false,
      schedule: false,
      lastSchedule: false,
      isDefault: false,
      weekdays: [
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat',
        'Sun'
      ]
    }
  },
  computed: {
    ...mapState([
      'profile'
    ]),
    active () {
      if (this.currentSchedule) {
        if (this.currentSchedule.to) {
          return this.getDate(this.currentSchedule.to) >= new Date()
        }
      }
      return false
    }
  },
  methods: {
    ...mapActions([
      'getScheduleByRequest',
      'saveSchedule',
      'updateProfile'
    ]),
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    },
    setDefault (val) {
      console.log('setDefault', val, this.isDefault)
    },
    isWeekend (day) {
      if (['Sat', 'Sun'].includes(day)) {
        return true
      }
      return false
    },
    getDate (day) {
      let date = day
      if (date.toDate) {
        date = date.toDate()
      }
      return date
    },
    copyLastSchedule () {
      if (this.lastSchedule) {
        this.schedule = {
          ...this.schedule,
          dates: [...this.lastSchedule.dates]
        }
        this.saveSchedule({ teamId: this.teamId, schedule: this.schedule }).then((savedSchedule) => {
          this.schedule = savedSchedule
          this.schedule.newType = true
        })
      }
    },
    isSelectedOffice (day) {
      let result = false
      if (this.currentSchedule.dates[day]) {
        result = this.currentSchedule.dates[day].office.includes(this.profile.id)
      }
      return result
    },
    toggleScheduleDayOffice (day) {
      if (this.active) {
        if (!this.scheduleSubmitted || this.editSchedule) {
          if (this.currentSchedule.dates[day].office.includes(this.profile.id)) {
            this.currentSchedule.dates[day].office = []
            this.currentSchedule.dates[day].remote = []
            this.currentSchedule.dates[day].tbc = [this.profile.id]
            this.currentSchedule = { ...this.currentSchedule }
          } else {
            this.currentSchedule.dates[day].office = [this.profile.id]
            this.currentSchedule.dates[day].remote = []
            this.currentSchedule.dates[day].tbc = []
            this.currentSchedule = { ...this.currentSchedule }
          }
        }
      }
    },
    isSelectedRemote (day) {
      let result = false
      if (this.currentSchedule.dates[day]) {
        result = this.currentSchedule.dates[day].remote.includes(this.profile.id)
      }
      return result
    },
    toggleScheduleDayRemote (day) {
      if (this.active) {
        if (!this.scheduleSubmitted || this.editSchedule) {
          if (this.currentSchedule.dates[day].remote.includes(this.profile.id)) {
            this.currentSchedule.dates[day].office = []
            this.currentSchedule.dates[day].remote = []
            this.currentSchedule.dates[day].tbc = [this.profile.id]
            this.currentSchedule = { ...this.currentSchedule }
          } else {
            this.currentSchedule.dates[day].office = []
            this.currentSchedule.dates[day].remote = [this.profile.id]
            this.currentSchedule.dates[day].tbc = []
            this.currentSchedule = { ...this.currentSchedule }
          }
        }
      }
    },
    isSelected (day) {
      let result = false
      if (this.currentSchedule.dates[day]) {
        result = this.currentSchedule.dates[day].office.includes(this.profile.id)
      }
      return result
    },
    toggleScheduleDay (day) {
      if (this.active) {
        if (!this.scheduleSubmitted || this.editSchedule) {
          if (this.currentSchedule.dates[day].office.includes(this.profile.id)) {
            this.currentSchedule.dates[day].office = []
            this.currentSchedule.dates[day].remote = [this.profile.id]
            this.currentSchedule.dates[day].tbc = []
            this.currentSchedule = { ...this.currentSchedule }
          } else {
            this.currentSchedule.dates[day].office = [this.profile.id]
            this.currentSchedule.dates[day].remote = []
            this.currentSchedule.dates[day].tbc = []
            this.currentSchedule = { ...this.currentSchedule }
          }
        }
      }
    },
    submitSchedule () {
      this.schedule = {
        ...this.schedule,
        dates: this.currentSchedule.dates
      }
      this.scheduleSubmitted = true
      this.editSchedule = false
      const self = this
      this.saveSchedule({ teamId: this.teamId, schedule: this.schedule }).then((savedSchedule) => {
        this.schedule = savedSchedule
        this.schedule.newType = true
        if (this.isDefault) {
          console.log('Schedue.submitSchedule setting default', self.teamId, savedSchedule)
          let defaultSchedules = this.profile.defaultSchedules
          if (!defaultSchedules) {
            defaultSchedules = {}
          }
          defaultSchedules[self.teamId] = this.schedule.dates
          let defaultScheduleIds = this.profile.defaultScheduleIds
          if (!defaultScheduleIds) {
            defaultScheduleIds = {}
          }
          defaultScheduleIds[self.teamId] = savedSchedule.id
          this.updateProfile({ profile: { defaultSchedules: defaultSchedules, defaultScheduleIds: defaultScheduleIds } })
        }
      })
    },
    cancelSchedule () {
      this.currentSchedule = false
      this.schedule = null
      this.scheduleSubmitted = true
      this.editSchedule = false
      this.initialise()
    },
    async getSchedule () {
      this.schedule = await this.getScheduleByRequest({ teamId: this.teamId, requestId: this.scheduleId })
      this.schedule.newType = true
      if (this.profile.defaultScheduleIds) {
        if (this.profile.defaultScheduleIds[this.teamId]) {
          this.isDefault = this.profile.defaultScheduleIds[this.teamId] === this.schedule.id
        }
      }
    },
    initialise () {
      if (this.teamId && this.scheduleId) {
        this.getSchedule()
      }
    }
  },
  mounted () {
    this.initialise()
  },
  watch: {
    teamId () {
      this.initialise()
    },
    schedule () {
      if (!this.currentSchedule && this.schedule) {
        this.currentSchedule = { ...this.schedule, dates: this.schedule.dates }
        this.scheduleSubmitted = !!this.schedule.id
      }
    }
  }
}
</script>
<style>
.schedule .button-row.weekly-buttons {
  display:flex;
}
.schedule table {
  border-spacing: 6px;
  border-collapse: separate;
  min-width:300px;
  width:auto !important;
}
.schedule .schedule-slot-background {
  width:36px;
  height:36px;
  position:absolute;
  margin:5px;
}

.schedule .schedule-table {
  width: 100%;
  table-layout: fixed;
}
.schedule table.schedule-table tr th {
  height:30px;
  min-width:36px;
  padding-left:3px;
  padding-right:3px;
  text-align: center;
}
.schedule table.schedule-table tr td {
  height:30px;
  width:36px;
  background-color:#fefefe;
  position: relative;
}

.schedule .profile-select {
  cursor:pointer;
  color:#c3c3c3;
}
.schedule .profile-select.selected {
  cursor:pointer;
  color:var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
@media (max-width: 510px) {
  .schedule .weekend {
    display:none;
  }
  .schedule .row-title {
    display:none;
  }
  .schedule .schedule-slot-background {
  width:30px;
  height:30px;
  position:absolute;
  margin:4px;
  }
  .schedule table.schedule-table .mdc-icon-button {
    padding:2px;
    height: 36px;
    width:40px;
  }
  .schedule table.schedule-table tr th {
  height:30px;
  min-width:30px;
  padding-left:1px;
  padding-right:1px;
  text-align: center;
}
.schedule table.schedule-table tr td {
  height:30px;
  width:30px;
  background-color:#fefefe;
  position: relative;
}
}
</style>
