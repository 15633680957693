<template>
  <div class="main-content recruiter-dash">
    <div class="main-wrapper" style="margin-top: 56px;">

      <div class="mobile-links-wrapper mobileonly">
        <div class="mobileonly mobile-links">

          <round-button
            v-for="link in links"
            :key="link.action"
            :medium="section === link.action"
            class="mobile-link"
            style="flex-grow:2;"
            elevated
            hasSlot
            @click="showSection(link.action)">
            {{link.title}}
            <mdc-icon slot="trailing-icon" style="margin-left:6px;margin-right:0" class="icon" :icon="link.icon"></mdc-icon>
          </round-button>

          <span style="width:1px; background: white;flex:0;">&nbsp;</span>
        </div>
      </div>

      <section class="report-section" v-if="section == 'signups'">
        <app-section
          class="chart-section"
          :editable="false"
          title="New Users">
          <div style="display:flex;" slot="post-title">
            <round-button class="mono-letter" :high="chartType === 'daily'" @click="chartType = 'daily'">D</round-button>
            <round-button class="mono-letter" :high="chartType === 'weekly'" @click="chartType = 'weekly'">W</round-button>
            <round-button class="mono-letter" :high="chartType === 'monthly'" @click="chartType = 'monthly'">M</round-button>
          </div>
          <mdc-body>Use this chart to understand new users over time</mdc-body>
          <div style="min-height:400px;">
            <div v-if="dataLoaded">
              <bar-chart :chartData="signupChartData" :chartOptions="signupChartOptions" />
            </div>
          </div>
        </app-section>
      </section>

      <section class="report-section" v-else-if="section == 'connections'">
        <app-section
          class="chart-section"
          :editable="false"
          title="Connections">
          <div style="display:flex;" slot="post-title">
            <round-button class="mono-letter" :high="chartType === 'daily'" @click="chartType = 'daily'">D</round-button>
            <round-button class="mono-letter" :high="chartType === 'weekly'" @click="chartType = 'weekly'">W</round-button>
            <round-button class="mono-letter" :high="chartType === 'monthly'" @click="chartType = 'monthly'">M</round-button>
          </div>
          <mdc-body>Use this chart to understand connections and messages over time</mdc-body>
          <div style="min-height:400px;">
            <div v-if="dataLoaded">
              <bar-chart :chartData="connectionsChartData" :chartOptions="connectionsChartOptions" />
            </div>
          </div>
        </app-section>
      </section>

      <section class="report-section" v-else-if="section == 'roles'">
        <app-section
          class="chart-section"
          :editable="false"
          title="Roles">
          <mdc-body>Use this chart to understand the top roles and skills of users</mdc-body>
          <div style="min-height:400px;">
            <div v-if="dataLoaded" style="display: flex;flex-flow: column;">
              <doughnut-chart @legend="legend = $event" :chartData="rolesChartData" :chartOptions="rolesChartOptions" />
              <div v-if="legend" v-html="legend"></div>
            </div>
          </div>
        </app-section>
      </section>

      <section class="report-section" v-if="section == 'learn'">
        <app-section
          class="chart-section"
          :editable="false"
          title="Learn Articles">
          <div style="display:flex;" slot="post-title">
            <round-button class="mono-letter" :high="chartRange === 'Yesterday'" @click="chartRange = 'Yesterday'">D</round-button>
            <round-button class="mono-letter" :high="chartRange === 'Last 7 days'" @click="chartRange = 'Last 7 days'">W</round-button>
            <round-button class="mono-letter" :high="chartRange === 'Last 30 days'" @click="chartRange = 'Last 30 days'">M</round-button>
          </div>
          <div class="learn-title-wrapper" style="display:flex;">
            <mdc-body class="learn-title" style="flex:1">Use this chart to understand what articles users are reading</mdc-body>
            <mdc-select v-model="chartRange" outlined label="Date Range">
              <select-item value="Today" />
              <select-item value="Yesterday" />
              <select-item value="Last week" />
              <select-item value="Last month" />
              <select-item value="Last 7 days" />
              <select-item value="Last 30 days" />
            </mdc-select>
          </div>
          <div style="min-height:400px;">
            <div v-if="dataLoaded">
              <horizontal-bar-chart :chartData="learnChartData" :chartOptions="learnChartOptions" />
            </div>
          </div>
        </app-section>
      </section>

      <section v-if="section === 'team'">
        <mdc-layout-grid>
          <mdc-layout-cell span=12>
            <app-section
              class="chart-section"
              :editable="false"
              title="Remote Sentiment">
              <div class="learn-title-wrapper" style="display:flex;">
                <mdc-body class="learn-title" style="flex:1">Use this chart to understand your team vs company wide</mdc-body>
              </div>
              <div style="min-height:400px;">
                <div>
                  <horizontal-bar-chart :chartData="sentimentChartData" :chartOptions="sentimentChartOptions" />
                </div>
              </div>
            </app-section>
          </mdc-layout-cell>
          <mdc-layout-cell span=12>
            <app-section
              class="chart-section"
              :editable="false"
              title="Remote Resilience">
              <div class="learn-title-wrapper" style="display:flex;">
                <mdc-body class="learn-title" style="flex:1;margin-bottom:12px;">Ensuring your team works remotely to improve their effectiveness when remote.</mdc-body>
              </div>
              <div style="min-height:400px;">
                <div style="display: flex;flex-flow: column;">
                  <div style="margin-left: auto;margin-right: auto;" v-if="legend" v-html="legend"></div>
                  <doughnut-chart @legend="legend = $event" :chartData="remoteChartData" :chartOptions="remoteChartOptions" />
                </div>
              </div>
            </app-section>
          </mdc-layout-cell>
        </mdc-layout-grid>
      </section>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as backend from '../utils/backend'
import saveAs from 'file-saver'
import AppSection from '../components/profile-v2/AppSection.vue'
import BarChart from '../components/BarChart.vue'
import HorizontalBarChart from '../components/HorizontalBarChart.vue'
import DoughnutChart from '../components/DoughnutChart.vue'
import {
  getLastMonth,
  getLast12Weeks,
  getLastYear,
  datesBetween,
  weeksBetween,
  monthsBetween,
  getDateRange
} from '../utils/time'
import fb from '../store/firebase'
const companyRef = fb.firestore.collection('companies')
export default {
  name: 'Manage',
  components: {
    BarChart,
    HorizontalBarChart,
    DoughnutChart,
    AppSection
  },
  data () {
    return {
      title: 'Manage',
      userid: '',
      result: '',
      loading: false,
      section: '',
      pageActions: ['signups', 'connections', 'roles', 'learn', 'team'],
      links: [
        {
          title: 'Signups',
          icon: 'bar_chart',
          action: 'signups'
        },
        {
          title: 'Connections',
          icon: 'show_chart',
          action: 'connections'
        },
        {
          title: 'Roles',
          icon: 'pie_chart',
          action: 'roles'
        },
        {
          title: 'Learn',
          icon: 'school',
          action: 'learn'
        },
        {
          title: 'Team',
          icon: 'people_outline',
          action: 'team',
          filter: 'remoteMode'
        }
      ],
      colours: [
        '#C10457',
        '#f4854d',
        '#ffd12b',
        '#2cbcb6',
        '#3560AF'
      ],
      legend: '',
      dataLoaded: false,
      chartType: 'daily',
      chartRange: 'Last 30 days',
      signupChartOptions: {
        title: {
          display: true,
          text: 'Signups and Profile Visible'
        },
        tooltips: {
          mode: 'index',
          intersect: false
        },
        maintainAspectRatio: false,
        height: '400px',
        responsive: true,
        scales: {
          xAxes: [
            {
              stacked: true
            }
          ],
          yAxes: [
            {
              stacked: true
            }
          ]
        }
      },
      signupChartData: {
        labels: [],
        datasets: [
          {
            label: 'Signup',
            backgroundColor: '#C10457',
            data: []
          },
          {
            label: 'Profile Visible',
            backgroundColor: '#f4854d',
            data: []
          }
        ]
      },
      learnChartOptions: {
        title: {
          display: true,
          text: 'Learn Article Views'
        },
        tooltips: {
          mode: 'index',
          intersect: false
        },
        maintainAspectRatio: false,
        height: 'auto',
        responsive: true,
        scales: {
          xAxes: [
            {
              ticks: { beginAtZero: true, stepSize: 1 }
            }
          ],
          yAxes: [
            {
              ticks: { beginAtZero: true, stepSize: 1 }
            }
          ]
        }
      },
      learnChartData: {
        labels: [],
        datasets: [
          {
            label: 'Article Views',
            barThickness: 16,
            backgroundColor: '#C10457',
            data: []
          }
        ]
      },
      rolesChartData: {
        datasets: [
          {
            label: 'Roles',
            labels: [],
            backgroundColor: [
              '#C10457',
              '#f4854d',
              '#ffd12b',
              '#2cbcb6',
              '#3560AF'
            ],
            data: []
          },
          {
            label: 'Skills',
            labels: [],
            backgroundColor: [
              '#730027',
              '#FF6C00',
              '#FFAE03',
              '#008080',
              '#172E94'
            ],
            data: []
          }
        ]
      },
      rolesChartOptions: {
        title: {
          display: true,
          text: 'Top Roles & Skills'
        },
        responsive: true,
        height: '400px',
        maintainAspectRatio: false,
        legendCallback: function (chart) {
          var text = []
          for (var j = 0; j < chart.data.datasets.length; j++) {
            text.push('<ul class="legend">')
            text.push(`<li class="legend-title">${chart.data.datasets[j].label}</li>`)
            for (var i = 0; i < chart.data.datasets[j].data.length; i++) {
              text.push('<li><span style="background-color:' + chart.data.datasets[j].backgroundColor[i] + '"></span>')
              text.push(chart.data.datasets[j].labels[i])
              text.push('</li>')
            }
            text.push('</ul>')
          }
          return text.join('')
        },
        legend: {
          display: true
        },
        tooltips: {
          callbacks: {
            label: function (item, data) {
              var label = ' ' + data.datasets[item.datasetIndex].label + ' ' + data.datasets[item.datasetIndex].labels[item.index]
              var value = data.datasets[item.datasetIndex].data[item.index]
              return label + ': ' + value
            }
          }
        }
      },
      connectionsChartOptions: {
        title: {
          display: true,
          text: 'Connections and Messages'
        },
        tooltips: {
          mode: 'index',
          intersect: false
        },
        maintainAspectRatio: false,
        height: '400px',
        responsive: true,
        scales: {
          xAxes: [
          ],
          yAxes: [{
            id: 'A',
            scaleLabel: {
              display: true,
              labelString: 'Connections'
            },
            type: 'linear',
            position: 'left',
            ticks: {
              stepSize: 1,
              beginAtZero: 0
            }
          }, {
            id: 'B',
            scaleLabel: {
              display: true,
              labelString: 'Messages'
            },
            type: 'linear',
            position: 'right',
            ticks: {
              stepSize: 1,
              beginAtZero: 0
            }
          }]
        }
      },
      connectionsChartData: {
        labels: [],
        datasets: [
          {
            yAxisID: 'B',
            label: 'Messages',
            type: 'line',
            fill: false,
            backgroundColor: '#f4854d',
            borderColor: '#f4854d',
            order: 1,
            data: []
          },
          {
            yAxisID: 'A',
            label: 'Connections',
            type: 'bar',
            fill: true,
            backgroundColor: '#C10457',
            order: 2,
            data: []
          }
        ]
      },
      sentimentChartOptions: {
        title: {
          display: false,
          text: 'Percentage'
        },
        tooltips: {
          mode: 'index',
          intersect: false
        },
        maintainAspectRatio: false,
        height: 'auto',
        responsive: true,
        scales: {
          xAxes: [
            {
              stacked: true
            }
          ],
          yAxes: [
            {
              stacked: true
            }
          ]
        }
      },
      sentimentChartData: {
        labels: ['Comfort Team', 'Comfort Company', 'Productivity Team', 'Productivity Company'],
        datasets: [
          {
            label: 'Very Negative',
            barThickness: 32,
            backgroundColor: '#C10457',
            data: [10, 14, 10, 13]
          },
          {
            label: 'Negative',
            barThickness: 32,
            backgroundColor: '#FF6C00',
            data: [16, 28, 12, 27]
          },
          {
            label: 'Neither',
            barThickness: 32,
            backgroundColor: '#FFD12B',
            data: [20, 26, 30, 28]
          },
          {
            label: 'Positive',
            barThickness: 32,
            backgroundColor: '#2CBCB6',
            data: [20, 16, 20, 16]
          },
          {
            label: 'Very Positive',
            barThickness: 32,
            backgroundColor: '#3560AF',
            data: [34, 16, 28, 16]
          }
        ]
      },
      remoteChartData: {
        datasets: [
          {
            label: 'Days without remote working',
            labels: [
              '0-30',
              '30-90',
              '90-180',
              '180+'
            ],
            backgroundColor: [
              '#C10457',
              '#f4854d',
              '#ffd12b',
              '#2cbcb6',
              '#3560AF'
            ],
            data: [10, 12, 3, 4]
          }
        ]
      },
      remoteChartOptions: {
        title: {
          display: false,
          text: ''
        },
        responsive: true,
        height: '400px',
        maintainAspectRatio: false,
        legendCallback: function (chart) {
          var text = []
          for (var j = 0; j < chart.data.datasets.length; j++) {
            text.push('<ul class="legend">')
            text.push(`<li class="legend-title">${chart.data.datasets[j].label}</li>`)
            for (var i = 0; i < chart.data.datasets[j].data.length; i++) {
              text.push('<li><span style="background-color:' + chart.data.datasets[j].backgroundColor[i] + '"></span>')
              text.push(chart.data.datasets[j].labels[i])
              text.push('</li>')
            }
            text.push('</ul>')
          }
          return text.join('')
        },
        legend: {
          display: true
        },
        tooltips: {
          callbacks: {
            label: function (item, data) {
              var label = ' ' + data.datasets[item.datasetIndex].label + ' ' + data.datasets[item.datasetIndex].labels[item.index]
              var value = data.datasets[item.datasetIndex].data[item.index]
              return label + ': ' + value
            }
          }
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'setAction',
      'setPageLoad',
      'setSnackMessage'
    ]),
    async getUserData () {
      this.loading = true
      const self = this
      backend.cloudFunction('getUserData', { id: this.userid }).then((res) => {
        self.result = res
        this.setSnackMessage('User Data Compiled')
        const data = JSON.stringify(self.result)
        const blob = new Blob([data], { type: 'application/json;charset=utf-8' })
        const filename = `user-data-${self.userid}.json`
        self.userid = ''
        self.loading = false
        return saveAs(blob, filename)
      })
    },
    deleteUserData () {
      if (confirm('Are you sure you want to delete this users data? This action cannot be undone!')) {
        this.loading = true
        const self = this
        backend.cloudFunction('deleteUserData', { id: this.userid }).then((res) => {
          self.result = res
          self.setSnackMessage('User Data Deleted')
          self.userid = ''
          self.loading = false
        })
      }
    },
    showSection: function (section) {
      this.dataLoaded = false
      this.section = section
      if (section === 'roles') {
        this.getTotals()
      } else if (section === 'learn') {
        this.getRange()
      } else {
        if (this.chartType === 'daily') {
          this.getDaily()
        } else if (this.chartType === 'weekly') {
          this.getWeekly()
        } else if (this.chartType === 'monthly') {
          this.getMonthly()
        }
      }
    },
    getTotals () {
      this.dataLoaded = false
      const companyId = this.profile.companyId || '_default'
      console.log(`RecruiterDash - roles chart for ${companyId}`)
      const dataRef = companyRef.doc(companyId).collection('data').doc('totals')
      this.rolesChartData.datasets[0].data = []
      this.rolesChartData.datasets[0].labels = []
      this.rolesChartData.datasets[1].data = []
      this.rolesChartData.datasets[0].labes = []
      dataRef.get().then(snapShot => {
        const totals = snapShot.data()
        if (totals.roles) {
          let topRoles = Object.keys(totals.roles).map(function (key) {
            return { key: key, value: this[key] }
          }, totals.roles)
          topRoles = topRoles.filter((role) => { return role.value > 0 })
          topRoles.sort(function (p1, p2) { return p2.value - p1.value })
          for (const role of topRoles.slice(0, 5)) {
            this.rolesChartData.datasets[0].data.push(role.value)
            this.rolesChartData.datasets[0].labels.push(role.key)
          }
        }
        if (totals.skills) {
          let topSkills = Object.keys(totals.skills).map(function (key) {
            return { key: key, value: this[key] }
          }, totals.skills)
          topSkills = topSkills.filter((skill) => { return skill.value > 0 })
          topSkills.sort(function (p1, p2) { return p2.value - p1.value })
          for (const skill of topSkills.slice(0, 5)) {
            this.rolesChartData.datasets[1].data.push(skill.value)
            this.rolesChartData.datasets[1].labels.push(skill.key)
          }
        }
        this.$nextTick(() => {
          this.dataLoaded = true
        })
      })
    },
    getRange () {
      this.dataLoaded = false
      const range = getDateRange(this.chartRange)
      const companyId = this.profile.companyId || '_default'
      const dataRef = companyRef.doc(companyId).collection('data')
      if (range) {
        console.log(`RecruiterDash - range chart for ${companyId} between ${range.from}, ${range.to} (${this.chartRange})`)
        this.learnChartData.labels = []
        this.learnChartData.datasets[0].data = []
        const learnDataMap = {}
        dataRef.where('date', '>=', range.from).where('date', '<=', range.to).get().then(querySnapShot => {
          querySnapShot.forEach(doc => {
            const dataRow = doc.data()
            if (dataRow.views) {
              for (const articleKey in dataRow.views) {
                const article = dataRow.views[articleKey]
                if (article.type === 'learn') {
                  if (learnDataMap[article.page]) {
                    learnDataMap[article.page] += article.count
                  } else {
                    learnDataMap[article.page] = article.count
                  }
                }
              }
            }
          })
          const learnSorted = Object.keys(learnDataMap).sort(function (a, b) { return learnDataMap[b] - learnDataMap[a] })
          for (const d of learnSorted) {
            const labelFormat = d.replace(new RegExp('-', 'g'), ' ')
            const labelArray = labelFormat.replace(/.{20}\S*\s+/g, '$&@').split(/\s+@/)
            this.learnChartData.labels.push(labelArray)
            this.learnChartData.datasets[0].data.push(learnDataMap[d])
          }
          this.$nextTick(() => {
            this.dataLoaded = true
          })
        })
      }
    },
    async getDaily () {
      this.dataLoaded = false
      const startDate = getLastMonth()
      const now = new Date()
      const companyId = this.profile.companyId || '_default'
      console.log(`RecruiterDash - daily chart for ${companyId} between ${startDate} and ${now}`)
      const dataRef = companyRef.doc(companyId).collection('data')

      this.signupChartData.labels = []
      this.signupChartData.datasets[0].data = []
      this.signupChartData.datasets[1].data = []

      this.connectionsChartData.labels = []
      this.connectionsChartData.datasets[0].data = []
      this.connectionsChartData.datasets[1].data = []

      const dates = datesBetween(startDate, now)
      const signupDataMap = {}
      const connectionsDataMap = {}

      for (const d of dates) {
        const date = d.toISOString().slice(0, 10)
        this.signupChartData.labels.push(date)
        this.connectionsChartData.labels.push(date)
        signupDataMap[date] = [0, 0]
        connectionsDataMap[date] = [0, 0]
      }

      dataRef.where('date', '>=', startDate).get().then(querySnapShot => {
        querySnapShot.forEach(doc => {
          const dataRow = doc.data()
          const date = dataRow.date.toDate().toISOString().slice(0, 10)
          const dSig = dataRow.signups || 0
          const dPVi = dataRow.profileVisible || 0
          signupDataMap[date] = [dSig, dPVi]
          const dCon = dataRow.connections || 0
          const dMes = dataRow.messages || 0
          connectionsDataMap[date] = [dMes, dCon]
        })
        for (const d in signupDataMap) {
          this.signupChartData.datasets[0].data.push(signupDataMap[d][0])
          this.signupChartData.datasets[1].data.push(signupDataMap[d][1])
        }
        for (const d in connectionsDataMap) {
          this.connectionsChartData.datasets[0].data.push(connectionsDataMap[d][0])
          this.connectionsChartData.datasets[1].data.push(connectionsDataMap[d][1])
        }
        this.$nextTick(() => {
          this.dataLoaded = true
        })
      })
    },
    getWeekly () {
      this.dataLoaded = false
      const startDate = getLast12Weeks()
      const now = new Date()
      now.setDate(now.getDate() + 8 - now.getDay())
      const companyId = this.profile.companyId || '_default'
      console.log(`RecruiterDash - weekly chart for ${companyId} between ${startDate} and ${now}`)
      const dataRef = companyRef.doc(companyId).collection('data')

      this.signupChartData.labels = []
      this.signupChartData.datasets[0].data = []
      this.signupChartData.datasets[1].data = []

      this.connectionsChartData.labels = []
      this.connectionsChartData.datasets[0].data = []
      this.connectionsChartData.datasets[1].data = []

      const dates = weeksBetween(startDate, now)

      const signupDataMap = {}
      const connectionsDataMap = {}

      for (const d of dates) {
        d.setDate(d.getDate() + 1 - d.getDay())
        const date = 'WC ' + d.toISOString().slice(0, 10)
        this.signupChartData.labels.push(date)
        this.connectionsChartData.labels.push(date)
        signupDataMap[date] = [0, 0]
        connectionsDataMap[date] = [0, 0]
      }
      dataRef.where('date', '>=', startDate).get().then(querySnapShot => {
        querySnapShot.forEach(doc => {
          const dataRow = doc.data()
          var d = dataRow.date.toDate()
          d.setDate(d.getDate() + 1 - d.getDay())
          const date = 'WC ' + d.toISOString().slice(0, 10)

          if (signupDataMap[date]) {
            const dSig = dataRow.signups || 0
            const dPVi = dataRow.profileVisible || 0

            signupDataMap[date][0] += dSig
            signupDataMap[date][1] += dPVi

            const dCon = dataRow.connections || 0
            const dMes = dataRow.messages || 0

            connectionsDataMap[date][1] += dCon
            connectionsDataMap[date][0] += dMes
          } else {
            console.log('Manage.getWeekly - DataError - the following week commencing date falls outside of range', date, dataRow)
          }
        })
        for (const d in signupDataMap) {
          this.signupChartData.datasets[0].data.push(signupDataMap[d][0])
          this.signupChartData.datasets[1].data.push(signupDataMap[d][1])
        }
        for (const d in connectionsDataMap) {
          this.connectionsChartData.datasets[0].data.push(connectionsDataMap[d][0])
          this.connectionsChartData.datasets[1].data.push(connectionsDataMap[d][1])
        }
        this.$nextTick(() => {
          this.dataLoaded = true
        })
      })
    },
    getMonthly () {
      this.dataLoaded = false
      const startDate = getLastYear()
      const now = new Date()
      const companyId = this.profile.companyId || '_default'
      console.log(`RecruiterDash - monthly chart for ${companyId} between ${startDate} and ${now}`)
      const dataRef = companyRef.doc(companyId).collection('data')
      this.signupChartData.labels = []
      this.signupChartData.datasets[0].data = []
      this.signupChartData.datasets[1].data = []

      this.connectionsChartData.labels = []
      this.connectionsChartData.datasets[0].data = []
      this.connectionsChartData.datasets[1].data = []

      const dates = monthsBetween(startDate, now)

      const signupDataMap = {}
      const connectionsDataMap = {}

      for (const d of dates) {
        const date = d.toISOString().slice(0, 7)
        this.signupChartData.labels.push(date)
        this.connectionsChartData.labels.push(date)
        signupDataMap[date] = [0, 0]
        connectionsDataMap[date] = [0, 0]
      }

      dataRef.where('date', '>=', startDate).get().then(querySnapShot => {
        querySnapShot.forEach(doc => {
          const dataRow = doc.data()
          const date = dataRow.date.toDate().toISOString().slice(0, 7)

          const dSig = dataRow.signups || 0
          const dPVi = dataRow.profileVisible || 0

          signupDataMap[date][0] += dSig
          signupDataMap[date][1] += dPVi

          const dCon = dataRow.connections || 0
          const dMes = dataRow.messages || 0

          connectionsDataMap[date][1] += dCon
          connectionsDataMap[date][0] += dMes
        })
        for (const d in signupDataMap) {
          this.signupChartData.datasets[0].data.push(signupDataMap[d][0])
          this.signupChartData.datasets[1].data.push(signupDataMap[d][1])
        }
        for (const d in connectionsDataMap) {
          this.connectionsChartData.datasets[0].data.push(connectionsDataMap[d][0])
          this.connectionsChartData.datasets[1].data.push(connectionsDataMap[d][1])
        }
        this.$nextTick(() => {
          this.dataLoaded = true
        })
      })
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'profiles',
      'action'
    ]),
    ...mapState({
      jobroles: (state) => state.jobs.jobs
    }),
    companyProfiles () {
      if (!this.profile) return null
      const companyId = this.profile.companyId
      if (!companyId) return null
      if (!this.profiles) return null
      return this.profiles.filter((profile) => profile.companyId === companyId)
    },
    publicProfiles () {
      if (!this.profiles) return []
      return this.profiles.filter((profile) => profile.view === 'public')
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$root.$emit('enableSearch', false)
  },
  mounted () {
    this.dataLoaded = false
    this.$emit('setLinks', this.links)
    if (!this.pageActions.includes(this.action)) {
      this.setAction('signups')
    } else {
      this.setAction(this.action)
    }

    if (['signups', 'connections'].includes(this.action)) {
      this.getDaily()
    }

    this.showSection(this.action)

    this.$root.$emit('enableSearch', true)

    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  },
  watch: {
    action: function (newA, oldA) {
      if (newA !== '') {
        this.showSection(newA)
      }
    },
    chartRange: {
      handler () {
        if (this.chartRange === 'Yesterday') {
          this.chartType = 'daily'
        } else if (this.chartRange === 'Last 7 days') {
          this.chartType = 'weekly'
        } else if (this.chartRange === 'Last 30 days') {
          this.chartType = 'monthly'
        }
        this.getRange()
      }
    },
    chartType: {
      handler () {
        if (this.chartType === 'daily') {
          this.chartRange = 'Yesterday'
          this.getDaily()
        } else if (this.chartType === 'weekly') {
          this.chartRange = 'Last 7 days'
          this.getWeekly()
        } else if (this.chartType === 'monthly') {
          this.chartRange = 'Last 30 days'
          this.getMonthly()
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.mono-letter {
  font-family: 'Roboto Mono', monospace;
  margin:2px;
  padding-left:9px;
  justify-content: center;
  align-items: center;
}

.profile-card-link {
  text-decoration: none;
  color: #333;
}

.report-section {
  padding-left: var(--mdc-layout-grid-margin-desktop, 24px);
  padding-right: var(--mdc-layout-grid-margin-desktop, 24px);
  padding-top:16px;
}
@media (max-width: 840px) {
  .report-section {
    padding-left: var(--mdc-layout-grid-margin-phone, 16px);
    padding-right: var(--mdc-layout-grid-margin-phone, 16px);
  }
}

@media (max-width: 480px) {
  .report-section {
    padding-left: 0;
    padding-right: 0;
  }
}

.learn-title-wrapper {
    flex-flow: row;
}

@media (max-width: 570px) {
  .learn-title-wrapper {
    flex-flow: column;
  }
  .learn-title {
    width:100%;
  }
}

/* Override grid media queries for cards
 * Built in screen sizes are not granular enough so need custom size
 * to allow profile content to file while maintaining aspect ratio.
 */
@media (min-width: 481px) and (max-width: 595px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 8;
  }
}
@media (min-width: 840px) and (max-width:   1020px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 6;
  }
}
@media (min-width: 1252px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 3;
  }
}

.mobile-links-wrapper {
  margin-left:-16px;
  margin-right:-16px;
  overflow:hidden;
  height:60px;
}
.mobile-links {
  display: flex;
  justify-content:space-between;
  flex-wrap: nowrap;
  margin-top:8px;
  padding-bottom: 10px;
  height:90px;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.mobile-links .mobile-link {
  padding-left:16px;
  flex-grow:1;
  flex-shrink:0;
  margin-left:6px;
  margin-right:6px;
  margin-bottom:2px;
}

@media (max-width: 481px) {
  .mobile-links-wrapper .mobile-links .mobile-link:first-child {
    margin-left:16px;
  }
}

@media (min-width: 481px) and (max-width:569px) {
  .mobile-links-wrapper .mobile-links .mobile-link:first-child {
    margin-left:32px;
  }
}

.mobile-links {
  padding-left:0px;
  padding-right:0px;
}

@media (any-hover: hover) {
  .recruiter-dash .mobile-links .mobile-link:hover {
    box-shadow: 0 0 0 1.5px #979797;
  }
}

</style>
