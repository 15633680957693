<template>
  <div class="navbar-holder">
    <span v-for="(item, index) in links" :key="index">
          <router-link class="nav-link" :to="item.to">
            <div class="navbar-item"
                :class="{ iconOnly: !item.text, lessOne: chemistryDisabled }"
                :style="companyStyle.bottombarItem" >
              <mdc-icon :icon="item.icon" :outlined="item.iconOutlined"></mdc-icon>
              <mdc-text typo='body2' tag="div">{{item.text}}</mdc-text>
            </div>
          </router-link>
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { inRole } from '../utils/role'
export default {
  name: 'AppNavBottom',
  props: {
    companyStyle: Object
  },
  computed: {
    ...mapState([
      'role',
      'company'
    ]),
    chemistryDisabled () {
      let chemistryDisabled = false
      if (this.company.assignedCompany) {
        chemistryDisabled = this.company.assignedCompany.chemistryDisabled
      }
      return chemistryDisabled
    },
    links () {
      return this.allLinks.filter((link) => {
        if (link.restricted) {
          return inRole(link.restricted)
        }
        if (link === 'Duos' && this.company.assignedCompany && this.company.assignedCompany.chemistryDisabled) {
          return false
        }
        return true
      })
    }
  },
  methods: {
  },
  data () {
    return {
      allLinks: [
        { text: 'Discover', to: '/dash', icon: 'search' },
        { text: 'Connect', to: '/connect', icon: 'mail', iconOutlined: true },
        { text: 'Learn', to: '/learn', icon: 'school', iconOutlined: true },
        { text: 'Manage', to: '/manage', icon: 'ballot', iconOutlined: true, restricted: 'manage' }
      ]
    }
  }
}
</script>

<style scoped>
.navbar-holder {
  padding: 0;
  margin: 0;
  display: flex;
}

.navbar-holder > span {
  flex:1;
}

.nav-link {
  text-decoration: none;
}

.navbar-item {
  font-family: 'Roboto Condensed', Roboto, sans-serif;
  font-Size: 14px;
  line-height: 16px;
  color: #F7FFFF;
  text-align: center;
  padding: 6px 0px 6px 0px;
}
.navbar-item.lessOne {
  width:33%;
  margin-left: auto;
  margin-right: auto;
}
.navbar-item.iconOnly {
  width:20%;
  font-size:1.5em;
}
.router-link-active .navbar-item {
  border-bottom: 2px solid #F8B517;
}
</style>
