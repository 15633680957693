<template>
  <div class="event-card">
    <div class="event-card-wrapper">
      <mdc-card class="event-wrapper">
        <div class="event"
        v-bind:class="{ active: isActive }"
        @click="isActive = !isActive"
        >

          <div class="event-image-wrapper">
            <div v-if="event.image" class="event-image">
              <div class="event-logo-wrapper">
                <img onerror='this.onerror = null; this.src="/static/img/icons/missing.png"' :src="event.image" class="event-logo"/>
              </div>
            </div>
            <h2 style="padding-top:24px;padding-bottom:24px;" v-else-if="event.bannerText">{{event.bannerText}}</h2>
          </div>

          <div class="event-text">
            <div class="event-detail">
              <mdc-icon style="color:var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));" icon="video_call" outlined></mdc-icon>
              <mdc-body class="event-headline">
                <strong v-html="event.title"></strong>
              </mdc-body>
            </div>
            <div class="event-detail">
              <img src="/static/img/icons/location_outline_light.svg"/>
              <mdc-body class="event-location" v-html="event.location"></mdc-body>
            </div>
            <div class="event-detail">
              <img src="/static/img/icons/calendar_outline_light.svg"/>
              <mdc-body class="event-date" v-html="event.date"></mdc-body>
            </div>
            <div class="event-detail">
              <img src="/static/img/icons/people_shares_outline_light.svg"/>
              <mdc-body class="event-location" v-html="event.interest"></mdc-body>
            </div>
          </div>

          <div  class="event-desc">
              <mdc-body style="white-space: pre-wrap;" typo="body2" v-html="event.description"></mdc-body>
          </div>
          <div class="expand-btn">
            <mdc-button>
              MORE
            </mdc-button>
          </div>

        </div>
      </mdc-card>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'EventCard',
  computed: {
    ...mapState([
      'profile'
    ]),
    isActive () {
      return this.active === true
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  watch: {
  },
  props: {
    event: {
      type: Object,
      default: function () {
        return {}
      }
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
em.text-highlight {
  font-style: normal;
  border-radius: 1em 0 1em 0;
  text-shadow: 1px 1px 1px #fff;
  background-image: linear-gradient(-100deg, rgba(255,250,150,0.15), rgba(248, 181, 23, 0.65) 100%, rgba(255,250,150,0.25) );
  padding:2px;
}
</style>
<style scoped>
.event-wrapper {
  overflow: hidden;
  background-color: white;
  position: relative;
  border-radius: 13px;
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}
.event-card {
  min-width: 255px;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}
.event {
  display:flex;
  flex-flow: column;
  top: 0;
  left: 0;
  width: 101%;
  height: auto;
  align-items: center;
}
.event .icon {
  color: #828282;
}

.event .event-headline {
  margin-left:12px !important;
}
.event .notification-dot {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 100%;
  background-color: #FFAB40;
  font-size: 9px;
  z-index: 1;
  text-align: center;
  position: absolute;
  margin-left:14px;
  margin-top:6px;
}

.event-image-wrapper {
  width: calc(100% - 32px);
  border-bottom:1px solid silver;
  display:flex;
  justify-content: center;
  align-items: center;
}
.event-headline {
  max-height: 23px;
  overflow: hidden;
}
.event-image {
  margin-top:16px;
  margin-bottom:16px;
  width: 80%;
  max-height:54px;
  margin-bottom:8px;
}
.event-text {
  width: calc(100% - 32px);
  text-align: left;
  margin-top:8px;
  padding-bottom:8px;
  overflow: hidden;
}
.event-text .event-detail {
  display:flex;
  width:100%;
  margin-left:0px;
  margin-right:0px;
  height:24px;
  align-items: center;
}
.event-text .event-detail img {
  padding-right:16px;
}
.event-desc {
  border-top:1px solid silver;
  width: calc(100% - 32px);
  margin:16px;
  margin-top:0;
  padding-top: 8px;
  margin-bottom: 8px;
  max-height: 36px;
  overflow: hidden;
  transition: all 0.8s ease-out;
}
.expand-btn {
  width: calc(100% - 16px);
  padding-top:4px;
  padding-bottom:4px;
}
.expand-btn .mdc-button{
  color: rgb(130, 130, 130);
}
.event-desc.expanded {
  max-height: 1200px;
}
.link-row {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.link-row > div {
  flex: 1;
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
}
.link-row-top > div {
  flex: 0;
}
.link-row .mdc-icon.duome-icon {
    margin-right: 3px;
}
.link-row-bottom > div:nth-of-type(2),
.link-row-bottom > div:nth-of-type(3) {
    flex: 0 0 20%;
    min-width: 30px;
    justify-content: flex-end;
}
.event-stat {
    display: flex;
    flex-direction: column;
    line-height: 0;
    flex: 1;
}
.event-card .mdc-subheading {
  margin:0;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.event-card .mdc-body {
  margin:0;
  font-size: 12px;
  line-height: 1;
}
.event-status-bar {
  display:flex;
  width:100%
}
.event-status-bar {
  display:flex;
  width:100%
}
.event-details {
  padding: 5% 2% 0 2%;
  font-family: 'Roboto', sans-serif;
  background: none;
  box-shadow: none;
}
.event-details .value {
    display: block;
    line-height: 1;
    color: #333333;
    min-height: 9px;
}
.event-details .field {
  font-style: italic;
  margin-top: 3px;
}
.event-details .duome-icon img {
  width:20px;
  height: 20px;
}

.event-logo-wrapper,
.event-logo-wrapper img {
    max-width: 100%;
    max-height: 44px;
}
.event-logo-wrapper {
    padding: 0 10%;
    display:flex;
    justify-content: center;
}
</style>
