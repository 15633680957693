import fb from './firebase'

const connectionsRef = fb.firestore.collection('connections')
let shareAgreementListener
let scheduleListener
let shareProposalListener

const state = {
  schedules: [],
  shareAgreements: [],
  shareProposals: []
}

const getConnectionActivityForUser = async (connectionId, userId, activityType) => {
  const doc = await connectionsRef
    .doc(connectionId)
    .collection(activityType)
    .doc(userId).get()
  if (!doc.exists) {
    return null
  }
  return doc.data()
}

const setConnectionActivityForUser = (connectionId, userId, activityType, data) => {
  return connectionsRef
    .doc(connectionId)
    .collection(activityType)
    .doc(userId)
    .set(data, { merge: true })
}

const resetShareAgreementListener = () => {
  if (shareAgreementListener) { shareAgreementListener() }
}

const resetScheduleListener = () => {
  if (scheduleListener) { scheduleListener() }
}

const resetShareProposalListener = () => {
  if (shareProposalListener) { shareProposalListener() }
}

const setConnectionActivity = (connectionId, activityType, data) => {
  const activityId = data.id
  if (activityId) {
    return connectionsRef
      .doc(connectionId)
      .collection(activityType)
      .doc(activityId)
      .set(data)
  } else {
    return connectionsRef
      .doc(connectionId)
      .collection(activityType)
      .add(data)
  }
}

const actions = {
  getChemistryCheckForConnection ({ commit }, { connectionId, userId }) {
    return getConnectionActivityForUser(connectionId, userId, 'chemistryCheck')
  },
  getFeedbackFormForConnection ({ commit }, { connectionId, userId }) {
    return getConnectionActivityForUser(connectionId, userId, 'feedbackForm')
  },
  loadShareProposalForConnection ({ commit }, { connectionId, userId }) {
    if (shareProposalListener) { shareProposalListener() }
    shareProposalListener = connectionsRef
      .doc(connectionId)
      .collection('shareProposal')
      .onSnapshot(querySnapshot => {
        const results = []
        querySnapshot.forEach((doc) => {
          results.push({ id: doc.id, ...doc.data() })
        })
        commit('setShareProposals', results)
      })
  },
  loadSchedulesForConnection ({ commit }, { connectionId }) {
    if (scheduleListener) { scheduleListener() }
    scheduleListener = connectionsRef
      .doc(connectionId)
      .collection('schedule')
      .onSnapshot(querySnapshot => {
        const results = []
        querySnapshot.forEach((doc) => {
          results.push({ id: doc.id, ...doc.data() })
        })
        commit('setSchedules', results)
      })
  },
  loadShareAgreementForConnection ({ commit }, { connectionId }) {
    if (shareAgreementListener) { shareAgreementListener() }
    shareAgreementListener = connectionsRef
      .doc(connectionId)
      .collection('shareAgreement')
      .onSnapshot(querySnapshot => {
        const results = []
        querySnapshot.forEach((doc) => {
          results.push({ id: doc.id, ...doc.data() })
        })
        commit('setShareAgreements', results)
      })
  },

  setChemistryCheckForConnection ({ commit }, { connectionId, userId, chemistryCheck }) {
    return setConnectionActivityForUser(connectionId, userId, 'chemistryCheck', chemistryCheck)
  },
  setShareAgreementForConnection ({ commit }, { connectionId, shareAgreement }) {
    return setConnectionActivity(connectionId, 'shareAgreement', shareAgreement)
  },
  setScheduleForConnection ({ commit }, { connectionId, schedule }) {
    return setConnectionActivity(connectionId, 'schedule', schedule)
  },
  setFeedbackFormForConnection ({ commit }, { connectionId, userId, feedbackForm }) {
    return setConnectionActivityForUser(connectionId, userId, 'feedbackForm', feedbackForm)
  },
  setShareProposalForConnection ({ commit }, { connectionId, shareProposal }) {
    return setConnectionActivity(connectionId, 'shareProposal', shareProposal)
  },
  clearSchedules ({ commit }) {
    if (scheduleListener) { scheduleListener() }
    commit('setSchedules', [])
  },
  clearShareAgreements ({ commit }) {
    if (shareAgreementListener) { shareAgreementListener() }
    commit('setShareAgreements', [])
  },
  clearShareProposals ({ commit }) {
    if (shareProposalListener) { shareProposalListener() }
    commit('setShareProposals', [])
  }
}

const mutations = {
  setSchedules: (state, schedules) => {
    state.schedules = schedules
  },
  setShareAgreements: (state, shareAgreements) => {
    state.shareAgreements = shareAgreements
  },
  setShareProposals: (state, shareProposals) => {
    state.shareProposals = shareProposals
  }
}

export default { state, actions, mutations, scheduleListener, shareAgreementListener, resetShareAgreementListener, resetScheduleListener, resetShareProposalListener }
