import { render, staticRenderFns } from "./FeaturedCardMobile.vue?vue&type=template&id=82be1816&scoped=true&"
import script from "./FeaturedCardMobile.vue?vue&type=script&lang=js&"
export * from "./FeaturedCardMobile.vue?vue&type=script&lang=js&"
import style0 from "./FeaturedCardMobile.vue?vue&type=style&index=0&id=82be1816&scoped=true&lang=css&"
import style1 from "./FeaturedCardMobile.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82be1816",
  null
  
)

export default component.exports