<template>
  <div>
    <div class="highlight-padding mobileonly"></div>
    <div class="highlight-row">
    <div class="highlight-wrapper">
        <div class="highlight">
        <router-link class="highlight-link" to="/my-profile">
          <img class="highlight-img" style="min-height:100px;min-width:100px" src="/static/img/highlights/resume.svg"/>
        </router-link>
        <span class="highlight-link-wrapper desktoponly">
            <router-link class="highlight-link" to="/my-profile"><b>Complete your profile</b></router-link>
            to improve your chance of success
        </span>
        <span class="highlight-link-wrapper mobileonly">
            <router-link class="highlight-link" to="/my-profile"><b>Update your Profile</b></router-link>
        </span>
        </div>
    </div>
    <div class="highlight-wrapper">
        <div class="highlight">
        <router-link class="highlight-link" to="/learn">
          <img class="highlight-img" style="min-height:100px;min-width:100px" src="/static/img/highlights/webinar.svg"/>
        </router-link>
        <span class="highlight-link-wrapper desktoponly">
            <router-link class="highlight-link" to="/learn"><b>Learn more</b></router-link>
            about your Flexible working options
        </span>
        <span class="highlight-link-wrapper mobileonly">
            <router-link class="highlight-link" to="/learn"><b>Learn about Flexi Work</b></router-link>
        </span>
        </div>
    </div>
    <div class="highlight-wrapper">
        <div class="highlight">
        <router-link class="highlight-link" to="/connect">
          <img class="highlight-img" style="min-height:100px;min-width:100px" src="/static/img/highlights/community.svg"/>
        </router-link>
        <span class="highlight-link-wrapper desktoponly">
            <router-link class="highlight-link" to="/connect"><b>Connect with others</b></router-link>
            looking for flexible working
        </span>
        <span class="highlight-link-wrapper mobileonly">
            <router-link class="highlight-link" to="/connect"><b>Connect to Partners</b></router-link>
        </span>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HighlightSection'
}
</script>

<style scoped>
.highlight-img {
  height:100px;
  width:100px;
}
.highlight-row {
  display:flex;
  margin-top:16px;
}
.highlight-wrapper {
  width:33%;
}
.highlight {
  display:flex;
  flex-flow:row wrap;
  align-items:center;
}
.highlight img {
  flex:1;
}
.highlight-link-wrapper {
  padding:6px;
  flex:1;
  min-width:80px;
  text-align:center;
}
.highlight-padding.mobileonly {
  margin-top:6px;
}
.highlight-link {
  color:inherit;
  text-decoration:none;
}
</style>
