import fb from './firebase'

const teamsRef = fb.firestore.collection('teams')
let teamSchedulesListener

const resetTeamScheduleListener = () => {
  if (teamSchedulesListener) { teamSchedulesListener() }
}

const actions = {
  updateTeamSchedule ({ commit }, { scheduleId, schedule }) {
    const teamId = schedule.teamId
    const scheduleRef = teamsRef
      .doc(teamId)
      .collection('schedules')
      .doc(scheduleId)
    return scheduleRef.update(schedule)
  },
  addTeamSchedule ({ commit }, { schedule }) {
    schedule.created = fb.serverTime()
    const teamId = schedule.teamId
    const team = {
      lastUpdate: fb.serverTime()
    }
    teamsRef.doc(teamId).update(team)
    const schedulesRef = teamsRef
      .doc(teamId)
      .collection('schedules')
    return schedulesRef.add(schedule)
  },
  loadMyTeamSchedules ({ commit }, { teamId }) {
    if (teamSchedulesListener) { teamSchedulesListener() }
    teamSchedulesListener = teamsRef
      .doc(teamId)
      .collection('schedules')
      .where('for', '==', this.state.user.uid)
      .orderBy('created', 'desc')
      .onSnapshot(querySnapshot => {
        const results = []
        querySnapshot.forEach((doc) => {
          results.push({ id: doc.id, ...doc.data() })
        })
        commit('setTeamSchedules', results)
      }, error => {
        console.log(error)
      })
  },
  loadAllTeamSchedules ({ commit }, teamId) {
    if (teamSchedulesListener) { teamSchedulesListener() }
    teamSchedulesListener = teamsRef
      .doc(teamId)
      .collection('schedules')
      .orderBy('created', 'desc')
      .onSnapshot(querySnapshot => {
        const results = []
        querySnapshot.forEach((doc) => {
          results.push({ id: doc.id, ...doc.data() })
        })
        commit('setTeamSchedules', results)
      }, error => {
        console.log(error)
      })
  }
}

const mutations = {
  setTeamSchedules: (state, teamSchedules) => {
    state.teamSchedules = teamSchedules
  }
}

export default { actions, mutations, resetTeamScheduleListener }
