<template>
  <div>

  <div class="team-schedule-wrapper">
    <div class="team-schedule"
      :class="{ 'has-office': office, 'has-remote': remote, 'has-tbc': tbc }"
      >
      <div class="office" v-if="office">
        <div class="title">Office</div>
        <div class="office-row">
          <div v-for="(prof, index) in office" :key="'office-' + prof + '-' + index">
            <span :title="getProfile(prof).displayName" class="avatar avatar-link">
              <span class="update-avatar">
                <img v-if="hasPhoto(getProfile(prof))" :src="getPhoto(getProfile(prof))"/>
                <default-avatar v-else fontSize="16px" :profile="getProfile(prof)"/>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="remote" v-if="remote">
        <div class="title">Remote</div>
        <div class="remote-row">
          <div v-for="(prof, index) in remote" :key="'remote-' + prof + '-' + index">
            <span :title="getProfile(prof).displayName" class="avatar avatar-link">
              <span class="update-avatar">
                <img v-if="hasPhoto(getProfile(prof))" :src="getPhoto(getProfile(prof))"/>
                <default-avatar v-else fontSize="16px" :profile="getProfile(prof)"/>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="tbc" v-if="tbc">
        <div class="title">Tbc</div>
        <div class="remote-row">
          <div v-for="(prof, index) in tbc" :key="'tbc-' + prof + '-' + index">
            <span :title="getProfile(prof).displayName" class="avatar avatar-link">
              <span class="update-avatar">
                <img v-if="hasPhoto(getProfile(prof))" :src="getPhoto(getProfile(prof))"/>
                <default-avatar v-else fontSize="16px" :profile="getProfile(prof)"/>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="team-schedule-day-row">
      <div class="day" :class="{selected: selectedDay == day}" v-for="(day, index) in days" :key="day">
        <span v-if="day == today" @click="changeDay(day, index)">
          <span>TODAY</span>
          <round-button
            v-if="day === topRemote"
            @mouseover.native="tooltipDay = day"
            @mouseleave.native="tooltipDay = false"
            @click="tooltipDay = day"
            noHover
            class="inline-button tooltip-icon small-dot blue"
            icon="house"/>
          <round-button
            v-if="day === topOffice"
            @mouseover.native="tooltipDay = day"
            @mouseleave.native="tooltipDay = false"
            @click="tooltipDay = day"
            noHover
            class="inline-button tooltip-icon small-dot blue"
            icon="corporate_fare"/>
        </span>
        <span v-else @click="changeDay(day, index)" >
          <span>{{day}}</span>
          <round-button
            v-if="day === topRemote"
            @mouseover.native="tooltipDay = day"
            @mouseleave.native="tooltipDay = false"
            @click="tooltipDay = day"
            noHover
            class="inline-button tooltip-icon small-dot blue"
            icon="house"/>
          <round-button
            v-if="day === topOffice"
            @mouseover.native="tooltipDay = day"
            @mouseleave.native="tooltipDay = false"
            @click="tooltipDay = day"
            noHover
            class="inline-button tooltip-icon small-dot blue"
            icon="corporate_fare"/>
        </span>
        <v-popover
            v-if="day === topRemote || day === topOffice"
            offset="1"
            delay="100"
            class="connection-modal-tooltip"
            container='#main-content'
            style="margin-left:-16px;"
            placement='bottom-end'
            :disabled="false"
            trigger="manual"
            :open="tooltipDay === day"
            @hide="tooltipDay = false"
            autoHide
          >
            <template slot="popover">
              <div style="display:flex;align-items:center;">
                <mdc-icon style="margin-right:16px" v-if="day === topRemote" icon="house" />
                <p v-if="day === topRemote" >Most team members remote</p>
              </div>
              <div style="display:flex;align-items:center;">
                <mdc-icon style="margin-right:16px" v-if="day === topOffice" icon="corporate_fare" />
                <p v-if="day === topOffice" >Most team members in office</p>
              </div>
            </template>
          </v-popover>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import DefaultAvatar from '../components/DefaultAvatar'

export default {
  name: 'TeamSchedule',
  components: {
    DefaultAvatar
  },
  props: {
    profiles: {},
    office: {},
    remote: {},
    tbc: {},
    topRemote: {},
    topOffice: {}
  },
  data () {
    return {
      days: [
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri'
      ],
      today: '',
      selectedDay: '',
      tooltipDay: ''
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile'
    ])
  },
  mounted () {
    let dayIndex = new Date().getDay()
    if (dayIndex === 7 || dayIndex === 0) {
      dayIndex = 1
    }
    this.today = this.days[dayIndex - 1]
    console.log('TeamSchedule.mounted', dayIndex)
    this.selectedDay = this.days[dayIndex - 1] ? this.days[dayIndex - 1] : ''
    this.$emit('getSchedule', dayIndex)
  },
  methods: {
    changeDay (day, dayIndex) {
      this.selectedDay = day
      this.$emit('getSchedule', dayIndex + 1)
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      if (profileId === 'bot') {
        return {
          displayName: 'DuoMe Bot',
          photoURL: '/static/img/chat-bubble-circle.svg'
        }
      }
      let prof = {}
      if (this.profiles) {
        const profileMap = new Map(this.profiles.map(i => [i.id, i]))
        prof = profileMap.get(profileId)
        if (!prof) {
          prof = {}
        }
      }
      return prof
    },
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    },
    getPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return profile.photoURL
        }
      }
      return '/static/img/user.jpg'
    }
  }
}
</script>

<style scoped>
.avatar {
    width:35px;
    height: 35px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: block;
    overflow: hidden;
    margin:4px;
    padding-top:0;
    position: relative;
}
.avatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.team-schedule-placeholder {
    height: 120px;
    background-image: url(/static/img/teams/team-schedule-placeholder.png);
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 10px;
}
.team-schedule-wrapper {
  display:flex;
  flex-flow:column;
  width: min-content;
}
.team-schedule {
  min-height:60px;
  display:flex;
}

.team-schedule .office {
  flex:1;
  background-color: #F2F2F2;
  border: 1px solid #828282;
  border-radius:6px;
}

.team-schedule.has-remote .office , .team-schedule.has-tbc .office {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right:none;
}

.team-schedule .remote {
  flex:1;
  background-color: #EFFFFF;
  border: 1px solid #828282;
  border-radius:6px;
}

.team-schedule.has-office .remote {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left:none;
}
.team-schedule.has-tbc .remote {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right:none;
}

.team-schedule .tbc {
  flex:1;
  background-color:  #F9E6EE;
  border: 1px solid #828282;
  border-radius:6px;
}

.team-schedule.has-office .tbc , .team-schedule.has-remote .tbc {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left:none;
}

.office > .title, .remote > .title, .tbc > .title {
  text-align: center;
  font-weight:100;
  font-size:0.9em;
  padding:6px;
}

.small-dot.blue {
  border-radius:16px;
  min-width: 16px;
  max-width: 16px;
  width:16px;
  height:16px;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  background-color:#3560af;
  color:white;
  margin-left:6px;
  margin-right:6px;
}

.team-schedule .remote-row, .team-schedule .office-row {
  display:flex;
  flex-flow: row wrap;
}

.team-schedule-day-row {
  display:flex;
}
.team-schedule-day-row > .day {
  padding:12px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.2em;
  color:#BBB;
  cursor: pointer;
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
}
.team-schedule-day-row > .day > span {
  display:flex;
  align-items: center;
}
.team-schedule-day-row > .day.selected {
  color:black
}
</style>
<style>
.small-dot.blue .material-icons{
  font-size: 12px !important;
}
</style>
