<template>
  <div class="number-selector-wrapper">

    <mdc-body v-if="prompt" typo="body1" class="padded-label" :class="{prompt: promptMobileOverlay}">{{prompt}}</mdc-body>
    <mdc-body v-if="promptMobileOverlay" typo="body1" class="padded-label prompt-mobile-overlay">{{promptMobileOverlay}}</mdc-body>

   <div class="selector-row">
       <div class="number-button"
         v-for="index in difference"
         :class="{active: value === (start + index)}"
         :key="index"
         :title="getOption(index)"
         @click="val = (start + index)" >
           {{start + index}}
       </div>
   </div>
   <div class="label-row">
       <label>{{getOption(1)}}</label>
       <label>{{getOption(difference)}}</label>
    </div>

  </div>
</template>

<script>

export default {
  name: 'NumberSelector',
  props: {
    prompt: {},
    promptMobileOverlay: {},
    value: {
      required: false
    },
    start: {
      type: Number,
      default: 0
    },
    end: {
      type: Number,
      default: 5
    },
    optionLabels: {
      type: Array
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    difference () {
      return this.end - this.start
    }
  },
  methods: {
    getOption (index) {
      if (this.optionLabels) {
        return this.optionLabels[index - 1]
      }
      return ''
    }
  },
  data () {
    return {
      keyboardfoucs: false,
      val: '',
      ready: false,
      active: true
    }
  },
  mounted () {
    this.val = this.value
  },
  watch: {
    value (newv, oldv) {
      this.formatIndex(this.value)
    },
    val (newv, oldv) {
      this.$emit('change', this.val, this.getOption(this.val))
    }
  }
}
</script>

<style>
.label-row {
  display:flex;
  flex-flow:row;
  margin-bottom:16px;
}
.label-row label {
  font-size: 12px;
}
.label-row label:first-child {
  margin-right:auto;
}

.selector-row {
  display:flex;
  flex-flow:row;
  margin-top:16px;
  margin-bottom:6px;
}
.selector-row .number-button {
   width:50px;
   height:50px;
   line-height: 50px;
   text-align: center;
   cursor:pointer;
   background: #F2F2F2;
   border-right: 1px solid #333;
   border-top: 2px solid #333;
   border-bottom: 2px solid #333;
   border-left: none;
   font-size:24px;
}
.selector-row .number-button:first-child {
   border-left: 2px solid #333;
   margin-left:auto;
   border-radius: 5px 0px 0px 5px;
}
.selector-row .number-button:last-child {
   border-right: 2px solid #333;
   margin-right:auto;
   border-radius: 0px 5px 5px 0px;
}
.selector-row .number-button:hover {
   color: #333;
   background: #BDBDBD;
}
.selector-row .number-button.active {
   color: white;
   background: #4D4D4D;
   animation: shadow-pulse 0.5s 1;
}
@keyframes shadow-pulse
{
     0% {
          background: #666;
          box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
     }
     100% {
         background: #4D4D4D;
         box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
     }
}
</style>
