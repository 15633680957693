<template>
  <div class="featured-card" v-bind:class="{ 'hover' : hoverStyle }">
    <div class="featured-card-wrapper">
      <div class="swap-container">
        <div class="swapper">
          <div class="front">
          <mdc-card style="cursor:pointer;" class="featured-wrapper">
            <div :class="'featured featured-design-6-multi'">
              <div :class="'featured-bg featured-bg-bottom'">
                  <div class="featured-image">
                    <span class="avatar">
                      <round-button noHover style="height: 46px;margin:0;border-radius: 22px;" hasSlot>
                        <img class="featured-logo" src="/static/img/Logo.svg">
                      </round-button>
                    </span>
                  </div>
              </div>
              <div class="featured-text">{{title}}</div>
            </div>
          </mdc-card>
          </div>
        </div>
      </div>
      <mdc-card @click.native.prevent.stop v-if="detail" class="featured-details" style="cursor:default" :class="{ hover : hoverStyle }">
        <div class="link-row link-row-top">
            <mdc-subheading>{{detailTitle}}</mdc-subheading>
            <mdc-body typo="body2">{{detailText}}</mdc-body>
        </div>
        <div class="link-row link-row-bottom" style="">
          <round-button
          :medium="true"
          :hoverHigh="true"
          style="padding-right:12px;width:126px;margin-right:0;margin-left:0;padding-left:12px;"
          hasSlot
          :class="{ medium: true }"
          class=""
          @click.prevent.stop="routeToProfilePersonalise"
           >
          <mdc-icon slot="leading-icon" style="margin-top: -1px; font-size:24px !important;" class="icon leading-icon" outlined icon="person_pin" />
          <span>Update</span>
        </round-button>
        </div>

      </mdc-card>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import RoundButton from '../../components/RoundButton.vue'

export default {
  name: 'FeaturedCard',
  props: {
    detail: {
      type: Boolean,
      default: true
    },
    featured: {
      type: Object,
      default: function () {
        return {}
      }
    },
    title: {
      type: String,
      default: ''
    },
    detailTitle: {
      type: String,
      default: ''
    },
    detailText: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    hoverStyle: {
      type: Boolean,
      default: true
    },
    medium: {
      type: Boolean,
      default: false
    },
    hoverHigh: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RoundButton
  },
  computed: {
    ...mapState([
      'profile'
    ])
  },
  data () {
    return {
      hover: false
    }
  },
  mounted () {
  },
  methods: {
    routeToProfilePersonalise () {
      const self = this
      if (!this.profile.aboutMe || this.profile.aboutMe === '') {
        this.$router.push({ name: 'MyProfile', params: { focus: 'aboutme' } })
          .catch(err => {
            console.warn(err)
            self.$root.$children[0].$refs.view.initialize('aboutme')
          })
      } else if (!this.profile.photoURL) {
        this.$router.push({ name: 'MyProfile', params: { focus: 'photo' } })
          .catch(err => {
            console.warn(err)
            self.$root.$children[0].$refs.view.initialize('photo')
          })
      } else {
        this.$router.push({ name: 'MyProfile', params: {} })
          .catch(err => {
            console.warn(err)
            self.$root.$children[0].$refs.view.initialize()
          })
      }
    }
  }
}
</script>

<style scoped>
.featured-card {
  min-width: 255px;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}

.featured-logo {
  height: 80%;
  padding-left: 10px;
  padding-right: 0px;
  margin-top:-8px;
}

@media (min-width: 662px){
  .featured-card .default-avatar .default-text {
    font-size: 8vw;
  }
}

@media (min-width: 840px){
  .featured-card .default-avatar .default-text {
    font-size: 5vw;
  }
}

@media (min-width: 1180px){
  .featured-card .default-avatar .default-text {
    font-size: 4vw;
  }
}

@media (min-width: 1480px){
  .featured-card .default-avatar .default-text {
    font-size: 3vw;
  }
}

.displayname {
  display:inline-block;
  overflow-wrap: break-word;
  max-width:100%;
}
.sharemenu {
  min-width: 170px;
}
/* entire container, keeps perspective */

.open-card-design .mdc-dialog__body .featured-card {
  width: 46%;
  display: inline-block;
  margin: 2%;
  cursor: pointer;
}

.featured-stat .hover-info {
  display:none;
  position:absolute;
  color:#fff;
  height:20px;
  background-color: rgba(0,0,0,1);
  align-items: center;
  padding: 2px 12px;
  margin-top: 40px;
  border-radius:5px;
  font-size:12px;
  z-index:9999;
}
.featured-stat:hover .hover-info {
  display:flex;
}

.featured-card.hover:hover .swapper .featured-wrapper {
  background:none;
}

.featured-wrapper {
  background-color:white;
  border-radius: 13px;
  width:100%;
  overflow: hidden;
  padding-top: calc(154 / 238 * 100%);
  position: relative;
}

.safari .featured-wrapper {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.featured, .featured-bg {
  display:flex;
  flex-flow: column;
  position: absolute;
  top: 0px;
  left: 0;
  width: 101%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.featured {
  top:-8px;
  background-size: cover;
  height:calc(100% + 16px);
}

/* Background position this goes on the same div as featured-bg class */
div[class*="featured-bg-"] .featured-text {
  padding-bottom: 0px;
  padding-left:0px;
}

div[class*="featured-bg-"] .featured-text img {
  display: none;
}

.featured-bg-diagonal {
  background: linear-gradient(70deg, transparent 35%, #F2EEE6 0%);
}
.featured-card:hover .featured-bg-diagonal {
  background: linear-gradient(70deg, transparent 35%, #DEDBD6 0%);
}
.featured-bg-top {
  background: linear-gradient(#F2EEE6 85%, transparent 0%);
}
.featured-bg-top .featured-text {
  padding: 0;
}
.featured-card:hover .featured-bg-top {
  background: linear-gradient(#DEDBD6 85%, transparent 0%);
}
.featured-bg-bottom {
  background: linear-gradient(transparent 40%, #F2EEE6 0%);
}
.featured-card:hover .featured-bg-bottom {
  background: linear-gradient(transparent 40%, #DEDBD6 0%);
}
.featured-bg-left {
  background: linear-gradient(90deg, transparent 22%, #F2EEE6 0%);
}
.featured-card:hover .featured-bg-left {
  background: linear-gradient(90deg, transparent 22%, #DEDBD6 0%);
}

div[class*="featured-bg-"] .featured-text {
  padding-bottom: 0px;
}

div[class*="featured-bg-"] .featured-text img {
  display: none;
}

/* Background colour/bg options this goes on the same div a featured class */
.featured-colour-cyan {
  background-color: #2cbcb6;
}
.featured-colour-magenta {
  background-color: #C10457;
}
.featured-colour-orange {
  background-color: #f4854d;
}
.featured-colour-yellow {
  background-color: #ffd12b;
}
.featured-colour-blue {
  background-color: #3560AF;
}
.featured-design-6-multi {
  background-image: url('/static/img/card-designs/profile-design-6-multi.svg');
}
.featured-image {
    padding-top: 4px;
}

.featured-text {
  text-align: center;
  padding: 0;
  font-family: 'Nanum Pen Script', cursive;
  font-size:2em;
  overflow: hidden;
  position: absolute;
  margin-top: 30%;
}
.link-row {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.link-row > div {
  flex: 1;
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.link-row-top {
  height:47px;
  flex-flow:column;
  justify-content: flex-start;
}
.link-row-top > div {
  flex: 0;
}
.featured-stat {
    display: flex;
    flex-direction: column;
    line-height: 0;
}
.featured-headline {
  font-style:italic;
  font-size: 14px;
  color: #333333;
}
.featured-card .featured-image {
  position:relative;
  bottom: 10%;
}
.featured-card .mdc-body.featured-status {
  max-width: calc(100% + 12px);
  overflow: hidden;
}
.featured-card .featured-image .featured-status {
  color:gray;
}
.featured-card .featured-image.editable:hover {
  cursor:pointer;
}
.featured-card .featured-image.editable .overlay{
  display:none;
  position:absolute;
  width:100%;
  height:100%;
}
.featured-card .featured-image.editable:hover .overlay{
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/424395/camera.svg");
  background-size: 50px 41px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.7;
  z-index:9;
  display:block;
}
.featured-card .mdc-subheading {
  margin:0;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.featured-card .mdc-body {
  margin:0;
  font-size: 12px;
  line-height: 1;
}
span[class^="days-"] {
  margin-top: 3px;
  width: 55px;
  white-space: nowrap;
}
span[class^="days-"] .day {
  height: 2px;
  background: #E0E0E0;
  display: inline-block;
  width: 8px;
  margin-left: 1.5px;
  margin-right: 1.5px;
}
span.days-1 .day:nth-of-type(-n+1) {
  background: #AD1457;
}
span.days-2 .day:nth-of-type(-n+2) {
  background: #AD1457;
}
span.days-3 .day:nth-of-type(-n+3) {
  background: #AD1457;
}
span.days-4 .day:nth-of-type(-n+4) {
  background: #AD1457;
}
span.days-5 .day:nth-of-type(-n+5) {
  background: #AD1457;
}

span.days-1-5 .day:nth-of-type(-n+1) {
  background: #AD1457;
}
span.days-2-5 .day:nth-of-type(-n+2) {
  background: #AD1457;
}
span.days-3-5 .day:nth-of-type(-n+3) {
  background: #AD1457;
}
span.days-4-5 .day:nth-of-type(-n+4) {
  background: #AD1457;
}

span.days-0-5 .day:nth-of-type(1) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-1-5 .day:nth-of-type(2) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-2-5 .day:nth-of-type(3) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-3-5 .day:nth-of-type(4) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-4-5 .day:nth-of-type(5) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}

.featured-status-bar {
  display:flex;
  width:100%
}
.featured-details {
  padding: 5% 0 0 0;
  font-family: 'Roboto', sans-serif;
  background: none;
  box-shadow: none;
}
.featured-details .duome-icon img {
  width:20px;
  height: 20px;
}
.featured .edit-icon {
  background: #fff;
  position: absolute;
  top: 18px;
  right: 17px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex;
  margin: 0;
}
.featured .edit-icon:hover {
  cursor:pointer;
}
.featured .edit-icon + .hover-info {
  display:none;
  position:absolute;
  color:white;
  height:20px;
  background-color: rgba(0,0,0,1);
  align-items: center;
  padding: 2px 12px;
  border-radius:5px;
  font-size:12px;
  z-index: 101;
  left: 30px;
  bottom: 25px;
}
.featured .edit-icon:hover + .hover-info {
  display:flex;
}
.featured-details .value {
    display: block;
    line-height: 1;
    color: #333333;
    min-height: 9px;
}
.featured-details .field {
  font-style: italic;
  margin-top: 3px;
}
.location {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.location .featured-stat .value {
  font-style:italic;
  font-size: 14px;
}
.role {
  font-weight: 500;
  font-style:italic;
  font-size: 14px;
}
.mdc-icon {
  margin-right: 5px;
}
.featured-card:not(:hover) .link-row-bottom .mdc-button {
  background-color: #ffffff;
  box-shadow: none;
}
.featured-card .link-row-bottom .mdc-button .mdc-icon {
  margin: 0 10px 5px 0;
}
.featured-card .link-row-bottom {
  justify-content: flex-start;
}
.featured-card .link-row-bottom > div {
  flex:0;
}
.featured-details .mdc-button img {
  margin-right:6px;
}
.featured-details .mdc-button img.icon-active {
  display:none;
}
.featured-card.hover:hover .featured-wrapper {
  background: #F6F6F6;
}
.about > div {
  font-size: 14px;
  margin: 10px 0;
}
.action {
  cursor: pointer;
}
.bookmark {
  cursor: pointer;
  color: #333333;
}
.modal .mdc-dialog__container {
  width: calc(100% - 20px);
  min-width: 340px !important;
  max-width: 885px !important;
}
</style>

<style>
/** Not Scoped for hover styling sub component **/

.featured-card .open-card-design .mdc-dialog__surface {
  height: calc(100vh - 80px);
  min-height: 400px;
}
.featured-card .open-card-design .mdc-dialog__body--scrollable {
  height:100%;
  max-height:100%;
}
.featured-card .button-row {
  display: flex;
  align-items: center;
}
.featured-card .button-row .verify {
  color:#b00020;
  margin-left:8px;
}

.featured-card .button-row .verifed {
  color:#00b020;
  margin-left:8px;
}
</style>
