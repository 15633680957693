<template>
  <mdc-dialog v-model="open"
      title="Decline Request" accept="Decline"
      @accept="sendRejection">
      <div class="mdc-textfield-wrapper message-wrap">
        <p class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">Message</p>
        <textarea-autosize
        style="margin-top: 4px; padding-left:4px;"
        class="mdc-text-field__input message-input"
        :placeholder="messageDefault"
        ref="messageInput"
        v-model="message"
        rows="1"
        :minHeight="20"
        :maxHeight="90"
        ></textarea-autosize>
        </div>
  </mdc-dialog>
</template>

<script>
import * as backend from '../utils/backend'
export default {
  name: 'RejectModal',
  data () {
    return {
      open: false,
      messageDefault: 'I’m not looking to partner right now',
      message: 'I’m not looking to partner right now'
    }
  },
  props: {
    openModal: Boolean,
    connection: Object
  },
  components: {
  },
  computed: {
  },
  methods: {
    async sendRejection () {
      await backend.appFunction('botmessage', {
        status: 'ChemistryRejection',
        fromId: this.user.uid,
        text: this.message,
        connectionId: this.connection.id,
        connection: this.connection
      })
      this.open = false
    }
  },
  watch: {
    openModal (newOpen, oldOpen) {
      this.open = newOpen
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
