<template>
  <div class="main-content">
    <div class="main-wrapper share-agreement">
      <mdc-layout-grid class="main-content-inner">
        <mdc-layout-cell span=12>

          <mdc-card class="share-schedule page-card">
            <mdc-card-primary-action>
              <mdc-card-media class="image-cover" src="/static/img/share.png">
                <mdc-card-header>
                    <mdc-card-action-icon style="color:white" @click="$router.go(-1)" icon="arrow_back_ios" />
                    <mdc-card-title>Operating Agreement</mdc-card-title>
                    <mdc-card-subtitle>How To Share</mdc-card-subtitle>
                </mdc-card-header>
              </mdc-card-media>
            </mdc-card-primary-action>

            <mdc-card-actions>
              <mdc-card-action-icons>
                <mdc-card-action-icon @click="saveShare" icon="done" />
              </mdc-card-action-icons>
            </mdc-card-actions>

            <mdc-card-text v-if="shareAgreement">

              <div class="profile-section">
                <mdc-body>How do you propose to share the role?</mdc-body>
                <mdc-body class="sub-label">
                    This provides an outline for how you plan to operate and informs the hiring manager of what to expect.
                </mdc-body>
                <mdc-layout-grid>
                    <mdc-layout-cell span="4">
                      <mdc-radio v-model="shareAgreement.shareType" :checked="shareAgreement.shareType === 'Shared Responsibility'" name="shareType" label="Shared Responsibility"  />
                        <span class="mobileonly stageinfohider" v-if="shareAgreement.shareType === 'Shared Responsibility'"></span>
                        <span class="mobileonly stageinfospacer" v-else></span>
                        <div class="mdc-form-field stageinfo">
                        <div class="label">This means</div>
                        <div>
                          The tasks or outcomes of the role are mostly shared between the partners.
                          This is the most frequently selected approach.
                        </div>
                      </div>

                    </mdc-layout-cell>
                    <mdc-layout-cell span="4">
                      <mdc-radio v-model="shareAgreement.shareType" :checked="shareAgreement.shareType === 'Split Responsibility'" name="shareType" label="Split Responsibility"  />
                      <span class="mobileonly stageinfohider" v-if="shareAgreement.shareType === 'Split Responsibility'"></span>
                      <span class="mobileonly stageinfospacer" v-else></span>
                      <div class="mdc-form-field stageinfo">
                        <div class="label">This means</div>
                        <div>
                          The tasks or outcomes of the role are mostly split between partners.
                          This still requires handover and awareness across partners but is appropriate in some instances
                        </div>
                      </div>

                    </mdc-layout-cell>
                    <mdc-layout-cell span="4">
                      <mdc-radio v-model="shareAgreement.shareType" :checked="shareAgreement.shareType === 'Hybrid'" name="shareType" label="Hybrid"  />
                      <span class="mobileonly stageinfohider" v-if="shareAgreement.shareType === 'Hybrid'"></span>
                      <div class="mdc-form-field stageinfo">
                        <div class="label">This means</div>
                        <div>A different approach is taken and can be described by the partners below.</div>
                      </div>
                    </mdc-layout-cell>
                  </mdc-layout-grid>

                  <div v-if="shareAgreement.shareType === 'Split Responsibility'">
                    <mdc-textfield type="text" label="Responsibilities"
                    style="width:100%"
                    helptext="Briefly describe how you expect to split the role, e.g. by projects, by clients, by task, etc."
                    helptext-persistent helptext-validation
                    v-model="shareAgreement.responsibilities" />
                  </div>

                  <div v-if="shareAgreement.shareType === 'Hybrid'">
                    <mdc-textfield type="text" label="Split Responsibilities"
                    style="width:100%"
                    helptext="Briefly describe how you intend to split the role, this will be include in your proposal for hiring managers."
                    helptext-persistent helptext-validation
                    v-model="shareAgreement.responsibilities" />
                  </div>

              </div>

              <div class="profile-section">
                <mdc-body>On what schedule do you propose to share the role?</mdc-body>
                <mdc-body class="sub-label">
                    This defines the agreement between partners on how the full-time role will be covered
                </mdc-body>
                <mdc-layout-grid>
                  <mdc-layout-cell span="3">
                    <mdc-radio v-model="shareAgreement.shareSplit" :checked="shareAgreement.shareSplit === 'Split Week'" name="shareSplit" label="Split Week"  />
                      <span class="mobileonly stageinfohider" v-if="shareAgreement.shareSplit === 'Split Week'"></span>
                      <span class="mobileonly stageinfospacer" v-else></span>
                      <div class="mdc-form-field stageinfo">
                      <div class="label">This means</div>
                      <div>The working week is split between partners with the full week covered</div>
                    </div>

                  </mdc-layout-cell>
                  <mdc-layout-cell span="3">
                    <mdc-radio v-model="shareAgreement.shareSplit" :checked="shareAgreement.shareSplit === 'Split Day'" name="shareSplit" label="Split Day"  />
                    <span class="mobileonly stageinfohider" v-if="shareAgreement.shareSplit === 'Split Day'"></span>
                    <span class="mobileonly stageinfospacer" v-else></span>
                    <div class="mdc-form-field stageinfo">
                      <div class="label">This means</div>
                      <div>Partners split the day into an AM/PM schedule</div>
                    </div>

                  </mdc-layout-cell>
                  <mdc-layout-cell span="3">
                    <mdc-radio v-model="shareAgreement.shareSplit" :checked="shareAgreement.shareSplit === 'Alternate Weeks'" name="shareSplit" label="Alternate Weeks"  />
                    <span class="mobileonly stageinfohider" v-if="shareAgreement.shareSplit === 'Alternate Weeks'"></span>
                    <span class="mobileonly stageinfospacer" v-else></span>
                    <div class="mdc-form-field stageinfo">
                      <div class="label">This means</div>
                      <div>That partners work alternate weeks.</div>
                    </div>

                  </mdc-layout-cell>
                  <mdc-layout-cell span="3">
                    <mdc-radio v-model="shareAgreement.shareSplit" :checked="shareAgreement.shareSplit === 'Other'" name="shareSplit" label="Other"  />
                    <span class="mobileonly stageinfohider" v-if="shareAgreement.shareSplit === 'Other'"></span>
                    <div class="mdc-form-field stageinfo">
                      <div class="label">This means</div>
                      <div>If non of these options are appropriate select this and describe below</div>
                    </div>

                  </mdc-layout-cell>
                </mdc-layout-grid>

                <div v-show="shareAgreement.shareSplit === 'Other'" >
                  <mdc-textfield type="text" label="Other Schedule"
                    style="width:100%"
                    helptext="Please set out your schedule arrangment here"
                    helptext-persistent helptext-validation
                    v-model="shareAgreement.otherSchedule" />
                </div>

                <div v-show="shareAgreement.shareSplit === 'Alternate Weeks'" style="">
                  <table v-if="connection">
                    <tr v-for="(val, userId) in connection.users" :key="userId">
                      <td>
                        <img :src="getProfile(userId).photoURL" class="avatar"/>
                      </td>
                      <td>
                        {{getProfile(userId).displayName}}
                      </td>
                      <td>
                        <mdc-select @change="setAlternateWeek" outlined v-model="shareAgreement.alternateWeeks[userId]" >
                          <select-item :value="userId + '-1'" text="Week 1"></select-item>
                          <select-item :value="userId + '-2'" text="Week 2"></select-item>
                        </mdc-select>
                      </td>
                    </tr>
                  </table>
                </div>

                <div v-show="shareAgreement.shareSplit === 'Split Day'" style="">
                  <table v-if="connection">
                    <tr v-for="(val, userId) in connection.users" :key="userId">
                      <td>
                        <img :src="getProfile(userId).photoURL" class="avatar"/>
                      </td>
                      <td>
                        {{getProfile(userId).displayName}}
                      </td>
                      <td>
                        <mdc-select @change="setSplitDay" outlined v-model="shareAgreement.splitDay[userId]" >
                          <select-item :value="userId + '-AM'" text="AM"></select-item>
                          <select-item :value="userId + '-PM'" text="PM"></select-item>
                        </mdc-select>
                      </td>
                    </tr>
                  </table>
                </div>

                <div v-show="shareAgreement.shareSplit === 'Split Week'" style="display:flex;flex-flow:row wrap">
                  <div style="min-width:200px;flex:1;">
                    <mdc-select outlined v-model="shareAgreement.scheduleType" >
                      <select-item
                        v-for="(scheduleType, index) in shareAgreement.scheduleTypes"
                        :key="index"
                        :value="scheduleType">
                        {{scheduleType}}
                      </select-item>
                    </mdc-select>

                    <div style="padding:6px;">
                      <table v-if="connection">
                        <tr v-for="(val, userId) in connection.users"
                        :key="userId"
                        :class="{'profile-select': true, selected: selectedProfile === userId || (selectedProfile === '' && userId === profile.id) }"
                        @click="selectedProfile = userId">
                          <td>
                            <img :src="getProfile(userId).photoURL" class="avatar"/>
                          </td>
                          <td>
                            {{getProfile(userId).displayName}}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div class="share-table-container">
                    <table class="share-table mobile-share-table" cell-spacing="4">
                      <tr>
                        <th></th>
                        <th v-for="(slot, index) in shareAgreement.daySlots" :key="index">
                          {{slot}}
                        </th>
                      </tr>
                      <tr v-for="(day, index) in shareAgreement.days" :key="index">
                        <th>
                          {{day}}
                        </th>
                        <td style="background-color:#eee" v-for="(slot, index) in shareAgreement.daySlots" :key="index">
                          <div v-if="profiles" class="wrapper">
                            <img v-if="shareAgreement.selected.week1[day][slot].selected" :src="getProfile(shareAgreement.selected.week1[day][slot].user).photoURL" class="avatar schedule-slot-background">
                            <mdc-icon-toggle primary
                              v-model="shareAgreement.selected.week1[day][slot].selected"
                              @input="toggleUser(selectedProfile, shareAgreement.selected.week1[day][slot])"
                              :toggle-on="{cssClass:'schedule-slot-active' }"
                              :toggle-off="{cssClass: 'schedule-slot'}">
                            </mdc-icon-toggle>
                          </div>
                        </td>
                      </tr>
                    </table>
                    <div v-if="shareAgreement.scheduleType === 'Two Week Schedule'">
                      <table class="share-table mobile-share-table" cell-spacing="4">
                        <tr>
                          <th></th>
                          <th v-for="(slot, index) in shareAgreement.daySlots" :key="index">
                            {{slot}}
                          </th>
                        </tr>
                        <tr v-for="(day, index) in shareAgreement.days" :key="index">
                          <th>
                            {{day}}
                          </th>
                          <td style="background-color:#eee" v-for="(slot, index) in shareAgreement.daySlots" :key="index">
                            <div v-if="profiles" class="wrapper">
                              <img v-if="shareAgreement.selected.week2[day][slot].selected" :src="getProfile(shareAgreement.selected.week2[day][slot].user).photoURL" class="avatar schedule-slot-background">
                              <mdc-icon-toggle primary
                                v-model="shareAgreement.selected.week2[day][slot].selected"
                                @input="toggleUser(selectedProfile, shareAgreement.selected.week2[day][slot])"
                                :toggle-on="{cssClass:'schedule-slot-active' }"
                                :toggle-off="{cssClass: 'schedule-slot'}">
                              </mdc-icon-toggle>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <table class="share-table" cell-spacing="4">
                      <tr>
                        <th></th>
                        <th v-for="(day, index) in shareAgreement.days" :key="index">
                          {{day}}
                        </th>
                      </tr>
                      <tr v-for="(slot, index) in shareAgreement.daySlots" :key="index">
                        <th>{{slot}}</th>
                        <td style="background-color:#eee" v-for="(day, index) in shareAgreement.days" :key="index">
                          <div v-if="profiles" class="wrapper">
                            <img v-if="shareAgreement.selected.week1[day][slot].selected" :src="getProfile(shareAgreement.selected.week1[day][slot].user).photoURL" class="avatar schedule-slot-background">
                            <mdc-icon-toggle primary
                              v-model="shareAgreement.selected.week1[day][slot].selected"
                              @input="toggleUser(selectedProfile, shareAgreement.selected.week1[day][slot])"
                              :toggle-on="{cssClass:'schedule-slot-active' }"
                              :toggle-off="{cssClass: 'schedule-slot'}">
                            </mdc-icon-toggle>
                          </div>
                        </td>
                      </tr>
                    </table>

                    <div v-if="shareAgreement.scheduleType === 'Two Week Schedule'">
                      <table class="share-table" cell-spacing="4">
                        <tr>
                          <th></th>
                          <th v-for="(day, index) in shareAgreement.days" :key="index">
                            {{day}}
                          </th>
                        </tr>
                        <tr v-for="(slot, index) in shareAgreement.daySlots" :key="index">
                          <th>{{slot}}</th>
                          <td style="background-color:#eee" v-for="(day, index) in shareAgreement.days" :key="index">
                            <div v-if="profiles" class="wrapper">
                              <img v-if="shareAgreement.selected.week2[day][slot].selected" :src="getProfile(shareAgreement.selected.week2[day][slot].user).photoURL" class="avatar schedule-slot-background">
                              <mdc-icon-toggle primary
                                v-model="shareAgreement.selected.week2[day][slot].selected"
                                @input="toggleUser(profile.id, shareAgreement.selected.week2[day][slot])"
                                :toggle-on="{cssClass:'schedule-slot-active' }"
                                :toggle-off="{cssClass: 'schedule-slot'}">
                              </mdc-icon-toggle>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>

              </div>

              <div class="profile-section">
                <mdc-body>The following are some best practice commitments you can add or define your own?</mdc-body>
                <mdc-body class="sub-label">
                    It is important to agree the commitments you are making between each other to make the share work, these will be shared as part of your proposal.
                </mdc-body>

                <p>
                  <mdc-checkbox v-model="shareAgreement.commitmentHandover">
                    <span>
                      We agree to provide timely and effective handovers to ensure that both parties are
                      effective and informed when they are the primary person in the role.
                    </span>
                  </mdc-checkbox>
                </p>

                <p>
                  <mdc-checkbox v-model="shareAgreement.commitmentFeedback">
                    <span>
                      We agree to routinely discuss feedback we receive about how the Job Share
                      operates with an aim to improve and optimize our joint performance.
                    </span>
                  </mdc-checkbox>
                </p>

                 <p>
                  <mdc-checkbox v-model="shareAgreement.commitmentReview">
                    <span>
                      We agree to periodically allow time to discuss how we are jointly performing the
                      direction we need to take the joint role.
                    </span>
                  </mdc-checkbox>
                </p>

                 <p>
                  <mdc-checkbox v-model="shareAgreement.commitmentMeetings">
                    <span>
                      We agree to schedule joint meetings with our manager to make the most effective
                      use of everybody's time.
                    </span>
                  </mdc-checkbox>
                </p>

                 <p>
                  <mdc-checkbox v-model="shareAgreement.commitmentResolve">
                    <span>
                      We agree to provide honest and fair feedback to our partner with an aim to resolve
                      issues within our partnership as a first priority.
                    </span>
                  </mdc-checkbox>
                </p>

                <div v-if="shareAgreement.otherCommitments">
                  <p v-for="(commitment, index) in shareAgreement.otherCommitments" class="custom-commitment" :key="index">

                    <span class="mdc-checkbox mdc-checkbox--upgraded">
                      <input checked='checked' disabled=true type="checkbox" class="mdc-checkbox__native-control">
                    <span class="mdc-checkbox__background">

                      <svg viewBox="0 0 24 24" class="mdc-checkbox__checkmark">
                        <path fill="none" stroke="white" d="M1.73,12.91 8.1,19.28 22.79,4.59" class="mdc-checkbox__checkmark-path"></path>
                      </svg>
                      <div class="mdc-checkbox__mixedmark"></div>
                    </span>
                    </span>

                    <span>
                      {{commitment}}
                    </span>

                    <mdc-button @click="remOtherCommitment(index)" >
                      <mdc-icon icon="clear"/>
                    </mdc-button>

                  </p>
                </div>

                <p class="commitment-add" >

                  <mdc-textfield type="text" label="Add Other Commitment"
                    style="flex:1;"
                    helptext="You can enter your own commitment here"
                    helptext-persistent helptext-validation
                    v-model="otherCommitment" />

                  <mdc-button @click="addOtherCommitment">
                    <mdc-icon  icon="add"/>
                  </mdc-button>
                </p>

              </div>

            </mdc-card-text>
          </mdc-card>

        </mdc-layout-cell>
      </mdc-layout-grid>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'ShareAgreement',
  data () {
    return {
      title: 'Share Agreement',
      links: [
      ],
      connectionId: null,
      connection: null,
      shareAgreementId: null,
      shareAgreementActivity: null,
      selectedProfile: '',
      otherCommitment: '',
      shareAgreement: {
        scheduleType: 'One Week Schedule',
        scheduleTypes: ['One Week Schedule', 'Two Week Schedule'],
        days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        daySlots: ['AM', 'PM'],
        responsibilities: '',
        otherSchedule: '',
        otherCommitments: [],
        shareSplit: null,
        shareType: null,
        splitDay: {
        },
        alternateWeeks: {
        },
        selected: {
          week1: {
            Mon: { AM: { selected: false }, PM: { selected: false } },
            Tue: { AM: { selected: false }, PM: { selected: false } },
            Wed: { AM: { selected: false }, PM: { selected: false } },
            Thu: { AM: { selected: false }, PM: { selected: false } },
            Fri: { AM: { selected: false }, PM: { selected: false } },
            Sat: { AM: { selected: false }, PM: { selected: false } },
            Sun: { AM: { selected: false }, PM: { selected: false } }
          },
          week2: {
            Mon: { AM: { selected: false }, PM: { selected: false } },
            Tue: { AM: { selected: false }, PM: { selected: false } },
            Wed: { AM: { selected: false }, PM: { selected: false } },
            Thu: { AM: { selected: false }, PM: { selected: false } },
            Fri: { AM: { selected: false }, PM: { selected: false } },
            Sat: { AM: { selected: false }, PM: { selected: false } },
            Sun: { AM: { selected: false }, PM: { selected: false } }
          }
        },
        questions: {
          q1: '',
          q2: '',
          q3: '',
          q4a: '',
          q4b: '',
          q5: '',
          q6: '',
          q7: ''
        }
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      profile: state => state.profile,
      connections: state => state.connections,
      profiles: state => state.profiles,
      shareAgreements: state => state.connectionActivities.shareAgreements
    })
  },
  methods: {
    ...mapActions(
      [
        'setLoading',
        'setSnackMessage',
        'loadShareAgreementForConnection',
        'clearShareAgreements',
        'setShareAgreementForConnection',
        'setPageLoad'
      ]
    ),
    toggleUser: function (userId, shareslot) {
      if (userId === '') {
        userId = this.profile.id
      }
      shareslot.user = userId
    },
    saveShare: function () {
      this.setShareAgreementForConnection({ connectionId: this.connectionId, shareAgreement: this.shareAgreement }).then(() => {
        this.setSnackMessage('Share Agreement updated')
      })
    },
    addOtherCommitment () {
      this.shareAgreement.otherCommitments.push(this.otherCommitment)
      this.otherCommitment = ''
    },
    remOtherCommitment (idx) {
      this.shareAgreement.otherCommitments.splice(idx, 1)
    },
    getProfile: function (profileId) {
      let profile = {}

      if (this.profiles) {
        const profileMap = new Map(this.profiles.map(i => [i.id, i]))
        if (this.profile) {
          profileMap.set(this.profile.id, this.profile)
        }
        profile = profileMap.get(profileId)
      }

      if (!profile) {
        profile = {}
      }

      if (!profile.photoURL) {
        profile.photoURL = '/static/img/icons-60.png'
      }

      return profile
    },
    setSplitDay: function (val) {
      if (!this.shareAgreement.splitDay) {
        this.shareAgreement.splitDay = this.connection.users
      }
      const userid = val.substring(0, val.length - 3)
      const split = val.substr(-2)
      for (const key in this.connection.users) {
        if (key !== userid) {
          this.shareAgreement.splitDay[key] = (split === 'AM' ? key + '-PM' : key + '-AM')
        }
      }
    },
    setAlternateWeek: function (val) {
      if (!this.shareAgreement.alternateWeeks) {
        this.shareAgreement.alternateWeeks = this.connection.users
      }
      const userid = val.substring(0, val.length - 2)
      const split = val.substr(-1)
      for (const key in this.connection.users) {
        if (key !== userid) {
          this.shareAgreement.alternateWeeks[key] = (split === '1' ? key + '-2' : key + '-1')
        }
      }
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$emit('setNavActive', null)
    this.clearShareAgreements()
  },
  mounted () {
    this.setLoading(true)
    this.$emit('setLinks', this.links)
    this.$emit('setNavActive', 'Connect')
    this.connectionId = this.$route.params.id
    if (this.connections) {
      const connectionsMap = new Map(this.connections.map(i => [i.id, i]))
      this.connection = connectionsMap.get(this.connectionId)
    }
    this.loadShareAgreementForConnection({ connectionId: this.connectionId })
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  },
  watch: {
    shareAgreements: function (newS, oldS) {
      if (!this.shareAgreementId) {
        // No shareAgreement Id, get last shareAgreement
        this.shareAgreementActivity = this.shareAgreements[0]
        Object.assign(this.shareAgreement, this.shareAgreementActivity)
        if (!this.shareAgreement.users) {
          if (this.connection) {
            this.shareAgreement.users = Object.keys(this.connection.users)
          }
        }
        this.setLoading(false)
      }
    },
    connections: function (newC, oldC) {
      const connectionsMap = new Map(newC.map(i => [i.id, i]))
      this.connection = connectionsMap.get(this.connectionId)
      if (!this.shareAgreement.users) {
        if (this.connection) {
          this.shareAgreement.users = Object.keys(this.connection.users)
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.share-agreement table {
  border-spacing: 6px;
  border-collapse: separate;
  min-width:350px;
}
.share-agreement .profile-section {
  margin-bottom:36px;
}
.share-agreement .mdc-radio-wrapper {
  border:1px solid silver;
  border-radius:20px;
  padding-right:20px;
}
.share-agreement .mdc-textfield {
  width:100%;
  border-bottom-color: rgba(0, 0, 0, 0.42);
  background-color: whitesmoke;
  height:56px;
}

.share-agreement .mdc-textfield input.mdc-text-field__input {
  padding-left:12px !important;
}
.share-agreement p.sub-label {
  font-size:0.8em;
  margin-top:0;
  margin-bottom:16px;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
}
.share-agreement p {
  margin-block-end: 0.2em;
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.share-agreement p.sub-label.nomargin {
  margin-bottom:0;
}
.share-agreement .stageinfo {
  display:block;
  margin-top:6px;
  margin-left:26px;
  border-left:2px solid silver;
  padding-left:6px;
}
.share-agreement .stageinfo .label{
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
  margin-bottom:12px;
}
.share-agreement {
  margin-bottom:50px;
}
.share-agreement  .mdc-select--box:not(.mdc-select--disabled) {
  background: transparent;
}
.share-agreement .share-schedule .wrapper {
  margin: 0 auto;
  width:48px;
  height:48px;
}
.share-agreement .share-schedule .avatar {
  width:48px;
  height:48px;
}
.share-agreement .share-schedule .schedule-slot-background {
  width:48px;
  height:48px;
  position:absolute;
}
.share-agreement .share-schedule .mdc-icon-toggle {
  margin:0;
}
.share-agreement .share-table {
  width: 100%;
  table-layout: fixed;
}
.share-agreement .share-schedule table.share-table tr th {
  height:60px;
  min-width:60px;
  padding-left:6px;
  padding-right:6px;
}
.share-agreement .share-schedule table.share-table tr td {
  height:60px;
  width:60px;
  background-color:#eee;
  position: relative;
}

.share-agreement .profile-select {
  cursor:pointer;
  color:#c3c3c3;
}
.share-agreement .profile-select.selected {
  cursor:pointer;
  color:var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.share-agreement .custom-commitment {
  align-items: center;
  display: flex;
}
.share-agreement .custom-commitment .mdc-button {
  top:-4px;
}
.share-agreement .commitment-add {
  display:flex;
}
.share-agreement .commitment-add .mdc-button {
  margin-top:16px;
  height: 48px;
}
.share-agreement .commitment-add .mdc-floating-label {
  padding-left: 10px;
}
.share-table-container {
  flex:2;
  border-left:1px solid silver;
  margin-left:16px;
  min-width: 400px;
}
.mobile-share-table {
  display:none;
}
@media(max-width:840px){
  .share-table:not(.mobile-share-table) {
    display: none;
  }
  .mobile-share-table {
    display: block;
  }
  .stageinfohider + .mdc-form-field.stageinfo {
      display: block;
  }
  .stageinfospacer {
    height: 26px;
    width: 100%;
    display: block;
    margin-top: 6px;
    margin-left: 26px;
    border-left: 2px solid silver;
    padding-left: 6px;
  }
  .mdc-form-field.stageinfo {
      display: none;
  }
}
</style>
