<template>
  <div class="main-content focus-test">
    <div class="main-wrapper">

      <mdc-layout-grid>
        <mdc-layout-cell span=12>
        </mdc-layout-cell>
      </mdc-layout-grid>

    <mdc-layout-grid>
      <mdc-layout-cell desktop=12 tablet=8 phone=4>
        <mdc-textfield type="text"
          label="TextField"
          helptext="- Help Text"
          outlined
          autocomplete="new-password"
          autofill="off"
          v-model="someVal" />
      </mdc-layout-cell>
    </mdc-layout-grid>

    <div class="height-filler">some filler content</div>

    <mdc-layout-grid>
      <mdc-layout-cell desktop=12 tablet=8 phone=4>
        <div class="mdc-textfield-wrapper location-autocomplete">
              <div class="mdc-textfield mdc-text-field mdc-text-field--upgraded mdc-text-field--outlined outlined mdc-text-field--with-leading-icon"
                v-bind:class="{'mdc-text-field--focused': locationFocused}"
                >
                <div style="padding: 17px 0 0 17px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path fill="none" d="M0 0h24v24H0V0z"/>
                  <path fill="#828282" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"/>
                  <circle fill="#828282" cx="12" cy="9" r="2.5"/>
                </svg>
                </div>
                <vue-google-autocomplete
                  ref="address"
                  id="location-ac"
                  autocomplete="new-password"
                  autofill="off"
                  @focus="locationFocus"
                  @blur="locationBlur"
                  @inputChange="updateLocation"
                  classname="mdc-text-field__input"
                  placeholder=""
                  types="(cities)"
                  :country="['uk']"
                  @placechanged="getAddressData"
                >
                </vue-google-autocomplete>
                <div class="mdc-notched-outline">
                  <div class="mdc-notched-outline__leading"></div>
                  <div class="mdc-notched-outline__notch">
                    <label for="location-ac" class="mdc-floating-label" :class="{'mdc-floating-label--float-above': locationInputVal || profileLocation || locationFocused}" >
                      Location
                      <i class="required"></i>
                    </label>
                  </div>
                  <div class="mdc-notched-outline__trailing"></div>
                </div>
              </div>
            </div>
      </mdc-layout-cell>
    </mdc-layout-grid>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
  name: 'LocationTest',
  components: {
    VueGoogleAutocomplete
  },
  data () {
    return {
      someVal: '',
      locationInputVal: '',
      profileLocation: '',
      locationFocused: false
    }
  },
  mounted () {
    this.setPageLoad(false)
  },
  methods: {
    ...mapActions([
      'setPageLoad'
    ]),
    locationFocus () {
    },
    locationBlur () {
    },
    updateLocation (evt) {
      this.profileLocation = evt
    },
    getAddressData () {
    }
  }
}
</script>

<style>
.focus-test .height-filler {
  height: 400px;
  margin: 16px;
  line-height: 400px;
  text-align: center;
  border: 1px dashed silver;
  background: rgba(0,0,0,0.1);
  color: #333333;
}
.focus-test p.sub-label {
  font-size:12px;
  margin-top:6px;
  margin-bottom:16px;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
}
.focus-test .mdc-text-field-helper-line {
  padding-left:0px;
}
</style>
