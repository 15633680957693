import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { datetimeFormat, dateFormat, dateDisplayFormat, dateShortDisplayFormat, timeFormat } from './utils/time'
import { rolePlugin, teamLeaderPlugin } from './utils/role'
import ShareNetwork from 'vue-social-sharing'
import VTooltip from 'v-tooltip'
import Fitty from 'vue-fitty'
import './fa.config'

import browserDetect from 'vue-browser-detect-plugin'

import VueCroppie from 'vue-croppie'
import 'croppie/croppie.css'

import VueCarousel from 'vue-carousel'

import PortalVue from 'portal-vue'

import VueTextareaAutosize from 'vue-textarea-autosize'

import components from './global.components'
Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})

Vue.use(VueTextareaAutosize)
Vue.use(PortalVue)
Vue.use(VueCarousel)
Vue.use(VueCroppie)
Vue.use(browserDetect)
Vue.use(VTooltip)
Vue.use(Fitty)
Vue.use(ShareNetwork)
Vue.use(rolePlugin, { router })
Vue.use(teamLeaderPlugin, { router })

// Filters
Vue.filter('datetimeFormat', datetimeFormat)
Vue.filter('dateFormat', dateFormat)
Vue.filter('dateDisplayFormat', dateDisplayFormat)
Vue.filter('dateShortDisplayFormat', dateShortDisplayFormat)
Vue.filter('timeFormat', timeFormat)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
