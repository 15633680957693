<template>
  <!-- experience -->
  <mdc-card class="experience">
    <mdc-card-text>

        <mdc-title>
        <span style="margin: 16px;" v-if="!editonly">Experience</span>
        <span v-if="editable && !editonly">
            <mdc-button title="Edit" v-if="!editingExperience" @click="editProfileExperience"><mdc-icon icon="create"/></mdc-button>
            <mdc-button title="Save" v-else @click="updateProfileExperience"><mdc-icon icon="done"/></mdc-button>
        </span>
        <span v-if="editable && !editonly" style="float:right">
          <mdc-switch v-if="detailedEnabled" label="Detailed Experience" v-model="detailed" />
        </span>
        </mdc-title>

        <div v-if="!detailed || !detailedEnabled">

          <div v-if="(editable && editingExperience) || editonly" >
            <div>
              <mdc-list>
                <div v-for="(ex, index) in profileRoles" :key="'exp-' + index">
                <mdc-list-item style="display:flex" v-bind:class="{active: expExpanded === index}">
                  <span style="margin-left:6px; flex:1" >{{ex.name}}</span>
                  <span>{{ex.experience}} years</span>
                  <span class="end-detail" slot="end-detail">
                  <round-button @click.prevent.stop="deleteRole(index)" iconOutline title="Delete" icon="delete" label="Delete"/>
                  </span>
                </mdc-list-item>
                </div>
              </mdc-list>
            </div>
            <div class="add-row">
              <mdc-body typo="body2" style="color:#b00020;margin-left:8px;" v-if="expValidationMessage">{{expValidationMessage}}</mdc-body>
              <mdc-layout-grid style="padding-left:6px;padding-right:6px;">
                <mdc-layout-cell desktop=6 tablet=8 phone=4>
                  <auto-complete-outline
                    autocompleteId="'newrole'"
                    :field="'role'"
                    :value="newRoleName"
                    @input="setNewRole"
                    @set="setNewRole"
                    label="Role / Speciality"
                    :items="roles"
                    ariaLabelledBy="newroleLabel"
                    :isMobile="isMobile"
                    :mobileOverlay="true"
                    next="experienceYears"
                    @focusOn="focusOn"
                    ref="experienceRole"
                    helptext = "- E.g. Project Manager"
                    promptMobileOverlay="What roles or specialities do you have experience in?"
                    :valid="roleNameValid  && (!expValidationMessage || newRoleName !== '')"
                  />
                </mdc-layout-cell>
                <mdc-layout-cell desktop=6 tablet=8 phone=4 style="display:flex">
                  <div style="flex:1; min-width:130px">
                    <app-select
                      outlined
                      style="width:100%;height: 56px;"
                      :valid="roleExpValid && (!expValidationMessage || newRoleExp !== '')"
                      v-model="newRoleExp"
                      label="Years Exp"
                      :isMobile="isMobile"
                      :mobileOverlay="true"
                      prev="experienceRole"
                      :finish="true"
                      @focusOn="focusOn"
                      @finish="addRole"
                      helptext = "- E.g. 3 Years"
                      :promptMobileOverlay="'How many years of Experience do you have in this Role? - ' + newRoleName"
                      ref="experienceYears">
                        <select-item v-for="i in 10" :key="'expy' + i" :value="i - 1" />
                        <select-item value="10+" />
                    </app-select>
                  </div>
                  <div class="round-button-wrapper-right">
                    <round-button
                      :medium="editonly"
                      @click="addRole"
                      elevated
                      hasSlot
                      title="Add"
                      icon="add"
                      label="Add Entry">Add</round-button>
                  </div>
                </mdc-layout-cell>
              </mdc-layout-grid>
            </div>
          </div>
          <div v-else style="margin-left:16px;margin-right:16px;">
            <div style="display:flex;padding:4px;position:relative;" v-for="(exp, index) in experienceBars" :key="'exp-'+index" :title="exp.role">
                <div :class="'exp-bar-wrapper-' + exp.class" >
                  <mdc-body typo="body2" :class="'exp-bar-' + exp.class">
                    <div class="bar" :style="{'width': exp.width}"></div>
                    <span class="exp-bar-role">{{exp.role}}</span>
                    <span class="exp-bar-label">{{exp.years}} Years</span>
                  </mdc-body>
                </div>
            </div>
          </div>

        </div>
        <div v-else>

        <div v-if="(editable && editingExperience) || editonly">
        <mdc-body typo="body2" style="color:#b00020" v-if="expValidationMessage">{{expValidationMessage}}</mdc-body>
        <mdc-list interactive two-line>
            <div v-for="(ex, index) in experience" :key="'exp-' + index">
            <mdc-list-item v-bind:class="{active: expExpanded === index}" @click="setExpExpanded(index)">
                <span>{{ex.place}}</span>
                <span slot="secondary">{{ex.position}}</span>
                <span class="end-detail" slot="end-detail">
                <mdc-button @click.prevent.stop="deleteExp(index)">
                    <i class="material-icons">delete</i>
                </mdc-button>
                <mdc-button @click.prevent.stop="setExpExpanded(index)">
                    <i v-if="expExpanded === index" class="material-icons">keyboard_arrow_up</i>
                    <i v-else class="material-icons">keyboard_arrow_down</i>
                </mdc-button>
                </span>
            </mdc-list-item>
            <div class="sub-expanded" v-show="expExpanded == index">
                <div style="padding:16px;">
                <mdc-textfield
                    required
                    helptext="Employer - E.g. Microsoft"
                    type="text"
                    style="width:49%"
                    label="Company"
                    v-model="ex.place" />

                    <mdc-textfield
                    required
                    helptext="Title - E.g. Manager"
                    type="text"
                    style="width:49%"
                    label="Title"
                    v-model="ex.position" />

                    <auto-complete
                    label="Role"
                    autocompleteId="'role'"
                    :index="index"
                    :field="'role'"
                    :value="ex.role"
                    v-on:set="setRole"
                    :items="roles"
                    ariaLabelledBy="roleLabel"
                    tooltip="Sometimes Job Titles can be confusing, Use this optional field to highlight a more understandable activity you performed, i.e. Project Manager."
                    />

                    <div class="flex-group">

                    <div class="flex-group" style="white-space:nowrap">
                        <mdc-select required class="dateSelect" v-model="ex.fromMonth" label="Month From">
                          <select-item v-for="i in getMonthsBefore(ex.fromYear, ex.toYear, ex.toMonth)" :key="'exmf-'+i" :value="i"/>
                        </mdc-select>

                        <mdc-select required class="dateSelect" v-model="ex.fromYear" label="Year From">
                          <select-item v-for="i in getYearsBefore(ex.toYear)" :key="'exyf-'+i" :value="i"/>
                        </mdc-select>
                    </div>

                    <div class="flex-group" style="white-space:nowrap">
                        <mdc-select required class="dateSelect" v-model="ex.toMonth" label="Month To">
                          <select-item v-for="i in getMonthsAfter(ex.fromYear, ex.toYear, ex.fromMonth)" :key="'exmt-'+i" :value="i"/>
                        </mdc-select>

                        <mdc-select required class="dateSelect"  v-model="ex.toYear" label="Year To">
                          <select-item  v-for="i in getYearsAfter(ex.fromYear)"  :key="'exyt-'+i" :value="i"/>
                        </mdc-select>
                    </div>
                    </div>

                    <mdc-checkbox style="padding-left:12px;"  label="Show this Experience in graphic" v-model="ex.show"/>

                    <div class="mdc-textfield-wrapper tiptap-wrap">
                    <p class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">Description</p>
                    <tiptap-editor @onTextUpdate="onExpDescriptionUpdate" :content="ex.description" :title="'Description'" :contentKey="'idx' + index">
                    </tiptap-editor>
                    </div>

                </div>
              </div>
            </div>
            <div style="display:flex">
            <mdc-button title="Add Entry" style="margin-left:auto;height:56px;" @click="addExperience">
                <mdc-icon icon="add"></mdc-icon>
            </mdc-button>
            </div>
        </mdc-list>
        </div>
        <div v-else>
        <div style="display:flex;padding:4px;position:relative;" v-for="(exp, index) in experienceBars" :key="'exp-'+index" :title="exp.role">
            <div :class="'exp-bar-wrapper-' + exp.class" >
              <mdc-body typo="body2" :class="'exp-bar-' + exp.class">
                <div class="bar" v-bind:style="{'width': exp.width}"></div>
                <span class="exp-bar-role">{{exp.role}}</span>
                <span class="exp-bar-label">{{exp.years}} Years</span>
              </mdc-body>
            </div>

        </div>
        <div v-if="!condensed">
          <div style="padding:4px;border-bottom:1px solid silver;" v-for="(exp, index) in experienceGrouped" :key="'expg-'+index">
              <mdc-body><b>{{exp.place}}</b></mdc-body>
              <mdc-body v-if="exp.experience.length > 1" typo="body2">{{exp.from}} - {{exp.to}}</mdc-body>
              <mdc-body typo="body2"></mdc-body>
              <ul>
              <li class="subexp" v-for="(ex, index) in exp.experience" :key="'subexp-' + index">
                  <mdc-body class="subexp-title"><b>{{ex.position}}</b></mdc-body>
                  <div class="subexp-body">
                  <mdc-body typo="body2">{{ex.fromMonth}} {{ex.fromYear}} - {{ex.toMonth}} {{ex.toYear}}</mdc-body>
                  <mdc-body v-if="ex.description" v-html="ex.description" typo="body2"></mdc-body>
                  </div>
              </li>
              </ul>
          </div>
        </div>
      </div>
      </div>

    </mdc-card-text>
  </mdc-card>
</template>

<script>
import { mapState } from 'vuex'
import AutoCompleteOutline from '../components/AutoCompleteOutline.vue'
import { getYearArray, getMonthArray, differenceYears, differenceYearsMonths } from '../utils/time'
import RoundButton from '../components/RoundButton'
import AppSelect from '../components/AppSelect'

function compareFrom (a, b) {
  if (a.fromYear < b.fromYear) {
    return 1
  }
  return -1
}

function compareYears (a, b) {
  if (a.years < b.years) {
    return 1
  }
  return -1
}

export default {
  name: 'ExperienceSection',
  components: {
    TiptapEditor: () => import('../components/Tiptap'),
    AutoCompleteOutline,
    RoundButton,
    AppSelect
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    editonly: {
      type: Boolean,
      default: false
    },
    condensed: {
      type: Boolean,
      default: false
    },
    roleNameValid: {
      type: Boolean,
      default: true
    },
    roleExpValid: {
      type: Boolean,
      default: true
    },
    basic: {
      type: Boolean,
      default: true
    },
    reset: {
      type: Boolean,
      default: false
    },
    defaultRole: {},
    profile: Object,
    isMobile: {
      type: Boolean,
      default: false
    },
    newRoleNameVal: {
      type: String,
      default: ''
    },
    newRoleExpVal: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      detailedEnabled: false,
      editingExperience: false,
      expExpanded: -1,
      expValidationMessage: '',
      expDescUpdates: {},
      experience: [],
      currentExp: null,
      newRoleName: '',
      newRoleExp: '',
      profileRoles: [],
      detailed: null
    }
  },
  computed: {
    ...mapState([
      'roles'
    ]),
    experienceBars: function () {
      const expBars = []
      const expBarMap = {}
      if (!this.detailed || !this.detailedEnabled) {
        if (this.profile.roles) {
          for (const exp of this.profile.roles) {
            const role = exp.name
            if (expBarMap[role]) {
              expBarMap[role].years += Number(exp.experience.replace('+', ''))
            } else {
              expBarMap[role] = { years: Number(exp.experience.replace('+', '')), role: role }
            }
          }
        }
      } else {
        if (this.profile.experience) {
          for (const exp of this.profile.experience) {
            const role = exp.role ? exp.role : exp.position
            if (expBarMap[role]) {
              expBarMap[role].years += this.getYearDiff(exp)
            } else {
              expBarMap[role] = { years: this.getYearDiff(exp), role: role }
            }
          }
        }
      }
      const expBarsArr = Object.values(expBarMap)
      expBarsArr.sort(compareYears)
      if (expBarsArr[0]) {
        const fullWidthYears = expBarsArr[0].years
        expBars.push({ years: expBarsArr[0].years, role: expBarsArr[0].role, width: 'calc(100% - 10px)', class: 'full' })
        if (expBarsArr[1]) {
          const medWidth = Math.round((expBarsArr[1].years * 100) / fullWidthYears)
          expBars.push({ years: expBarsArr[1].years, role: expBarsArr[1].role, width: `calc(${medWidth}% - 8px)`, class: 'med' })
          if (expBarsArr[2]) {
            const smallWidth = Math.round((expBarsArr[2].years * 100) / fullWidthYears)
            expBars.push({ years: expBarsArr[2].years, role: expBarsArr[2].role, width: `calc(${smallWidth}% - 8px)`, class: 'small' })
            if (expBarsArr.length > 3) {
              var otherYears = 0
              for (var i = 3; i < expBarsArr.length; i++) {
                const exp = expBarsArr[i]
                otherYears += exp.years
              }
              expBars.push({ years: otherYears, role: 'Other Experience', width: '100%', class: 'other' })
            }
          }
        }
      }
      return expBars
    },
    experienceGrouped: function () {
      const expGrouped = {}
      if (this.profile.experience) {
        var lastPlace = null
        var lastPlaceIndex = null
        var i = 0
        const experience = this.profile.experience
        if (experience) {
          experience.sort(compareFrom)
          for (const exp of experience) {
            if (exp.place === lastPlace) {
              if (exp.fromYear) {
                if (expGrouped[exp.place + '-' + lastPlaceIndex].from < exp.fromYear) {
                  expGrouped[exp.place + '-' + lastPlaceIndex].from = exp.fromYear
                }
              }
              if (exp.toYear) {
                if (expGrouped[exp.place + '-' + lastPlaceIndex].from > exp.toYear) {
                  expGrouped[exp.place + '-' + lastPlaceIndex].from = exp.toYear
                }
              }
              expGrouped[exp.place + '-' + lastPlaceIndex].experience.push(exp)
            } else {
              expGrouped[exp.place + '-' + i] = { experience: [exp], place: exp.place }
              expGrouped[exp.place + '-' + i].from = exp.fromYear
              expGrouped[exp.place + '-' + i].to = exp.toYear

              lastPlaceIndex = i
              lastPlace = exp.place
            }
            i++
          }
          for (const group in expGrouped) {
            if (expGrouped[group].length > 1) {
              expGrouped[group].sort(compareFrom)
            }
          }
        }
      }
      return expGrouped
    }
  },
  methods: {
    focusOn (target, direction) {
      console.log('focusOn', target, this.$refs[target])
      this.focusField = target
      if (this.$refs[target]) {
        try {
          this.$refs[target].focus()
        } catch (err) {
          if (this.$refs[target].$el) {
            this.$refs[target].$el.focus()
          }
        }
      }
    },
    triggerNext (next) {
      console.log('ProfileModals.TriggerNext', next)
      this.focusOn(next)
    },
    triggerPrev (prev) {
      console.log('ProfileModals.TriggerPrev', prev)
      this.focusOn(prev, 'previous')
    },
    closePortal () {
      this.$root.$children[0].$refs.view.$emit('inputBlurred')
      this.focusField = false
    },
    setRole: function (role, index) {
      this.$set(this.experience[index], 'role', role.role)
    },
    addRole: function () {
      this.expValidationMessage = ''
      if (this.newRoleName && this.newRoleExp) {
        const newRole = { name: this.newRoleName, experience: this.newRoleExp }
        this.profileRoles.push(newRole)
        this.newRoleName = ''
        this.newRoleExp = null
        if (this.editonly) {
          this.$emit('updateExperience', { roles: this.profileRoles })
        }
      } else {
        this.expValidationMessage = 'Please enter a role and years experience.'
      }
    },
    deleteRole: function (index) {
      this.profileRoles.splice(index, 1)
      if (this.editonly) {
        this.$emit('updateExperience', { roles: this.profileRoles })
      }
    },
    setNewRole: function (role) {
      if (typeof role === 'object') {
        this.newRoleName = role.role
      } else {
        this.newRoleName = role
      }
    },
    editProfileExperience: function () {
      this.editingExperience = true
      this.experience = this.profile.experience
      this.profileRoles = this.profile.roles
    },
    updateProfileExperience: function () {
      if (!this.detailed || !this.detailedEnabled) {
        this.editingExperience = false
        if (this.newRoleName && this.newRoleExp) {
          this.addRole()
        }
        this.$emit('updateProfileSection', { roles: this.profileRoles })
      } else {
        if (this.experienceValid()) {
          this.editingExperience = false
          for (const descUpdateKey in this.expDescUpdates) {
            const index = parseInt(descUpdateKey.replace('idx', ''))
            this.experience[index].description = this.expDescUpdates[descUpdateKey]
          }
          this.$emit('updateProfileSection', { experience: this.experience })
          this.editingExperience = false
        }
      }
    },
    getMonths: function () {
      return getMonthArray()
    },
    getYears: function () {
      return getYearArray()
    },
    getYearDiff: function (exp) {
      if (exp) {
        if (exp.fromYear && exp.toYear) {
          return differenceYears(exp.fromYear, exp.toYear, exp.fromMonth, exp.toMonth)
        }
      }
      return 0
    },
    getYearMonthDiff: function (exp) {
      if (exp) {
        if (exp.fromYear && exp.toYear) {
          return differenceYearsMonths(exp.fromYear, exp.toYear, exp.fromMonth, exp.toMonth)
        }
      }
      return ''
    },
    getYearsAfter: function (after) {
      const years = getYearArray()
      if (after) {
        const afterInt = parseInt(after)
        const index = years.indexOf(afterInt)
        if (index <= 0) {
          return years
        }
        return years.slice(0, index + 1)
      }
      return years
    },
    getYearsBefore: function (before) {
      const years = getYearArray()
      if (before) {
        const beforeInt = parseInt(before)
        const index = years.indexOf(beforeInt)
        if (index === -1) {
          return years
        }
        return years.slice(index)
      }
      return years
    },
    getMonthsAfter: function (beforeYear, afterYear, afterMonth) {
      const months = getMonthArray()
      if (afterYear && (afterYear === beforeYear) && afterMonth) {
        const index = months.indexOf(afterMonth)
        if (index === -1) {
          return months
        }
        return months.slice(index)
      }
      return months
    },
    getMonthsBefore: function (beforeYear, afterYear, beforeMonth) {
      const months = getMonthArray()
      if (afterYear && (afterYear === beforeYear) && beforeMonth) {
        const index = months.indexOf(beforeMonth)
        if (index <= 0) {
          return months
        }
        return months.slice(0, index + 1)
      }
      return months
    },
    onExpDescriptionUpdate: function (text, contentKey) {
      this.expDescUpdates[contentKey] = text
    },
    addExperience: function () {
      if (!this.experience) {
        this.experience = []
      }
      if (this.experienceValid()) {
        if (this.editonly) {
          for (const descUpdateKey in this.expDescUpdates) {
            const index = parseInt(descUpdateKey.replace('idx', ''))
            this.experience[index].description = this.expDescUpdates[descUpdateKey]
          }
          this.$emit('updateProfileSection', { experience: this.experience })
        }
        this.experience.push({
          place: '',
          position: '',
          show: true,
          years: '',
          fromMonth: '',
          fromYear: '',
          toMonth: '',
          toYear: ''
        })
        this.setExpExpanded(this.experience.length - 1)
      }
    },
    moveExpDown: function (index) {
      if (index < this.experience.length - 1) {
        const item = this.experience[index]
        this.experience.splice(index, 1)
        this.experience.splice(index + 1, 0, item)
        if (this.expExpanded !== -1) {
          this.expExpanded = index + 1
        }
      }
    },
    moveExpUp: function (index) {
      if (index > 0) {
        const item = this.experience[index]
        this.experience.splice(index, 1)
        this.experience.splice(index - 1, 0, item)
        if (this.expExpanded !== -1) {
          this.expExpanded = index - 1
        }
      }
    },
    deleteExp: function (index) {
      this.experience.splice(index, 1)
      this.expExpanded = -1
    },
    setExpExpanded: function (indx) {
      if (this.expExpanded === indx) {
        this.expExpanded = -1
        this.currentExp = null
      } else {
        this.expExpanded = indx
        this.currentExp = this.experience[indx]
      }
    },
    experienceValid: function () {
      let valid = true
      this.expValidationMessage = ''
      this.experience.forEach((ex, i) => {
        if (ex.place === '' || ex.position === '' || ex.fromMonth === '' || ex.fromYear === '' || ex.toMonth === '' || ex.toYear === '') {
          valid = false
          if (this.expExpanded !== i) {
            this.setExpExpanded(i)
          }
          this.expValidationMessage = '* Please fill out the Company, Role Title and Dates'
        }
      })
      return valid
    },
    cronoOrder: function (list) {
      return list
    }
  },
  mounted () {
    if (this.editonly) {
      this.editProfileExperience()
    }
    if (!this.detailedEnabled) {
      this.detailed = !this.basic
    }
    if (this.newRoleNameVal !== '') {
      this.newRoleName = this.newRoleNameVal
    }
    if (typeof this.defaultRole !== 'undefined') {
      if (this.newRoleName === '') {
        if (this.profileRoles.length < 1) {
          this.newRoleName = this.defaultRole
        }
      }
    }
    this.$emit('updateNewRoleName', this.newRoleName)
    this.newRoleExp = this.newRoleExpVal
  },
  watch: {
    defaultRole: function (newd, oldd) {
      if (typeof this.defaultRole !== 'undefined') {
        if (this.newRoleName === '') {
          if (this.profileRoles.length < 1) {
            this.newRoleName = this.defaultRole
            this.$emit('updateNewRoleName', this.newRoleName)
          }
        }
      }
    },
    detailed: function (newd, oldd) {
      if (oldd !== null) {
        this.$emit('updateProfileSection', { experienceDetailed: this.detailed })
      }
    },
    newRoleName: function (newr, oldr) {
      this.$emit('updateNewRoleName', this.newRoleName)
    },
    newRoleExp: function (newr, oldr) {
      this.$emit('updateNewRoleExp', this.newRoleExp)
    },
    reset: function (newr, oldr) {
      if (this.reset) {
        this.newRoleName = ''
        this.newRoleExp = ''
      }
    }
  }
}
</script>

<style scoped>

.round-button-wrapper-right {
  margin-bottom: 2px;
  margin-top: 11px;
}
.round-button-wrapper-right .round-button {
  margin-right:0;
}

.experience .mdc-card-text {
  padding:0;
}

.experience.negative-margin {
  margin-left: -24px;
  margin-right: -24px;
}

.experience.negative-margin li, .experience.negative-margin .add-row {
  padding-left: 20px;
  padding-right: 20px;
}

.experience.negative-margin li.mdc-list-item {
  padding-left: 32px;
  padding-right: 20px;
}

@media (max-width: 840px) {
  .experience.negative-margin {
    margin-left: -16px;
    margin-right: -16px;
  }
  .experience.negative-margin li, .experience.negative-margin .add-row {
    padding-left: 12px;
    padding-right: 12px;
  }
  .experience.negative-margin li.mdc-list-item {
    padding-left: 24px;
    padding-right: 12px;
  }
}

.experience {
  min-width:230px;
}
.experience .bar {
  position:absolute;
}
.experience .flex-row {
  display: flex;
  flex-flow: row wrap;
  margin-top:16px;
}
.experience .flex-left {
  flex: 1;
}
.exp-bar-label {
  position: absolute;
  right:12px;
  background-color:transparent;
  font-size: 12px;
  font-weight: 500;
  padding:4px 6px 4px 6px;
  height:calc(100% - 16px);
}
.exp-bar-role {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 70px);
  z-index: 9;
  padding-left: 12px;
}
.exp-bar-wrapper-other {
  flex:1;
  overflow:hidden;
  font-style:italic;
}
.exp-bar-other {
  flex:1;
  border-radius:3px;
  padding:7px 4px 4px 16px;
  margin:0;
  width:100%;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.exp-bar-wrapper-full {
  flex:1;
  overflow:hidden;
  border-radius: 3px;
}
.exp-bar-full {
  flex:1;
  color:white;
  padding:5px 6px 6px 0px;
  margin:0;
  width:auto;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-radius: 3px;
}
.exp-bar-full .bar {
  width:100%;
  height:31px;
  background-color:#E91E63;
  border-radius: 3px;
}
.exp-bar-full > span {
  white-space: nowrap;
}
.exp-bar-wrapper-med {
  flex:1;
  background-color:#BDBDBD;
  border-radius: 3px;
}
.exp-bar-med {
  flex:1;
  color:white;
  padding:5px 6px 6px 0px;
  margin:0;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-radius: 3px;
}
.exp-bar-med .bar {
  width:60%;
  height:31px;
  background-color:#FF6C00;
  border-radius: 3px;
}
.exp-bar-med > span {
  white-space: nowrap;
}
.exp-bar-wrapper-small {
  flex:1;
  background-color:#BDBDBD;
  border-radius: 3px;
}
.exp-bar-small {
  flex:1;
  color:white;
  padding:5px 6px 6px 0px;
  margin:0;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-radius: 3px;
}
.exp-bar-small .bar {
  width:30%;
  background-color:#27BCB5;
  border-radius: 3px;
  height:31px;
}
.exp-bar-small > span {
  white-space: nowrap;
}

.education .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
  margin-right:12px;
}
.education .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
  height:36px;
}
.education {
  margin-bottom:16px;
}

.round-button-wrapper-right {
  margin-bottom: 2px;
  margin-top: 11px;
  margin-right:0;
}

@media (max-width: 479px) {
  .flex-row .flex-left {
    min-width:100%;
    margin-right:0 !important;
  }
}
</style>
<style>
.experience .add-row .mdc-layout-grid__inner {
  row-gap: 0;
}
.experience .mdc-switch-label {
  padding-left:12px;
}
.experience .mdc-list-item__meta {
  z-index:1;
}
.experience .mdc-list-item {
  padding-top:6px;
  padding-bottom:6px;
  padding-right:0px;
}
.experience .end-detail .round-button {
  margin-right: 6px !important;
}
</style>
