<template>
  <app-dialog
    v-model="open"
    title="Profile Photo"
    accept="Save"
    :accept-disabled="!saveEnabled"
    @accept="uploadAvatar"
    keepOpen
    cancel="cancel"
    class="photo-modal fullsize-mobile"
    scrollable>
      <div class="mdc-dialog__body">
        <image-uploader :save="saveAvatar" :image="profilePhoto" @loaded="setAvatarSaveDisabled" @change="uploadAvatarFile" id="test" width="250" height="250" />
      </div>
    </app-dialog>
</template>

<script>
import AppDialog from '../../components/AppDialog'
import ImageUploader from '../../components/profile-v2/ImageUploader.vue'

export default {
  name: 'PhotoModal',
  props: {
    openPhoto: {
      type: Boolean,
      default: false
    },
    profile: {}
  },
  components: {
    AppDialog,
    ImageUploader
  },
  computed: {
    profilePhoto () {
      if (this.hasPhoto(this.profile)) {
        return this.profile.photoURL
      }
      return null
    }
  },
  data () {
    return {
      open: false,
      saveAvatar: false,
      saveEnabled: false
    }
  },
  methods: {
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    },
    uploadAvatar () {
      this.saveAvatar = true
    },
    setAvatarSaveDisabled (val) {
      this.saveEnabled = val
    },
    uploadAvatarFile (file, filename) {
      this.saveAvatar = false
      this.$emit('upload', file, filename)
    }
  },
  watch: {
    openPhoto: {
      handler (newo, oldo) {
        this.open = this.openPhoto
      },
      immediate: true
    },
    open (newo, oldo) {
      if (this.open !== this.openPhoto) {
        this.$emit('change', this.open)
      }
    }
  }
}

</script>

<style>
.photo-modal .mdc-dialog__surface {
     max-width: 500px !important;
}
@media all and (min-width: 540px) {
    .photo-modal .mdc-dialog__surface {
        min-width:500px !important;
        max-width: 500px !important;
    }
}
</style>
