<template>
  <div class="main-content profile">

    <div class="profile-header-wrapper">
      <div class="profile-header">

        <cover-section :profile="profile" @updateProfileSection="updateProfileSection" :editable="myProfile" style="width:100%;height:100%;" />

        <div class="main-wrapper" style="overflow:visible">
          <mdc-layout-grid >
            <mdc-layout-cell mobile="1" tablet="4" desktop="8">
            </mdc-layout-cell>
            <mdc-layout-cell mobile="3" tablet="4" desktop="4">
            </mdc-layout-cell>
          </mdc-layout-grid>
        </div>
      </div>
    </div>

    <mdc-title v-if="message">{{message}}</mdc-title>

    <div v-if="profile" class="main-wrapper main-content-wrapper">

      <div class="profile-col-wrapper">

        <div class="upshift profile-col-1">

          <!-- Headline -->
          <headline-section @hasFocused="focus = null" :focus="focus" :isMobile="isMobile" @editing="setEditing" style="margin-bottom:16px;" @updateProfileSection="updateProfileSection" :profile="profile" :other="!myProfile" :editable="myProfile  && (!editing || editing === 'headline')"></headline-section>

          <!-- Profile modals -->
          <div v-if="myProfile">
            <profile-modals :isMobile="isMobile" :triggerOpen="openProfileModals" @triggered="openProfileModals = false" @editing="setEditing" @uploadCV="cvuploadmodalopen = true" ></profile-modals>
          </div>

          <!-- Avatar photo -->
          <photo-modal @editing="setEditing" :openPhoto="avatarmodalopen" @upload="uploadAvatarFile" @change="avatarmodalopen = $event" :profile="profile" :accept-disabled="saveAvatarDisabled" />

          <!-- Card design -->
          <card-design-modal :advancedEnabled="tasksCompleted" @openTasks="$emit('openProfileTasks')" @editing="setEditing" @openPhoto="avatarmodalopen = true;openCardDesign = false" :openCardDesign="openCardDesign" @change="openCardDesign = $event" @cardDesign="updateCardDesign" :profile="profile" />

          <!-- Experience -->
          <experience-section :isMobile="isMobile" @editing="setEditing" @updateProfileSection="updateProfileSection" :profile="profile" :basic="!profile.experienceDetailed" :editable="myProfile && (!editing || editing === 'experience')"></experience-section>

          <!-- Education -->
          <education-section :isMobile="isMobile" @editing="setEditing" v-on:updateProfileSection="updateProfileSection" :profile="profile" :other="!myProfile" :editable="myProfile && (!editing || editing === 'education')"></education-section>

          <!-- Skills for mobile only -->
          <skill-section :isMobile="isMobile" class="mobile" @editing="setEditing" @updateProfileSection="updateProfileSection" :profile="profile" :other="!myProfile" :editable="myProfile && (!editing || editing === 'skills')"></skill-section>

        </div>

        <div class="upshift--tablet profile-col-2">

          <!-- Profile Card-->
          <div v-if="profile" style="margin-right:auto;margin-left:auto;" class="profile-card-wrapper">
            <profile-card style="margin-bottom:16px;margin-right:auto;margin-left:auto;" @blocked="$emit('openProfileTasks')" @avatar="openAvatarDialog" @openCardDesign="openCardDesign = true" :detail="!myProfile" :hoverStyle="false" :user="user" :profile="profile" inProfile></profile-card>
          </div>

          <!-- Preferences -->
          <preferences-section :isMobile="isMobile" @editing="setEditing" v-if="myProfile" @updateProfileSection="updateProfileSection" :profile="profile" :editable="!editing || editing === 'preferences'"></preferences-section>

          <!-- Skills -->
          <skill-section class="tablet" @editing="setEditing" @updateProfileSection="updateProfileSection" :profile="profile" :other="!myProfile" :editable="myProfile && (!editing || editing === 'skills')"></skill-section>

        </div>

      </div>

    </div>
  </div>

</template>

<script>
import ProfileCard from '../components/profile-v2/ProfileCard.vue'
import CardDesignModal from '../components/profile-v2/CardDesignModal.vue'
import ProfileModals from '../components/profile-v2/ProfileModals.vue'
import SkillSection from '../components/profile-v2/SkillSection.vue'
import EducationSection from '../components/profile-v2/EducationSection.vue'
import ExperienceSection from '../components/profile-v2/ExperienceSection.vue'
import PreferencesSection from '../components/profile-v2/PreferencesSection.vue'
import HeadlineSection from '../components/profile-v2/HeadlineSection.vue'
import PhotoModal from '../components/profile-v2/PhotoModal.vue'
import CoverSection from '../components/profile-v2/CoverSection.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { docxFunctionUrl } from '../utils/docx'

export default {
  name: 'user',
  components: {
    ProfileCard,
    CardDesignModal,
    ProfileModals,
    PhotoModal,
    SkillSection,
    EducationSection,
    ExperienceSection,
    PreferencesSection,
    HeadlineSection,
    CoverSection
  },
  props: {
    profileIdSet: {
      type: String,
      default: null
    },
    initializePage: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      title: 'Profile',
      completed: 1,
      focus: '',
      editing: null,
      backselected: 0,
      profileId: null,
      profile: null,
      message: null,
      aboutMe: '',
      headline: '',
      cvmodalopen: false,
      cvfile: '',
      avatarmodalopen: false,
      openCardDesign: false,
      cvuploadmodalopen: false,
      editingAboutMe: false,
      aboutexpanded: false,
      highlightCVValidation: false,
      myFiles: [],
      file: null,
      saveAvatar: false,
      saveAvatarDisabled: true,
      profileNotification: false,
      links: [
        {
          title: 'All',
          icon: 'done_all',
          action: 'aggregated',
          route: '/dash'
        },
        {
          title: 'Partners',
          svgicon: 'account',
          action: 'partners',
          route: '/dash'
        },
        {
          title: 'Jobs',
          svgicon: 'work',
          action: 'jobs',
          route: '/dash',
          filter: 'jobsEnabled'
        },
        {
          title: 'Bookmarked',
          icon: 'star_outline',
          action: 'bookmarked',
          route: '/dash'
        }
      ],
      openProfileModals: false
    }
  },
  computed: {
    ...mapState([
      'user',
      'profiles'
    ]),
    ...mapGetters([
      'getProfile'
    ]),
    ...mapState({
      ownProfile: (state) => { return state.profile }
    }),
    myProfile: function () {
      return this.user.uid === this.profileId
    },
    showMore: function () {
      return true
    },
    tasksCompleted () {
      return this.completed === 4
    }
  },
  methods: {
    ...mapActions([
      'setLoading',
      'updateProfile',
      'uploadCV',
      'uploadAvatar',
      'setSnackMessage',
      'setPageLoad'
    ]),
    setEditing (section) {
      this.editing = section
    },
    getCV: function () {
      const file = this.profile.resumeFilename || ''
      if (file.split('.').pop() === 'docx') {
        this.cvfile = docxFunctionUrl(this.profile.resumeCipher)
        this.cvmodalopen = true
      } else {
        this.cvfile = this.profile.resume
        this.cvmodalopen = true
      }
    },
    gotoProfile: function () {
      this.$router.push('/user/' + this.ownProfile.id)
    },
    setProfile: async function () {
      if (!this.profileId) {
        if (this.profileIdSet) {
          this.profileId = this.profileIdSet
        } else {
          this.profileId = this.user.uid
        }
      }
      if (this.myProfile) {
        this.profile = this.ownProfile
      } else {
        this.profile = await this.getProfile(this.profileId)
        if (this.profile && this.ownProfile) {
          this.trackView()
        }
      }
    },
    trackView: function () {
      // eslint-disable-next-line no-undef
      analytics.track('viewProfile', {
        viewUserId: this.profileId,
        id: this.ownProfile.id,
        journeyStage: this.ownProfile.journeyStage,
        role: this.ownProfile.role,
        locationFull: this.ownProfile.locationFull,
        flexschedule: this.ownProfile.flexschedule,
        company: this.ownProfile.company
      })
    },
    openAvatarDialog: function () {
      this.avatarmodalopen = true
    },
    onAboutMeUpdate: function (text, contentKey) {
      this.aboutMe = text
    },
    editProfileAboutMe: function () {
      this.aboutMe = this.profile.aboutMe
      this.headline = this.profile.headline
      this.editingAboutMe = true
    },
    updateCardDesign: function (design, layout) {
      this.profile.cardDesign = design
      this.profile.cardDesignBackground = layout
      this.updateProfile({ profile: { cardDesign: this.profile.cardDesign, cardDesignBackground: this.profile.cardDesignBackground } })
      this.trackProfileUpdate()
    },
    updateProfileAboutMe: function () {
      this.profile.aboutMe = this.aboutMe
      this.profile.headline = this.headline
      this.editingAboutMe = false
      this.updateProfile({ profile: { aboutMe: this.profile.aboutMe, headline: this.profile.headline } })
      this.trackProfileUpdate()
    },
    updateProfileSection: function (profileUpdates) {
      if (this.myProfile) {
        this.updateProfile({ profile: profileUpdates })
        this.trackProfileUpdate()
      }
    },
    trackProfileUpdate () {
      // eslint-disable-next-line no-undef
      analytics.track('profileUpdated', {
        id: this.user.uid,
        role: this.profile.role,
        company: this.profile.company,
        journeyStage: this.profile.journeyStage
      })
    },
    detectFiles (fileList) {
      this.highlightCVValidation = false
      this.file = fileList[0]
      var ext = this.file.name.split('.').pop()
      if (ext !== 'pdf' && ext !== 'docx' && ext !== 'doc') {
        this.setSnackMessage('Wrong file type')
        this.resetFile()
        this.highlightCVValidation = true
      }
    },
    uploadCVFile () {
      const self = this
      this.uploadCV({ file: this.file }).then(() => {
        self.resetFile()
      })
    },
    setAvatarSaveDisabled (loaded) {
      this.saveAvatarDisabled = !loaded
    },
    uploadAvatarFile (file, filename) {
      const self = this
      this.uploadAvatar({ file, filename }).then(() => {
        self.avatarmodalopen = false
      })
    },
    resetFile () {
      const input = this.$refs.fileInput
      input.type = 'text'
      input.type = 'file'
      this.file = null
    },
    showCompleteProfileNotification: function () {
      this.profileNotification = true
    },
    initialize (focus) {
      // console.log('User.initialize', focus)

      this.profile = null

      if (!this.profileId) {
        if (this.profileIdSet) {
          this.profileId = this.profileIdSet
        } else {
          this.profileId = this.user.uid
        }
      }
      if (focus) {
        this.focus = focus
        this.profileNotification = false
      }

      this.$emit('setNavActive', 'Discover')
      this.$emit('setLinks', this.links)
      if (this.user.displayName !== 'Not Authenticated') {
        this.$nextTick(() => {
          this.setProfile()
        })
      }
      const self = this
      this.$nextTick(() => {
        self.backselected = 1
        self.setPageLoad(false)
        this.openProfileModals = true
      })

      if (this.focus === 'aboutme') {
        this.editing = 'headline'
        this.editingAboutMe = true
      } else if (this.focus === 'photo') {
        this.openAvatarDialog()
      }

      this.$emit('initialized')
    }
  },
  beforeDestroy () {
    this.$root.$emit('enableSearch', false)
    this.$emit('setLinks', [])
    this.$emit('setNavActive', null)
  },
  async beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    document.getElementsByClassName('body')[0].classList.remove('mdc-dialog-scroll-lock')
    this.$root.$emit('enableSearch', false)
    this.setPageLoad(true)
    next()
  },
  async beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.profile = null
      vm.profileId = to.params.id
      vm.$root.$emit('enableSearch', true, { back: true })
      vm.initialize()
    })
  },
  async beforeRouteUpdate (to, from, next) {
    this.profile = null
    this.profileId = to.params.id
    // this.$root.$emit('enableSearch', false)
    setTimeout(() => {
      this.$root.$emit('enableSearch', true, { back: true })
    }, 300)
    this.initialize()
    next()
  },
  async mounted () {
    this.$root.$emit('enableSearch', true, { back: true })
    this.profile = null
    this.profileId = (this.$route) ? this.$route.params.id : null
    this.focus = (this.$route) ? this.$route.params.focus : ''
    this.initialize()
  },
  watch: {
    initializePage (newip, oldip) {
      if (this.initializePage) {
        this.initialize()
      }
    },
    ownProfile: {
      handler (newop, oldop) {
        if (this.ownProfile) {
          this.completed = 1
          if (this.ownProfile.basicCompleted) {
            this.basic = true
            this.completed++
          }
          if (this.ownProfile.experienceCompleted) {
            this.experience = true
            this.completed++
          }
          if (this.ownProfile.photoURL) {
            this.personalise = true
            this.completed++
          }
        }

        if (this.profile) {
          if (this.ownProfile.id === this.profile.id) {
            this.profile = this.ownProfile
          } else if (this.profile && this.ownProfile) {
            this.trackView()
          }
        }
      },
      immediate: true
    },
    profiles (newp, oldp) {
      this.setProfile()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.profile .avatar-modal .mdc-dialog__surface {
  width:310px;
  min-width:310px;
}

.profile .app-section {
  margin-bottom:2px;
}

.profile .highlight {
  color:red;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  display: flex;
  flex-flow: row;
  cursor: pointer; /* "hand" cursor */
  width:200px;
}
.inputfile + label .file-select-icon {
  margin-top: -6px;
  margin-right: 12px;
}
.inputfile + label * {
  pointer-events: none;
}

.profile .mdc-chip.less-prominent {
  opacity:0.6;
}

.profile .subexp-body {
  border-left:2px solid #CCCCCC;
  margin-left:-16px;
  padding-left:16px;
}
.profile .about .about-text {
  max-height:8.2em;
  overflow:hidden;
  transition: max-height 0.25s ease-in;
  white-space:pre-wrap;
  overflow-wrap: break-word;
}
.profile .about.expanded .about-text {
  max-height:2000px;
  height:auto;
  overflow:hidden;
}
.profile .mdc-list .mdc-list-item {
  flex-flow: row wrap;
  height: auto;
}
.profile .tiptap-wrap {
  padding:6px;
  width:100%;
  background:whitesmoke;
  margin-top:16px;
}
.profile .tiptap-wrap .editor{
  margin-top:-12px;
}
.profile .mdc-text-field-helper-text {
  color:rgba(0, 0, 0, 0.7);
}
.profile .mdc-list-item.active {
  background-color: #FCFCFC;
}
.profile .sub-expanded {
  padding-top:16px;
}
.profile .mdc-textfield.mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box):not(.mdc-text-field--textarea):not(.mdc-text-field--outlined) {
  width:100%;
  border-bottom-color: rgba(0, 0, 0, 0.42);
  background-color: whitesmoke;
  height:56px;
}
.profile .subinfo {
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
  margin-top:0;
}
.profile .sub-label {
  font-size:0.8em;
  line-height:1.4em;
  margin-top:0px;
  margin-bottom:6px;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
}
.profile .mdc-card.about {
  min-height: 200px;
}
.profile .mdc-select--box:not(.mdc-select--disabled).dense {
  background-color:white;
  height:auto;
  min-width:40px;
}
.profile .mdc-select--box:not(.mdc-select--disabled).dense select {
  height:auto;
  padding:0;
  padding-left:6px;
  height:36px;
}
.profile .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
  height:36px;
}
.profile .align-bottom {
  margin-top:46px;
  height:400px;
}
.profile .upshift {
  margin-top:-100px;
  margin-bottom: -16px;
}
.profile .upshift--tablet {
  margin-top:-100px;
}
.profile .mobile {
  display:none;
}
.profile .tablet {
  display:block;
}
@media (max-width: 650px) {
  .profile .align-bottom {
    margin-top:16px;
  }
  .profile .upshift--tablet {
    margin-top: -108px;
  }
  .profile .upshift {
    margin-top:0px;
  }
  .profile .mobile {
    display:block;
  }
  .profile .tablet {
    display:none;
  }
}

.profile .mdc-dialog.mdc-dialog--open {
    z-index: 9999;
}

.profile .mdc-dialog__body {
  padding-bottom:6px;
}
.profile .mdc-dialog__footer {
  height:42px;
}

.profile .mdc-dialog.mdc-dialog--open.complete-notification {
  height:100%;
  margin-top:0;
}

.main-content.profile {
  height:auto;
}

.profile .profile-header {
  height: 318px;
  background-image: none;
  background-size: cover;
  background-repeat: no-repeat;
}
.profile .flex-group {
  display:flex;
  flex-flow: row wrap;
}
.profile .flex-group div{
  margin-right:0px;
  margin-bottom:16px;
}
.profile .dateSelect {
  height:56px;
  min-width:140px;
  flex:1;
}
.profile .education, .profile .experience, .profile .about {
  margin-bottom:16px;
}

.experience-padded .mdc-card-text {
  margin: 8px 0;
}

.experience-padded .mdc-title {
  margin-left: 4px;
}

.experience-padded .mdc-card-text .mdc-layout-grid {
  padding:0;
}

.profile-col-wrapper {
  display:flex;
  flex-direction: row;
}

.profile-col-1 {
  margin-right:16px;
  flex:5;
  margin-left:24px;
}

.profile-col-2 {
  flex:2;
  margin-right:24px;
  max-width:380px
}

@media (max-width: 1060px) {
  .profile-col-1 {
    margin-left:16px;
  }
  .profile-col-2 {
    margin-right:16px;
  }
}

@media (min-width: 480px) and (max-width: 650px) {
  .profile-col-wrapper {
    flex-direction: column;
  }
  .profile-col-1 {
    order :2;
  }
  .profile-col-2 {
    margin-left: 16px;
    order :1;
    max-width:100%;
  }
}

@media(max-width: 480px) {
  .profile-col-wrapper {
    flex-direction: column;
  }
  .profile-col-1 {
    margin-left: 0px;
    margin-right: 0px;
      order :2;
  }
  .profile-col-2 {
    margin-left: 0px;
    margin-right: 0px;
    order :1;
    max-width:100%;
  }
}

</style>
