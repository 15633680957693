import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faGoogle, faFacebook, faLinkedin, faPinterest, faReddit, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

library.add(
  faGoogle,
  faFacebook,
  faLinkedin,
  faPinterest,
  faReddit,
  faTwitter,
  faWhatsapp,
  faEnvelope
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
