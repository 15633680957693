<template>
  <div class="main-content focus-test">
    <div class="main-wrapper">

      <mdc-layout-grid>
        <mdc-layout-cell span=12>
        </mdc-layout-cell>
      </mdc-layout-grid>

    <mdc-layout-grid>
      <mdc-layout-cell desktop=12 tablet=8 phone=4>
        <mdc-textfield type="text"
          label="TextField"
          helptext="- Help Text"
          outlined
          autocomplete="new-password"
          autofill="off"
          v-model="someVal" />
      </mdc-layout-cell>
    </mdc-layout-grid>

    <div class="height-filler">some filler content</div>

    <mdc-layout-grid>
      <mdc-layout-cell desktop=12 tablet=8 phone=4>
        <mdc-textfield type="text"
          label="TextField"
          helptext="- Help Text"
          outlined
          autocomplete="new-password"
          autofill="off"
          v-model="someVal1" />
      </mdc-layout-cell>
      <mdc-layout-cell desktop=12 tablet=8 phone=4>
        <div>
          <mdc-select style="width:100%;margin-bottom:12px;" required outlined v-model="someSelectVal" label="Select">
            <select-item :value="1" text="Option 1"></select-item>
            <select-item :value="2" text="Option 2"></select-item>
            <select-item :value="3" text="Option 3"></select-item>
            <select-item :value="4" text="Option 4"></select-item>
            <select-item :value="5" text="Option 5"></select-item>
            <select-item :value="6" text="Option 6"></select-item>
            <select-item :value="7" text="Option 7"></select-item>
            <select-item :value="8" text="Option 8"></select-item>
            <select-item :value="9" text="Option 9"></select-item>
          </mdc-select>
        </div>
      </mdc-layout-cell>
      <mdc-layout-cell desktop=12 tablet=8 phone=4>
        <auto-complete-outline
          label="AC scroll"
          :value="newACOption"
          :scrollFocus="true"
          autocompleteId="'ac-scroll'"
          field="name"
          :items="items"
          :min="1"
        />
        <mdc-body class="sub-label">- Help Text</mdc-body>
      </mdc-layout-cell>
    </mdc-layout-grid>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AutoCompleteOutline from '../../components/AutoCompleteOutline.vue'

export default {
  name: 'FocusTest',
  components: {
    AutoCompleteOutline
  },
  data () {
    return {
      someVal: '',
      someVal1: '',
      someSelectVal: null,
      items: [
        { name: 'someval 1' },
        { name: 'someval 2' },
        { name: 'someval 3' },
        { name: 'someval 4' },
        { name: 'someval 5' },
        { name: 'someval 6' },
        { name: 'otherval 1' },
        { name: 'otherval 2' },
        { name: 'otherval 3' },
        { name: 'otherval 4' }
      ]
    }
  },
  mounted () {
    this.setPageLoad(false)
  },
  methods: {
    ...mapActions([
      'setPageLoad'
    ])
  }
}
</script>

<style>
.focus-test .height-filler {
  height: 400px;
  margin: 16px;
  line-height: 400px;
  text-align: center;
  border: 1px dashed silver;
  background: rgba(0,0,0,0.1);
  color: #333333;
}
.focus-test p.sub-label {
  font-size:12px;
  margin-top:6px;
  margin-bottom:16px;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
}
.focus-test .mdc-text-field-helper-line {
  padding-left:0px;
}
</style>
