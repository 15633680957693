import { render, staticRenderFns } from "./NextActionButton.vue?vue&type=template&id=95b97c60&scoped=true&"
import script from "./NextActionButton.vue?vue&type=script&lang=js&"
export * from "./NextActionButton.vue?vue&type=script&lang=js&"
import style0 from "./NextActionButton.vue?vue&type=style&index=0&id=95b97c60&scoped=true&lang=css&"
import style1 from "./NextActionButton.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95b97c60",
  null
  
)

export default component.exports