<template>
  <div class="featured-card featured-card-mobile" v-bind:class="{ 'hover' : hoverStyle }">
    <div class="featured-card-wrapper">
      <div class="swap-container">
        <div class="no-swapper">
          <div class="front">
          <mdc-card class="featured-wrapper">
            <div :class="'featured featured-design-6-multi'">
              <div :class="'featured-bg featured-bg-bottom'">
                <div class="featured-text">{{title}}</div>
              </div>
            </div>
          </mdc-card>
          <div @click.prevent.stop v-if="detail" style="cursor:default" class="detail-text">
            <mdc-subheading class="displayname">{{detailTitle}}</mdc-subheading>
            <mdc-body class="featured-status" typo="body2">{{detailText}}</mdc-body>
          </div>
          </div>
        </div>
      </div>
      <mdc-card @click.native.prevent.stop v-if="detail" style="cursor:default" class="featured-details" >
        <div class="link-row" style="">
          <div style="flex:0;min-width: 128px;">
              <round-button
                :medium="true"
                :hoverHigh="true"
                style="padding-right:12px;width:126px;margin-right:0;margin-left:0;padding-left:12px;"
                hasSlot
                :class="{ medium: true }"
                class=""
                @click.prevent.stop="$router.push('/my-profile')"
                >
                <mdc-icon slot="leading-icon" style="margin-top: -6px; margin-right:10px; font-size:24px !important;" class="icon leading-icon" outlined icon="person_pin" />
                <span>Update</span>
                </round-button>
          </div>
          <div>
            <round-button @click="emitClick" ripple hasSlot background="transparent" style="color:#828282;padding-left:12px;">MORE</round-button>
          </div>
        </div>

      </mdc-card>

    </div>

  </div>
</template>

<script>
import RoundButton from '../../components/RoundButton.vue'

export default {
  name: 'FeaturedCardMobile',
  props: {
    detail: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    detailTitle: {
      type: String,
      default: ''
    },
    detailText: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    hoverStyle: {
      type: Boolean,
      default: true
    },
    showMore: {
      type: Boolean,
      default: true
    }
  },
  components: {
    RoundButton
  },
  computed: {
  },
  data () {
    return {
      hover: false
    }
  },
  mounted () {
  },
  methods: {
    emitClick (event) {
      this.$emit('click', event)
    }
  }
}
</script>

<style scoped>
.dot-spacer {
  position: relative;
  top: 2px;
}
.featured-card-mobile .bookmark-icon {
  min-width:20px;
  width:20px;
  min-height:20px;
  height:20px;
}
.featured-card-mobile {
  margin-left: auto;
  margin-right: auto;
}
.featured-card-mobile .front {
  display:flex;
  flex-direction: row;
  align-items: center;
}

.front .mdc-card {
  height: 82px;
  width:124px;
  min-width:124px;
  max-width:unset;
}

.featured-card-mobile .featured-image {
  width: 65%;
}

.displayname {
  display:inline-block;
  overflow-wrap: break-word;
  max-width:100%;
}
.sharemenu {
  min-width: 170px;
}
/* entire container, keeps perspective */

.open-card-design .mdc-dialog__body .featured-card {
  width: 46%;
  display: inline-block;
  margin: 2%;
  cursor: pointer;
}

.featured-stat .hover-info {
  display:none;
  position:absolute;
  color:#fff;
  height:20px;
  background-color: rgba(0,0,0,1);
  align-items: center;
  padding: 2px 12px;
  margin-top: 40px;
  border-radius:5px;
  font-size:12px;
  z-index:9999;
}
.featured-stat:hover .hover-info {
  display:flex;
}

.flip-container {
  perspective: 1000px;
  transform-style: preserve-3d;
}

/*  flip the pane when hovered */
.flip-container.hover-enabled:hover .back, .flip-container.hover-enabled.hover .back {
  transform: rotateY(0deg);
}
.flip-container.hover-enabled:hover .front, .flip-container.hover-enabled.hover .front  {
  transform: rotateY(180deg);
}

.flip-container, .front, .back {
  width: 100%;
  min-height:80px;
  height: auto;
}

.no-swapper {
  padding-left:16px;
  padding-right:16px;
}

.swapper .back .featured .back-featured-overlay {
  height:100%;
  width:100%;
  background-color:rgba(0,0,0,0.5);
  color:#fff !important;
  position:absolute;
  overflow: hidden;
  z-index:1;
}

.swapper .back .featured .back-featured-overlay p {
  color:#fff !important;
}

.swapper .back .featured .featured-card-design {
  height:100%;
  width:100%;
  position:absolute;
  background:none;
  filter:blur(1px);
}

/* hide back of pane during swap */
.flipper .front, .flipper .back {
  backface-visibility: hidden;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  top: 0;
  left: 0;
}

.back {
  margin-top:-65%;
}

.back .featured-bg {
  filter:blur(1px);
  margin-left:-2px;
}

.flipper .back .featured .back-featured-overlay {
  height:100%;
  width:100%;
  background-color:rgba(0,0,0,0.3);
  color:#fff !important;
  position:absolute;
  overflow: hidden;
  z-index:1;
}

/* front pane, placed above back */
.flipper .front {
  z-index: 2;
  transform: rotateY(0deg);
}

/* back, initially hidden pane */
.flipper .back {
  transform: rotateY(-180deg);
}

.flipper .back .featured .featured-card-design {
  height:100%;
  width:100%;
  position:absolute;
  background-size: cover;
  filter:blur(1px);
}

.back .featured .back-featured-overlay .back-featured-content{
  margin-top:16px;
  margin-left:6px;
  margin-right:6px;
  overflow: hidden;
}

.featured-wrapper {
  background-color:white;
  border-radius: 13px;
  width:100%;
  overflow: hidden;
  position: relative;
}

.safari .featured-wrapper {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.featured, .featured-bg {
  display:flex;
  flex-flow: row;
  position: absolute;
  top: 0px;
  left: 0;
  width: 101%;
  height: 100%;
  align-items: center;
}

.featured {
  top:-8px;
  background-size: cover;
  height:calc(100% + 16px);
}

div[class*="featured-bg-"] .featured-text img {
  display: none;
}

.featured-bg-diagonal {
  background: linear-gradient(70deg, transparent 35%, #F2EEE6 0%);
}
.featured-card:hover .featured-bg-diagonal {
  background: linear-gradient(70deg, transparent 35%, #DEDBD6 0%);
}
.featured-bg-top {
  background: linear-gradient(#F2EEE6 85%, transparent 0%);
}
.featured-card:hover .featured-bg-top {
  background: linear-gradient(#DEDBD6 85%, transparent 0%);
}
.featured-bg-bottom {
  background: linear-gradient(transparent 40%, #F2EEE6 0%);
}
.featured-card:hover .featured-bg-bottom {
  background: linear-gradient(transparent 40%, #DEDBD6 0%);
}
.featured-bg-left {
  background: linear-gradient(90deg, transparent 22%, #F2EEE6 0%);
}
.featured-card:hover .featured-bg-left {
  background: linear-gradient(90deg, transparent 22%, #DEDBD6 0%);
}

div[class*="featured-bg-"] .featured-text {
  padding-bottom: 0px;
}

div[class*="featured-bg-"] .featured-text img {
  display: none;
}

/* Background colour/bg options this goes on the same div a featured class */

.featured-design-6-multi {
  background-image: url('/static/img/card-designs/profile-design-6-multi.svg');
}

.avatar {
  width: 80%;
  margin: 10%;
  padding-top: 80%;
  position: relative;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  display: block;
  overflow: hidden;
}
.avatar img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;;
    left: 0;
    object-fit: cover;
}
.detail-text {
    width: 52%;
    height: 50%;
    text-align: left;
    padding: 0;
    padding-left: 26px;
    overflow: hidden;
}
.featured-text {
    text-align: center;
    padding: 0;
    font-family: 'Nanum Pen Script', cursive;
    font-size: 1.8em;
    overflow: hidden;
    line-height: 0.7em;
    padding-top: 25px;
}
.link-row {
  display: flex;
  font-size: 16px;
}
.link-row > div {
  flex: 1;
  flex-direction: row;
  display: flex;
}
.link-row-top > div {
  flex: 0;
}
.featured-stat {
    display: flex;
    flex-direction: column;
    line-height: 0;
}
.featured-headline {
  font-style:italic;
  font-size: 14px;
  color: #333333;
}
.featured-card .featured-image {
  position:relative;
}
.featured-card .mdc-body.featured-status {
  max-width: calc(100% + 12px);
  overflow: hidden;
}
.featured-card .featured-image .featured-status {
  color:gray;
}
.featured-card .featured-image.editable:hover {
  cursor:pointer;
}
.featured-card .featured-image.editable .overlay{
  display:none;
  position:absolute;
  width:100%;
  height:100%;
}
.featured-card .featured-image.editable:hover .overlay{
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/424395/camera.svg");
  background-size: 50px 41px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.7;
  z-index:9;
  display:block;
}
.featured-card .mdc-subheading {
  margin:0;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.featured-card .mdc-body {
  margin:0;
  font-size: 12px;
  line-height: 1;
}
span[class^="days-"] {
  margin-top: 3px;
  width: 55px;
  white-space: nowrap;
}
span[class^="days-"] .day {
  height: 2px;
  background: #E0E0E0;
  display: inline-block;
  width: 8px;
  margin-left: 1.5px;
  margin-right: 1.5px;
}
span.days-1 .day:nth-of-type(-n+1) {
  background: #AD1457;
}
span.days-2 .day:nth-of-type(-n+2) {
  background: #AD1457;
}
span.days-3 .day:nth-of-type(-n+3) {
  background: #AD1457;
}
span.days-4 .day:nth-of-type(-n+4) {
  background: #AD1457;
}
span.days-5 .day:nth-of-type(-n+5) {
  background: #AD1457;
}

span.days-1-5 .day:nth-of-type(-n+1) {
  background: #AD1457;
}
span.days-2-5 .day:nth-of-type(-n+2) {
  background: #AD1457;
}
span.days-3-5 .day:nth-of-type(-n+3) {
  background: #AD1457;
}
span.days-4-5 .day:nth-of-type(-n+4) {
  background: #AD1457;
}

span.days-0-5 .day:nth-of-type(1) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-1-5 .day:nth-of-type(2) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-2-5 .day:nth-of-type(3) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-3-5 .day:nth-of-type(4) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-4-5 .day:nth-of-type(5) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}

.featured-status-bar {
  display:flex;
  width:100%
}
.featured-details {
  padding: 0% 16px 0 16px;
  font-family: 'Roboto', sans-serif;
  background: none;
  box-shadow: none;
}
.featured-details .duome-icon img {
  width:20px;
  height: 20px;
}
.featured .edit-icon {
  background: #fff;
  position: absolute;
  top: 18px;
  right: 17px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex;
  margin: 0;
}
.featured .edit-icon .mdc-icon.mdc-icon--material {
  font-size: 20px;
  line-height: 25px;
  color: #888;
  margin-right: 0;
}
.featured .edit-icon:hover {
  cursor:pointer;
}
.featured .edit-icon + .hover-info {
  display:none;
  position:absolute;
  color:white;
  height:20px;
  background-color: rgba(0,0,0,1);
  align-items: center;
  padding: 2px 12px;
  border-radius:5px;
  font-size:12px;
  z-index: 101;
  left: 30px;
  bottom: 25px;
}
.featured .edit-icon:hover + .hover-info {
  display:flex;
}
.featured-details .value {
    display: block;
    line-height: 1;
    color: #333333;
    min-height: 9px;
    display: inline;
}
.featured-details .field {
  font-style: italic;
  margin-top: 3px;
}
.location {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.location .featured-stat .value {
  font-style:italic;
  font-size: 14px;
}
.role {
  font-weight: 500;
  font-style:italic;
  font-size: 14px;
}
.mdc-icon {
  margin-right: 5px;
}
.featured-card:not(:hover) .link-row-bottom .mdc-button {
  background-color: #ffffff;
  box-shadow: none;
}
.featured-card .link-row-bottom .mdc-button .mdc-icon {
  margin: 0 10px 5px 0;
}
.featured-details .mdc-button img {
  margin-right:6px;
}
.featured-details .mdc-button img.icon-active {
  display:none;
}
.featured-card.hover:hover .featured-wrapper {
  background: #F6F6F6;
}
.about > div {
  font-size: 14px;
  margin: 10px 0;
}
.action {
  cursor: pointer;
}
.bookmark {
  cursor: pointer;
  color: #333333;
  margin-top: 0px;
  margin-bottom: 50px;
  width: 26px;
  min-width: 26px;
  height: 26px;
  min-height: 26px;
  margin-left: auto;
  margin-right: 8px;
}
.modal .mdc-dialog__container {
  width: calc(100% - 20px);
  min-width: 340px !important;
  max-width: 885px !important;
}

@media (max-width: 570px) {
  .no-swapper {
    padding-left:0px;
    padding-right:0px;
  }
  .featured-details {
    padding: 0;
  }
}
</style>

<style>
/** Not Scoped for hover styling sub component **/

.featured-card .open-card-design .mdc-dialog__surface {
  height: calc(100vh - 80px);
  min-height: 400px;
}
.featured-card .open-card-design .mdc-dialog__body--scrollable {
  height:100%;
  max-height:100%;
}
.featured-card .button-row {
  display: flex;
  align-items: center;
}
.featured-card .button-row .verify {
  color:#b00020;
  margin-left:8px;
}

.featured-card .button-row .verifed {
  color:#00b020;
  margin-left:8px;
}
</style>
