<template>
  <!-- Skills -->
  <app-section class="skills"
  :class="{editonly: editonly}"
  v-if="!other || skills.length > 0"
  animationTime="0ms"
  :editable="editable"
  :editonly="editonly"
  @change="setEditing"
  @save="updateProfileSkills"
  title="Skills"
  >

    <div v-if="editingSkills || editonly">

        <draggable v-model="skills" :draggable="'.item'" :handle="'.handle'">
          <div class="row item" v-for="(skill, index) in skills" :key="index" >
            <div style="flex:1" class="handle">
              <span class="handleicon">☰</span>
              <span class="skill-name" :title="skill.name">{{skill.name}}</span>
            </div>
            <span class="rating" :class="{first: index === 0, second: index === 1, third: index === 2, 'hover-row': hoverIndex === index}">
              <span class="dot editable" @mouseover="setHover('1★', index)" @mouseleave="unsetHover()" role="button" :aria-pressed="ratingEqual(skill.rating, 1)" @click="skill.rating = '1*'" :class="{active: ratingEqual(skill.rating, 1), lower: hoverHigher(index, 1), higher: hoverLower(index, 1)}"/>
              <span class="dot editable" @mouseover="setHover('2★', index)" @mouseleave="unsetHover()" role="button" :aria-pressed="ratingEqual(skill.rating, 2)" @click="skill.rating = '2*'" :class="{active: ratingEqual(skill.rating, 2), lower: hoverHigher(index, 2), higher: hoverLower(index, 2)}"/>
              <span class="dot editable" @mouseover="setHover('3★', index)" @mouseleave="unsetHover()" role="button" :aria-pressed="ratingEqual(skill.rating, 3)" @click="skill.rating = '3*'" :class="{active: ratingEqual(skill.rating, 3), lower: hoverHigher(index, 3), higher: hoverLower(index, 3)}"/>
              <span class="dot editable" @mouseover="setHover('4★', index)" @mouseleave="unsetHover()" role="button" :aria-pressed="ratingEqual(skill.rating, 4)" @click="skill.rating = '4*'" :class="{active: ratingEqual(skill.rating, 4), lower: hoverHigher(index, 4), higher: hoverLower(index, 4)}"/>
              <span class="dot editable" @mouseover="setHover('5★', index)" @mouseleave="unsetHover()" role="button" :aria-pressed="ratingEqual(skill.rating, 5)" @click="skill.rating = '5*'" :class="{active: ratingEqual(skill.rating, 5), lower: hoverHigher(index, 5), higher: hoverLower(index, 5)}"/>
            </span>
            <round-button tabindex="0" style="margin-top:0;margin-bottom:0;margin-left:4px;margin-right:4px" @click="removeSkill(index)" title="Remove" class="draggable-cancel-icon" icon="clear" label="Remove Entry"/>
          </div>
        </draggable>

        <div class="add-row">
        <mdc-body typo="body2" style="color:#b00020;margin-left:8px;" v-if="skillValidationMessage && !newSkill && !skillRatingValid">{{skillValidationMessage}}</mdc-body>
        <mdc-layout-grid style="padding-left:6px;padding-right:6px;padding-top:0px;">
          <mdc-layout-cell :desktop="isEditOnly('6', '12')" tablet=8 phone=4>
          <auto-complete-outline
            label="New Skill"
            :value="newSkill"
            autocompleteId="'newSkill'"
            :field="'name'"
            @input="setNewSkillValue"
            @set="setNewSkill"
            :items="skillList"
            :min="1"
            :isMobile="isMobile"
            :mobileOverlay="true"
            next="skillRating"
            @focusOn="focusOn"
            ref="skill"
            :valid="skillValid && (!skillValidationMessage || newSkillRating !== '')"
            promptMobileOverlay="Tell us about your skills"
            helptext = "- E.g. Market Research"
          />
        </mdc-layout-cell>
        <mdc-layout-cell :desktop="isEditOnly('6', '12')" tablet=8 phone=4 style="display:flex">
          <div style="flex:1; min-width:130px">
            <app-select
              label="Level"
              dense
              style="width:100%;flex:1;min-width:120px;height:56px;"
              outlined
              :isMobile="isMobile"
              :mobileOverlay="true"
              prev="skill"
              ref="skillRating"
              :finish="true"
              :promptMobileOverlay="'How would you rate your proficiency in this Skill? - ' + newSkill"
              @focusOn="focusOn"
              @finish="addSkill"
              v-model="newSkillRating"
              :valid="skillRatingValid && (!skillValidationMessage || newSkillRating !== '')">
              <select-item value=""></select-item>
              <select-item value="1★"><span class="one-star">★ Basics</span></select-item>
              <select-item value="2★"><span class="two-star">★★ Novice</span></select-item>
              <select-item value="3★"><span class="three-star">★★★ Intermediate</span></select-item>
              <select-item value="4★"><span class="four-star">★★★★ Advanced</span></select-item>
              <select-item value="5★"><span class="five-star">★★★★★ Expert</span></select-item>
            </app-select>
          </div>
          <div class="round-button-wrapper-right">
            <round-button
              :high="!editonly"
              :elevated="editonly"
              :medium="editonly"
              @click="addSkill"
              :hasSlot="editonly"
              title="Add"
              icon="add"
              label="Add Entry"><span v-if="editonly">Add</span></round-button>
            </div>
          </mdc-layout-cell>
        </mdc-layout-grid>
      </div>
      <mdc-body typo="body2" class="sub-label" v-if="hoverRating">{{hoverRating}} {{getRatingText(hoverRating)}}</mdc-body>
      <mdc-body typo="body2" class="sub-label" v-else-if="newSkillRating">{{newSkillRating}} {{getRatingText(newSkillRating)}}</mdc-body>
    </div>
    <div v-else>
      <div>
        <div class="row" v-for="(skill, index) in skills" :key="index" >
            <span :title="skill.name" class="skill-name">{{skill.name}}</span>
            <span class="rating" :class="getClasses(index, skill)">
              <span class="dot" :class="{active: ratingEqual(skill.rating, 1)}"/>
              <span class="dot" :class="{active: ratingEqual(skill.rating, 2)}"/>
              <span class="dot" :class="{active: ratingEqual(skill.rating, 3)}"/>
              <span class="dot" :class="{active: ratingEqual(skill.rating, 4)}"/>
              <span class="dot" :class="{active: ratingEqual(skill.rating, 5)}"/>
            </span>
        </div>
      </div>
    </div>
  </app-section>
</template>

<script>
import RoundButton from '../../components/RoundButton'
import AppSelect from '../../components/AppSelect'
import AutoCompleteOutline from '../../components/AutoCompleteOutline.vue'
import AppSection from '../../components/profile-v2/AppSection.vue'
import { mapState } from 'vuex'

export default {
  name: 'SkillSection',
  components: {
    draggable: () => import('vuedraggable'),
    RoundButton,
    AutoCompleteOutline,
    AppSection,
    AppSelect
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    editonly: {
      type: Boolean,
      default: false
    },
    skillValid: {
      type: Boolean,
      default: true
    },
    skillRatingValid: {
      type: Boolean,
      default: true
    },
    profile: Object,
    other: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    newSkillVal: {
      type: String,
      default: ''
    },
    newSkillRatingVal: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      skillValidationMessage: '',
      hoverCancel: null,
      editingSkills: false,
      skills: [],
      newSkillOption: { name: '' },
      newSkill: '',
      newSkillRating: null,
      hoverRating: null,
      hoverIndex: null,
      ratingText: {
        '': '',
        '1★': 'Basics - You have a common knowledge or an understanding of basic techniques and concepts.',
        '2★': 'Novice - You have the level of experience gained in a classroom and/or experimental scenarios or as a trainee on-the-job. You are expected to need help when performing this skill.',
        '3★': 'Intermediate - You are able to successfully complete tasks in this competency as requested. Help from an expert may be required from time to time, but you can usually perform the skill independently.',
        '4★': 'Advanced - You can perform the actions associated with this skill without assistance. You might be recognised within your immediate organisation as "a person to ask" when difficult questions arise regarding this skill.',
        '5★': 'Expert - You are known as an expert in this area. You can provide guidance, troubleshoot and answer questions related to this area of expertise and the field where the skill is used.'
      }
    }
  },
  computed: {
    ...mapState({
      skillList: (state) => state.skills
    })
  },
  mounted () {
    // console.log('SkillSection.mounted', this.newSkillRatingVal, this.newSkillVal)
    this.newSkillRating = this.newSkillRatingVal
    this.newSkill = this.newSkillVal
  },
  methods: {
    focusOn (target, direction) {
      console.log('focusOn', target, this.$refs[target])
      this.focusField = target
      if (this.$refs[target]) {
        try {
          this.$refs[target].focus()
        } catch (err) {
          if (this.$refs[target].$el) {
            this.$refs[target].$el.focus()
          }
        }
      }
    },
    triggerNext (next) {
      console.log('ProfileModals.TriggerNext', next)
      this.focusOn(next)
    },
    triggerPrev (prev) {
      console.log('ProfileModals.TriggerPrev', prev)
      this.focusOn(prev, 'previous')
    },
    closePortal () {
      this.$root.$children[0].$refs.view.$emit('inputBlurred')
      this.focusField = false
    },
    isEditOnly (pos, neg) {
      if (this.editonly) {
        return pos
      }
      return neg
    },
    ratingEqual (rating, threshold) {
      if (rating) {
        return Number(rating[0]) >= threshold
      }
      return false
    },
    ratingHigher (rating, threshold) {
      if (rating) {
        return Number(rating[0]) < threshold
      }
      return false
    },
    editProfileSkills () {
      this.editingSkills = true
      if (this.profile.skills) {
        this.skills = this.profile.skills
      }
    },
    setNewSkillValue (val) {
      this.newSkill = val
    },
    setNewSkill (val) {
      if (val) {
        this.newSkill = val.name
      }
    },
    updateProfileSkills () {
      if (this.newSkill && this.newSkillRating) {
        this.addSkill()
      }
      this.$emit('updateProfileSection', { skills: this.skills })
      this.editingSkills = false
    },
    addSkill () {
      this.skillValidationMessage = ''
      const skill = this.newSkill
      if (!this.skills) {
        this.skills = []
      }
      if (skill !== undefined && skill !== '') {
        this.skills.push({ name: skill, rating: this.newSkillRating })
        this.newSkill = ''
        this.newSkillRating = ''
        this.newSkillOption = { name: '' }
        this.$emit('updateSkills', { skills: this.skills })
      } else {
        this.skillValidationMessage = 'Please enter a skill and level.'
      }
    },
    removeSkill (index) {
      this.skills.splice(index, 1)
      this.$emit('updateSkills', { skills: this.skills })
    },
    getRatingText (rating) {
      return this.ratingText[rating]
    },
    setEditing (val) {
      if (!val) {
        this.$emit('editing', false)
      } else {
        this.$emit('editing', 'skills')
      }
      this.editingSkills = val
      if (this.editingSkills) {
        this.editProfileSkills()
      }
    },
    setHover (rating, index) {
      if (this.hoverCancel) {
        clearTimeout(this.hoverCancel)
      }
      this.hoverRating = rating
      this.hoverIndex = index
    },
    unsetHover () {
      const self = this
      this.hoverIndex = null
      this.hoverCancel = setTimeout(function () {
        self.hoverRating = null
      }, 4000)
    },
    hoverHigher (index, rating) {
      if (this.hoverIndex === index) {
        if (Number(this.hoverRating[0]) >= rating) {
          return true
        }
      }
      return false
    },
    hoverLower (index, rating) {
      if (this.hoverIndex === index) {
        if (Number(this.hoverRating[0]) < rating) {
          return true
        }
      }
      return false
    },
    getClasses (index, skill) {
      var classes = {
        first: index === 0,
        second: index === 1,
        third: index === 2,
        rating1: skill.rating === 1,
        rating2: skill.rating === 2,
        rating3: skill.rating === 3,
        rating4: skill.rating === 4,
        rating5: skill.rating === 5
      }
      return classes
    }
  },
  watch: {
    profile: {
      handler (newp, oldp) {
        if (this.profile) {
          this.skills = this.profile.skills
        }
      },
      immediate: true
    },
    newSkill: function (newr, oldr) {
      this.$emit('updateNewSkill', this.newSkill)
    },
    newSkillRating: function (newr, oldr) {
      // console.log('SkillSection.watch:newSkillRating', this.newSkillRating)
      this.$emit('updateNewSkillRating', this.newSkillRating)
    }
  }
}
</script>

<style scoped>
.skills .sub-label {
  font-size:0.8em;
  margin-top:16;
  margin-bottom:6px;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
}

.skills .row {
  display:flex;
  flex-flow:row;
  align-items: center;
  height:36px;
}

.skills .row .skill-name {
  flex:1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.skills.editonly .row .skill-name {
  max-width: unset;
}

.skills .rating {
  padding-left: 6px;
  color: white;
  display:flex;
  flex-flow: row;
  margin-left:auto;
}
.skills .rating .dot {
  display:block;
  width:16px;
  height:16px;
  line-height:16px;
  margin:1px;
  border-radius: 50%;
  background-color: #C3C4C3;
  transition: background-color 300ms ease-in-out;
}

.skills .row .rating .dot.editable {
  cursor:pointer;
}

.skills .row .rating .dot.active {
  background-color: #828282;
}

.skills .row .rating.first .dot.active {
  background-color: #C10457;
}
.skills .row .rating.second .dot.active {
  background-color: #FF6C00;
}
.skills .row .rating.third .dot.active {
  background-color: #27BCB5;
}

.skills .row .rating .dot.editable::after {
  content:'';
  display:block;
  width:16px;
  height:16px;
  line-height:16px;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

/** Lower hover active **/
.skills .row .rating .dot.editable.higher.active::after {
  background-color: #C3C4C3;
  opacity:0.8;
}

/** Higher hover non-active **/
.skills .row .rating .dot.editable.lower:not(.active):after {
  background-color: #828282;
  opacity:0.4;
}
.skills .row .rating.first .dot.editable.lower:not(.active):after {
  background-color: #C10457;
  opacity:0.4;
}
.skills .row .rating.second .dot.editable.lower:not(.active):after {
  background-color: #FF6C00;
  opacity:0.4;
}
.skills .row .rating.third .dot.editable.lower:not(.active):after {
  background-color: #27BCB5;
  opacity:0.4;
}

.skills .flex-row .flex-left {
  flex:1;
}
.skills .item .handle {
  display: flex;
  flex-wrap: nowrap;
  max-width: calc(100% - 145px);
  align-items: center;
}
.skills .item .handle .handleicon {
  margin-right: 6px;
}
.skills .item .handle .mdc-chip__text {
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 6px;
}
.skills .item .handle .rating {
  margin-right: 22px
}

.skills .item.mdc-chip .mdc-chip__icon.mdc-chip__icon--trailing {
  position:absolute;
  right:12px;
}

.skills .flex-row {
  display:flex;
  flex-flow:row;
  align-items: center;
}
.skills .flex-row .mdc-button {
  min-width:20px;
}

.skills .mdc-select--box:not(.mdc-select--disabled).dense {
  background-color:white;
  height:auto;
  min-width:40px;
}
.skills .mdc-select--box:not(.mdc-select--disabled).dense select {
  height:auto;
  padding:0;
  padding-left:6px;
  height:36px;
}
.skills .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
  margin-right:12px;
}
.skills .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
  height:36px;
}
.skills {
  margin-bottom:16px;
}
.myprofile .skills .rating {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    color: inherit;
}
</style>
<style>
.skills .add-row .mdc-layout-grid__inner {
  row-gap: 0;
}
.skills .autocomplete .autocomplete.mdc-text-field.mdc-text-field--upgraded.outlined[data-v-09396c1a] {
  margin-bottom: 0px;
}
.one-star::after, .two-star::after, .three-star::after, .four-star::after, .five-star::after {
  display:none;
}

@media all and (max-width: 570px) {
  .one-star::after {
    width: 100%;
    display: block;
    margin-top: 2px;
    margin-bottom: 6px;
    margin-left:0px;
    font-size:0.8em;
    line-height: 1em;
    color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
    height: auto;
    white-space: normal;
    content: 'You have a common knowledge or an understanding of basic techniques and concepts.';
  }
  .two-star::after {
    width: 100%;
    display: block;
    margin-top: 2px;
    margin-bottom: 6px;
    margin-left:0px;
    font-size:0.8em;
    line-height: 1em;
    color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
    height: auto;
    white-space: normal;
    content: 'You have the level of experience gained in a classroom and/or experimental scenarios or as a trainee on-the-job. You are expected to need help when performing this skill.';
  }
  .three-star::after {
    width: 100%;
    display: block;
    margin-top: 2px;
    margin-bottom: 6px;
    margin-left:0px;
    font-size:0.8em;
    line-height: 1em;
    color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
    height: auto;
    white-space: normal;
    content: 'You are able to successfully complete tasks in this competency as requested. Help from an expert may be required from time to time, but you can usually perform the skill independently.';
  }
  .four-star::after {
    width: 100%;
    display: block;
    margin-top: 2px;
    margin-bottom: 6px;
    margin-left:0px;
    font-size:0.8em;
    line-height: 1em;
    color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
    height: auto;
    white-space: normal;
    content: 'You can perform the actions associated with this skill without assistance. You might be recognised within your immediate organisation as "a person to ask" when difficult questions arise regarding this skill.';
  }
  .five-star::after {
    width: 100%;
    display: block;
    margin-top: 2px;
    margin-bottom: 6px;
    margin-left:0px;
    font-size:0.8em;
    line-height: 1em;
    color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
    height: auto;
    white-space: normal;
    content: 'You are known as an expert in this area. You can provide guidance, troubleshoot and answer questions related to this area of expertise and the field where the skill is used.';
  }
}
</style>
