import store from '../store'
import { appName, allowedEmails } from '../settings'

export const rolePlugin = {}
export const teamLeaderPlugin = {}

const secured = {
  '/manage': 'manage',
  '/admin': 'admin'
}

export const inRole = (roleName) => {
  if (store.state.user.claims) {
    return store.state.user.claims[roleName]
  }
  return false
}

export const isTeamLeader = () => {
  if (store.state.profile.email === store.state.team.owner) {
    return true
  }
  return false
}

export const emailValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const emailValidForDomain = (email) => {
  if (appName === 'duome-live') {
    return true
  } else {
    const inAllowedEmails = allowedEmails.some(allowedEmail => email.toLowerCase().includes(allowedEmail))
    return inAllowedEmails && (email.includes('@i-tao.com') || email.toLowerCase().includes('@duome.co'))
  }
}

rolePlugin.install = (Vue, opts) => {
  const role = (roleName) => {
    return inRole(roleName)
  }

  Vue.prototype.$role = role

  opts.router.beforeEach((to, from, next) => {
    if (to.path in secured) {
      if (!role(secured[to.path])) {
        next('/dash')
      } else {
        next()
      }
    } else {
      next()
    }
  })
}

teamLeaderPlugin.install = (Vue, opts) => {
  const teamLeader = () => {
    return isTeamLeader()
  }
  Vue.prototype.$teamLeader = teamLeader
}
