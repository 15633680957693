<template>
  <section :style="{'width': `${diameter + borderWidth}px`, 'height': `${diameter + borderWidth}px`}" @click="$emit('click')" class="btn-pulse">
    <span v-if="visible" class="ring" :style="{'width': `${diameter + borderWidth}px`, 'height': `${diameter + borderWidth}px`, 'margin': `-${(diameter + borderWidth) / 2}px auto auto -${(diameter + borderWidth) / 2}px`}"></span>
    <span v-if="visible" class="circle" :style="{'width': `${diameter + borderWidth}px`, 'height': `${diameter + borderWidth}px`, 'line-height': `${diameter}px`, 'border-width': `${borderWidth}px`, 'margin': `-${(diameter + borderWidth) / 2}px auto auto -${(diameter + borderWidth) / 2}px`}">
    </span>
    <span class="wrapper"><slot/></span>
  </section>
</template>

<script>
export default {
  name: 'Hotspot',
  props: {
    diameter: {
      default: 32
    },
    borderWidth: {
      default: 5
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    if (this.visible) {
      this.$emit('shown')
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  height:100%;
  margin-top:-2px;
}
/* Animation */
@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  45% {
    transform: scale(1.75);
    opacity: 0;
  }
}
@keyframes stop-pulsate {
  from {
    opacity: 0.4;
  }
  to {
    transform: scale(2);
    opacity: 0;
  }
}

/* Button */
.btn-pulse {
    margin-top: -3px;
    position: absolute;

  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    display:flex;
    justify-content: center;
    align-items: center;

    border:6px solid rgba(255, 171, 64, 1);
    border-radius: 50%;
    opacity: 0.6;
    transform-origin: 50% 50%;
    transition: opacity 0.2s ease-in, transform 0.1s ease-out;
    color: white;
    font-size: 1.5em;
    padding: 0;
    text-align: center;
    overflow: hidden;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
    &:active {
      transform: scale(0.875);
    }
  }

  .ring {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 50% 50%;
    border-radius: 50%;
    border: 2px solid rgba(255, 171, 64, 0.9);
    opacity: 0;
    animation: pulsate 3s ease-out infinite;
  }

  &:hover .ring {
    animation: none;
  }

  &:active .ring {
    animation: stop-pulsate 0.3s;
  }
}
</style>
