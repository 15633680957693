<template>
  <span class="default-avatar">
    <span class="default-avatar-wrapper">
      <div class="default-text" v-if="fontSize" :style="{'font-size': fontSize}">{{initials()}}</div>
      <fitty v-else :options="options">
        <template v-slot:content>
          <div class="default-text">{{initials()}}</div>
        </template>
      </fitty>
    </span>
    <span v-if="showCover" class="default-span">Upload a photo</span>
  </span>
</template>

<script>
export default {
  name: 'DefaultAvatar',
  props: {
    profile: {},
    options: {},
    fontSize: {},
    showCover: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    initials () {
      let initialFirst = ''
      let initialLast = ''
      let displayNameParts = ''
      if (this.profile) {
        displayNameParts = `${this.profile.displayName}`.split(' ')
        if (this.profile.firstName) {
          initialFirst = this.profile.firstName.substring(0, 1)
        } else {
          initialFirst = displayNameParts[0].substring(0, 1)
        }
        if (this.profile.lastName) {
          initialLast = this.profile.lastName.substring(0, 1)
        } else {
          if (displayNameParts.length > 1) {
            initialLast = displayNameParts[1].substring(0, 1)
          }
        }
        return `${initialFirst}${initialLast}`.toUpperCase()
      }
      return ''
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
.default-avatar {
  background: #FCFCFC;
  color: #4F4F4F;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: -1px;
  font-weight:500;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left:0;
  font-size: 50px; /* default size */
}

.default-avatar-wrapper {
  display:block;
  margin:18%;
  width:64%;
  height: 64%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-avatar .default-text {
  text-align: center;
  display: inline-block;
  white-space: nowrap;
}

.profile-card .default-span {
  background: black;
  opacity: 0.7;
  color: #fff;
  position: absolute;
  width: 100%;
  height: 35%;
  top: 65%;
  display: flex;
  justify-content: center;
  padding-top: 5%;
  font-size: 10px;
}
</style>
