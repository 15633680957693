<template>
  <div class="app-dialog mdc-dialog"
      role="alertdialog"
      aria-modal="true"
      aria-labelledby="my-dialog-title"
      aria-describedby="my-dialog-content"
      @MDCDialog:opened="onOpened"
      @MDCDialog:closed="onClosed">
    <div class="mdc-dialog__container">
      <div class="mdc-dialog__surface">
        <!-- Title cannot contain leading whitespace due to mdc-typography-baseline-top() -->
        <div class="mdc-dialog__title modal-title">
          <span v-if="!required" class="close-button-wrapper">
            <round-button background="transparent" @click="close" class="close" title="Close" icon="close" label="Close"/>
          </span>
          <span class="title-content">
            <mdc-body v-if="title" typo="headline5">{{title}}</mdc-body>
            <slot name="title"></slot>
          </span>
          <span>
            <slot name="post-title" />
          </span>
          <span>
            <round-button :hasSlot="accept !== ''" v-if="accept" high @disabled-click="disabledAccept" @click="onAccept" class="save" :disabled="acceptDisabled" :title="accept" :icon="acceptIcon" :label="accept">
              <span class="button-text">{{accept}}</span>
            </round-button>
          </span>
        </div>
        <div class="message" v-if="acceptDisabled && disabledMessage && showDisabledMessage">{{disabledMessage}}</div>
        <div v-if="open || keepOpen" class="mdc-dialog__content" id="my-dialog-content">
          <mdc-body v-if="subtitle" style="margin-top: 12px;" typo="subtitle1">{{subtitle}}</mdc-body>
          <slot/>
        </div>
        <div v-if="footer" class="mdc-dialog__actions footer-wrapper">
          <mdc-body class="footer-line" typo="body2" v-html="footer"/>
        </div>
      </div>
    </div>
    <div class="mdc-dialog__scrim"></div>
  </div>
</template>

<script>
import { MDCDialog } from '@material/dialog'
import RoundButton from '../components/RoundButton'
export default {
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: undefined
    },
    disabledMessage: {
      type: String,
      default: undefined
    },
    subtitle: {
      type: String,
      default: undefined
    },
    accept: {
      type: String,
      default: 'Accept'
    },
    acceptIcon: {
      type: String,
      default: 'done'
    },
    acceptDisabled: {
      type: Boolean,
      default: false
    },
    acceptCloses: {
      type: Boolean,
      default: true
    },
    cancel: {
      type: String,
      default: 'Cancel'
    },
    required: {
      type: Boolean,
      default: false
    },
    pauseFocus: {
      type: Boolean,
      default: false
    },
    keepOpen: {
      type: Boolean,
      default: false
    },
    footer: {
    }
  },
  components: {
    RoundButton
  },
  data () {
    return {
      dialog: null,
      opened: false,
      showDisabledMessage: false
    }
  },
  mounted () {
    this.dialog = MDCDialog.attachTo(this.$el)
    if (this.open) {
      this.dialog.open()
    }
    this.dialog.escapeKeyAction = this.required ? '' : 'close'
    this.dialog.scrimClickAction = this.required ? '' : 'close'
  },
  watch: {
    open: {
      handler: function () {
        if (this.dialog) {
          if (this.open) {
            this.dialog.open()
          } else {
            this.dialog.close()
          }
        }
      },
      immediate: true
    },
    pauseFocus () {
      if (this.pauseFocus) {
        this.pauseFocusTrap()
      } else {
        this.unpauseFocusTrap()
      }
    }
  },
  methods: {
    disabledAccept () {
      this.showDisabledMessage = true
      this.$emit('disabled-accept')
    },
    onAccept () {
      this.showDisabledMessage = false
      this.$emit('accept')
      if (this.acceptCloses) {
        this.$emit('change', false)
      }
    },
    close () {
      this.showDisabledMessage = false
      this.dialog.close()
    },
    pauseFocusTrap () {
      if (this.opened) {
        if (this.dialog.focusTrap_.pause) {
          this.dialog.focusTrap_.pause()
        }
      }
    },
    unpauseFocusTrap () {
      if (this.opened) {
        if (this.dialog.focusTrap_.unpause) {
          this.dialog.focusTrap_.unpause()
        }
      }
    },
    onOpened (event) {
      this.showDisabledMessage = false
      this.opened = true
      if (this.pauseFocus) {
        this.pauseFocusTrap()
      }
    },
    onClosed (event) {
      this.showDisabledMessage = false
      this.opened = false
      if (this.open) {
        console.log('AppDialog.onClosed:closing', event)
        this.$emit('close', event)
      }
      this.$emit('change', false)
      if (event.detail.action) {
        if (event.detail.action !== 'close') {
          this.$emit(event.detail.action)
        }
      }
    }
  }
}
</script>

<style>
.app-dialog .footer-wrapper {
  display:flex;
  align-items: center;
  justify-content: flex-start;
  border-top:1px solid silver;
  padding:0px;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
  margin-top: 0;
  padding-left:32px;
  padding-right:32px;

}
.mdc-dialog__container {
  width:100%;
}
.app-dialog.mdc-dialog .mdc-dialog__content {
    overflow-x: hidden;
    position: relative;
    display:block;
}
.app-dialog.mdc-dialog .mdc-dialog__title.modal-title {
  z-index:2;
}
.app-dialog .message{
  background-color: #E6B9CD;
  font-size:14px;
  text-align: center;
  padding:6px;
}
.app-dialog.mdc-dialog .mdc-dialog__surface {
  padding: 0;
  transition: all 250ms ease-in-out 0s;
}
.app-dialog.mdc-dialog .modal-title {
  padding-right: 8px;
  background-color: #F2F2F2;
  border-radius: 4px 4px 0 0;
  align-items: center;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.25);
}
.app-dialog .mdc-dialog__title {
  height:56px;
  padding-bottom:0;
  border-bottom:0;
}
.app-dialog.mdc-dialog .title-content {
  flex:1;
}
.app-dialog.mdc-dialog .title-content .mdc-typography--headline5 {
  margin-top:2px;
  text-align: left;
  white-space: nowrap;
}
.app-dialog .close-button-wrapper {
  margin-right: 2px;
  margin-left: -22px;
}

@media (max-width: 840px) {
  .mdc-dialog-scroll-lock .mdc-drawer-app-content {
    z-index:12;
  }
}

@media all and (max-width: 570px){
  .app-dialog.fullsize-mobile .mdc-dialog__surface, .fullsize-mobile .app-dialog .mdc-dialog__surface {
    max-height: 100vh;
    max-height:-webkit-fill-available;
    height: 100vh;
    min-height: 100%;
    height:-webkit-fill-available;
    max-width: 100%;
    width: 100%;
  }

  .app-dialog.mdc-dialog .title-content .mdc-typography--headline5 {
    font-size: 1em;
  }
}

@media all and (max-width: 370px){
  .app-dialog .close-button-wrapper {
    margin-right: 2px;
    margin-left: -22px;
    padding:0;
  }
  .app-dialog .close-button-wrapper .close {
    margin:10px;
    margin-right:0;
  }
  .app-dialog .button-text {
    display:none;
  }
  .app-dialog .round-button.shaped.save .material-icons {
    margin-right:-12px;
    margin-left:-3px;
  }
  .app-dialog.mdc-dialog .title-content .mdc-typography--headline5 {
    font-size: 16px;
  }
}

</style>
