<template>
  <div class="main-content">
    <div class="main-wrapper" style="margin-top: 56px;">

      <mdc-layout-grid class="main-content-inner">
        <mdc-layout-cell span=12>

        <app-section class="connect"
        dialogHeader
        :editable="false"
        animationTime="0ms"
        title="Subscribed"
        >
         <mdc-list style="padding: 0;">
              <mdc-list-item v-for="update in updatesFiltered" :key="update.id"  class="connection" @click="$router.push('/update/' + update.id)">
                <div class="connectionRow">
                  <span class="avatar" v-if="profiles">
                    <img v-if="hasPhoto(getProfile(update.fromId))" :src="getPhoto(getProfile(update.fromId))"/>
                    <default-avatar v-else fontSize="26px" :profile="getProfile(update.fromId)"/>
                  </span>
                  <div>
                    <div class="connectionDetails">
                      <div class="connectionTitle">
                        <span v-if="getProfile(update.fromId)">
                          <span>{{getProfile(update.fromId).displayName}}</span>
                          <span style="margin-left:6px;margin-right:6px;">&middot;</span>
                        </span>
                        <span v-if="update.date">
                          <time-ago refresh long :datetime="update.date.toDate()"></time-ago>
                          <span style="margin-left:6px;margin-right:6px;">&middot;</span>
                       </span>
                        <span>{{update.type || 'Update'}}</span>
                      </div>
                      <mdc-body tag="span" typo="body2" class="lastMessage">{{extractText(update.message)}}</mdc-body>
                    </div>
                  </div>
                  <span class="notification-icon">
                  </span>
                </div>
              </mdc-list-item>
            </mdc-list>
        </app-section>

        </mdc-layout-cell>
      </mdc-layout-grid>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ConnectMenu from '../components/ConnectMenu.vue'
import ConnectMenuRemote from '../components/ConnectMenuRemote.vue'
import DefaultAvatar from '../components/DefaultAvatar'
import TimeAgo from '../components/TimeAgo.vue'
import AppSection from '../components/profile-v2/AppSection.vue'
export default {
  name: 'connect',
  data () {
    return {
      title: 'ConnectSubscribed',
      links: [
        { component: ConnectMenu }
      ],
      filter: null
    }
  },
  components: {
    TimeAgo,
    DefaultAvatar,
    AppSection
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'profiles',
      'connections',
      'remote',
      'teamProfiles',
      'teamUpdatesLookingForward',
      'teamUpdatesRoundTable'
    ]),
    updatesFiltered: function () {
      let results = []
      results = [...this.teamUpdatesLookingForward.filter((update) => {
        if (update.subscribers) {
          return update.subscribers.includes(this.profile.id)
        }
        return false
      })]
      results = [...results, ...this.teamUpdatesRoundTable.filter((update) => {
        if (update.subscribers) {
          return update.subscribers.includes(this.profile.id)
        }
        return false
      })]
      function compare (a, b) {
        const adate = a.date || a.lastUpdate
        const bdate = b.date || b.lastUpdate
        if (adate.toDate() > bdate.toDate()) {
          return -1
        }
        if (adate.toDate() < bdate.toDate()) {
          return 1
        }
        return 0
      }
      console.log('updatesFiltered', results)
      results.sort(compare)
      return results
    }
  },
  methods: {
    ...mapActions([
      'setPageLoad'
    ]),
    fitlerStatus: function (status) {
      this.filter = status
    },
    otherUser: function (users) {
      const usersCopy = Object.assign({}, users)
      delete usersCopy[this.profile.id]
      if (Object.keys(usersCopy).length === 1) {
        const profileId = Object.keys(usersCopy)[0]
        const profileMap = new Map(this.profiles.map(i => [i.id, i]))
        return profileMap.get(profileId)
      }
      return {}
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      if (profileId === 'bot') {
        return {
          displayName: 'DuoMe Bot',
          photoURL: '/static/img/chat-bubble-circle.svg'
        }
      }
      let profileMap = new Map()
      if (this.teamProfiles) {
        profileMap = new Map(this.teamProfiles.map(i => [i.id, i]))
      } else {
        profileMap = new Map(this.profiles.map(i => [i.id, i]))
      }
      return profileMap.get(profileId)
    },
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    },
    getPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return profile.photoURL
        }
      }
      return false
    },
    extractText (s) {
      var span = document.createElement('span')
      span.innerHTML = s
      return span.textContent || span.innerText
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$root.$emit('enableSearch', false)
  },
  mounted () {
    if (this.profile) {
      if (this.remote) {
        this.links = [{ component: ConnectMenuRemote }]
      } else {
        this.links = [{ component: ConnectMenu }]
      }
    }
    this.$emit('setLinks', this.links)
    this.$root.$emit('enableSearch', true, { context: 'messages' })
  },
  watch: {
    remote () {
      if (this.remote) {
        this.links = [{ component: ConnectMenuRemote }]
      } else {
        this.links = [{ component: ConnectMenu }]
      }
      this.$emit('setLinks', this.links)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.connect .avatar {
    min-width: 48px;
    max-width: 48px;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: block;
    overflow: hidden;
    margin-right: 12px;
    position: relative;
}
.connect .app-section .section-content {
  padding:0;
}
.connect .avatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.connect .connection {
    height: auto;
    padding: 0;
}
.connect .connection:hover {
  background-color: #F9F9F9;
}
.connect .connection .connectionRow {
  cursor:pointer;
  width: calc(100% - 20px);
  border-bottom:1px solid #eee;
  display: flex;
  padding: 10px;
}
.connect .lastMessage{
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  padding-right: 20px;
}
.connect .flexrow {
  display:flex;
  flex-flow: row wrap;
}
.connect .flexrow .left{
  min-width:300px;
  flex-grow: 1;
}
.connect .flexrow .right{
  min-width:300px;
  flex: 1;
}
.connect .connection .mdc-layout-grid {
  width:100%;
  padding:12px;
}
.connectionTitle {
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
}
.connectionRow > div {
  min-width: 0;
}
.connect .notification-icon {
  margin-left: auto;
  min-width: auto;
  align-self: center;
  z-index:1;
}
.connect .notification-icon .unread {
  width:24px;
  height:24px;
  font-size:16px;
  font-weight: bold;
  color: #333333;
  z-index:1;
  left:0;
}
.connect .mdc-card__supporting-text,
.connect .mdc-card__supporting-text > .mdc-list {
  padding: 0;
}
.connect .mdc-card-text.mdc-card__supporting-text {
  padding: 0;
}
.connect .connection .connectionRow {
  align-items: center;
}
</style>
