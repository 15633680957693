<template>
  <portal to="destination" :disabled="!(focused && isMobile && mobileOverlay)">

  <round-button style="position:fixed; top:0;left:0" v-if="focused && isMobile && mobileOverlay" noHover icon="close" background="transparent" @click="closePortal"></round-button>

  <mdc-body v-if="prompt" typo="body1" class="padded-label" style="margin-top:0;" :class="{prompt: promptMobileOverlay}">{{prompt}}</mdc-body>
  <mdc-body v-if="promptMobileOverlay" typo="body1" class="padded-label prompt-mobile-overlay">{{promptMobileOverlay}}</mdc-body>

    <mdc-select
    class="app-select"
    outlined
    style="width:100%"
    :class="{'mdc-select--invalid': !valid, 'mdc-select--disabled': disabled}"
    :selectId="id"
    :ref="id"
    :label="label"
    :tabindex="tabindex"
    v-model="val"
    :required="true"
    :disabled="disabled"
    @click="focus"
    @keypress.stop="checkKey"
    v-bind="$attrs"
    @model="$emit('model', $event)"
    @change="$emit('change', $event)"
    >
      <slot />
    </mdc-select>

  <div v-if="focused && isMobile && mobileOverlay" style="display:flex; width:100%; position:relative;left:0;z-index:-1;">
      <round-button :disabled="!prev" class="prev" noHover style="margin-right:auto"  @click="triggerPrev" hasSlot icon="arrow_back">
        <span>Previous</span>
      </round-button>
      <round-button v-if="!finish" :disabled="!next" class="next" noHover style="margin-left:auto"  @click="triggerNext" hasSlot trailingIcon="arrow_forward">
        <span>Next</span>
      </round-button>
      <round-button v-if="finish" class="next" noHover style="margin-left:auto"  @click="closePortalFinish" hasSlot icon="done">
        <span>Done</span>
      </round-button>
    </div>

  </portal>
</template>

<script>

export default {
  name: 'app-select',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String
    },
    label: {
      type: String,
      required: false
    },
    valid: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      required: false
    },
    helptext: {
      type: String,
      required: false
    },
    ariaLabelledBy: {
      type: String,
      required: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    mobileOverlay: {
      type: Boolean,
      default: false
    },
    prompt: {
      type: String
    },
    promptMobileOverlay: {
      type: String
    },
    helptextMobileOverlay: {
      type: String
    },
    tabindex: {
    },
    next: {
    },
    prev: {
    },
    finish: {
    }
  },
  data () {
    return {
      id: '',
      val: '',
      focused: false,
      inputLength: 0,
      refocus: false,
      direction: null
    }
  },
  methods: {
    getRndId () {
      this.id = 'app-select-' + Math.floor(100000 + Math.random() * 900000)
    },
    triggerNext () {
      console.log('AppSelect.TriggerNext', this.next)
      this.refocus = false
      this.focused = false
      this.$emit('focusOn', this.next)
    },
    triggerPrev () {
      console.log('AppSelect.TriggerPrev', this.prev)
      this.refocus = false
      this.focused = false
      this.$emit('focusOn', this.prev, 'previous')
    },
    closePortal () {
      this.$emit('blur')
      this.$root.$children[0].$refs.view.$emit('inputBlurred')
      this.focused = false
    },
    closePortalFinish () {
      this.closePortal()
      this.$emit('finish')
    },
    hasCounter () {
      if (this.$slots.counter) return true
      return false
    },
    checkKey (event) {
      if (event.key === 'Enter' || event.key === 'Escape') {
        event.preventDefault()
        if (this.finish) {
          this.closePortal()
        } else if (this.next) {
          this.triggerNext()
        } else {
          this.handleBlur()
        }
      }
    },
    update () {
    },
    focus (direction) {
      console.log('AppSelect.focus', this.label, this.direction)
      this.direction = direction
      this.handleFocus()
    },
    handleFocus () {
      console.log('AppSelect.handleFocus', this.isMobile, this.mobileOverlay, this.refocus)
      // this.$emit('focus')
      this.focused = true
      if (this.isMobile && this.mobileOverlay) {
        if (!this.refocus) {
          this.refocus = true
          this.$root.$children[0].$refs.view.$emit('inputFocused')
          const self = this
          setTimeout(function () {
            console.log('AppSelect.handleFocus - refocus', self.id, self.$refs, self.$refs[this.id])
            if (self.$refs[self.id]) {
              console.log('AppSelect.handleFocus.refocus', self.$refs[self.id].select)
              self.$refs[self.id].select.handleFocus_()
            }
          }, 200)
        } else {
          this.refocus = false
          this.$root.$children[0].$refs.view.$emit('inputFocused')
        }
        if (this.direction !== 'previous' && this.direction !== 'direct') {
          const self = this
          if (self.$refs[self.id]) {
            setTimeout(function () {
              console.log('AppSelect.focus.openMenu', self.$refs[self.id].select)
              self.$refs[self.id].select.menu_.open = true
            }, 400)
          }
        }
      }
    },
    handleBlur () {
      if (!this.refocus) {
        console.log('AppSelect.blur', this.isMobile, this.mobileOverlay, this.refocus)
        const self = this
        this.$emit('change', this.value)
        if (!(this.isMobile && this.mobileOverlay)) {
          self.$root.$children[0].$refs.view.$emit('inputBlurred')
          self.focused = false
          self.$emit('blur')
        }
      } else {
        if (this.isMobile && this.mobileOverlay) {
          // console.log('blur refocus', this.isMobile, this.mobileOverlay)
          const self = this
          setTimeout(function () {
            self.$refs[self.id].select.handleFocus_()
          }, 300)
        }
      }
    }
  },
  watch: {
    value () {
      this.val = this.value
    },
    isMobile: function () {
      if (this.focused && this.mobileOverlay) {
        this.refocus = false
        this.handleFocus()
      }
    }
  },
  mounted () {
    this.getRndId()
    this.val = this.value
  }
}
</script>

<style>
.padded-label {
  margin-right:auto;
}

.app-select.mdc-select--disabled {
  background-color: transparent;
}

.app-select.mdc-select--invalid:not(.mdc-select--disabled) .mdc-floating-label {
  color: #b00020 !important;
}

.app-select .mdc-floating-label::after {
  top: -2px;
  margin-left: 3px !important;
  position: relative;
}

.app-select.mdc-select--invalid:not(.mdc-select--disabled) .mdc-floating-label::after {
  color: #b00020 !important;
}

.app-select.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.app-select.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.app-select.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
}

.app-select .mdc-floating-label--float-above {
  background:white;
  padding-right: 6px;
  padding-left: 4px;
}
</style>
