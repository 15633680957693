<template>
  <div class="main-content">
    <div class="main-wrapper" style="margin-top: 90px;">

      <mdc-layout-grid>
        <mdc-layout-cell v-for="(page, index) in pages" :key="page.id">
          <mdc-card class="learn-item">
            <div class="mdc-card__primary-action" @click="$router.push('/learn/'+page.slug)" :tabindex="index">
              <mdc-card-media :src="page.feature_image">
                <section class="mdc-card-header mdc-card__primary">
                  <h2 class="mdc-card__title">{{page.title}}</h2>
                  <h3 class="mdc-card__subtitle">by {{page.primary_author.name}}</h3>
                </section>
              </mdc-card-media>
              <section class="mdc-card-text mdc-card__supporting-text">
                <div class="short-content" v-html="$options.filters.removeLinks(page.html)"></div>
              </section>
            </div>
          </mdc-card>
        </mdc-layout-cell>
      </mdc-layout-grid>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as ghost from '../utils/ghost'
export default {
  name: 'Learn',
  data () {
    return {
      title: 'Learn',
      pages: [],
      links: [
        {
          title: 'Manager',
          svgicon: 'account',
          action: 'manager'
        },
        {
          title: 'Employee',
          svgicon: 'work',
          action: 'employee'
        },
        {
          title: 'Business',
          svgicon: 'building',
          action: 'business'
        }
      ],
      filter: null
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'company'
    ])
  },
  filters: {
    removeLinks: function (value) {
      const updateLinksInHTML = (html) => {
        const regex = /href\s*=\s*(['"])(https?:\/\/.+?)\1/ig
        let link
        while ((link = regex.exec(html)) !== null) {
          html = html.replace(link[2], 'javascript:void(0)')
        }
        return html
      }
      if (!value) return ''
      return updateLinksInHTML(value.toString())
    }
  },
  methods: {
    ...mapActions([
      'setPageLoad'
    ]),
    fitlerStatus: function (status) {
      this.filter = status
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$root.$emit('enableSearch', false)
  },
  async mounted () {
    // this.$emit('setLinks', this.links)
    this.$emit('setLinks', [])
    if (this.profile) {
      if (!this.profile.companyId) {
        const result = await ghost.posts(1, 'learn')
        this.pages = result.posts
      }
    }
    this.$root.$emit('enableSearch', true, { context: 'learn' })
  },
  watch: {
    'company.assignedCompany': {
      handler: async function (newac, oldac) {
        if (newac) {
          let result
          if (this.company.assignedCompany) {
            if (this.company.assignedCompany.id === 'mPCZgIYAdO66IHiD0DPm') {
              result = await ghost.posts(1, 'learn')
            } else {
              console.log(`Learn.mounted - get ghost posts by tag ${this.company.assignedCompany.name}`)
              result = await ghost.posts(1, this.company.assignedCompany.name)
            }
          } else {
            result = await ghost.posts(1, 'learn')
          }
          this.pages = result.posts
        }
      },
      immediate: true
    }
  }
}
</script>

<style>
.learn-item .short-content {
  height: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.learn-item .mdc-card-header:last-child {
  padding-bottom: 24px;
}
.learn-item .mdc-card-header {
  background-color: rgba(0,0,0,0.3);
  height:calc(100% - 40px);
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 16px;
}
.learn-item .mdc-card__title  {
  display:block;
  color: white;
  font-size: 1.5rem;
  line-height: 1.6rem;
  letter-spacing: .01786em;
}
.learn-item .mdc-card__subtitle  {
  display:block;
  color: white;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.6rem;
}
.learn-item .mdc-card__title, .mdc-card__title--large {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  text-decoration: inherit;
  text-transform: inherit;
}
.learn-item .mdc-card__supporting-text:last-child {
   padding-bottom: 24px;
}
.learn-item .mdc-card__supporting-text {
  font-family: Roboto,sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: .03125em;
  text-decoration: inherit;
  text-transform: inherit;
  color: rgba(0,0,0,.87);
  color: var(--mdc-theme-text-primary-on-light,rgba(0,0,0,.87));
  box-sizing: border-box;
  padding: 8px 16px;
}
</style>
