<template>
  <div class="navbar-holder" :style="reducedMenu">
    <span class="navbar-item-wrapper" v-for="(item, index) in links" :key="index">
      <span v-if="item.restricted">
        <span v-if="$role(item.restricted)">
          <router-link active-class="router-link-active" v-bind:class="{'router-link-active': navActive === item.text}" :to="item.to">
            <div class="navbar-item">
              <mdc-text typo='body2' tag="span" :style="linktext">{{item.text}}</mdc-text>
            </div>
          </router-link>
        </span>
      </span>
      <span v-else>
        <span v-if="showThisLink(item.text)">
          <router-link active-class="router-link-active" v-bind:class="{'router-link-active': navActive === item.text}" :to="item.to">
            <div class="navbar-item">
              <mdc-text typo='body2' tag="span" :style="linktext">{{item.text}}</mdc-text>
            </div>
          </router-link>
        </span>
      </span>
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AppNav',
  props: {
    navActive: {
      type: String,
      required: false,
      default: undefined
    },
    linktext: {
      type: Object,
      required: false,
      default: undefined
    },
    remote: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState([
      'role',
      'company'
    ]),
    reducedMenu () {
      if (this.company.assignedCompany && this.company.assignedCompany.chemistryDisabled) {
        return 'min-width: 200px;'
      }
      return ''
    }
  },
  methods: {
    showThisLink (item) {
      if (item !== 'Duos') return true
      if (this.company.assignedCompany && this.company.assignedCompany.chemistryDisabled) {
        return false
      }
      return true
    }
  },
  data () {
    return {
      links: [
        { text: 'Discover', to: '/dash' },
        { text: 'Connect', to: '/connect' },
        { text: 'Learn', to: '/learn' },
        { text: 'Manage', to: '/manage', restricted: 'manage' }
      ]
    }
  },
  mounted () {
    console.log(this.remote)
  }
}
</script>

<style scoped>
.navbar-holder {
  display: flex;
  justify-content: center;
  flex-direction: row;
  min-width: 286px;
  float: right;
  height: 48px;
  align-items: center;
}
.navbar-holder a {
  text-decoration: none;
}
.navbar-holder > .navbar-item-wrapper {
    flex-grow: 1;
    text-align: center;
}
.navbar-item .mdc-text {
  font-family: 'Roboto Condensed', Roboto, sans-serif;
  font-Size: 14px;
  line-height: 16px;
  color: #F7FFFF;
}
.router-link-active .navbar-item > span {
  border-bottom: 2px solid #F8B517;
}
</style>
