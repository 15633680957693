<template>
  <div class="app-section-wrapper" :class="{ editonly: editonly, 'dialog-header': dialogHeader }">
    <div class="mdc-card app-section" :class="{'editing': editing, 'non-edit': !editing}">
      <div class="section-title">
        <span class="close-button-wrapper">
          <round-button v-if="editable" background="transparent" @click="cancel" class="close" title="Close" icon="close" label="Close"/>
        </span>
        <span class="title-content">
          <mdc-body v-if="dialogHeader" typo="headline5">{{title}}</mdc-body>
          <mdc-body v-else typo="headline6">{{title}}</mdc-body>
        </span>
        <span>
          <slot name="post-title" />
        </span>
        <span v-show="editing && editable">
          <round-button elevated hasSlot background="transparent" :class="{startclose: startclose}" @click="save" class="save" title="Save" icon="done" label="Save">
            <span class="button-text">{{accept}}</span>
          </round-button>
        </span>
        <span v-show="!editing && editable" >
          <round-button elevated hasSlot background="transparent" :class="{startclose: startclose}" @click="edit" class="edit" title="Edit" icon="create" label="Edit">
            <span class="button-text">{{accept}}</span>
          </round-button>
        </span>
      </div>
      <div :style="{'height': contentHeightValue, 'transition-duration': animationStyle}" class="section-content-wrapper">
        <div ref="content" class="section-content">
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResizeObserverPolyfill from '@juggle/resize-observer'

import { MDCRipple } from '@material/ripple'
import RoundButton from '../RoundButton'

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill

export default {
  name: 'AppSection',

  props: {
    title: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    },
    editonly: {
      type: Boolean,
      default: false
    },
    animationTime: {
      type: String,
      default: '500ms'
    },
    accept: {
      type: String,
      default: 'Save'
    },
    closeOnAccept: {
      type: Boolean,
      default: true
    },
    focused: {
      type: Boolean,
      default: false
    },
    dialogHeader: {
      type: Boolean,
      default: false
    }
  },

  components: {
    RoundButton
  },

  data () {
    return {
      editing: false,
      startclose: false,
      contentHeight: null,
      animationStyle: null
    }
  },

  computed: {
    contentHeightValue () {
      if (this.contentHeight) {
        return `${this.contentHeight}px`
      }
      return 'auto'
    }
  },

  mounted () {
    this.fabRipple = MDCRipple.attachTo(this.$el)
    this.calcHeight()
    var observer = new ResizeObserver(this.calcHeight)
    observer.observe(this.$refs.content)
    this.setAnimationStyle()
  },

  methods: {
    cancel () {
      this.close()
      this.$emit('cancel')
    },
    calcHeight () {
      const self = this
      this.$nextTick(() => {
        if (self.$refs.content) {
          if (self.contentHeight !== self.$refs.content.offsetHeight) {
            self.contentHeight = self.$refs.content.offsetHeight
          }
        }
      })
    },
    edit () {
      this.editing = true
      const self = this
      setTimeout(() => {
        self.$nextTick(() => {
          self.startclose = true
        })
      }, 100)
      this.$emit('change', this.editing)
    },
    close () {
      this.editing = false
      const self = this
      setTimeout(() => {
        self.$nextTick(() => {
          self.startclose = false
        })
      }, 100)
      this.$emit('change', this.editing)
    },
    save () {
      if (this.closeOnAccept) {
        this.close()
      }
      this.$emit('save')
    },
    onClick () {
      this.$emit('click')
    },
    setAnimationStyle () {
      this.animationStyle = `${this.animationTime}`
    }
  },
  watch: {
    focused: {
      handler () {
        if (this.focused) {
          console.log('appsection - focused')
          this.edit()
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

.app-section.non-edit .title-content {
  transform: translateX(-16px);
  background-color: #F2F2F2;
}
.app-section.non-edit .close-button-wrapper {
  transform: translateX(-35px);
  background-color: #F2F2F2;
}

.app-section {
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.25);
}

.app-section-wrapper {
  position: relative;
}

.app-section .section-title {
  padding-right: 8px;
  background-color: #F2F2F2;
  border-radius: 4px 4px 0 0;
  align-items: center;
  display:flex;
  overflow: hidden;
}
.app-section .section-title {
  height:36px;
  padding-bottom:0;
  border-bottom:0;
}
.app-section .close-button-wrapper {
  transition: transform 500ms ease-out;
}
.app-section .title-content {
  flex:1;
  transition: transform 500ms ease-out;
}
.app-section .title-content .mdc-typography--headline6 {
  margin-top:2px;
  margin-bottom:2px;
}

.app-section .section-content-wrapper {
  /** overflow: hidden; **/
  transition-property: height;
  transition-timing-function: ease-in-out;
  will-change: contents;
  overflow:visible;
}

.app-section .section-content {
  padding:16px;
}

.app-section .section-title .round-button{
  height: 26px;
  width: 26px;
  min-width: 26px;
}

.app-section .close-button-wrapper {
  min-width: 35px;
}

.app-section .close-button-wrapper .close {
  margin-right:8px;
  margin-left:8px;
  margin-top:0;
  margin-bottom:0;
}

.editonly .app-section {
  box-shadow: none;
}
.editonly .app-section .section-title {
  display:none;
}
.editonly .app-section .section-content {
  padding:0;
  padding-top:16px;
}
.editonly .app-section .section-content-wrapper {
  overflow: visible;
  height:auto !important;
}
</style>
<style>
.app-section .section-title .material-icons {
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.app-section .round-button.save.disabled {
  color:#C4C4C4;
}

.app-section .section-title .round-button {
  transition: max-width 300ms;
  max-width: 26px;
  justify-content: flex-start;
  width: auto;
  overflow:hidden;
}

.app-section .section-title .round-button.startclose {
  max-width:100px;
}

.app-section .section-title .round-button .material-icons {
  margin-left:-5px;
}
.app-section .section-title .round-button.startclose .material-icons {
  margin-left:6px;
}

.dialog-header .app-section .section-title {
  z-index: 2;
  height:  54px;
  margin: 0;
  padding: 0 0 9px;
  padding-bottom: 0;
  border-bottom: 0;
  display: flex;
  align-items: flex-start;
  color: rgba(0,0,0,.87);
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 600;
  letter-spacing: .0125em;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  text-decoration: inherit;
  text-transform: inherit;
  align-items: center;
  padding-right: 8px;
  background-color: #F2F2F2;
  border-color: #DADADA;
  border-radius: 4px 4px 0 0;
  border-bottom-style: solid;
  border-width: 1px
}

.dialog-header .app-section .section-title .mdc-typography--headline5 {
  font-weight: 500;
}

@media (max-width: 310px){
  .button-text {
    display:none;
  }
  .section-title .edit {
    margin: 0;
  }
  .section-title .save {
    padding-left:6px !important;
    padding-right:6px !important;
    width: 26px !important;
    margin: 0;
  }
  .app-section .section-title .round-button.save .material-icons{
    margin-left: -3px !important;
    margin-right: -3px !important;
  }
}

</style>
