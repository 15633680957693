<template>
  <div class="button-wrapper">

    <span title="Schedule a Meeting">
      <mdc-button @click="runAction('goto:scheduler')">
          <mdc-icon icon="calendar_today"></mdc-icon>
      </mdc-button>
    </span>

    <span title="Attend a Meeting">
      <mdc-button @click="runAction('goto:voice')">
        <mdc-icon icon="call"></mdc-icon>
      </mdc-button>
    </span>

    <span title="Operating Agreement">
      <mdc-button @click="runAction('goto:share-agreement')">
        <mdc-icon icon="merge_type"></mdc-icon>
      </mdc-button>
    </span>

    <span title="Duo Proposal">
      <mdc-button :disabled="!duoEnabled" @click="runAction('goto:duo-proposal')">
        <mdc-icon icon="supervisor_account"></mdc-icon>
      </mdc-button>
    </span>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'ShareAgreementButtons',
  data () {
    return {
    }
  },
  props: {
    connection: Object,
    duoEnabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  methods: {
    ...mapActions([
      'setSnackMessage',
      'updateConnection'
    ]),
    emitAction: function (status) {
      this.$emit('action', { action: 'statusMenu' })
    },
    runAction (action) {
      const [command, param] = action.split(':')
      if (command === 'goto') {
        this.$router.push(`/${param}/${this.connection.id}`)
      } else if (command === 'setStatus') {
        this.updateConnection({ connectionId: this.connection.id, connection: { status: param } })
      }
      this.statusMenuOpen = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button-wrapper {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  padding-left:24px;
}
.button-wrapper .mdc-button {
  min-width:26px;
  color: #666666;
}
.button-wrapper .notification-icon {
  width:9px;
  height:9px;
  display:inline-block;
  border-radius: 100%;
  background-color: #FFAB40;
  font-size:9px;
  z-index: 100;
  text-align: center;
  position: relative;
  left: -10px;
}
</style>
