<template>
  <div
  class="cover"
  :class="{ editing: editing }"
  >

    <div
      @mouseover="hover = true"
      @mouseleave="hover = false"
      style="position:relative;height:300px;display:flex; justify-content: center;"
    >
      <div v-if="editing" class="edit-message">
        <mdc-body class="edit-message-text">
          Drag image to reposition
        </mdc-body>
      </div>

      <vue-croppie
          v-show="editable && editing"
          class="croppie"
          ref="croppieRef"
          :enableResize="false"
          :enableZoom="true"
          :mouseWheelZoom="false"
          :enforceBoundary="true"
          :showZoomer="false"
          :viewport="{ width: '100%', height: 300, type: 'square' }"
          @result="result"
          @update="update">
      </vue-croppie>

      <div v-show="!editable || !editing"
      id="coverimg"
      :style="{
        'background-repeat': 'no-repeat',
        'background-image': coverImageCss,
        'background-position': backgroundPosition,
        'background-size': backgroundSize,
        'height': '300px',
        'width': '100%'
      }"
      >
      </div>

      <div class="edit-buttons-wrapper">
        <div v-if="editable" class="edit-buttons tabletonly" :class="{ 'hidden': !hover && !editing}">
          <round-button hasSlot v-if="!editing" @click="editing = true" iconOutline icon="pan_tool">Reposition</round-button>
          <round-button hasSlot v-else @click="savePosition" icon="done">Save Position</round-button>
          <round-button hasSlot v-if="!editing" @click="open = true" icon="add_a_photo">Select Cover</round-button>
          <round-button hasSlot v-else @click="cancelPosition" icon="clear">Cancel</round-button>
        </div>
        <div v-if="editable" class="edit-buttons mobileonly">
          <round-button v-if="!editing" label="Select Cover" title="Select Cover" @click="open = true" icon="add_a_photo"></round-button>
          <round-button v-else label="Cancel" title="Cancel" @click="cancelPosition" icon="clear"></round-button>
        </div>
      </div>
    </div>

      <app-dialog v-model="open"
      title="Select Cover Image"
      subtitle="Personalise your profile with a cover photo you like."
      accept=""
      cancel="cancel"
      class="open-cover-image modal fullsize-mobile"
      scrollable>

      <div class="mdc-dialog__body">
        <div v-for="(covers, index) in coverImages" :key="index">
          <mdc-subheading>{{index}}</mdc-subheading>
          <mdc-layout-grid style="padding:0"  >
            <mdc-layout-cell desktop="3" tablet="2" mobile="2"
              v-for="(cover, index) in covers" :key="index"
              style="cursor:pointer;overflow:hidden;height: 115px;border-radius: 8px;"
              @click.native="selectCover('/static/img/cover-image/' + cover)"
              >
                <div class="cover-wrapper">
                  <img style="width:100%" v-if="cover" :src="'/static/img/cover-image/' + cover">
                </div>
            </mdc-layout-cell>
          </mdc-layout-grid>
        </div>

      </div>
    </app-dialog>

  </div>
</template>

<script>
import AppDialog from '../../components/AppDialog'

export default {
  name: 'CoverSection',
  components: {
    AppDialog
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    profile: {
      type: Object
    }
  },
  data () {
    return {
      open: false,
      hover: false,
      coverImage: '/static/img/cover-image/pattern4.jpg',
      coverImageFile: null,
      editing: false,
      cropped: null,
      backgroundPosition: 'center',
      newBackgroundPosition: 'center',
      zoom: 0,
      points: null,
      backgroundSize: '100% auto',
      coverImages: {
        Patterns: [
          'pattern1.jpg',
          'pattern2.jpg',
          'pattern3.jpg',
          'pattern4.jpg',
          'pattern5.jpg',
          'pattern6.jpg',
          'pattern7.jpg',
          'pattern8.jpg'
        ],
        Urban: [
          'urban1.jpg',
          'urban2.jpg',
          'urban3.jpg',
          'urban4.jpg',
          'urban5.jpg',
          'urban6.jpg',
          'urban7.jpg',
          'urban8.jpg'
        ],
        Space: [
          'space1.jpg',
          'space2.jpg',
          'space3.jpg',
          'space4.jpg',
          'space5.jpg',
          'space6.jpg',
          'space7.jpg',
          'space8.jpg'
        ]
      }
    }
  },
  mounted () {
    this.coverImageFile = new Image()
    this.coverImageFile.src = this.coverImage
  },
  computed: {
    coverImageCss () {
      return `url(${this.coverImage})`
    }
  },
  methods: {
    selectCover (cover) {
      this.backgroundPosition = 'center'
      this.open = false
      this.points = null
      this.zoom = 0
      this.coverImage = cover
      this.$emit('updateProfileSection', { coverImage: this.coverImage, coverPoints: this.points, coverPosition: this.backgroundPosition })
    },
    savePosition () {
      this.points = this.currentPoints
      this.backgroundPosition = this.newBackgroundPosition
      this.editing = false
      this.$emit('updateProfileSection', { coverImage: this.coverImage, coverPoints: this.points, coverPosition: this.backgroundPosition })
    },
    cancelPosition () {
      this.editing = false
    },
    result (output) {
      this.cropped = output
    },
    update (val) {
      const y = val.points[1]

      const dy = val.points[3] - val.points[1]

      this.currentPoints = val.points
      if (!this.zoom) {
        this.zoom = val.zoom
      }
      const coverImg = this.coverImageFile

      const imgHeight = coverImg.naturalHeight

      const offstHeight = imgHeight - dy

      const ypc = y / offstHeight * 100
      this.newBackgroundPosition = `left 50% top ${ypc}%`
    }
  },
  watch: {
    editing: {
      handler (newe, olde) {
        const options = {
          url: this.coverImage
        }
        if (this.points) {
          options.points = this.points
        }
        if (this.zoom) {
          options.zoom = this.zoom
        }
        if (this.editing) {
          this.$refs.croppieRef.bind(options)
          document.body.scrollTop = document.documentElement.scrollTop = 0
          document.getElementsByClassName('body')[0].classList.add('mdc-dialog-scroll-lock')
        } else {
          document.getElementsByClassName('body')[0].classList.remove('mdc-dialog-scroll-lock')
        }
      }
    },
    coverImage: {
      handler (newc, oldc) {
        const options = {
          url: this.coverImage
        }
        if (this.points) {
          options.points = this.points
        }
        if (this.zoom) {
          options.zoom = this.zoom
        }
        this.coverImageFile.src = this.coverImage
        this.$refs.croppieRef.bind(options)
      }
    },
    profile: {
      handler (newp, oldp) {
        if (this.profile) {
          if (this.profile.coverImage) {
            this.coverImage = this.profile.coverImage
          }
          if (this.profile.coverPoints) {
            this.points = this.profile.coverPoints
          }
          if (this.profile.coverPosition) {
            this.backgroundPosition = this.profile.coverPosition
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style>
.cover .edit-message {
  position: absolute;
  z-index: 12;
  text-align: center;
  color: white;
  opacity: 0.8;
}
.cover .croppie {
  opacity: 0.9;
  z-index: 11;
}
.cover .edit-message .edit-message-text {
  padding:6px;
  display:inline-block;
  border-radius: 4px;
  background-color: rgba(0,0,0,0.5)
}

.cover .edit-buttons-wrapper {
  position:absolute;
  top: 70px;
  z-index: 1;
  display:flex;
  justify-content: flex-end;
  width: calc(100% - 4*(var(--mdc-layout-grid-margin-desktop, 24px)));
  max-width: 1152px;
}

.cover.editing .edit-buttons-wrapper {
  z-index: 12;
}

.cover .edit-buttons {
  opacity: 1;
  transition: all 300ms ease-out;
  display:flex;
}

.cover .edit-buttons .round-button {
  margin: 0, 6px, 6px, 6px;
}

.cover .edit-buttons .material-icons, .cover .edit-buttons .material-icons-outlined {
  height:20px;
  width:20px;
  font-size:20px;
}

.cover .edit-buttons.tabletonly .material-icons, .cover .edit-buttons .material-icons-outlined {
  margin-right:6px;
}

.cover .edit-buttons.hidden {
  opacity: 0;
}

@media all and (min-width: 570px) {
  .cover .mobileonly {
    display: none !important;
  }
}
@media all and (max-width: 840px) {
  .cover .edit-buttons-wrapper{
    width: calc(100% - 24px - 2*(var(--mdc-layout-grid-margin-desktop, 16px)));
  }
}
@media all and (max-width: 570px) {
  .cover .tabletonly {
    display: none !important;
  }
  .cover .coverimg {
    background-position: center !important;
  }
  .cover .edit-buttons-wrapper{
    width: calc(100% + 24px - 2*(var(--mdc-layout-grid-margin-desktop, 16px)));
  }
}

</style>
