<template>
  <div class="main-content channel-group">
    <div class="main-wrapper" style="margin-top: 90px;">

      <mdc-layout-grid class="main-content-inner">
        <mdc-layout-cell span=12>

          <app-section
          class="connect"
          dialogHeader
          :editable="false"
          animationTime="0ms"
          :title="group"
          >
            <div v-if="$role('admin')" style="display:flex; justify-content: flex-end;">
                <round-button @click="addChannelOpen = true" icon="add" />
            </div>

            <div>
              <mdc-list style="padding: 0;">
                <mdc-list-item v-for="channel in channelsFiltered" :key="channel.id"  class="connection" >
                  <div class="connection-row" @click="$router.push(`/channels/${group}/${channel.id}`)">

                    <span class="avatar" v-if="channel.lastSender">
                      <img v-if="hasPhoto(getProfile(channel.lastSender))" :src="getPhoto(getProfile(channel.lastSender))"/>
                      <default-avatar v-else fontSize="26px" :profile="getProfile(channel.lastSender)"/>
                    </span>
                    <span v-else>
                      <img v-if="channel.channelLogo" style="max-width:48px;max-height:48px;margin-right: 12px;" :src="channel.channelLogo"/>
                    </span>

                    <div class="connection-details">
                      <div class="connection-title">
                        <span v-if="channel.lastSender">{{getProfile(channel.lastSender).displayName}}</span>
                        <span v-if="channel.lastSender" style="margin-left:6px;margin-right:6px;">&middot;</span>

                        <span v-if="channel.lastUpdate">
                          <time-ago refresh long :datetime="channel.lastUpdate.toDate()"></time-ago>
                          <span style="margin-left:6px;margin-right:6px;">&middot;</span>
                        </span>
                        <span style="text-transform:capitalize">{{channel.channelType}} {{channel.channelName}}</span>

                      </div>

                      <mdc-body tag="span" typo="body2" class="lastMessage">{{stripTags(channel.lastMessage) || 'No messages found'}}</mdc-body>

                    </div>

                  </div>
                </mdc-list-item>
              </mdc-list>
            </div>
          </app-section>

        </mdc-layout-cell>

      </mdc-layout-grid>

      <mdc-dialog v-model="addChannelOpen"
        :title="'Add Channel'" accept="Save" cancel="Cancel"
              @accept="onChannelAdd" @cancel="onChannelCancel">
        <mdc-textfield style="margin-top:0px;" label="Add New Channel Name here" v-model="newChannelName"/>
        <br><br/>
        <mdc-textfield style="margin-top:0px;" label="Add New Channel Message here" v-model="firstMessage"/>
      </mdc-dialog>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ConnectMenu from '../components/ConnectMenu.vue'
import DefaultAvatar from '../components/DefaultAvatar'
import ConnectMenuRemote from '../components/ConnectMenuRemote.vue'
import AppSection from '../components/profile-v2/AppSection.vue'
import TimeAgo from '../components/TimeAgo.vue'

export default {
  name: 'channelgroup',
  data () {
    return {
      addChannelOpen: false,
      newChannelName: '',
      firstMessage: '',
      title: 'Channels',
      group: null,
      links: [
        { component: ConnectMenu }
      ]
    }
  },
  components: {
    AppSection,
    TimeAgo,
    DefaultAvatar
  },
  computed: {
    ...mapState([
      'role',
      'user',
      'profile',
      'profiles',
      'channels',
      'remote',
      'teamProfiles'
    ]),
    channelsFiltered: function () {
      if (this.group === 'Community') {
        const publicChannels = this.$store.state.channelMessages.publicChannels.filter(channel => channel.channelType === 'Community')
        return [...publicChannels]
      } else if (this.group === 'Team') {
        const publicChannels = this.$store.state.channelMessages.teamChannels
        return [...publicChannels]
      } else {
        return this.$store.state.channelMessages.companyChannels
      }
    }
  },
  methods: {
    ...mapActions([
      'setPageLoad',
      'addChannel'
    ]),
    stripTags (str) {
      let strippedStr = ''
      if (str) {
        strippedStr = str.replace(/(<([^>]+)>)/gi, '')
      }
      return strippedStr
    },
    onChannelAdd () {
      const self = this
      this.addChannel({ channel: { channelName: this.newChannelName, channelType: this.group, createdBy: this.profile.id, firstMessage: this.firstMessage } }).then(() => {
        self.newChannelName = ''
        self.firstMessage = ''
      })
    },
    onChannelCancel () {
      this.newChannelName = ''
      this.firstMessage = ''
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      if (profileId === 'bot') {
        return {
          displayName: 'DuoMe Bot',
          photoURL: '/static/img/chat-bubble-circle.svg'
        }
      }
      let profileMap = new Map()
      if (this.teamProfiles) {
        profileMap = new Map(this.teamProfiles.map(i => [i.id, i]))
      } else {
        profileMap = new Map(this.profiles.map(i => [i.id, i]))
      }
      const prof = profileMap.get(profileId)
      if (prof) {
        return prof
      }
      return {}
    },
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    },
    getPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return profile.photoURL
        }
      }
      return false
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$emit('setNavActive', null)
    this.$root.$emit('enableSearch', false)
  },
  beforeRouteUpdate (to, from, next) {
    this.group = to.params.group
    setTimeout(() => {
      console.log('ChannelGroup.beforeRouteUpdate.enabledSearch')
      this.$root.$emit('enableSearch', true, { back: true, context: 'duos' })
    }, 300)
    next()
  },
  mounted () {
    this.$emit('setNavActive', 'Connect')
    if (this.remote) {
      this.links = [{ component: ConnectMenuRemote }]
    } else {
      this.links = [{ component: ConnectMenu }]
    }
    this.$emit('setLinks', this.links)
    this.$root.$emit('enableSearch', true, { context: 'messages' })
    this.group = this.$route.params.group
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  },
  watch: {
    remote () {
      if (this.remote) {
        this.links = [{ component: ConnectMenuRemote }]
      } else {
        this.links = [{ component: ConnectMenu }]
      }
      this.$emit('setLinks', this.links)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.channel-group .connect .connection-row .avatar {
    min-width: 48px;
    max-width: 48px;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: block;
    overflow: hidden;
    margin-right: 12px;
    position: relative;
}
.channel-group .connect .avatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.channel-group .connect .connection {
    height: auto;
    padding: 0;
}
.channel-group .connection .connection-row {
  cursor:pointer;
  width:100%;
}
.channel-group .connect .connection:hover {
  background-color: #F9F9F9;
}
.channel-group .connect .connection .connection-row {
  cursor:pointer;
  width: calc(100% - 20px);
  border-bottom:1px solid #eee;
  display: flex;
  padding: 10px;
}
.channel-group .connect .connection  .lastMessage {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  padding-right: 20px;
}
.channel-group .connect .connection .connection-details {
  max-width: calc(100% - 60px);
}
.channel-group .connect .connection .connection-title {
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
}
.channel-group .connect .connection .lastMessage{
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  padding-right: 20px;
}
</style>
