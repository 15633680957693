<template>
  <!-- About -->
  <app-section class="headline"
  :editable="editable"
  :focused="focusField === 'aboutme'"
  animationTime="0ms"
  @change="setEditing"
  @cancel="cancel"
  @save="updateAbout"
  title="Headline"
  >
    <div style="position:relative" v-if="editingAbout">

      <auto-complete-outline
      label="Role"
      prompt="Tell people what you do:"
      :required="true"
      :value="headline"
      autocompleteId="'role'"
      :field="'role'"
      @set="setRole"
      @input="setRole"
      :items="roles"
      ariaLabelledBy="roleLabel"
      style="z-index:2;"
      maxlength="120"
      :isMobile="isMobile"
      :mobileOverlay="true"
      :next="ifCompany(!!industryByCompany,'aboutme','industry')"
      @focusOn="focusOn"
      ref="role"
      helptext = "- E.g. Project Manager"
      >
        <mdc-text-field-counter slot="counter">0 / 120</mdc-text-field-counter>
      </auto-complete-outline>

      <div>
        <auto-complete-outline
          label="Industry"
          prompt="And what types of places you've worked:"
          :required="true"
          :value="industry"
          :readOnly="!!industryByCompany"
          :disabled="!!industryByCompany"
          autocompleteId="'industry'"
          :field="'name'"
          @set="setIndustry"
          @input="setIndustry"
          :items="industries"
          ariaLabelledBy="industryLabel"
          style="z-index:1"
          maxlength="120"
          :isMobile="isMobile"
          :mobileOverlay="true"
          next="aboutme"
          prev="role"
          @focusOn="focusOn"
          ref="industry"
          :helptext="helpTextCompany('- E.g. Insurance', industryByCompany)"
          >
            <mdc-text-field-counter slot="counter">{{industry.length}} / 120</mdc-text-field-counter>
        </auto-complete-outline>
      </div>

      <mdc-body typo="body2">Explain a bit more about yourself, think elevator pitch:</mdc-body>
      <mdc-body typo="body2">Tell people what you do:</mdc-body>
      <mdc-text-area-autosize
        :focusedOn="focusField === 'aboutme'"
        @focusOn="focusOn"
        @focus="$emit('hasFocused')"
        ref="aboutme"
        label="About Me"
        :value="aboutMe"
        @input="aboutMe = $event"
        outlined
        :maxHeight="ifMobile(150, 200)"
        maxlength="2000"
        promptMobileOverlay="Tell people what you do"
        @blur="saveAboutMe"
        :isMobile="isMobile"
        :mobileOverlay="true"
        style="margin-bottom:16px;"
        :prev="ifCompany(!!industryByCompany,'role','industry')"
        :finish="true"
        helptextMobileOverlay="- E.g. Experienced Project Manager with a degree in Economics"
        helptext="- E.g. Experienced Project Manager with a degree in Economics">
        <mdc-text-field-counter slot="counter">0 / 2000</mdc-text-field-counter>
      </mdc-text-area-autosize>

    </div>
    <div v-bind:class="{expanded: aboutexpanded}" v-else>
      <div ref="abouttext">
        <mdc-body style="margin-bottom:16px;margin-top:0; display:flex; align-items:center;">
          <mdc-icon>
            <img style="width:18px;margin-right:16px" src="/static/img/icons/people_outline_light.svg">
          </mdc-icon>
          <span>{{profile.role}}</span>
        </mdc-body>
        <mdc-body style="margin-bottom:16px;;margin-top:0; display:flex; align-items:center;">
          <mdc-icon>
            <img style="width:18px;margin-right:16px" src="/static/img/icons/business-outline-light.svg">
          </mdc-icon>
          <span>{{profile.industry}}</span>
        </mdc-body>
        <mdc-body ref="aboutcontent" id="aboutcontent" v-show="!other || profile.aboutMe" class="about-text" style="margin-top:-6px;" typo="body2" v-html="profile.aboutMe"></mdc-body>
      </div>
      <div v-if="!other || profile.aboutMe">
        <div v-if="contentHeight > 100">
          <round-button style="margin-left:auto;margin-right:auto;margin-top:0;margin-bottom:0;" v-if="aboutexpanded" @click="aboutexpanded = !aboutexpanded" icon="keyboard_arrow_up" />
          <round-button style="margin-left:auto;margin-right:auto;margin-top:0;margin-bottom:0;" v-else @click="aboutexpanded = !aboutexpanded" icon="keyboard_arrow_down"/>
        </div>
      </div>
    </div>

  </app-section>
</template>

<script>
import ResizeObserverPolyfill from '@juggle/resize-observer'
import RoundButton from '../../components/RoundButton'
import AutoCompleteOutline from '../../components/AutoCompleteOutline.vue'
import AppSection from '../../components/profile-v2/AppSection.vue'

import { mapState } from 'vuex'

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill

export default {
  name: 'AboutSection',
  components: {
    RoundButton,
    AutoCompleteOutline,
    AppSection
  },
  props: {
    focus: {},
    editable: {
      type: Boolean,
      default: false
    },
    profile: Object,
    other: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      focusField: '',
      cancelSave: false,
      isMounted: false,
      contentHeight: null,
      editingAbout: false,
      aboutexpanded: false,
      showMore: false,
      aboutMe: '',
      headline: '',
      industry: '',
      observer: null,
      previousAbout: ''
    }
  },
  computed: {
    ...mapState([
      'roles',
      'industries',
      'company'
    ]),
    industryByCompany () {
      if (this.company.assignedCompany) {
        if (this.company.assignedSubCompany) {
          if (this.company.assignedSubCompany.industry) {
            return this.company.assignedSubCompany.industry
          }
        }
        if (this.company.assignedCompany.industry) {
          return this.company.assignedCompany.industry
        }
      }
      return false
    }
  },
  methods: {
    calcHeight () {
      const self = this
      this.$nextTick(() => {
        if (self.aboutMe) {
          if (self.$refs.aboutcontent) {
            if (self.contentHeight !== self.$refs.aboutcontent.$el.scrollHeight) {
              self.contentHeight = self.$refs.aboutcontent.$el.scrollHeight
            }
          }
        }
      })
    },
    ifCompany (fieldSetByCompany, pos, neg) {
      if (fieldSetByCompany) {
        return pos
      }
      return neg
    },
    helpTextCompany (helpText, fieldSetByCompany) {
      if (fieldSetByCompany) {
        return '- This field is set by your employer'
      }
      return helpText
    },
    ifMobile (ifTrue, ifFalse) {
      if (this.isMobile) {
        return ifTrue
      }
      if (ifFalse) {
        return ifFalse
      }
      return false
    },
    setEditing (val) {
      if (!val) {
        this.$emit('editing', false)
      } else {
        this.$emit('editing', 'headline')
      }
      this.editingAbout = val
      if (this.editingAbout) {
        this.cancelSave = false
        this.previousAbout = `${this.profile.aboutMe}`
        this.aboutMe = this.profile.aboutMe
        this.headline = this.profile.role
        this.industry = this.profile.industry
      }
    },
    saveAboutMe () {
      setTimeout(() => {
        if (!this.cancelSave) {
          // console.log('HeadlineSection.saveAboutMe', this.profile.aboutMe, this.profile.role, this.profile.industry)

          this.profile.role = this.headline
          this.profile.industry = this.industry
          this.profile.aboutMe = this.aboutMe
          const updates = {
            aboutMe: this.aboutMe,
            role: this.headline,
            industry: this.industry
          }
          this.$emit('updateProfileSection', updates)
        } else {
          this.cancelSave = false
        }
      }, 300)
    },
    cancel () {
      this.cancelSave = true
      this.$nextTick(() => {
        // console.log(this.profile.aboutMe, this.previousAbout)
        if (this.profile.aboutMe !== this.previousAbout) {
          this.profile.aboutMe = this.previousAbout
          const updates = {
            aboutMe: this.previousAbout
          }
          this.$emit('updateProfileSection', updates)
        }
      })
    },
    updateAbout () {
      this.profile.aboutMe = this.aboutMe
      this.profile.role = this.headline
      this.profile.industry = this.industry
      // console.log('HeadlineSection.updateAbout', this.profile.aboutMe, this.profile.role, this.profile.industry)
      const updates = {
        aboutMe: this.aboutMe,
        role: this.headline,
        industry: this.industry
      }
      this.calcHeight()
      this.$emit('updateProfileSection', updates)
    },
    setRole: function (role) {
      if (typeof role.role !== 'undefined') {
        this.headline = role.role
      } else {
        this.headline = role
      }
    },
    setIndustry: function (industry, index) {
      if (typeof industry === 'object') {
        this.industry = industry.name
      } else {
        this.industry = industry
      }
    },
    focusOn (target, direction) {
      // console.log('focusOn', target, this.$refs[target])
      this.focusField = target
      if (this.$refs[target]) {
        try {
          this.$refs[target].focus()
        } catch (err) {
          if (this.$refs[target].$el) {
            this.$refs[target].$el.focus()
          }
        }
      }
    }
  },
  mounted () {
    this.isMounted = true
    this.calcHeight()
    this.observer = new ResizeObserver(this.calcHeight)
    if (this.$refs.aboutcontent) {
      this.observer.observe(this.$refs.aboutcontent.$el)
    }
  },
  watch: {
    profile: {
      handler (newp, oldp) {
        this.aboutMe = this.profile.aboutMe
        this.headline = this.profile.role
        this.industry = this.profile.industry
        if (this.industryByCompany) {
          this.industry = this.industryByCompany
          this.profile.industry = this.industryByCompany
        }
      },
      immediate: true
    },
    editingAbout () {
      if (!this.editingAbout) {
        if (this.$refs.aboutcontent) {
          this.observer.observe(this.$refs.aboutcontent.$el)
        }
      }
    },
    focus: {
      handler () {
        // console.log('HeadlineSection.watch:focus', this.focus)
        this.focusField = this.focus
      },
      immediate: true
    },
    focusField: {
      handler () {
        if (this.focusField === 'aboutme') {
          this.setEditing(true)
        }
      },
      immediate: true
    }
  }
}
</script>

<style>
.headline .sub-label {
  font-size:0.8em;
  margin-top:6px;
  margin-bottom:16px;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
}

.headline .about-text {
  max-height:100px;
  overflow:hidden;
  white-space:pre-wrap;
  overflow-wrap: break-word;
}
.headline .expanded .about-text {
  max-height: unset;
  height:auto;
  overflow:hidden;
}
</style>
