import firebase from 'firebase/app'
import { functionsUrl, appName } from '../settings'
import 'firebase/auth'
import fb from '../store/firebase'
const connectionsRef = fb.firestore.collection('connections')

const callFunction = async (funcType, func, payload) => {
  payload.token = ''
  if (firebase.auth().currentUser) {
    payload.token = await firebase.auth().currentUser.getIdToken(true)
    // const tokenRes = await firebase.auth().currentUser.getIdTokenResult()
    // console.log('backend.callFuncion', funcType, func, tokenRes)
  }
  let nextAction = null
  if (func === 'botmessage') {
    if (payload.connection) {
      nextAction = payload.connection.nextAction
      delete payload.connection
    }
  }
  let res = {}
  try {
    const url = (funcType === 'search')
      ? `https://backend-dot-${appName}.appspot.com`
      : functionsUrl
    const funcPath = (funcType)
      ? `${funcType}/${func}`
      : `${func}`
    const result = await fetch(`${url}/${funcPath}`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    res = await result.json()
  } catch (err) {
    console.log('backend.callFunction error', err)
    res.error = err
    if (func === 'botmessage') {
      if (nextAction) {
        nextAction.disabled = false
        connectionsRef.doc(payload.connectionId).update({ nextAction })
      }
    }
  }
  return res
}

export const mail = async (func, payload) => {
  return callFunction('mailer', func, payload)
}

export const appFunction = async (func, payload) => {
  return callFunction('app', func, payload)
}

export const search = async (func, payload) => {
  return callFunction('search', func, payload)
}

export const cloudFunction = async (func, payload) => {
  return callFunction(false, func, payload)
}
