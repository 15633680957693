<template>
  <div class="image-uploader">
    <div class="image-uploader-wrapper">
      <div class="image-input__container image-uploader"
        v-bind:style="containerStyles"
        v-bind:class="{ 'image-loaded': isImageLoaded }">

        <!-- Hidden input we use to access file contents -->
        <form class="image-input__form" ref="form">
        <input @change="previewThumbnail" accept="image/*" class="image-input__input" ref="fileInput" type="file" />
        </form>

        <div v-bind:style="containerStyles" class="image-upload-avatar-overlay">
          <div id="existing" v-show="!isImageLoaded && !working" v-if="image"><img :src="image" :style="containerStyles" /></div>
          <div id="box"><div id="circle"></div></div>
        </div>
        <div @click="triggerInput" class="image-input__overlay"></div>

        <!-- Used in place of an image tag -->
        <canvas class="image-input__canvas"
          ref="canvas"
          v-show="isImageLoaded"
          :height="height"
          :width="width"
          :class="{ 'is-draggable': hoverIsDraggable }"
        >
        </canvas>

        <div class="image-input__control-buttons">
          <round-button class="control-button" background="transparent" title="Zoom in" label="Zoom in" @click="scaleUp" icon="zoom_in"/>
          <round-button class="control-button" background="transparent" title="Zoom out" label="Zoom out" @click="scaleDown" icon="zoom_out"/>
          <round-button class="control-button" background="transparent" title="Rotate" label="Rotate" @click="rotate" icon="rotate_90_degrees_ccw"/>
          <round-button class="control-button" background="transparent" title="Clear" label="Clear" @click="resetInput" icon="clear"/>
        </div>

        <round-button hasSlot elevated title="Upload" label="Upload" @click="triggerInput" class="upload-button" icon="arrow_upward">Upload Photo</round-button>

        <mdc-body typo="body2" class="sub-label" style="position:absolute; top: 325px;margin-right:auto;margin-left:auto">Drag picture to reposition</mdc-body>

      </div>
    </div>
  </div>
</template>

<script>
import { dataURItoBlob } from '../../utils/files'
import RoundButton from '../../components/RoundButton'

export default {
  name: 'imageuploader',
  props: ['id', 'width', 'height', 'image', 'save'],
  data: function () {
    return {
      isImageLoaded: false,
      working: false,
      hoverIsDraggable: false,
      ctx: null,
      canvas: null,
      filename: null,
      img: null,
      imgSrc: null,
      scaleSteps: 0,
      imageX: 0,
      imageY: 0,
      scaledImageWidth: 0,
      scaledImageHeight: 0,
      imageWidth: 0,
      imageHeight: 0,
      imageRight: 0,
      imageBottom: 0,
      draggingImage: false,
      startX: 0,
      startY: 0,
      mouseX: 0,
      mouseY: 0,
      degreesrotated: 0,
      imageData: ''
    }
  },
  components: {
    RoundButton
  },
  computed: {
    containerStyles: function () {
      return {
        width: this.width + 'px',
        height: this.height + 'px'
      }
    }
  },
  mounted: function () {
    this.$on('get-image-base64', function () {
      this.updateTemplateImage()
    })
    // Instantiate
    this.canvas = this.$refs.canvas
    this.ctx = this.canvas.getContext('2d')
    this.imgSrc = document.createElement('img')
    this.img = document.createElement('img')
  },
  watch: {
    save: function (news, olds) {
      if (news) {
        this.toBase64()
      }
    }
  },
  methods: {
    updateImageData: function (newData) {
      this.imageData = newData
      var file = dataURItoBlob(newData)
      this.$emit('change', file, this.filename)
      this.resetInput()
    },
    toBase64: function () {
      var imageData = this.canvas.toDataURL('image/png')
      imageData.replace('data:image/pngbase64,', '')
      this.updateImageData(imageData)
    },
    draw: function (withBorders) {
      // clear the canvas
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

      var scaleFactor = 1 - (this.scaleSteps * 0.1)
      this.scaledImageWidth = this.img.width * scaleFactor
      this.scaledImageHeight = this.scaledImageWidth * (this.img.height / this.img.width)

      if (this.scaledImageWidth < this.width) {
        this.scaleSteps--
        scaleFactor = 1 - (this.scaleSteps * 0.1)
        this.scaledImageWidth = this.img.width * scaleFactor
        this.scaledImageHeight = this.scaledImageWidth * (this.img.height / this.img.width)
      }
      if (this.scaledImageHeight < this.height) {
        this.scaleSteps--
        scaleFactor = 1 - (this.scaleSteps * 0.1)
        this.scaledImageWidth = this.img.width * scaleFactor
        this.scaledImageHeight = this.scaledImageWidth * (this.img.height / this.img.width)
      }

      if (this.imageX < this.width - this.scaledImageWidth) {
        this.imageX = this.width - this.scaledImageWidth
      }

      if (this.imageY < this.height - this.scaledImageHeight) {
        this.imageY = this.height - this.scaledImageHeight
      }

      // draw the image
      // this.ctx.drawImage(
      // this.img, (top left coords of source to use (x,y))
      // width & height of source to use (width, height)
      // (x,y) on canvas
      // width & height on canvas
      this.ctx.drawImage(this.img, 0, 0, this.img.width, this.img.height, this.imageX, this.imageY, this.scaledImageWidth, this.scaledImageHeight)
      this.imageRight = this.imageX + this.scaledImageWidth
      this.imageBottom = this.imageY + this.scaledImageHeight

      // optionally draw a box around the image (indicates "selected")
      if (withBorders) {
        this.ctx.beginPath()
        this.ctx.moveTo(this.imageX, this.imageY)
        this.ctx.lineTo(this.imageRight, this.imageY)
        this.ctx.lineTo(this.imageRight, this.imageBottom)
        this.ctx.lineTo(this.imageX, this.imageBottom)
        this.ctx.closePath()
        this.ctx.stroke()
      }
    },
    resetInput: function () {
      if (this.isImageLoaded) {
        // clear the canvas
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
        this.scaleSteps = 0
        // Reset state
        this.$refs.form.reset()
        this.isImageLoaded = false
        this.working = false
        this.$emit('loaded', false)
      }
    },
    rotate: function () {
      if (this.isImageLoaded) {
        const self = this
        const degrees = -90
        this.degreesrotated += degrees
        if (this.degreesrotated < 0) {
          this.degreesrotated = this.degreesrotated + 360
        }
        this.isImageLoaded = false
        this.working = true
        this.$nextTick(() => {
          // save the unrotated context of the canvas so we can restore it later
          this.ctx.save()

          var isHorizontal = false

          // calc max dim
          const maxD = this.img.width > this.img.height ? this.img.width : this.img.height

          if (this.img.width >= this.img.height) {
            isHorizontal = true
          } else {
            isHorizontal = false
          }

          this.canvas.width = maxD
          this.canvas.height = maxD

          // this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

          // move to the center of the canvas
          this.ctx.translate(this.canvas.width / 2, this.canvas.height / 2)

          // rotate the canvas to the specified degrees
          this.ctx.rotate(degrees * Math.PI / 180)

          var X = -maxD / 2
          var Y = -maxD / 2

          if (isHorizontal) {
            Y = -this.img.height / 2
          } else {
            X = -this.img.width / 2
          }
          this.ctx.drawImage(this.img, X, Y)

          var imgSquared = new Image()
          // squared image
          imgSquared.src = this.canvas.toDataURL('image/png')
          imgSquared.onload = function () {
            // resize the canvas to oposite Dim
            self.canvas.width = self.img.height
            self.canvas.height = self.img.width
            var sourceY = 0
            var sourceX = 0

            if (isHorizontal) {
              sourceX = (maxD - self.canvas.width) / 2
            } else {
              sourceY = (maxD - self.canvas.height) / 2
            }
            var sourceWidth = self.canvas.width
            var sourceHeight = self.canvas.height

            // destination canvas resized
            var destX = 0
            var destY = 0
            var destWidth = self.canvas.width
            var destHeight = self.canvas.height

            // restore context + draw image
            self.ctx.restore()
            // console.log('self.ctx.clearRect()')
            // self.ctx.clearRect(0, 0, self.canvas.width, self.canvas.height)
            self.ctx.drawImage(imgSquared, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight)
            self.imgSrc.src = self.canvas.toDataURL()

            self.canvas.width = self.width
            self.canvas.height = self.height

            // Grab position info
            self.imageWidth = imgSquared.width
            self.imageHeight = imgSquared.height
            self.imageRight = destX + self.imageWidth
            self.imageBottom = destY + self.imageHeight

            // Redraw
            imgSquared = null

            setTimeout(() => {
              self.isImageLoaded = true
              self.draw(false)
              self.working = false
            }, 100)
          }
        })
      }
    },
    scaleUp: function () {
      if (this.isImageLoaded) {
        this.scaleSteps--
        this.draw(false)
      }
    },
    scaleDown: function () {
      if (this.isImageLoaded) {
        this.scaleSteps++
        this.draw(false)
      }
    },
    getMousePos: function (evt) {
      var rect = this.canvas.getBoundingClientRect()
      var scaleX = this.canvas.width / rect.width
      var scaleY = this.canvas.height / rect.height
      return {
        x: (evt.clientX - rect.left) * scaleX,
        y: (evt.clientY - rect.top) * scaleY
      }
    },
    isImageHit: function (x, y) {
      return (x > this.imageX && x < this.imageX + this.imageWidth && y > this.imageY && y < this.imageY + this.imageHeight)
    },
    triggerInput: function (event) {
      event.preventDefault()
      this.$refs.fileInput.click()
    },
    previewThumbnail: function (event) {
      var vm = this
      var input = event.target

      if (input.files && input.files[0]) {
        var reader = new FileReader()

        // Set as source
        reader.onload = function (e) {
          vm.imgSrc.src = e.target.result
        }
        this.filename = input.files[0].name
        reader.readAsDataURL(input.files[0])

        vm.imgSrc.onload = function () {
          // Resize to max 1000px
          var canvas = document.createElement('canvas')
          var ctx = canvas.getContext('2d')
          ctx.drawImage(vm.imgSrc, 0, 0)
          var MAX_WIDTH = 1000
          var MAX_HEIGHT = 1000
          var width = vm.imgSrc.width
          var height = vm.imgSrc.height

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width
              width = MAX_WIDTH
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height
              height = MAX_HEIGHT
            }
          }
          canvas.width = width
          canvas.height = height
          ctx = canvas.getContext('2d')
          ctx.drawImage(vm.imgSrc, 0, 0, width, height)
          var dataurl = canvas.toDataURL('image/png')
          vm.img.src = dataurl
          vm.img.width = width
          vm.img.height = height

          // Draw
          vm.ctx.drawImage(vm.img, 0, 0)

          // Grab position info
          vm.imageWidth = vm.img.width
          vm.imageHeight = vm.img.height
          vm.imageRight = vm.imageX + vm.imageWidth
          vm.imageBottom = vm.imageY + vm.imageHeight

          vm.scaleSteps = 0
          let scaledWidth = vm.imageWidth
          let scaledHeight = vm.imageHeight
          if (scaledWidth > 250 && scaledHeight > 250) {
            while (scaledWidth > 250 && scaledHeight > 250) {
              scaledWidth = scaledWidth * (1 - (0.1 * vm.scaleSteps))
              scaledHeight = scaledHeight * (1 - (0.1 * vm.scaleSteps))
              vm.scaleSteps++
            }
            vm.scaleSteps++
            vm.scaleSteps++
          } else {
            while (scaledWidth < 250 && scaledHeight < 250) {
              scaledWidth = scaledWidth * (1 - (0.1 * vm.scaleSteps))
              scaledHeight = scaledHeight * (1 - (0.1 * vm.scaleSteps))
              vm.scaleSteps--
            }
            vm.scaleSteps--
          }

          // Notify component
          vm.isImageLoaded = true
          vm.$emit('loaded', true)

          // Update CTX
          setTimeout(() => {
            vm.draw(false)
          }, 100)
        }
      }

      function handleMouseDown (e) {
        var pos = vm.getMousePos(e)
        vm.startX = pos.x
        vm.startY = pos.y
        vm.draggingImage = vm.hoverIsDraggable
      }

      function handleMouseUp (e) {
        vm.draggingImage = false
        vm.draw(false)
      }

      function handleMouseOut (e) {
        handleMouseUp(e)
      }

      function handleMouseMove (e) {
        if (this.moveInProgress && this.clearedProgress < 10) {
          clearTimeout(this.moveInProgress)
          this.clearedProgress += 1
        } else {
          this.clearedProgress = 0
        }
        this.moveInProgress = setTimeout(() => {
          // Update cursor property var (
          var pos = vm.getMousePos(e)
          vm.hoverIsDraggable = vm.isImageHit(pos.x, pos.y)

          if (vm.draggingImage) {
            vm.mouseX = pos.x
            vm.mouseY = pos.y
            // move the image by the amount of the latest drag
            var dx = vm.mouseX - vm.startX
            var dy = vm.mouseY - vm.startY
            // Lock image to canvas viewport
            var collidedOnLeft = vm.imageX > 0 && dx > 0
            var collidedOnRight = vm.imageRight < vm.canvas.width && dx < 0
            var collidedOnTop = vm.imageY > 0 && dy > 0
            var collidedOnBottom = vm.imageBottom < vm.canvas.height && dy < 0

            if (collidedOnLeft) {
              vm.imageX = 0
              vm.imageRight = vm.scaledImageWidth
            } else if (collidedOnRight) {
              vm.imageX = vm.canvas.width - vm.scaledImageWidth
              vm.imageRight = vm.canvas.width
            } else {
              vm.imageX += dx
              vm.imageRight += dx
            }
            if (collidedOnTop) {
              vm.imageY = 0
              vm.imageBottom = vm.scaledImageHeight
            } else if (collidedOnBottom) {
              vm.imageY = vm.canvas.height - vm.scaledImageHeight
              vm.imageBottom = vm.canvas.height
            } else {
              vm.imageY += dy
              vm.imageBottom += dy
            }

            vm.startX = vm.mouseX
            vm.startY = vm.mouseY
            vm.draw(true)
          }
        }, 50)
      }

      vm.canvas.addEventListener('mousedown', handleMouseDown, false)
      vm.canvas.addEventListener('mousemove', handleMouseMove, false)
      vm.canvas.addEventListener('mouseup', handleMouseUp, false)
      vm.canvas.addEventListener('mouseout', handleMouseOut, false)

      // Set up touch events for mobile, etc
      vm.canvas.addEventListener('touchstart', function (e) {
        e.preventDefault()
        var touch = e.touches[0]
        var mouseEvent = new MouseEvent('mousedown', {
          clientX: touch.clientX,
          clientY: touch.clientY
        })
        vm.canvas.dispatchEvent(mouseEvent)
      }, false)
      vm.canvas.addEventListener('touchend', function (e) {
        e.preventDefault()
        var mouseEvent = new MouseEvent('mouseup', {})
        vm.canvas.dispatchEvent(mouseEvent)
      }, false)
      vm.canvas.addEventListener('touchmove', function (e) {
        e.preventDefault()
        var touch = e.touches[0]
        var mouseEvent = new MouseEvent('mousemove', {
          clientX: touch.clientX,
          clientY: touch.clientY
        })
        vm.canvas.dispatchEvent(mouseEvent)
      }, false)

      // Prevent scrolling when touching the canvas
      document.body.addEventListener('touchstart', function (e) {
        if (e.target === vm.canvas) {
          e.preventDefault()
        }
      }, false)
      document.body.addEventListener('touchend', function (e) {
        if (e.target === vm.canvas) {
          e.preventDefault()
        }
      }, false)
      document.body.addEventListener('touchmove', function (e) {
        if (e.target === vm.canvas) {
          e.preventDefault()
        }
      }, false)
    }
  }
}
</script>

<style lang="scss" >
.not-visible {
  opacity:0 !important;
  visibility: hidden !important;
}
.image-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-uploader .sub-label {
  font-size:0.8em;
  margin-top:16;
  margin-bottom:6px;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
}
.image-uploader-wrapper {
  position:relative;
  flex:0;
  display:inline-block;
  margin-top:12px;
  margin-bottom:100px;
}
.image-upload-avatar-overlay {
  position:absolute;
}
.image-upload-avatar-overlay #existing {
  position:absolute;
  opacity:0.7;
}
.image-upload-avatar-overlay #box{
  position:absolute;
  width:100%;
  height:100%;
  overflow:hidden; /* to contain #circle's box-shadow */
  opacity: 0.6;
  border-radius: 8px;
}
.image-upload-avatar-overlay #circle{
  position:relative;
  width:100%;
  height:100%;
  box-shadow: 0 0 0 100px #CCC; /* !!! */
  border-radius:50%;
}
.image-loaded .image-upload-avatar-overlay #circle{
  box-shadow: 0 0 0 100px black; /* !!! */
}
.image-input__input
{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0px;
  height: 0px;
  cursor: pointer;
  opacity: 0;
}

.image-input__container {
  overflow: hidden;
  border: 3px dashed whitesmoke;
  border-radius: 5px;
  box-sizing: border-box; /* display border inside */
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition: 0.15s border;
}

.image-input__overlay
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  cursor: pointer;

  .image-loaded & {
    display: none;
  }
}

.image-uploader .upload-button {
  position: absolute;
  top: 280px;
  margin-right:auto;
  margin-left:auto
}

.image-input__control-buttons
{
  display:flex;
  width:100%;
  justify-content: space-around;

  .image-loaded & {
    display: flex;
    flex-flow: row;
  }

  position: absolute;
  top: 245px;
  left: 0px;

  .control-button {
    color: #828282;
    cursor:not-allowed;
    display:flex
  }
  .image-loaded & {
    .control-button {
      color: #AFAFAF;
      cursor: pointer;
    }
  }
  .spacer {
    flex:1;
  }
}

.image-input__canvas {
  touch-action: manipulation;
  position: absolute;
  opacity: 0.7;
  &.is-draggable {
    cursor: move;
  }
}

</style>
