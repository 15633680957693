<template>
  <div class="main-content focus-test">
    <div class="main-wrapper">
      <input type="text">
      <div style="height: 300px;"></div>
      <input type="text">
      <div style="height: 20px;"></div>
      <input type="text">
      <div style="height: 20px;"></div>
      <input type="text">
      <div style="height: 20px;"></div>
      <input type="text">
      <div style="height: 20px;"></div>
      <input type="text">
      <div style="height: 20px;"></div>
      <input type="text">
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  mounted () {
    this.setPageLoad(false)
  },
  methods: {
    ...mapActions([
      'setPageLoad'
    ])
  }
}
</script>
