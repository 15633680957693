<template>
  <div class="main-content">
    <div class="main-wrapper">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'start',
  data () {
    return {
      title: 'Start'
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  mounted () {
    console.log('Start mounted')
  },
  watch: {
    user: {
      handler (newUser, oldUser) {
        console.log('Start user watch triggered')
        if (this.user) {
          this.$router.push('/dash')
        } else {
          this.$router.push('/signin')
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
</style>
