<template>
  <button
    :aria-label="label"
    :title="title"
    @click="click"
    class="round-button mdc-button toggle-button"
    :class="classes"
    :style="{background: background}"
    role="button">
      <slot name="leading-icon"/>
      <slot name="leading-icon-hover"/>
      <i v-if="icon" :class="{'material-icons': !iconOutline, 'material-icons-outlined': iconOutline}">{{icon}}</i>
      <slot/>
      <i v-if="trailingIcon" style="margin-left:6px;" :class="{'material-icons': !iconOutline, 'material-icons-outlined': iconOutline}">{{trailingIcon}}</i>
      <slot name="trailing-icon"/>
      <slot name="trailing-icon-hover"/>
  </button>
</template>

<script>
import { MDCRipple } from '@material/ripple'

export default {
  name: 'RoundButton',
  props: {
    icon: String,
    trailingIcon: String,
    iconOutline: Boolean,
    label: String,
    title: String,
    background: String,
    elevated: {
      type: Boolean,
      default: false
    },
    noHover: {
      type: Boolean,
      default: false
    },
    hoverMedium: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    },
    hoverHigh: {
      type: Boolean,
      default: false
    },
    high: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    ripple: {
      type: Boolean,
      default: false
    },
    hasSlot: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes () {
      return {
        'hover-medium': this.hoverMedium,
        medium: this.medium,
        'hover-high': this.hoverHigh,
        high: this.high,
        disabled: this.disabled,
        elevated: this.elevated,
        shaped: this.hasSlot,
        'has-hover-icon': this.$slots['leading-icon-hover'] || this.$slots['trailing-icon-hover'],
        'no-hover': this.noHover,
        'full-width': this.fullWidth
      }
    }
  },
  data: function () {
    return {
      buttonRipple: null
    }
  },
  mounted () {
    if (this.ripple) {
      this.buttonRipple = new MDCRipple(this.$el)
    }
  },
  methods: {
    click ($event) {
      if (this.disabled) {
        this.$emit('disabled-click', $event)
      } else {
        this.$emit('click', $event)
      }
    }
  }
}
</script>

<style scoped>
.round-button:focus {
  outline:0;
}

.round-button {
    align-items: center;
    border-radius: 16px;
    background: #FFFFFF;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    width: 31px;
    min-width: 31px;
    max-width: 31px;
    height: 31px;
    -ms-flex-pack: center;
    justify-content: center;
    border-width:0;
    margin:12px;
    transition: box-shadow 0.4s ease-in-out, max-width 500ms ease-in-out;
    color:rgba(0, 0, 0, 0.54);
}

.round-button.has-hover-icon .leading-icon {
  display:block !important;
}
.round-button.has-hover-icon .leading-icon-hover {
  display:none !important;
}

.round-button.has-hover-icon .trailing-icon {
  display:block
}
.round-button.has-hover-icon .trailing-icon-hover {
  display:none;
}

.round-button.has-hover-icon:hover .leading-icon {
  display:none !important;
}
.round-button.has-hover-icon:hover .leading-icon-hover {
  display:block !important;
}

.round-button.has-hover-icon:hover .trailing-icon {
  display:none
}
.round-button.has-hover-icon:hover .trailing-icon-hover {
  display:block;
}

.round-button .material-icons {
  font-size:20px;
}

.round-button.shaped {
  width: auto !important;
  max-width: 200px;
  padding-right:16px;
}

.round-button.shaped .material-icons {
  margin-right:6px;
}

.round-button.elevated {
  box-shadow: 0 0 0 1px rgba(0,0,0,.25);
}

.round-button.medium {
  border: 1.5px solid var(--mdc-theme-primary, #6200ee);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  color: var(--mdc-theme-primary, #6200ee);
}

.round-button.high {
  box-shadow: 0 0 0 1px var(--mdc-theme-primary, #6200ee);
  background-color: var(--mdc-theme-primary, #6200ee);
  color: white;
}

.round-button.disabled {
  box-shadow: none;
  background-color: #ffffff;
  color: #E1E1E1;
  border:0;
  cursor: default;
}

.round-button.active {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

@media (any-hover: hover) {
  .round-button:not(.no-hover):hover {
    background: #ffffff !important;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  }
  .round-button.medium:not(.no-hover):hover {
    border: 1.5px solid var(--mdc-theme-primary, #6200ee);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  }

  .round-button.hover-medium:not(.no-hover):hover {
    color: var(--mdc-theme-primary, #6200ee);
    border: 1.5px solid var(--mdc-theme-primary, #6200ee);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  }

  .round-button.high:not(.no-hover):hover {
    background-color: var(--mdc-theme-primary, #6200ee) !important;
    color: white;
  }
  .round-button.hover-high:not(.no-hover):hover {
    background-color: var(--mdc-theme-primary, #6200ee) !important;
    color: white !important;
  }
  .round-button.disabled:not(.no-hover):hover {
    border:0;
    box-shadow: none;
    background-color: #ffffff !important;
    color: #E1E1E1;
    cursor: default;
  }
}
.round-button.full-width {
  width:calc(100% - 12px) !important;
  max-width: unset;
  margin-left:0px;
}
</style>

<style>
.round-button::before, .round-button::after {
  display: none;
}
.round-button {
    --mdc-ripple-fg-opacity: 0;
}
.round-button.mdc-ripple-upgraded::after {
  display: unset;
}
.round-button.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12;
}

</style>
