<template>
  <header v-if="!$route.meta.noheader"
          :style="companyStyle.topbar"
          :class="topBarBg"
          class="mdc-top-app-bar mdc-top-app-bar--fixed mdc-top-app-bar--dense" id="app-bar">
    <div v-if="$route.meta.nomenu" class="mdc-top-app-bar__row">
      <img v-if="appLogo" :src="appLogo" class="logo">
    </div>
    <div v-else class="mdc-top-app-bar__row">
      <section v-if="profileReady" class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end">
        <router-link :to="{ name: 'Dash'}" class="logo-wrapper">
          <img :src="appLogo" class="logo">
        </router-link>

        <div class="top-navbar desktoponly">
          <app-nav :remote="remote" :navActive="navActive" :linktext="companyStyle.linktext"></app-nav>
        </div>

        <notification-area :companyStyle="companyStyle" @openProfileTasks="$emit('openProfileTasks')" v-model="unreadOpen" :profile="profile" />

        <mdc-icon-button @click="toggleMenu" :style="companyStyle.linktext">more_vert</mdc-icon-button>
        <div class="mdc-menu-surface--anchor">
          <mdc-menu class="more-menu" v-model="menuOpen" @selected="onSelected">
            <mdc-menu-item aria-label="Profile" icon="perm_identity">Profile</mdc-menu-item>
            <mdc-menu-item aria-label="Settings" icon="settings">Settings</mdc-menu-item>
            <mdc-menu-item aria-label="Legal" icon="account_balance">Legal</mdc-menu-item>
            <mdc-menu-item aria-label="Support" icon="contact_support">Support</mdc-menu-item>
            <mdc-menu-item v-if="userClaimAdmin" aria-label="Admin" iconOutlined icon="build">Admin</mdc-menu-item>
            <mdc-menu-divider></mdc-menu-divider>
            <mdc-menu-item aria-label="Sign out" icon="exit_to_app">Sign Out</mdc-menu-item>
          </mdc-menu>
        </div>
        <div v-if="menuOpen || unreadOpen" class="mdc-menu__scrim mdc-dialog__scrim"></div>
      </section>
    </div>
  </header>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { MDCTopAppBar } from '@material/top-app-bar/index'
import AppNav from './AppNav.vue'
import NotificationArea from './profile-v2/NotificationArea.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
export default {
  components: {
    AppNav,
    NotificationArea
  },
  data () {
    return {
      menuOpen: false,
      unreadOpen: false,
      menuClose: false
    }
  },
  props: {
    navActive: {
      default: null
    },
    profileReady: {
      default: false
    },
    companyStyle: {
    }
  },
  computed: {
    ...mapState([
      'user',
      'connections',
      'company',
      'profiles',
      'profile',
      'isBranded',
      'brand',
      'remote',
      'teamMode'
    ]),
    userClaimAdmin () {
      if (this.user) {
        if (this.user.claims) {
          return this.user.claims.admin
        }
      }
      return false
    },
    connsWithUnread () {
      return this.connections.filter(con => con.unread[this.user.uid] > 0)
    },
    mobileLogo () {
      if (this.isBranded) {
        return this.brand.logo
      }
      return '/static/img/icons-60.png'
    },
    appLogo () {
      if (this.isBranded) {
        if (!this.brand.logo) {
          return '/static/img/logo-black.svg'
        }
        return this.brand.logo
      }
      if (this.teamMode) {
        return '/static/img/flexibily.svg'
      }
      return '/static/img/duome-logo-blacklogo.svg'
    },
    topBarBg () {
      if (this.profileReady || !this.isBranded) {
        return 'app-bar-bg'
      }
      return 'app-bar-base'
    },
    unread () {
      var unread = 0
      const uid = this.user.uid
      if (this.connections) {
        Object.values(this.connections).forEach(function (con) {
          if (con.unread) {
            unread += con.unread[uid]
          }
        })
      }
      return unread
    }
  },
  mounted () {
    this.topAppBar = MDCTopAppBar.attachTo(this.$el)
  },
  methods: {
    ...mapActions([
      'clearUnread'
    ]),
    clearAllUnread () {
      const uid = this.user.uid
      const self = this
      Object.values(this.connections).forEach(function (con) {
        if (con.unread) {
          if (con.unread[uid] > 0) {
            self.clearUnread({ connectionId: con.id })
          }
        }
      })
    },
    onSelected (event) {
      switch (event.detail.index) {
        case 0:
          this.$router.push('/my-profile')
          break
        case 1:
          this.$router.push('/settings')
          break
        case 2:
          this.$router.push('/legal')
          break
        case 3:
          window.open('https://duome.zendesk.com/hc/en-gb', '_blank')
          break
        case 4:
          if (this.user.claims.admin) {
            this.$router.push('/admin')
          } else {
            this.signOut()
          }
          break
        case 5:
          this.signOut()
          break
      }
    },
    otherUser: function (users) {
      const usersCopy = Object.assign({}, users)
      delete usersCopy[this.profile.id]
      if (Object.keys(usersCopy).length === 1) {
        const profileId = Object.keys(usersCopy)[0]
        const profileMap = new Map(this.profiles.map(i => [i.id, i]))
        return profileMap.get(profileId)
      }
      return {}
    },
    onMessageMenu (event) {
      if (event.detail.index === 0) {
        this.clearAllUnread()
      } else {
        const conSelected = this.connsWithUnread[event.detail.index - 1]
        if (conSelected) {
          this.clearUnread({ connectionId: conSelected.id })
        }
      }
    },
    signOut () {
      firebase.auth().signOut().then(() => {
        window.location = '/signin'
      })
    },
    toggleMenu () {
      if (!this.menuClose) {
        if (!this.menuOpen) {
          this.menuOpen = true
        }
      }
    }
  },
  watch: {
    menuOpen (newo, oldo) {
      if (!this.menuOpen) {
        this.menuClose = true
        const self = this
        setTimeout(function () {
          self.menuClose = false
        }, 300)
      }
    }
  }
}
</script>

<style>
.mdc-menu__scrim {
  height:100vh;
  width:100vw;
}
.app-bar-base {
  background: #FFFFFF;
}
.app-bar-bg {
  background: linear-gradient(0.23deg, #C10457 0%, #730027 100%);
}
.logo-wrapper {
  width: 100%;
}
.logo {
  max-width: 100px;
  margin-left: 15px;
}
.message-menu {
  margin-top: 30px;
  top:48px;
  right:24px;
  height:260px;
  width:300px;
  border-radius:5px;
}
.message-menu .mark-all {
  justify-content: flex-end;
  font-size:12px;
  cursor:pointer;
}
.message-menu .mdc-menu-item span {
  height: 48px;
  line-height: 48px;
}
.message-menu .mdc-menu-item > span:first-child {
  width:100%
}
.message-menu .mdc-icon {
  margin-left:auto;
  padding-left:16px;
}
.message-menu .mdc-list-item .mdc-list-item__text {
  display: flex;
  align-content: center;
  height: 100%;
  width: 100%;
}
.message-menu .mdc-list-item .mdc-list-item__text > span {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
