<template>
  <div class="notification-area" >
    <round-button ripple background="transparent !important" style="display:inline-block;color:white;" @click="openNotifications" class="notification-icon">
      <mdc-icon slot="leading-icon" :style="companyStyle.linktext" icon="check_circle_outline"></mdc-icon>
      <span class="unread" v-if="unread > 0"></span>
    </round-button>
    <div class="mdc-menu-surface--anchor">
      <mdc-menu class="message-menu" style="width:auto;" v-model="open" @selected="onMessageMenu">
      <mdc-menu-item id="all" :ref="'message-menu-option-0'">
          <span @click.stop="clearAllUnread" class="mark-all">Mark All Read</span>
      </mdc-menu-item>
      <mdc-menu-divider></mdc-menu-divider>
      <div class="notification-message" style="height:48px;" v-if="unread > 0">
          <mdc-menu-item
          @click.native.prevent.stop="$emit('openProfileTasks')"
          class="expanded profile-incomp"
          v-if="completed < 4">
            <div style="display:flex;width:100%;">
              <span style="flex:1; height:26px;" >Your profile is [{{completed}}/4] complete</span>
              <span style="margin-left:16px; height:26px;"><mdc-icon outlined icon="account_circle" ></mdc-icon></span>
            </div>
            <div v-if="!this.profile.basicCompleted || !this.profile.experienceCompleted" class="sub-label">Your Profile is not visible to other users until complete</div>
            <div v-else class="sub-label">Adding a photo and a brief introduction makes connecting to others more likely</div>
          </mdc-menu-item>
          <mdc-menu-item
            v-for="(notification, index) in notifications"
            :ref="'message-menu-option-notification-' + (index + 1)"
            :key="notification.jobId"
            class="expanded notification"
            @click.native.prevent.stop="notificationClick(notification.jobId)">
            <div style="display:flex;width:100%;">
              <span style="flex:1; height:26px;">New interest in a job</span>
              <span style="margin-left:16px; height:26px;" @click.stop="clearNotification(notification.jobId)"><mdc-icon outlined icon="supervised_user_circle"></mdc-icon></span>
            </div>
            <div class="sub-label">A job you have marked interest in has other people interested.</div>
          </mdc-menu-item>
          <mdc-menu-item v-for="(con, index) in connsWithUnread" :ref="'message-menu-option-' + (index + 1)" :key="con.id">
          <span v-if="profiles" style="flex:1" @click.stop="routeTo('/connection/'+ con.id)" >{{otherUser(con.users).displayName}} ({{con.unread[user.uid]}})</span>
          <span style="margin-left:16px" @click.stop="clearUnreadNotification(con.id)"><mdc-icon icon="drafts" ></mdc-icon></span>
          </mdc-menu-item>
      </div>
      <div class="notification-message" v-else>
        <div style="padding-left:16px;padding-right:16px;padding-bottom:16px;">
          <div style="text-align:center;padding:16px;"><mdc-icon style="padding-right:16px;" icon="done"/></div>
          <div style="text-align:center;">
          <div>No notifications</div>
          <small>Good job, you're up to date <img class="thumbsup" src="/static/img/icons/thumbs-up.png"/></small>
          </div>
        </div>
      </div>
      </mdc-menu>
    </div>

  </div>
</template>

<script>
import RoundButton from '../../components/RoundButton'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'NotificationArea',
  model: {
    prop: 'notificationOpen',
    event: 'change'
  },
  props: {
    notificationOpen: {
      type: Boolean
    },
    profile: {},
    companyStyle: {}
  },
  components: {
    RoundButton
  },
  computed: {
    ...mapState([
      'isBranded',
      'user',
      'connections',
      'company',
      'profiles'
    ]),
    connsWithUnread () {
      if (this.connections) {
        return this.connections.filter(con => con.unread[this.user.uid] > 0)
      } else {
        return []
      }
    },
    unread () {
      var unread = 0
      const uid = this.user.uid
      if (this.connections) {
        Object.values(this.connections).forEach(function (con) {
          if (con.unread) {
            unread += con.unread[uid]
          }
        })
      }
      if (this.completed < 4) {
        unread++
      }
      console.log('NotificationArea.unread notifications', this.notifications)
      if (this.notifications) {
        unread = unread + Object.keys(this.notifications).length
      }
      return unread
    }
  },
  data () {
    return {
      open: false,
      closing: false,
      completed: null,
      basic: false,
      experience: false,
      personalise: false,
      profileNotification: false,
      notifications: []
    }
  },
  methods: {
    ...mapActions([
      'clearUnread',
      'updateProfile'
    ]),
    openNotifications () {
      if (!this.closing) {
        if (!this.open) {
          this.open = true
        }
      }
    },
    calcCompleted () {
      if (this.profile) {
        this.completed = 1
        if (this.profile.basicCompleted) {
          this.basic = true
          this.completed++
        }
        if (this.profile.experienceCompleted) {
          this.experience = true
          this.completed++
        }
        if (this.profile.photoURL && this.profile.aboutMe !== '') {
          this.personalise = true
          this.completed++
        }
      }
    },
    clearUnreadNotification (connectionId) {
      this.clearUnread({ connectionId: connectionId })
    },
    clearNotification (notificationId) {
      delete this.profile.notifications[notificationId]
      this.updateProfile({ profile: { notifications: this.profile.notifications }, noSnack: true })
    },
    otherUser: function (users) {
      const usersCopy = Object.assign({}, users)
      delete usersCopy[this.profile.id]
      if (Object.keys(usersCopy).length === 1) {
        const profileId = Object.keys(usersCopy)[0]
        const profileMap = new Map(this.profiles.map(i => [i.id, i]))
        return profileMap.get(profileId)
      }
      return {}
    },
    clearAllUnread () {
      const uid = this.user.uid
      const self = this
      Object.values(this.connections).forEach(function (con) {
        if (con.unread) {
          if (con.unread[uid] > 0) {
            self.clearUnread({ connectionId: con.id })
          }
        }
      })
      if (this.profile.notifications !== {}) {
        this.profile.notifications = {}
        this.updateProfile({ profile: { notifications: this.profile.notifications }, noSnack: true })
      }
      this.open = false
    },
    notificationClick (notificationId) {
      this.clearNotification(notificationId)
      this.routeTo('/job-detail/' + notificationId)
    },
    routeTo (dest) {
      console.log('NotificationArea.routeTo', dest)
      this.$router.push(dest)
      this.open = false
    },
    onMessageMenu (event) {
      if (event.detail.index === 0) {
        this.clearAllUnread()
      } else {
        const conSelected = this.connsWithUnread[event.detail.index - 1]
        if (conSelected) {
          this.clearUnread({ connectionId: conSelected.id })
        }
      }
    }
  },
  watch: {
    openCardDesign (newo, oldo) {
      this.open = this.notificationOpen
    },
    open (newo, oldo) {
      if (!this.open) {
        this.closing = true
        const self = this
        setTimeout(function () {
          self.closing = false
        }, 300)
      }
      if (this.open !== this.notificationOpen) {
        this.$emit('change', this.open)
      }
    },
    profile: {
      handler (newp, oldp) {
        this.calcCompleted()
        this.notifications = this.profile.notifications || {}
      },
      immediate: true
    }
  }
}
</script>

<style>
.notification-area {
  margin-left:12px;
  margin-right:12px;
}
.notification-area .mdc-menu li.mdc-list-item {
  height:36px;
}

.notification-area .mdc-menu li .sub-label {
  font-size: 12px;
  line-height: 12px;
  margin-top: 2px;
  margin-bottom: 6px;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
  text-align: left;
  display:block;
  width:100%;
}
.notification-area .notification-icon {
  height: 20px;
  width: 20px;
  margin:0 !important;
  padding:0 !important;
  overflow:visible;
  box-shadow:none !important;
  min-width:20px;
  max-width:20px;
}
.notification-area .notification-icon .unread{
  left: 6px;
  top: -12px;
}
.notification-area .mdc-menu-surface--anchor {
    position: absolute;
    right: 20px;
    top: 22px;
}
.notification-area .mdc-menu {
  overflow: visible;
}
.notification-area .mdc-list {
  padding:0;
}
.notification-area .mdc-list-divider {
  margin:0;
}
.message-menu {
  overflow: visible;
  max-height: unset !important;
  min-width:220px;
}
.message-menu .mdc-list .mdc-list-item__text {
  display: flex;
  width: 100%;
}
.notification-area .mdc-menu li.expanded {
  padding-top:6px;
  height:48px;
  max-height:48px;
}
.notification-area .mdc-menu li.expanded .mdc-list-item__text {
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.notification-area .mdc-menu li.expanded.profile-incomp,
.notification-area .mdc-menu li.expanded.notification {
  max-height: unset;
  height:auto;
  min-height: 48px;
}

.notification-area .mdc-menu li.expanded.profile-incomp .mdc-list-item__text .sub-label,
.notification-area .mdc-menu li.expanded.notification .mdc-list-item__text .sub-label {
  flex-flow: row wrap;
  display: flex;
  height: auto;
  white-space: normal;
}

</style>
