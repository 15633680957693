<template>
  <div class="main-content channel">
    <div class="main-wrapper" style="margin-top: 56px;">

      <div class="message-section">
      <mdc-layout-grid class="main-content-inner">
        <mdc-layout-cell desktop=12 tablet=8 phone=4>

          <div v-if="channelName === 'Remote Today' && connectionType === 'Team'">
            <div v-if="$teamLeader()">
              <div class="message-text" style="display:flex; align-items:center;box-shadow: none;" >
              <mdc-icon icon="info_outline" style="padding-left:6px;">info_outline</mdc-icon>
              <mdc-body typo="body2" style="padding-left:16px;" >Send Remote Check in
                <span class="link" style="margin-left:12px;" @click="sendRemoteCheckin">Send Now</span>
              </mdc-body>
              </div>
            </div>
            <div v-else class="message-text" style="display:flex; align-items:center;box-shadow: none;">
              <mdc-icon icon="info_outline" style="padding-left:6px;">info_outline</mdc-icon>
              <mdc-body typo="body2" style="padding-left:16px;" >How has your week been? Please share any achievements you'd like the team to see.</mdc-body>
            </div>
          </div>

          <div v-if="channelName === 'Round Table' && connectionType === 'Team'">
            <div v-if="$teamLeader()">
              <div class="message-text" style="display:flex; align-items:center;box-shadow: none;" >
              <mdc-icon icon="info_outline" style="padding-left:6px;">info_outline</mdc-icon>
              <mdc-body typo="body2" style="padding-left:16px;" >The next Round Table is scheduled to be sent on {{nextRoundTable}}
                <span class="link" style="margin-left:12px;" @click="sendRoundTable">Send Now</span>
              </mdc-body>
              </div>
            </div>
            <div v-else class="message-text" style="display:flex; align-items:center;box-shadow: none;" >
              <mdc-icon icon="info_outline" style="padding-left:6px;">info_outline</mdc-icon>
              <mdc-body typo="body2" style="padding-left:16px;" >Each team member is selected to provide an update once a week and pass the baton to the next team member</mdc-body>
            </div>
          </div>

          <div v-if="channelName === 'Looking Forward' && connectionType === 'Team'">

            <div v-if="$teamLeader()">
              <div class="message-text" style="display:flex; align-items:center;box-shadow: none;" >
              <mdc-icon icon="info_outline" style="padding-left:6px;">info_outline</mdc-icon>
              <mdc-body typo="body2" style="padding-left:16px;" >The next Looking Forward checkin is scheduled to be sent on {{nextLookingForward}}
                <span class="link" style="margin-left:12px;" @click="sendLookingForward">Send Now</span>
              </mdc-body>
              </div>
            </div>

            <app-dialog
              v-if="update"
              v-model="updateShowing"
              @close="update = null"
              :pauseFocus="isMobile"
              class="update-modal fullsize-mobile with-messagebar"
              style="z-index: 13;"
              accept=""
             :title="groupDisplay(update.group)"
             :footer="'Update ' + update.date"
            >

            <update-section style="margin-top:12px;" :showReplyCount="false" :updates="[update]" :profiles="teamProfiles" :team="profile.team"/>

          <div style="display:flex;align-items:center;">
            <round-button icon="rss_feed" style="border: 1px solid;width:160px !important;" hasSlot>
              <span v-if="isSubscriber(update)" @click="unsubscribe(update)">Unsubscribe</span>
              <span v-else @click="subscribe(update)">Subscribe</span>
            </round-button>
            <round-button icon="thumb_up" @click="toggleLike(update)" :iconOutline="!likes(update)" ></round-button>
            <span v-for="like in update.likes" :key="'like-'+like" :title="getProfile(like).displayName" class="avatar avatar-link avatar-like">
            <router-link class="update-avatar" v-if="like !== 'bot'" :to="'/user/' + like">
                <img v-if="hasPhoto(getProfile(like))" :src="getPhoto(getProfile(like))"/>
                <default-avatar v-else fontSize="16px" :profile="getProfile(like)"/>
            </router-link>
            <span class="update-avatar" v-else>
                <img v-if="hasPhoto(getProfile(like))" :src="getPhoto(getProfile(like))"/>
                <default-avatar v-else fontSize="16px" :profile="getProfile(like)"/>
            </span>
            </span>
          </div>
          <mdc-title style="display:flex;margin-top: 0;margin-bottom: 12px;">
            <span class="update-reply-count-wrapper">
              <span class="update-reply-count">{{commentCount(update)}}</span>
            </span>
            Comments
          </mdc-title>

          <update-section v-if="update" showDateLines :updates="updateComments" :profiles="teamProfiles" :team="profile.team"/>

          <message-bar oneline relative v-on:focus="setInputFocused" :after="[]" :disabled="sendDisabled" :profile="profile" @send="addComment" ></message-bar>
        </app-dialog>

          </div>

          <div v-if="channelName === 'Working From' && connectionType === 'Team'" >

            <div v-if="$teamLeader()">
              <div class="message-text" style="display:flex;align-items:center;box-shadow:none;">
              <mdc-icon icon="info_outline" style="padding-left:6px;">info_outline</mdc-icon>
              <mdc-body typo="body2" style="padding-left:16px;" >The next schedule checkin is scheduled to be sent on {{nextSchedule}}
                <span class="link" style="margin-left:12px;" @click="sendSchedule">Send Now</span>
              </mdc-body>
              </div>
            </div>
          </div>

          <message-section
            ref="messageSection"
            v-if="profile && profiles"
            :channelName="channelName"
            :connectionType="connectionType"
            :connectionId="connectionId"
            :connection="connection"
            :profile="profile"
            :profiles="profiles"
            :user="user"
            :messages="messages"
            @action="messageActionHandler"
            @showUpdate="showUpdate"
            >
          </message-section>

        </mdc-layout-cell>
      </mdc-layout-grid>
      </div>

      <message-bar v-if="hasBar" :connectionId="connectionId" :profile="profile" :disabled="profileNotCompleted" :disabledClick="showProfileNotification" @send="sendMessage"></message-bar>

      <profile-tasks @modalClosed="profileNotification = false" :profile="profile" :tasksModalOpen="profileNotification"></profile-tasks>

      <app-dialog
          v-model="updateInputOpen"
          :pauseFocus="false"
          class="update-modal update-input-modal fullsize-mobile"
          style="z-index: 13;"
          accept="Publish"
          acceptIcon="publish"
          @accept="saveUpdates"
          :footer="'Tempo requested ' + requestWeek"
          :title="updatesTitle"
          >
          <update-input
            :checkId="updateInputCheckId"
            ref="updates"
            :group="getGroup(channelName)"
            :updateGroup="updates"
            @save="saveUpdates"
            @deleteUpdate="onDeleteUpdate"
            :dateDesc="true"
            :groupByDate="false"
            disableNotes
            copyNotes
            :notes="notes">
          </update-input>
      </app-dialog>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ConnectMenu from '../components/ConnectMenu.vue'
import ConnectMenuRemote from '../components/ConnectMenuRemote.vue'
import MessageSection from '../components/MessageSection.vue'
import MessageBar from '../components/MessageBar.vue'
import ProfileTasks from '../components/ProfileTasks.vue'
import UpdateInput from '../components/UpdateInput.vue'
import UpdateSection from '../components/UpdateSection.vue'
import AppDialog from '../components/AppDialog.vue'
import { dateDisplayFormat, dateYearFormat, getNextMonday, getNextFriday } from '../utils/time'

export default {
  name: 'channel',
  props: {
    isMobile: {
      type: Boolean
    }
  },
  data () {
    return {
      title: 'Channel',
      links: [
        { component: ConnectMenu }
      ],
      lookingForwardPublished: false,
      sendDisabled: false,
      hasBar: false,
      update: null,
      requestWeek: '',
      updateShowing: false,
      updateInputOpen: false,
      updatesTitle: '',
      updateInputCheckId: null,
      editSchedule: false,
      lastSchedule: {
        id: 0,
        mon: false,
        tue: true,
        wed: true,
        thu: true,
        fri: false
      },
      currentSchedule: {
        id: 1,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false
      },
      teamSchedule: {
        id: 1,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false
      },
      channelName: null,
      connectionId: null,
      connectionType: null,
      newMessage: '',
      messageList: null,
      profileNotification: false,
      scheduleSubmitted: false,
      updates: [],
      notes: []
    }
  },
  components: {
    MessageSection,
    MessageBar,
    ProfileTasks,
    AppDialog,
    UpdateInput,
    UpdateSection
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'profiles',
      'messages',
      'connection',
      'remote',
      'teamSchedules',
      'teamUpdatesLookingForward',
      'teamUpdatesRoundTable',
      'updateComments',
      'teamNotes',
      'teamProfiles',
      'tempos'
    ]),
    nextRoundTable () {
      return getNextFriday()
    },
    nextLookingForward () {
      return getNextMonday()
    },
    nextSchedule () {
      return getNextMonday()
    },
    currentWeek () {
      const d = new Date()
      const day = d.getDay()
      const diff = d.getDate() - day + (day === 0 ? -6 : 1) - 1
      return dateYearFormat(new Date(d.setDate(diff)))
    },
    profileNotCompleted () {
      if (!this.profile) {
        return true
      } else if (!this.profile.basicCompleted || !this.profile.experienceCompleted) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions([
      'loadChannelMessages',
      'loadChannelMessagesReverse',
      'addChannelMessage',
      'clearUnread',
      'clearMessages',
      'setLoading',
      'clearConnection',
      'getConnection',
      'setPageLoad',
      'getChannel',
      'updateTeamSchedule',
      'loadMyTeamSchedules',
      'saveUpdate',
      'deleteUpdate',
      'saveUpdatesBatch',
      'saveComment',
      'loadUpdateComments',
      'clearUpdateComments'
    ]),
    dateFormat (date) {
      return dateDisplayFormat(date.toDate())
    },
    getGroup (channelName) {
      if (channelName === 'Looking Forward') {
        return 'lookingForward'
      }
      if (channelName === 'Round Table') {
        return 'roundTable'
      }
    },
    groupDisplay (group) {
      if (group === 'lookingForward') {
        return 'Looking Forward'
      }
      if (group === 'roundTable') {
        return 'Round Table'
      }
      return group
    },
    addComment (message) {
      console.log('DashTeam.addComment', message)
      const comment = {
        fromId: message.fromId,
        message: message.text,
        created: new Date(),
        date: new Date()
      }
      this.saveComment({ teamId: this.profile.team, updateId: this.update.id, commentId: comment.id, comment })
      if (!this.update.commentCount) {
        if (this.updateComments) {
          this.update.commentCount = this.updateComments.length
        } else {
          this.update.commentCount = 0
        }
      }
      this.update.commentCount = this.update.commentCount + 1
      this.saveUpdate({ teamId: this.profile.team, updateId: this.update.id, update: { commentCount: this.update.commentCount } })
    },
    commentCount (update) {
      if (update.commentCount) {
        return update.commentCount
      }
      return 0
    },
    showUpdate (update, week) {
      console.log('showUpdate', update, week)
      this.update = update
      this.updateShowing = true
    },
    likes (update) {
      if (update.likes) {
        return update.likes.includes(this.profile.id)
      }
      return false
    },
    toggleLike (update) {
      if (!update.likes) {
        update.likes = []
      }
      if (update.likes.includes(this.profile.id)) {
        update.likes = update.likes.filter((prof) => prof !== this.profile.id)
      } else {
        update.likes.push(this.profile.id)
      }
      console.log('toggleLike', update.likes)
      this.saveUpdate({ teamId: this.profile.team, updateId: this.update.id, update: { likes: this.update.likes } })
    },
    isSubscriber (update) {
      if (update.subscribers) {
        return update.subscribers.includes(this.profile.id)
      }
      return false
    },
    subscribe (update) {
      if (!update.subscribers) {
        update.subscribers = []
      }
      if (!update.subscribers.includes(this.profile.id)) {
        update.subscribers.push(this.profile.id)
        this.saveUpdate({ teamId: this.profile.team, updateId: this.update.id, update: { subscribers: this.update.subscribers } })
      }
    },
    unsubscribe (update) {
      if (!update.subscribers) {
        update.subscribers = []
      }
      if (update.subscribers.includes(this.profile.id)) {
        update.subscribers = update.subscribers.filter((prof) => prof !== this.profile.id)
        this.saveUpdate({ teamId: this.profile.team, updateId: this.update.id, update: { subscribers: this.update.subscribers } })
      }
    },
    onDeleteUpdate (teamId, updateId) {
      console.log('onDeleteUpdate', teamId, updateId)
      this.deleteUpdate({ teamId: teamId, updateId })
    },
    saveUpdates () {
      console.log('Channel.saveUpdates', this.channelName)
      if (this.channelName === 'Looking Forward') {
        this.updateLookingForward()
      } else if (this.channelName === 'Round Table') {
        this.updateRoundTable()
      }
    },
    updateLookingForward () {
      let firstSave = true
      const self = this
      const lfs = this.$refs.updates.getContent()
      for (const message of this.messages) {
        firstSave = firstSave && !(message.requestId === this.updateInputCheckId && message.fromId === this.profile.id)
      }
      for (const update of lfs) {
        if (update.changed) {
          update.group = 'lookingForward'
        }
      }
      this.saveUpdatesBatch({ teamId: this.profile.team, updates: lfs.filter(up => up.changed) }).then(() => {
        this.notes = this.fitlerNotes()
        if (self.$refs.updates) {
          self.$refs.updates.setUpdated()
        }
      })
      if (firstSave) {
        this.publishLookingForward()
      }
      this.$refs.messageSection.updatesChanged(this.updateInputCheckId)
    },
    updateRoundTable () {
      console.log('Channel.updateRoundTable')
      let firstSave = true
      const self = this
      const rts = this.$refs.updates.getContent()
      for (const message of this.messages) {
        firstSave = firstSave && !(message.requestId === this.updateInputCheckId && message.fromId === this.profile.id)
      }
      for (const update of rts) {
        if (update.changed) {
          update.group = 'roundTable'
        }
      }
      console.log('Channel.saveUpdatesBatch', rts.filter(up => up.changed))
      this.saveUpdatesBatch({ teamId: this.profile.team, updates: rts.filter(up => up.changed) }).then(() => {
        this.notes = this.fitlerNotes()
        if (self.$refs.updates) {
          self.$refs.updates.setUpdated()
        }
      })
      if (firstSave) {
        this.publishRoundTable()
      }
      this.$refs.messageSection.updatesChanged(this.updateInputCheckId)
    },
    filterUpdates (group, checkId) {
      if (group === 'Looking Forward') {
        return this.teamUpdatesLookingForward.filter(up => {
          return up.checkId === checkId && up.fromId === this.profile.id
        })
      }
      if (group === 'Round Table') {
        return this.teamUpdatesRoundTable.filter(up => {
          return up.checkId === checkId && up.fromId === this.profile.id
        })
      }
      return []
    },
    fitlerNotes () {
      return this.teamNotes.filter(up => {
        return up.type !== 'note' && !up.copied
      })
    },
    editLookingForward (message) {
      this.updatesTitle = 'Looking Forward'
      this.updateInputOpen = true
      this.updateInputCheckId = message.id
      this.requestDate = message.created.toDate()
      this.requestWeek = dateDisplayFormat(this.requestDate)
      this.updates = this.filterUpdates('Looking Forward', message.id)
      this.notes = this.fitlerNotes()
    },
    editRoundTable (message) {
      this.updatesTitle = 'Round Table'
      this.updateInputOpen = true
      this.updateInputCheckId = message.id
      this.requestDate = message.created.toDate()
      this.requestWeek = dateDisplayFormat(this.requestDate)
      this.updates = this.filterUpdates('Round Table', message.id)
      this.notes = this.fitlerNotes()
    },
    setInputFocused () {
    },
    messageActionHandler (action, message) {
      if (action === 'editLookingForward') {
        this.editLookingForward(message)
      } else if (action === 'editRoundTable') {
        this.editRoundTable(message)
      }
    },
    sendRemoteCheckin () {
      let messageText = 'Hi - you are scheduled as working remotely today, update your preferences to stay connected with the team.<br>Are you interested in connecting with other people? Click the following options available today?'
      for (const tempo of this.tempos) {
        if (tempo.name === 'Remote Today') {
          messageText = tempo.question
        }
      }
      messageText = messageText + '  <REMOTE>'
      const message = {
        isCheck: true,
        connectionId: this.connectionId,
        fromId: this.profile.id,
        text: messageText,
        buttons: [
          {
            text: 'Edit',
            icon: 'create',
            remote: true,
            lastRequestId: null,
            round: true,
            high: true,
            match: '<REMOTE>',
            action: 'editRemoteContact'
          }
        ]
      }
      message.connectionType = this.connectionType
      this.addChannelMessage({ message })
    },
    sendRoundTable () {
      let messageText = `Tell the team about our progress this week? Take less than 10 minutes to provide information about achievements, announcements and key information you would like to share. 
Update and publish your status `
      for (const tempo of this.tempos) {
        if (tempo.name === 'Round Table') {
          messageText = tempo.question
        }
      }
      messageText = messageText + ' <SEND_ROUND_TABLE>'
      const message = {
        ischeck: true,
        connectionId: this.connectionId,
        fromId: this.profile.id,
        text: messageText,
        buttons: [
          {
            text: 'Edit',
            icon: 'create',
            round: true,
            high: true,
            match: '<SEND_ROUND_TABLE>',
            action: 'editRoundTable'
          }
        ]
      }
      message.connectionType = this.connectionType
      this.addChannelMessage({ message })
    },
    sendSchedule () {
      let messageText = 'Can you let me know if you plan to work in the office any days next week by clicking the days below: '
      for (const tempo of this.tempos) {
        if (tempo.name === 'Schedule') {
          messageText = tempo.question
        }
      }
      messageText = messageText + ' <SCHEDULE>'
      const message = {
        isCheck: true,
        connectionId: this.connectionId,
        fromId: this.profile.id,
        text: messageText,
        buttons: [
          {
            text: 'Edit',
            icon: 'create',
            schedule: true,
            lastRequestId: null,
            round: true,
            high: true,
            match: '<SCHEDULE>',
            action: 'editLookingForward'
          }
        ]
      }
      message.connectionType = this.connectionType
      this.addChannelMessage({ message })
    },
    sendLookingForward () {
      let messageText = `What do you plan to work on this week? Take less than 10 minutes to provide information on key activities you aim to progress this week. (This will reduce the number of status calls or info requests you recieve). 
Update and publish your status`
      for (const tempo of this.tempos) {
        if (tempo.name === 'Looking Forward') {
          messageText = tempo.question
        }
      }
      messageText = messageText + ' <SEND_LOOKING_FORWARD>'
      const message = {
        ischeck: true,
        connectionId: this.connectionId,
        fromId: this.profile.id,
        text: messageText,
        buttons: [
          {
            text: 'Edit',
            icon: 'create',
            round: true,
            high: true,
            match: '<SEND_LOOKING_FORWARD>',
            action: 'editLookingForward'
          }
        ]
      }
      message.connectionType = this.connectionType
      this.addChannelMessage({ message })
    },
    publishLookingForward () {
      const message = {
        isUpdates: true,
        requestId: this.updateInputCheckId,
        requestDate: this.requestDate,
        connectionId: this.connectionId,
        fromId: this.profile.id,
        textFrom: '',
        text: `${this.profile.firstName} just published their Looking Forward update.`
      }
      message.connectionType = this.connectionType
      this.addChannelMessage({ message })
    },
    publishRoundTable () {
      const message = {
        isUpdates: true,
        requestId: this.updateInputCheckId,
        requestDate: this.requestDate,
        connectionId: this.connectionId,
        fromId: this.profile.id,
        textFrom: '',
        text: `${this.profile.firstName} just submitted their Round Table update.`
      }
      message.connectionType = this.connectionType
      this.addChannelMessage({ message })
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      if (profileId === 'bot') {
        return {
          displayName: 'DuoMe Bot',
          photoURL: '/static/img/chat-bubble-circle.svg'
        }
      }
      const profileMap = new Map(this.profiles.map(i => [i.id, i]))
      return profileMap.get(profileId)
    },
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    },
    getPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return profile.photoURL
        }
      }
      return '/static/img/user.jpg'
    },
    showProfileNotification: function () {
      this.profileNotification = true
    },
    gotoProfile: function () {
      this.$router.push('/user/' + this.profile.id)
    },
    sendMessage: function (message) {
      // eslint-disable-next-line no-undef
      analytics.track('sendChannelMessage', {
        id: this.profile.id,
        role: this.profile.role,
        company: this.profile.company,
        journeyStage: this.profile.journeyStage
      })
      message.connectionType = this.connectionType
      this.addChannelMessage({ message })
    },
    scrollToBottom: function (mutations) {
      if (!this.unread) {
        this.messageList.scrollTop = this.messageList.scrollHeight
      }
    },
    scrollToUnread: function () {
      const self = this
      setTimeout(function () {
        var messages = document.querySelectorAll('.message')
        var unreadMessage = messages[messages.length - self.unread]
        if (unreadMessage) {
          unreadMessage.scrollIntoView(true)
          self.unread = 0
          self.clearUnread({ connectionId: self.connectionId })
        }
      }, 200)
    },
    isTempoChannel (channelName) {
      return ['Looking Forward', 'Round Table', 'Remote Today', 'Working From'].includes(channelName)
    },
    initialize: function () {
      this.getChannel({ connectionId: this.connectionId, connectionType: this.connectionType }).then(channel => {
        console.log('Channel.initialize', channel.channelName, channel)
        if (channel) {
          this.channelName = channel.channelName
        }
        if (this.isTempoChannel(this.channelName)) {
          this.loadChannelMessagesReverse({ connectionId: this.connectionId, connectionType: this.connectionType })
          this.hasBar = false
        } else {
          this.loadChannelMessages({ connectionId: this.connectionId, connectionType: this.connectionType })
          this.hasBar = true
        }
      })
      this.loadMyTeamSchedules({ teamId: this.profile.team })
      // Just for dummy schedules this will be sent when schedule created on send
      this.currentSchedule.for = this.profile.id
      this.lastSchedule.for = this.profile.id
      // this.getConnection({connectionId: this.connectionId})
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$emit('setNavActive', null)
    this.$root.$emit('enableSearch', false)
    this.clearMessages()
    this.clearConnection()
  },
  beforeRouteUpdate (to, from, next) {
    this.setLoading(true)
    this.clearMessages()
    this.clearConnection()

    this.connectionId = to.params.id
    this.connectionType = to.params.type
    this.initialize()
    setTimeout(() => {
      console.log('Channel.beforeRouteUpdate.enabledSearch')
      this.$root.$emit('enableSearch', true, { back: true, context: 'messages' })
    }, 300)
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
    next()
  },
  mounted () {
    if (this.remote) {
      this.links = [{ component: ConnectMenuRemote }]
    } else {
      this.links = [{ component: ConnectMenu }]
    }
    this.$emit('setLinks', this.links)
    this.$emit('setNavActive', 'Connect')
    this.$root.$emit('enableSearch', true, { back: true, context: 'messages' })
    this.setLoading(true)
    this.connectionId = this.$route.params.id
    this.connectionType = this.$route.params.type
    this.initialize()
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  },
  watch: {
    update: async function (newU, oldU) {
      if (newU) {
        this.loadUpdateComments({ teamId: this.profile.team, updateId: this.update.id })
      } else {
        this.clearUpdateComments()
      }
    },
    remote () {
      if (this.remote) {
        this.links = [{ component: ConnectMenuRemote }]
      } else {
        this.links = [{ component: ConnectMenu }]
      }
      this.$emit('setLinks', this.links)
    },
    connection: function (newC, oldC) {
      if (this.connection) {
        if (this.connection.unread) {
          if (this.connection.unread[this.user.uid] > 0) {
            this.unread = this.connection.unread[this.user.uid]
            this.scrollToUnread()
          }
        }
      }
    },
    messages: function (newM, oldM) {
      if (newM !== null) {
        this.setLoading(false)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.channel .update-input-modal .mdc-dialog__surface {
  height: 100%;
}
.channel .update-modal .mdc-dialog__surface {
  position: relative;
}
.channel .update-modal .mdc-dialog__surface{
  margin-bottom:6px;
}
.channel .update-input-modal .mdc-dialog__surface  .mdc-dialog__content  {
  padding-left:0;
  padding-right:0;
}
.channel .update-modal .mdc-dialog__surface .bottom-bar-wrapper {
  min-height: 42px;
  max-width: unset;
  z-index:2;
  position: absolute;
  width: 100%;
  padding-left: 0;
}
.channel .update-modal .mdc-dialog__surface .bottom-bar-wrapper .bottom-bar {
  padding-right: 0;
}
.channel .update-modal .mdc-dialog__surface .bottom-bar-wrapper .bottom-bar textarea {
  margin-left: 0px;
  margin-bottom: 2px;
  margin-top: 8px;
  padding-left: 0;
}
.channel .update-modal.with-messagebar .mdc-dialog__content {
  margin-bottom:40px;
}
.update-reply-count-wrapper {
  display:flex;
  align-items: center;
  margin-right:6px;
  padding-right:6px;
  align-self: stretch;
}

.update-reply-count {
  border-radius:16px;
  width:26px;
  height:26px;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
  background-color:#3560af;
  color:white;
}

.submit-schedule .round-button.disabled {
  background-color:#999999;
  color:white;
}
.submit-schedule .round-button.disabled:hover {
  background-color:#AAAAAA !important;
  color:white;
  cursor:pointer;
}
.channel .update-input-modal .mdc-dialog__surface {
  height: 100%;
}
.channel .update-modal .mdc-dialog__surface {
  position: relative;
}
.channel .update-modal .mdc-dialog__surface{
  margin-bottom:6px;
}
</style>
<style scoped>
.message-text {
  position:relative;
}
.message-text .avatar {
    position: absolute;
    top: -5px;
    left: -20px;
    width:35px;
    height:35px;
}
.messagebar_field {
  border:none;
  height:auto;
  margin:0;
}
.button-row {
  display:flex;
}
.message-pre {
  white-space: pre-wrap;
}

.mdc-drawer-app-content {
  height:100%;
}
main {
  height: calc(100% - 4.5rem);
}
.main-content {
  height:100%;
}
.main-content .main-wrapper {
  height: 100%;
}
.main-content .main-wrapper .messageList{
  height: calc(100vh - 150px);
}
.avatar {
    width:56px;
    height: 56px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: block;
    overflow: hidden;
}
.avatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.messageList {
  overflow-y: scroll;
}
.message {
  min-height:66px;
  overflow: visible;
  height:auto;
}
.message:first-of-type > .dateline > hr {
  margin-top: -8px;
}
.messageTS {
  font-style: italic;
  font-size: 10px;
}
.messageName {
  font-weight: 500;
}
.message-text {
  padding:6px;
  margin:6px;
  background: #FFFFFF;
  border: 0.25px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0.503638px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 0px 5px;
}
.message .mdc-layout-grid {
  padding:6px;
  margin:0px;
  width:100%;
}
.mdc-headline  {
  margin:0;
}
.flexrow {
  display:flex;
  flex-flow: row;
  align-items: center;
}
.flexrow .left{
  min-width:300px;
  flex-grow: 1;
}

.bottom-bar-wrapper {
  position:relative;
  width: calc(100% - 294px);
  position:fixed;
  min-height:45px;
  height:auto;
  bottom:0;
  left:0;
  padding-left:288px;
}
.bottom-bar {
  margin:6px;
  box-sizing: border-box;
  margin: 0;
  width:100%;
  max-width:1200px;
  margin-left:auto;
  margin-right:auto;
  padding-right:32px;
  z-index: 4;
}
.bottom-message-bar .mdc-textfield {
    margin: 0;
}

/* Removes width of grid padding, wrapper padding and collapsed sidebar */
@media (min-width: 571px) and (max-width: 840px) {
  .bottom-bar-wrapper {
    padding-left: calc(24px + var(--mdc-layout-grid-margin-phone, 16px));
    width: calc(100% - 16px - 2*(var(--mdc-layout-grid-margin-phone, 16px)));
  }
}

@media all and (max-width: 570px) {
  .bottom-bar-wrapper {
    width: calc(100% - 2px);
    padding-left: 1px;
    bottom: 0px;
  }
  .bottom-bar {
    padding-right:0px;
  }
}
.bottom-message-bar {
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  background-color:#FCFCFC;
  width:100%;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bottom-message-bar .mdc-button {
  min-width: 37px;
  width: 37px;
  height: 30px;
}
.bottom-bar .mdc-layout-grid {
  padding: 4px 12px;
}
.bottom-bar .mdc-textfield {
  height:48px;
  overflow: hidden;
  width:100%;
}
.bottom-bar .mdc-icon.mdc-icon--material.material-icons {
  width: 24px;
  height: 24px;
  margin-right: 0;
}
.channel .avatar-like {
  height:35px;
  width:35px;
}

</style>
<style>
.mdc-textfield-wrapper.inline {
  width:95%;
}
.mdc-textfield-wrapper.inline .mdc-text-field {
  height:30px !important;
  margin-top:0;
}
.mdc-textfield-wrapper.inline .mdc-text-field .mdc-text-field__input {
  padding:0;
}
</style>
