import moment from 'moment'
import range from 'lodash/range'

const _ = { range }

require('moment/locale/en-gb')
moment.locale('en-gb')

function roundedToFixed (_float, _digits) {
  var rounder = Math.pow(10, _digits)
  return Math.round(_float * rounder) / rounder
}

export function getMonthArray () {
  return moment.monthsShort()
}

export function getYearArray () {
  const startYear = new Date().getFullYear()
  return _.range(startYear, 1900, -1)
}

export function diffNowDays (ts) {
  return moment().diff(ts, 'days')
}

export function differenceYears (startYear, endYear, startMonth, endMonth) {
  const months = getMonthArray()
  startYear = parseInt(startYear)
  if (!startMonth) {
    startMonth = 1
  } else {
    if (isNaN(startMonth)) {
      startMonth = months.indexOf(startMonth)
    }
  }
  if (!endYear) {
    endYear = moment().year()
    endMonth = moment().month()
  } else {
    endYear = parseInt(endYear)
  }

  if (!endMonth) {
    endMonth = 1
  } else {
    if (isNaN(endMonth)) {
      endMonth = months.indexOf(endMonth)
    }
  }
  var a = moment([startYear, startMonth, 1])
  var b = moment([endYear, endMonth, 1])
  var years = b.diff(a, 'year')
  return years
}

export function differenceYearsMonths (startYear, endYear, startMonth, endMonth) {
  const months = getMonthArray()
  startYear = parseInt(startYear)
  if (!startMonth) {
    startMonth = 1
  } else {
    if (isNaN(startMonth)) {
      startMonth = months.indexOf(startMonth)
    }
  }
  if (!endYear) {
    endYear = moment().year()
    endMonth = moment().month()
  } else {
    endYear = parseInt(endYear)
  }

  if (!endMonth) {
    endMonth = 1
  } else {
    if (isNaN(endMonth)) {
      endMonth = months.indexOf(endMonth)
    }
  }

  var a = moment([startYear, startMonth, 1])
  var b = moment([endYear, endMonth, 1])
  var years = b.diff(a, 'year')
  var monthDiff = b.diff(a, 'month')
  return '' + years + ' yrs ' + monthDiff + ' mos'
}

export function differenceHours (startTS, endTS) {
  var end = moment(endTS)
  var start = moment(startTS)
  var duration = moment.duration(start.diff(end))
  const hours = duration.asHours()
  return roundedToFixed(hours, 1)
}
export function parseDateFormat (dateStr) {
  if (dateStr) {
    var date = moment(dateStr, 'DD/MM/YY')
    return date.toDate()
  } else {
    return null
  }
}
export function parseDate (dateStr) {
  if (dateStr) {
    var date = moment(dateStr, 'ddd D MMM')
    return date.toDate()
  }
  return null
}
export function parseDateTime (dateStr) {
  if (dateStr) {
    var date = moment(dateStr, 'ddd D MMM HH:mm')
    return date.toDate()
  }
  return null
}
export function parseDatetoTS (dateStr) {
  if (dateStr) {
    var date = moment(dateStr, 'ddd D MMM HH:mm')
    return date.toDate().getTime()
  }
  return 0
}

export function addDaysDate (dateObj, days) {
  if (dateObj) {
    var date = moment(dateObj)
    date = date.add(days, 'days')
    return date.toDate()
  }
  return null
}

export function addDays (dateStr, days) {
  if (dateStr) {
    var date = moment(dateStr, 'ddd D MMM HH:mm')
    date = date.add(days, 'days')
    return date.toDate()
  }
  return null
}

export function getNextMonday () {
  if (moment().day() === 0) {
    return moment().day(1).format('Do MMM [at 9:00AM]')
  }
  return moment().day(8).format('Do MMM [at 9:00AM]')
}

export function getNextFriday () {
  if (moment() === 6) {
    return moment().day(12).format('Do MMM [at 9:00AM]')
  }
  return moment().day(5).format('Do MMM [at 9:00AM]')
}

export function getFirstDayOfMonth (day, since, scheduleTime) {
  const dayNums = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  }
  const now = moment()
  let result = moment().set('year', now.year()).set('month', now.month() + 1).set('date', 1).add(dayNums[day] + 1, 'days')

  if (result.isBefore(now)) {
    result = result.add(1, 'month')
  }

  var format = 'hh:mm'
  // var time = moment() gives you current time. no format required.
  var timeMoment = moment(scheduleTime, format)
  result = result.hour(timeMoment.get('hour'))
  result = result.minute(timeMoment.get('minute'))
  result = result.second('00')
  return result
}

export function getNextDay (listOfDays, since, scheduleTime, additionalDays) {
  const dayNums = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  }
  const dayNumsSelected = listOfDays.map(d => dayNums[d])
  const daysNeeded = dayNumsSelected.sort()

  function isThisInFuture (targetDayNum) {
    // param: positive integer for weekday
    // returns: matching moment or false
    let todayNum = moment().isoWeekday()
    const date = moment()

    if (scheduleTime) {
      var format = 'hh:mm'
      // var time = moment() gives you current time. no format required.
      var time = moment(scheduleTime, format)
      if (time.isBefore()) {
        date.add(1, 'day')
        todayNum = date.isoWeekday()
      }
    }

    if (since) {
      if (moment(since).isSame(new Date(), 'day')) {
        date.add(additionalDays || 1, 'day')
        todayNum = date.isoWeekday()
      }
    }

    if (todayNum <= targetDayNum) {
      return date.isoWeekday(targetDayNum)
    }
    return false
  }

  function findNextInstanceInDaysArray (daysArray) {
    const tests = daysNeeded.map(isThisInFuture)

    const thisWeek = tests.find((sample) => { return sample instanceof moment })
    const nextWeek = moment().add(1, 'weeks').isoWeekday(daysNeeded[0])
    console.log('time.findNextInstanceInDaysArray', thisWeek, nextWeek)
    let result = thisWeek || nextWeek
    var format = 'hh:mm'

    var timeMoment = moment(scheduleTime, format)
    if (result.hour) {
      result = result.hour(timeMoment.get('hour'))
      result = result.minute(timeMoment.get('minute'))
      result = result.second('00')
    }
    return result
  }
  return findNextInstanceInDaysArray(daysNeeded)
}

export function getTimeslotsBetween (start, end) {
  var startTime = moment(start, 'HH:mm')
  var endTime = moment(end, 'HH:mm')
  endTime = endTime.add(-1, 'h')
  const timeslots = []
  while (startTime <= endTime) {
    timeslots.push({ startTime: timeFormat(startTime.toDate()) })
    startTime = startTime.add(30, 'm')
  }
  return timeslots
}

export function datesBetween (start, end) {
  var dateArray = []
  var currentDate = moment(start)
  var stopDate = moment(end)
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).toDate())
    currentDate = moment(currentDate).add(1, 'days')
  }
  return dateArray
}

export function weeksBetween (start, end) {
  var dateArray = []
  var currentDate = moment(start)
  var stopDate = moment(end)
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).endOf('day').toDate())
    currentDate = moment(currentDate).add(1, 'weeks').endOf('day')
  }
  return dateArray
}

export function monthsBetween (start, end) {
  var dateArray = []
  var currentDate = moment(start)
  var stopDate = moment(end)
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).toDate())
    currentDate = moment(currentDate).add(1, 'months')
  }
  return dateArray
}

export function getLastWeek () {
  return moment.utc().subtract(7, 'days').startOf('day').toDate()
}

export function getLastMonth () {
  return moment.utc().subtract(30, 'days').startOf('day').toDate()
}

export function getLastQuarter () {
  return moment.utc().subtract(90, 'days').startOf('day').toDate()
}

export function getLast12Weeks () {
  return moment.utc().subtract(11, 'weeks').startOf('day').toDate()
}

export function getLastYear () {
  return moment.utc().subtract(1, 'years').startOf('day').toDate()
}

export function getDateRange (range) {
  if (range === 'Today') {
    const from = moment.utc().startOf('day').toDate()
    const to = moment.utc().endOf('day').toDate()
    return { from, to }
  } else if (range === 'Yesterday') {
    const from = moment.utc().subtract(1, 'days').startOf('day').toDate()
    const to = moment.utc().subtract(1, 'days').endOf('day').toDate()
    return { from, to }
  } else if (range === 'Last week') {
    const from = moment.utc().subtract(1, 'weeks').startOf('week').toDate()
    const to = moment.utc().subtract(1, 'weeks').endOf('week').toDate()
    return { from, to }
  } else if (range === 'Last month') {
    const from = moment.utc().subtract(1, 'months').startOf('month').toDate()
    const to = moment.utc().subtract(1, 'months').endOf('month').toDate()
    return { from, to }
  } else if (range === 'Last 7 days') {
    const from = getLastWeek()
    const to = moment.utc().endOf('day').toDate()
    return { from, to }
  } else if (range === 'Last 30 days') {
    const from = getLastMonth()
    const to = moment.utc().endOf('day').toDate()
    return { from, to }
  }
  return null
}

export function getWC (date) {
  var wc = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
  if (wc.getDay() !== 1) {
    if (wc.getDay() === 0) {
      wc.setDate(wc.getDate() - 6)
    } else {
      wc.setDate(wc.getDate() - wc.getDay() + 1)
    }
  }
  return wc
}

export function getWE (date) {
  var we = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
  if (we.getDay() !== 0) {
    we.setDate(we.getDate() - we.getDay() + 7)
  }
  return we
}

export function now () {
  return moment.utc().local().format('DD/MM/YY')
}

export function dayFormat (timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('ddd')
  } else {
    return ''
  }
}
export function datetimeFormat (timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('Do MMM HH:mm')
  } else {
    return ''
  }
}
export function datetimeFormatFull (timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('Do MMMM YYYY [at] HH:mm')
  } else {
    return ''
  }
}
export function dateTimeArrayFormat (dateStr) {
  if (dateStr) {
    var datetime = moment(dateStr, 'ddd D MMM HH:mm')
    const formatdt = datetime.format('YYYY, M, D, H, m')
    return `[${formatdt}]`
  } else {
    return ''
  }
}
export function dateFormat (timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('DD/MM/YY')
  } else {
    return ''
  }
}
export function dateDisplayFormat (timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('Do MMMM YYYY')
  } else {
    return ''
  }
}

export function dateShortDisplayFormat (timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('DD MMM')
  } else {
    return ''
  }
}

export function dateTimeDisplayFormat (timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('Do MMMM YYYY [at] HH:mm')
  } else {
    return ''
  }
}

export function dateExtFormat (timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('ddd D MMM')
  } else {
    return ''
  }
}
export function dateYearFormat (timestamp) {
  if (timestamp) {
    return moment(timestamp).format('DD MMM YYYY')
  } else {
    return ''
  }
}
export function timeFormat (timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('HH:mm')
  } else {
    return ''
  }
}
