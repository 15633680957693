<template>
  <div class="main-content admin">
    <div class="main-wrapper" style="margin-top: 90px;">

      <div class="mobile-links-wrapper mobileonly">
        <div class="mobileonly mobile-links">

          <round-button
            v-for="link in links"
            :key="link.action"
            :medium="section === link.action"
            class="mobile-link"
            style="flex-grow:2;"
            elevated
            hasSlot
            @click="showSection(link.action)">
            {{link.title}}
            <mdc-icon slot="trailing-icon" style="margin-left:6px;margin-right:0" class="icon" :icon="link.icon"></mdc-icon>
          </round-button>

          <span style="width:1px; background: white;flex:0;">&nbsp;</span>
        </div>
      </div>

      <section class="report-section" v-if="section === 'users' && user.claims.admin">
        <app-section
          class="admin-section"
          :editable="false"
          title="User Data">
          <h3>Download and Delete User Data</h3>
          <div class="row" style="display:flex; flex-flow: row wrap">
            <mdc-textfield style="margin-right:16px;" outlined label="User ID" v-model="userid"/>
            <div style="display:flex; flex-flow: row wrap">
              <round-button :disabled="loading" style="padding-left:16px;margin-left:0" hasSlot high elevated @click="getUserData">Get User Data</round-button>
              <round-button :disabled="loading" style="padding-left:16px;margin-left:0" hasSlot high elevated @click="deleteUserData">Delete User Data</round-button>
            </div>
          </div>
        </app-section>
      </section>

      <section class="report-section" v-if="section === 'jobs' && user.claims.admin">
        <app-section
          class="admin-section"
          :editable="false"
          title="Edit Job"
          style="margin-bottom:16px;">
          <h3>Edit Job in Index</h3>

          <div class="row" style="display:flex; flex-flow: row wrap">
            <mdc-textfield required class="job-input" outlined label="ID" v-model="editJobId"/>
            <div v-if="editJob">
              <mdc-textfield required class="job-input" outlined label="Company" v-model="editJob.company"/>
              <mdc-textfield required class="job-input" outlined label="Title" v-model="editJob.title"/>
              <mdc-textfield class="job-input" outlined label="City" v-model="editJob.city"/>
              <mdc-textfield class="job-input" outlined label="County" v-model="editJob.county"/>
              <mdc-textfield required class="job-input" outlined label="Location" v-model="editJob.location"/>
              <mdc-textfield required class="job-input" outlined label="Lat Lon" v-model="editJob.latlong"/>
              <mdc-textfield class="job-input" outlined label="Type" v-model="editJob.jobtype"/>
              <mdc-textfield class="job-input" outlined label="Full Part" v-model="editJob.full_part"/>
              <mdc-textfield class="job-input" outlined label="Category (comma seperated)" v-model="editJob.category"/>
              <mdc-textfield class="job-input" outlined label="Salary" v-model="editJob.salary"/>
              <mdc-textfield class="job-input" outlined label="Salary Per" v-model="editJob.salary_per"/>
              <mdc-textfield class="job-input" outlined label="Benefits" v-model="editJob.benefits"/>
              <mdc-textfield required class="job-input" outlined label="Image" v-model="editJob.image"/>
              <mdc-textfield required class="job-input" outlined label="URL" v-model="editJob.url"/>
              <mdc-textfield required class="job-input" outlined label="Date" v-model="editJob.date"/>
              <tiptap-editor @onTextUpdate="onTextUpdate" :title="'Description'" class="tiptap-editor" :content="job.description" reducedOptions fixedMenu :contentKey="'description'"></tiptap-editor>
              <div v-if="confirmDelete" style="display:flex; flex-flow: row wrap">
                <mdc-body style="margin-right:16px;">Are you sure you wish to remove this Job?</mdc-body>
                <round-button :disabled="loading" style="padding-left:16px;margin-left:0" hasSlot high elevated @click="deleteJobConfirm">Delete Job</round-button>
                <round-button :disabled="loading" style="padding-left:16px;margin-left:0" hasSlot elevated @click="confirmDelete = false">Cancel</round-button>
              </div>
              <div v-else style="display:flex; flex-flow: row wrap">
                <round-button :disabled="loading" style="padding-left:16px;margin-left:0" hasSlot high elevated @click="saveJob">Save Job</round-button>
                <round-button :disabled="loading" style="padding-left:16px;margin-left:0" hasSlot high elevated @click="confirmDelete = true">Delete Job</round-button>
                <round-button :disabled="loading" style="padding-left:16px;margin-left:0" hasSlot elevated @click="editJob = false">Cancel</round-button>
              </div>
            </div>
            <div v-else>
              <div style="display:flex; flex-flow: row wrap">
                <round-button :disabled="loading" style="padding-left:16px;margin-left:0" hasSlot high elevated @click="findJob">Find Job</round-button>
              </div>
              <div v-if="message">{{message}}</div>
            </div>
          </div>
        </app-section>
        <app-section
          class="admin-section"
          :editable="false"
          title="Add Job">
          <h3>Add Job to Index</h3>

          <div class="row" style="display:flex; flex-flow: row wrap">
            <mdc-textfield readonly="readonly" required class="job-input" outlined label="ID" v-model="job.id"/>
            <mdc-textfield required class="job-input" outlined label="Company" v-model="job.company"/>
            <mdc-textfield required class="job-input" outlined label="Title" v-model="job.title"/>
            <mdc-textfield class="job-input" outlined label="City" v-model="job.city"/>
            <mdc-textfield class="job-input" outlined label="County" v-model="job.county"/>
            <mdc-textfield required class="job-input" outlined label="Location" v-model="job.location"/>
            <mdc-textfield required class="job-input" outlined label="Lat Lon" v-model="job.latlon"/>
            <mdc-textfield class="job-input" outlined label="Type" v-model="job.jobtype"/>
            <mdc-textfield class="job-input" outlined label="Full Part" v-model="job.fullpart"/>
            <mdc-textfield class="job-input" outlined label="Category (comma seperated)" v-model="job.category"/>
            <mdc-textfield class="job-input" outlined label="Salary" v-model="job.salary"/>
            <mdc-textfield class="job-input" outlined label="Salary Per" v-model="job.salaryper"/>
            <mdc-textfield class="job-input" outlined label="Benefits" v-model="job.benefits"/>
            <mdc-textfield required class="job-input" outlined label="Image" v-model="job.image"/>
            <mdc-textfield required class="job-input" outlined label="URL" v-model="job.url"/>
            <mdc-textfield required class="job-input" outlined label="Date" v-model="job.date"/>
            <tiptap-editor @onTextUpdate="onTextUpdate" :title="'Description'" class="tiptap-editor" :content="job.description" reducedOptions fixedMenu :contentKey="'description'"></tiptap-editor>
            <div style="display:flex; flex-flow: row wrap">
              <round-button :disabled="loading" style="padding-left:16px;margin-left:0" hasSlot high elevated @click="createJob">Add Job</round-button>
            </div>
          </div>
        </app-section>
      </section>

      <section class="report-section" style="padding-left:0;padding-right:0" v-if="section === 'recommendations' && user.claims.admin">
        <app-section
          class="admin-section"
          :editable="false"
          title="Recommendations">
          <h3>Check Recommended Partners and Jobs</h3>
          <div class="row" style="display:flex; flex-flow: row wrap">

            <div style="display:flex; flex-flow: row wrap; margin-bottom:16px;width:100%">
              <mdc-textfield style="margin-right:16px;" outlined label="User ID" v-model="userid"/>
              <round-button :disabled="loading" style="padding-left:16px;margin-left:0" hasSlot high elevated @click="setQueriesByUser">Get By User</round-button>
            </div>
            <div style="display:flex; flex-flow: row wrap; margin-bottom:16px;width:100%;align-items:center;margin-top:6px;">
              <mdc-select outlined v-model="location" label="Location" style="margin:2px;margin-right:16px;">
                <select-item value=" " />
                <select-item v-for="(loc, index) in locations" :key="index" :value="`${loc.lat},${loc.lon}`" />
              </mdc-select>
              <round-button :disabled="loading" style="padding-left:16px;margin-left:0" hasSlot high elevated @click="setQueriesByLocation">Get By Location</round-button>
            </div>
            <div style="display:flex; flex-flow: row wrap; margin-bottom:16px;width:100%">
              <mdc-textfield style="margin-right:16px;" outlined label="Role" v-model="role"/>
              <round-button :disabled="loading" style="padding-left:16px;margin-left:0" hasSlot high elevated @click="setQueriesByRole">Get By Role</round-button>
            </div>
            <mdc-text-area-autosize maxlength=1000 style="width:100%;" label="Partner Query" v-model="partnerQuery"></mdc-text-area-autosize>
            <div style="width:100%;margin-bottom:16px;color:red" v-if="partnerQueryError">Error running query: {{partnerQueryError}}</div>
            <mdc-text-area-autosize maxlength=1000 style="width:100%;" label="Jobs Query" v-model="jobsQuery"></mdc-text-area-autosize>
            <div style="width:100%;margin-bottom:16px;color:red" v-if="jobQueryError">Error running query: {{jobQueryError}}</div>
            <div style="width:100%; display:flex; flex-flow: row wrap;margin-top:6px;">
              <mdc-textfield type="number" style="margin-right:16px;" outlined label="Start" v-model="start"/>
              <mdc-textfield type="number" style="margin-right:16px;" outlined label="Rows" v-model="rows"/>
             <round-button :disabled="loading" style="padding-left:16px;margin-left:0" hasSlot high elevated @click="getRecommendations">Get Results</round-button>
            </div>
            <div style="display:flex; flex-flow: row; margin-bottom:16px;text-align:right;width:100%;justify-content:center;align-items:center;">

              <mdc-icon style="margin-left:auto;margin-right:6px;" icon="list"/>
              <mdc-switch v-model="cardView"/>
              <mdc-icon style="margin-left:6px;margin-right:6px;" icon="apps"/>
            </div>
          </div>
        </app-section>
        <mdc-body typo="body2" style="margin-top:16px">Showing Partners 0 to {{partners.length}} of {{partnersFound}}</mdc-body>
        <mdc-layout-grid v-if="cardView">
          <mdc-layout-cell v-for="prof in partners" :key="prof.id" span=4 class="profiles">
            <router-link class="profile-card-link" :to="{ name: 'User', params: {id: prof.id} }">
              <profile-card-mobile hoverHigh hideShare class="mobileonly" :profile="prof"></profile-card-mobile>
              <profile-card hoverHigh :medium="true" hideShare class="desktoponly" :profile="prof"></profile-card>
            </router-link>
          </mdc-layout-cell>
        </mdc-layout-grid>
        <div v-else>
          <table class="data-table" style="width:100%">
            <tr v-for="prof in partners" :key="prof.id">
              <td>{{prof.id}}</td>
              <td>{{prof.displayName}}</td>
              <td>{{prof.role}}</td>
              <td>{{prof.title}}</td>
              <td>{{prof.industry}}</td>
              <td>{{prof.location}}</td>
              <td>{{prof.created}}</td>
            </tr>
          </table>
        </div>
        <hr/>
        <mdc-body typo="body2" style="margin-top:16px">Showing Jobs 0 to {{jobs.length}} of {{jobsFound}}</mdc-body>
        <mdc-layout-grid v-if="cardView">
          <mdc-layout-cell v-for="jobrole in jobs" :key="jobrole.id" span=4 class="jobroles">
            <router-link class="job-card-link" :to="{ name: 'JobDetail', params: {id: jobrole.id} }">
              <job-role-card-mobile hoverHigh showDesc hideShare class="mobileonly" :jobrole="jobrole"></job-role-card-mobile>
              <job-role-card hoverHigh hideShare showDesc class="desktoponly" :jobrole="jobrole"></job-role-card>
            </router-link>
          </mdc-layout-cell>
        </mdc-layout-grid>
        <div v-else>
          <table class="data-table" style="width:100%">
            <tr v-for="jobrole in jobs" :key="jobrole.id">
              <td>{{jobrole.id}}</td>
              <td>{{jobrole.title}}</td>
              <td>{{jobrole.location}}</td>
              <td>{{jobrole.date}}</td>
            </tr>
          </table>
        </div>
      </section>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as backend from '../utils/backend'
import saveAs from 'file-saver'
import AppSection from '../components/profile-v2/AppSection.vue'
import ProfileCardMobile from '../components/profile-v2/ProfileCardMobile.vue'
import ProfileCard from '../components/profile-v2/ProfileCard.vue'
import JobRoleCardMobile from '../components/profile-v2/JobRoleCardMobile.vue'
import JobRoleCard from '../components/profile-v2/JobRoleCard.vue'

export default {
  name: 'Admin',
  components: {
    AppSection,
    ProfileCard,
    ProfileCardMobile,
    JobRoleCard,
    JobRoleCardMobile,
    TiptapEditor: () => import('../components/Tiptap')
  },
  data () {
    return {
      title: 'Admin',
      userid: '',
      userProfile: {},
      partnerQuery: '',
      jobsQuery: '',
      jobQueryError: '',
      partnerQueryError: '',
      start: '0',
      rows: '8',
      partnersFound: 0,
      jobsFound: 0,
      jobs: [],
      partners: [],
      cardView: true,
      location: '',
      role: '',
      message: '',
      editJob: false,
      editJobId: '',
      confirmDelete: false,
      job: {
        id: '',
        title: '',
        company: '',
        category: '',
        url: '',
        image: '',
        city: '',
        county: '',
        location: '',
        latlon: '',
        fullpart: '',
        jobtype: '',
        date: '',
        benifits: '',
        salaryper: '',
        salary: '',
        description: ''
      },
      result: '',
      loading: false,
      section: '',
      validationMessage: '',
      pageActions: ['users', 'jobs', 'recommendations'],
      links: [
        {
          title: 'Users',
          icon: 'build',
          action: 'users'
        },
        {
          title: 'Jobs',
          svgicon: 'work',
          action: 'jobs'
        },
        {
          title: 'Recommendations',
          icon: 'ballot',
          iconOutlined: true,
          action: 'recommendations'
        }
      ]
    }
  },
  methods: {
    ...mapActions([
      'setAction',
      'setPageLoad',
      'setSnackMessage',
      'addJob',
      'updateJob',
      'deleteJob'
    ]),
    findJob () {
      console.log('admin.findJob')
      this.message = ''
      this.editJob = false
      const jobsQuery = `id:${this.editJobId}`
      this.jobsSearch(`rows=1&start=0&q=${jobsQuery}`).then(result => {
        if (result) {
          const jobs = result.docs
          if (jobs) {
            const foundJob = jobs[0]
            if (foundJob) {
              if (foundJob.category) {
                foundJob.category = foundJob.category.join(',')
              }
              console.log('admin.findJob found', foundJob)
              this.editJob = foundJob
            }
          }
        }
        if (!this.editJob) {
          this.message = `Job ${this.editJobId} not found`
        }
      })
    },
    saveJob () {
      console.log('admin.saveJob')
      this.message = ''
      let valid = true
      if (this.editJob.id === '') {
        valid = false
        this.message = 'Jobs must have a valid ID'
      } else if (this.editJob.title === '') {
        valid = false
        this.message = 'Title is required'
      } else if (this.editJob.location === '') {
        valid = false
        this.message = 'Location is required'
      } else if (this.editJob.latlon === '') {
        valid = false
        this.message = 'LatLon is required'
      } else if (this.editJob.image === '') {
        valid = false
        this.message = 'Image is required'
      } else if (this.editJob.url === '') {
        valid = false
        this.message = 'Url is required'
      } else if (this.editJob.date === '') {
        valid = false
        this.message = 'Date is required'
      }

      if (valid) {
        this.updateJob({ job: this.editJob, jobId: this.editJob.id }).then(() => {
          this.setSnackMessage('Job Role Updated')
          this.editJob = false
          this.editJobId = ''
        })
      } else {
        this.setSnackMessage(this.message)
      }
    },
    deleteJobConfirm () {
      console.log('admin.deleteJob')
      this.deleteJob({ jobId: this.editJob.id }).then(() => {
        this.setSnackMessage('Job Role Deleted')
        this.editJob = false
        this.editJobId = ''
      })
    },
    getRecommendations () {
      this.jobQueryError = ''
      this.partnerQueryError = ''
      if (this.jobsQuery) {
        this.jobsSearch(`rows=${this.rows}&start=${this.start}&q=${this.jobsQuery}`).then(result => {
          if (result) {
            this.jobs = result.docs
          }
        })
      }
      if (this.partnerQuery) {
        this.profilesSearch(`rows=${this.rows}&start=${this.start}&q=${this.partnerQuery}`).then(result => {
          if (result) {
            this.partners = result.docs
          }
        })
      }
    },
    onTextUpdate: function (text, contentKey) {
      this.job[contentKey] = text
    },
    async jobsSearch (query) {
      const res = await backend.search('jobs', {
        query: { query }
      })
      if (res.error) {
        console.log('Dash.jobsSearch.error', query, res, res.error)
        this.jobQueryError = res.error
        return
      }
      const result = res.result.response
      if (res.result.facet_counts) {
        result.facets = {}
        for (const facetField in res.result.facet_counts.facet_fields) {
          let facetsArray = res.result.facet_counts.facet_fields[facetField]
          const facetsObj = {}
          facetsArray = facetsArray.map((x, i) => i % 2 === 0 && facetsArray.slice(i, i + 2)).filter(x => x)
          facetsArray.forEach(function (data) {
            facetsObj[data[0]] = data[1]
          })
          result.facets[facetField] = facetsObj
        }
      }
      this.jobsFound = result.numFound || '0'
      console.log('Admin.jobsSearch', query, res)
      return result
    },
    async profilesSearch (query) {
      const res = await backend.search('profiles', {
        query: { query }
      })
      if (res.error) {
        console.log('Dash.profilesSearch.error', query, res, res.error)
        this.partnerQueryError = res.error
        return
      }
      const result = res.result.response
      this.partnersFound = result.numFound || '0'
      console.log('Admin.profilesSearch', query, res)
      return result
    },
    setQueriesByLocation () {
      let locJobQuery = '*'
      if (this.location) {
        locJobQuery += this.getDistanceQuery(this.location)
      }
      this.jobsQuery = locJobQuery
      this.partnerQuery = ''
    },
    setQueriesByRole () {
      let roleJobQuery = ''
      let rolePartnerQuery = ''
      if (this.role) {
        roleJobQuery += `(_title:"${this.role}")`
        rolePartnerQuery += `${this.role}`
      }
      this.jobsQuery = roleJobQuery
      this.partnerQuery = rolePartnerQuery
    },
    setDefaultQueries () {
      // Default Partners
      if (this.userProfile.companyId) {
        this.partnerQuery = `visible:true -id:${this.userProfile.id} AND companyId:"${this.userProfile.companyId}"`
      } else {
        let publicPartnerQuery = ''
        if (this.userProfile.role) {
          publicPartnerQuery = `${('' + this.userProfile.role).replace('&', '')}`
        }
        this.partnerQuery = `${publicPartnerQuery} -id:${this.userProfile.id} -companyId:* AND visible:true`
      }

      // Default Jobs
      let defaultJobQuery = ''

      if (this.userProfile.industry) {
        const industry = this.userProfile.industry.replace('/', ' ')
        defaultJobQuery += `(_category:"${industry}")`
      }
      if (this.profile.role) {
        if (defaultJobQuery !== '') {
          defaultJobQuery += ' OR '
        }
        defaultJobQuery += `(_title:"${this.userProfile.role.replace('&', '')}")^3`
      }
      if (this.userProfile.companyId) {
        defaultJobQuery += ` AND (company:"${this.userProfile.companyId}")`
      }
      defaultJobQuery += this.getDistanceQuery() === '' ? '&sort=date+desc' : this.getDistanceQuery()
      this.jobsQuery = defaultJobQuery
    },
    getDistanceQuery (latlon) {
      if (!latlon) {
        if (this.userProfile.locationLat && this.userProfile.locationLon) {
          latlon = `${this.userProfile.locationLat},${this.userProfile.locationLon}`
        }
      }
      if (latlon) {
        return `&fq={!geofilt}&sfield=latlong&pt=${latlon}&d=2500&bf=recip(geodist(),2,200,20)&sort=geodist()+asc,score+desc`
      }
      return ''
    },
    uuidv4 () {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      )
    },
    setDefaultJob () {
      this.job.id = this.uuidv4()
      this.job.title = ''
      this.job.company = ''
      this.job.category = ''
      this.job.url = ''
      this.job.image = ''
      this.job.city = 'London'
      this.job.county = 'London'
      this.job.location = 'London'
      this.job.latlon = '51.5073509,-0.1277583'
      this.job.fullpart = 'Full Time'
      this.job.jobtype = 'Permanent'
      this.job.date = new Date().toISOString().replace('T', ' ').substring(0, 19)
      this.job.benefits = 'flexible working + benefits'
      this.job.salaryper = 'Annum'
      this.job.salary = ''
      this.job.description = ''
    },
    createJob () {
      this.validationMessage = ''
      let valid = true
      if (this.job.id === '' || this.job.id.length < 6) {
        valid = false
        this.validationMessage = 'Jobs must have a valid ID of at least 6 characters'
      } else if (this.job.title === '') {
        valid = false
        this.validationMessage = 'Title is required'
      } else if (this.job.location === '') {
        valid = false
        this.validationMessage = 'Location is required'
      } else if (this.job.latlon === '') {
        valid = false
        this.validationMessage = 'LatLon is required'
      } else if (this.job.image === '') {
        valid = false
        this.validationMessage = 'Image is required'
      } else if (this.job.url === '') {
        valid = false
        this.validationMessage = 'Url is required'
      } else if (this.job.date === '') {
        valid = false
        this.validationMessage = 'Date is required'
      }

      if (valid) {
        this.addJob({ job: this.job, jobId: this.job.id }).then(() => {
          this.setSnackMessage('Job Role Published')
          this.setDefaultJob()
        })
      } else {
        this.setSnackMessage(this.validationMessage)
      }
    },
    async setQueriesByUser () {
      this.userProfile = this.getProfile(this.userid)
      this.setDefaultQueries()
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      const profileMap = new Map(this.profiles.map(i => [i.id, i]))
      return profileMap.get(profileId)
    },
    async getUserData () {
      this.loading = true
      const self = this
      backend.cloudFunction('getUserData', { id: this.userid }).then((res) => {
        self.result = res
        this.setSnackMessage('User Data Compiled')
        const data = JSON.stringify(self.result)
        const blob = new Blob([data], { type: 'application/json;charset=utf-8' })
        const filename = `user-data-${self.userid}.json`
        self.userid = ''
        self.loading = false
        return saveAs(blob, filename)
      })
    },
    async deleteUserData () {
      if (confirm('Are you sure you want to delete this users data? This action cannot be undone!')) {
        this.loading = true
        const self = this
        backend.cloudFunction('deleteUserData', { id: this.userid }).then((res) => {
          self.result = res
          self.setSnackMessage('User Data Deleted')
          self.userid = ''
          self.loading = false
        })
      }
    },
    showSection: function (section) {
      this.section = section
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'profiles',
      'action',
      'locations'
    ])
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$root.$emit('enableSearch', false)
  },
  mounted () {
    this.setDefaultJob()
    this.$emit('setLinks', this.links)
    if (!this.pageActions.includes(this.action)) {
      this.setAction('users')
    } else {
      this.setAction(this.action)
    }

    this.showSection(this.action)
    this.$root.$emit('enableSearch', true)

    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  },
  watch: {
    action: function (newA, oldA) {
      if (newA !== '') {
        this.showSection(newA)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.job-card-link {
  text-decoration: none;
  color: #333;
  margin-top: 0;
  width: 100%;
}

/* Override grid media queries for cards
 * Built in screen sizes are not granular enough so need custom size
 * to allow profile content to file while maintaining aspect ratio.
 */

@media (min-width: 481px) and (max-width: 595px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 8;
  }
}
@media (min-width: 595px) and (max-width: 671px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 12;
  }
}
@media (min-width: 840px) and (max-width: 920px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 6;
  }
}
@media (min-width: 920px) and (max-width:   1180px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 6;
  }
}
@media (min-width: 1180px) and (max-width:   1380px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 4;
  }
}
@media (min-width: 1480px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 3;
  }
}

.mono-letter {
  font-family: 'Roboto Mono', monospace;
  margin:2px;
  padding-left:9px;
  justify-content: center;
  align-items: center;
}

.profile-card-link {
  text-decoration: none;
  color: #333;
}

.report-section {
  padding-left: var(--mdc-layout-grid-margin-desktop, 24px);
  padding-right: var(--mdc-layout-grid-margin-desktop, 24px);
  padding-top:16px;
}
@media (max-width: 840px) {
  .report-section {
    padding-left: var(--mdc-layout-grid-margin-phone, 16px);
    padding-right: var(--mdc-layout-grid-margin-phone, 16px);
  }
}

@media (max-width: 480px) {
  .report-section {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Override grid media queries for cards
 * Built in screen sizes are not granular enough so need custom size
 * to allow profile content to file while maintaining aspect ratio.
 */
@media (min-width: 481px) and (max-width: 595px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 8;
  }
}
@media (min-width: 840px) and (max-width:   1020px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 6;
  }
}
@media (min-width: 1252px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 3;
  }
}

.job-input {
  margin-right:16px;
  margin-top:12px;
  width:calc(50% - 16px);
}
@media (max-width: 1000px) {
  .job-input {
    margin-right:16px;
    margin-top:12px;
    width:calc(100% - 16px);
  }
}

.mobile-links-wrapper {
  margin-left:-16px;
  margin-right:-16px;
  overflow:hidden;
  height:60px;
}
.mobile-links {
  display: flex;
  justify-content:space-between;
  flex-wrap: nowrap;
  margin-top:8px;
  padding-bottom: 10px;
  height:90px;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.mobile-links .mobile-link {
  padding-left:16px;
  flex-grow:1;
  flex-shrink:0;
  margin-left:6px;
  margin-right:6px;
  margin-bottom:2px;
}

@media (max-width: 481px) {
  .mobile-links-wrapper .mobile-links .mobile-link:first-child {
    margin-left:16px;
  }
}

@media (min-width: 481px) and (max-width:569px) {
  .mobile-links-wrapper .mobile-links .mobile-link:first-child {
    margin-left:32px;
  }
}

.mobile-links {
  padding-left:0px;
  padding-right:0px;
}

@media (any-hover: hover) {
  .admin .mobile-links .mobile-link:hover {
    box-shadow: 0 0 0 1.5px #979797;
  }
}

</style>
<style>
.admin .tiptap-editor {
  border: 1px solid rgba(0,0,0,.38);
  margin-top: 16px !important;
  padding: 16px;
  border-radius: 4px;
  padding-top: 0;
  width:calc(100% - 48px);
}
</style>
