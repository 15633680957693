<template>
  <div class="main-content dash">
    <div class="main-wrapper" style="margin-top: 90px;">

      <div class="mobile-links-wrapper mobileonly">
        <div class="mobileonly mobile-links">

          <round-button v-if="section === 'aggregated'" medium  class="mobile-link" style="flex-grow:2;" elevated hasSlot @click="showSection('aggregated')">
            All
            <mdc-icon slot="trailing-icon" style="margin-left:6px;color:#2D9CDB" class="icon" icon="done_all"></mdc-icon>
          </round-button>
          <round-button v-else class="mobile-link" style="flex-grow:2;" elevated hasSlot @click="setAction('aggregated')">
            All
            <mdc-icon slot="trailing-icon" style="margin-left:6px;" class="icon" icon="done_all"></mdc-icon>
          </round-button>

          <round-button v-if="profile.remoteMode && section === 'team'" medium  class="mobile-link" style="flex-grow:2;" elevated hasSlot @click="showSection('aggregated')">
            Team
            <mdc-icon slot="trailing-icon" style="margin-left:6px;color:#2D9CDB" class="icon" icon="people_outline"></mdc-icon>
          </round-button>
          <round-button v-else-if="profile.remoteMode" class="mobile-link" style="flex-grow:2;" elevated hasSlot @click="setAction('team')">
            Team
            <mdc-icon slot="trailing-icon" style="margin-left:6px;" class="icon" icon="people_outline"></mdc-icon>
          </round-button>

          <round-button v-if="profile.remoteMode && section === 'activities'" medium  class="mobile-link" style="flex-grow:2;margin-left:0;" elevated hasSlot @click="showSection('aggregated')">
            Social
            <mdc-icon slot="trailing-icon" style="margin-left:6px;color:#2D9CDB" class="icon" outlined icon="grade"></mdc-icon>
          </round-button>
          <round-button v-else-if="profile.remoteMode" class="mobile-link" style="flex-grow:2;margin-left:0;" elevated hasSlot @click="setAction('activities')">
            Social
            <mdc-icon slot="trailing-icon" style="margin-left:6px;" class="icon" outlined icon="grade"></mdc-icon>
          </round-button>

          <round-button v-if="section === 'partners'" medium  class="mobile-link" style="flex-grow:2;margin-left:0;" elevated hasSlot @click="showSection('aggregated')">
            Partners
            <img slot="trailing-icon" style="margin-left:6px;" class="icon" src="/static/img/icons/people_outline_blue.svg">
          </round-button>
          <round-button v-else class="mobile-link" style="flex-grow:2;margin-left:0;" elevated hasSlot @click="setAction('partners')">
            Partners
            <img slot="trailing-icon" style="margin-left:6px;" class="icon" src="/static/img/icons/people_outline_light.svg">
          </round-button>

          <round-button v-if="!jobsDisabled && section === 'jobs'" medium class="mobile-link" style="margin-left:0;margin-right:0" elevated hasSlot @click="showSection('aggregated')">
            Jobs
            <img slot="trailing-icon" style="margin-left:6px;" class="icon" src="/static/img/icons/work_outline_blue.svg">
          </round-button>
          <round-button v-else class="mobile-link" style="margin-left:0;margin-right:0" elevated hasSlot @click="setAction('jobs')">
            Jobs
            <img slot="trailing-icon" style="margin-left:6px;" class="icon" src="/static/img/icons/work_outline_light.svg">
          </round-button>

          <round-button v-if="section === 'bookmarked'" medium class="mobile-link" style="flex-grow:2;margin-right:12px;" elevated hasSlot @click="showSection('aggregated')">
            Bookmarks
            <img slot="trailing-icon" style="margin-left:6px;height:19px;width:19px;" class="icon" src="/static/img/icons/bookmark-blue.svg">
          </round-button>
          <round-button v-else class="mobile-link" style="flex-grow:2;margin-right:12px;" elevated hasSlot @click="setAction('bookmarked')">
            Bookmarks
            <img slot="trailing-icon" style="margin-left:6px;height:19px;width:19px;" class="icon" src="/static/img/icons/bookmark-off.svg">
          </round-button>
          <span style="width:1px; background: white;flex:0;">&nbsp;</span>
        </div>
      </div>

      <highlight-section
        v-if="(section !== 'aggregated') && (section !== 'team') && !(section === 'jobs' && (hasfeaturedCompanies || internalJobsOnly))"
        style="margin-bottom:6px;">
      </highlight-section>
      <company-feature-section
        ref="featuredCompanies"
        @filter="applyCompanyFilter"
        v-if="section === 'jobs' && hasfeaturedCompanies && !internalJobsOnly"
        :isMobile="isMobile"
        :isDesktopLarge="isDesktopLarge"
        :isTabletLarge="isTabletLarge"
        :isTablet="isTablet"
        :companies="featuredCompanies"
        style="margin-bottom:6px;"/>

      <section v-if="section === 'aggregated'">

        <highlight-section style="margin-bottom:16px;"></highlight-section>

        <section v-if="company.assignedCompany && companyProfiles">
          <mdc-title class="section-title" v-if="company.assignedSubCompany" style="margin-bottom:0">
            <span v-if="company.assignedSubCompany.private">{{company.assignedSubCompany.name}} Partner Profiles</span>
            <span v-else>{{company.assignedCompany.displayName}} Partner Profiles</span>
          </mdc-title>
          <mdc-title class="section-title" v-else-if="company.assignedCompany" style="margin-bottom:0">{{company.assignedCompany.displayName}} Partner Profiles</mdc-title>
          <mdc-body class="section-sub-title" v-if="company.assignedCompany">People interested or working flexibly</mdc-body>

          <mdc-body class="section-sub-title" style="margin-top:16px;" typo="body2" v-if="companyProfilesFound === 0">Whoops, this search has no results, try something different.</mdc-body>
          <transition name="fade-in" appear>
          <mdc-layout-grid v-if="company.assignedCompany && (!company.assignedCompany.private || publicSubCompanies)">
            <mdc-layout-cell v-for="prof in companyProfiles" :key="prof.id" span=4 class="profiles">
              <router-link class="profile-card-link" :to="{ name: 'User', params: {id: prof.id} }">
                <profile-card-mobile hoverHigh hideShare class="mobileonly" @blocked="showCompleteProfileNotification" :profile="prof"></profile-card-mobile>
                <profile-card hoverHigh :medium="true" hideShare class="desktoponly" @blocked="showCompleteProfileNotification" :profile="prof"></profile-card>
              </router-link>
            </mdc-layout-cell>
          </mdc-layout-grid>
          </transition>
          <!-- <div class="additional">
            <span>See </span>
            <round-button class="inline-button" hasSlot @click="seeMoreCompanyPartners">More</round-button>
            <span>{{company.assignedCompany.name}} Partners... </span>
          </div> -->
        </section>

        <section class="public-partner-section" v-if="companyProfilesOnly !== true && publicProfiles">
          <mdc-title class="section-title" style="margin-bottom:0"> Public Partner Profiles</mdc-title>
          <mdc-body style="display:flex;" class="section-sub-title">

            <span style="margin-right:6px;">Public users interested or working flexibly</span>
            <span style="width: 25px;height: 25px;">
              <hotspot
                class="partners-hotspot"
                @shown="updateHotspot('partners')"
                :visible="!profile.hotspotClicked.partners && (profile.hotspotSeen.partners <= 5 || !profile.hotspotSeen.partners)" :diameter="20">
                <round-button
                  background="transparent"
                  @mouseover.native="mouseOverTooltip"
                  @click="showTooltip = true"
                  noHover
                  class="inline-button tooltip-icon"
                  style="padding:0;color:#828282;height:32px;width:20px;min-width:20px;margin:0;"
                  icon="help_outline"/>
              </hotspot>
            </span>
            <v-popover
              offset="1"
              delay="100"
              container='.public-partner-section'
              style="margin-left:-16px;"
              placement='bottom-end'
              :disabled="false"
              trigger="manual"
              :open="showTooltip"
              @show="trackDiscoverPartnerHelp"
              @hide="showTooltip = false"
              autoHide
            >
              <template slot="popover">
                <mdc-title>Partner Profiles</mdc-title>
                <div>
                  <p>Finding a Job Share partner before applying for a role increases your chances of success. Connect below and message others interested in flexible working.</p>
                </div>
                <div style="width:100%;text-align:right">
                  <a class="mdc-button" @click="showTooltip = false"><mdc-icon style="height: 24px;" icon="clear"/> CLOSE</a>
                </div>
              </template>
            </v-popover>

          </mdc-body>

          <mdc-body class="section-sub-title" style="margin-top:16px;" typo="body2" v-if="publicProfilesFound === 0">Whoops, this search has no results, try something different.</mdc-body>

          <mdc-layout-grid>
            <template v-for="prof in publicProfiles">
              <mdc-layout-cell v-if="prof.featured && !profile.experienceCompleted" :key="prof.id"  span=4 class="profiles">
                <div @click="showCompleteProfileNotification" class="featured-card-link">
                  <featured-card-mobile @click="showCompleteProfileNotification" class="mobileonly" :title="prof.title" :text="prof.text" :detailTitle="prof.detailTitle" :detailText="prof.detailText"/>
                  <featured-card class="desktoponly" :title="prof.title" :text="prof.text" :detailTitle="prof.detailTitle" :detailText="prof.detailText"/>
                </div>
              </mdc-layout-cell>
              <mdc-layout-cell v-else-if="!prof.featured" :key="prof.id"  span=4 class="profiles">
                <router-link class="profile-card-link" :to="{ name: 'User', params: {id: prof.id} }">
                  <profile-card-mobile hoverHigh hideShare class="mobileonly" @blocked="showCompleteProfileNotification" :profile="prof"></profile-card-mobile>
                  <profile-card hoverHigh :medium="true" hideShare class="desktoponly" @blocked="showCompleteProfileNotification" :profile="prof"></profile-card>
                </router-link>
              </mdc-layout-cell>
            </template>
          </mdc-layout-grid>
          <div class="additional">
            <span>See </span>
            <round-button class="inline-button" hasSlot @click="seeMorePartners">More</round-button>
            <span>Public Partners... </span>
          </div>
        </section>

        <section class="jobs-section" v-if="profile && jobs && !jobsDisabled">
          <mdc-title class="section-title" style="margin-bottom:0"> Job Vacancies</mdc-title>
          <mdc-body style="display:flex;" class="section-sub-title">
            <span style="margin-right:6px;">Look for positions to work flexibly</span>
            <span style="width: 25px;height: 25px;">
              <hotspot
                class="jobs-hotspot"
                @shown="updateHotspot('jobs')"
                :visible="!profile.hotspotClicked.jobs && (profile.hotspotSeen.jobs <= 5 || !profile.hotspotSeen.jobs)" :diameter="20">
                <round-button
                  background="transparent"
                  @mouseover.native="mouseOverTooltipJobs"
                  @click="showTooltipJobs = true"
                  noHover
                  class="inline-button tooltip-icon"
                  style="padding:0;color:#828282;height:32px;width:20px;min-width:20px;margin:0;"
                  icon="help_outline"/>
              </hotspot>
            </span>
            <v-popover
              offset="1"
              delay="100"
              container='.jobs-section'
              style="margin-left:-16px;"
              placement='bottom-end'
              :disabled="false"
              trigger="manual"
              :open="showTooltipJobs"
              @show="trackDiscoverJobsHelp"
              @hide="showTooltipJobs = false"
              autoHide
            >
              <template slot="popover">
                <mdc-title>Work Flexibly</mdc-title>
                <div>
                  <p>Jobs supporting flexibility offer more control over where, when and how you work. We highlight the section of jobs that mentions flexibility.</p>
                </div>
                <div style="width:100%;text-align:right">
                  <a class="mdc-button" @click="showTooltipJobs = false"><mdc-icon style="height: 24px;" icon="clear"/> CLOSE</a>
                </div>
              </template>
            </v-popover>

          </mdc-body>

          <mdc-body class="section-sub-title" style="margin-top:16px;" typo="body2" v-if="jobsfound === 0">Whoops, this search has no results, try something different.</mdc-body>

          <mdc-layout-grid>
            <template v-for="jobrole in jobs">
              <mdc-layout-cell v-if="jobrole.featured && !profile.experienceCompleted" :key="jobrole.id" span=4 class="jobroles">
                <div @click="showCompleteProfileNotification" class="featured-card-link">
                  <featured-card-mobile @click="showCompleteProfileNotification" class="mobileonly" :title="jobrole.title" :text="jobrole.text" :detailTitle="jobrole.detailTitle" :detailText="jobrole.detailText"/>
                  <featured-card class="desktoponly" :title="jobrole.title" :text="jobrole.text" :detailTitle="jobrole.detailTitle" :detailText="jobrole.detailText"/>
                </div>
              </mdc-layout-cell>
              <mdc-layout-cell v-else-if="!jobrole.featured" :key="jobrole.id" span=4 class="jobroles">
                <router-link class="job-card-link" :to="{ name: 'JobDetail', params: {id: jobrole.id} }">
                  <job-role-card-mobile :likelyFlexFilter="filterFlex === 'Very Likely' || filterFlex === 'Likely'" hoverHigh showDesc hideShare class="mobileonly" @blocked="showCompleteProfileNotification" :jobrole="jobrole"></job-role-card-mobile>
                  <job-role-card :likelyFlexFilter="filterFlex === 'Very Likely' || filterFlex === 'Likely'" hoverHigh showDesc class="desktoponly" @blocked="showCompleteProfileNotification" :jobrole="jobrole"></job-role-card>
                </router-link>
              </mdc-layout-cell>
            </template>
          </mdc-layout-grid>
          <div class="additional">
            <span>See </span>
            <round-button class="inline-button" hasSlot @click="seeMoreJobs">More</round-button>
            <span>Job Vacancies... </span>
          </div>
        </section>

        <!--
        <section class="jobs-interest-section" v-if="profile && jobsInterest && !jobsDisabled">
          <mdc-title class="section-title" style="margin-bottom:0"> Jobs with Interest</mdc-title>
          <mdc-body style="display:flex;" class="section-sub-title">
            <span style="margin-right:6px;">Connect to Job Share partners via roles you like</span>
            <span style="width: 25px;height: 25px;">
              <hotspot
                class="jobs-hotspot"
                @shown="updateHotspot('jobsInterest')"
                :visible="!profile.hotspotClicked.jobsInterest && (profile.hotspotSeen.jobsInterest <= 5 || !profile.hotspotSeen.jobsInterest)" :diameter="20">
                <round-button
                  background="transparent"
                  @mouseover.native="mouseOverTooltipJobsInterest"
                  @click="showTooltipJobsInterest = true"
                  noHover
                  class="inline-button tooltip-icon"
                  style="padding:0;color:#828282;height:32px;width:20px;min-width:20px;margin:0;"
                  icon="help_outline"/>
              </hotspot>
            </span>
            <v-popover
              offset="1"
              delay="100"
              container='.jobs-interest-section'
              style="margiJobs with Interestn-left:-16px;"
              placement='bottom-end'
              :disabled="false"
              trigger="manual"
              :open="showTooltipJobsInterest"
              @show="markDiscoverJobsInterest"
              @hide="showTooltipJobsInterest = false"
              autoHide
            >
              <template slot="popover">
                <mdc-title>Jobs with Interested Partners</mdc-title>
                <div>
                  <p>Mark your interest in these jobs to reveal other interested partners and get notified when more join.</p>
                </div>
                <div style="width:100%;text-align:right">
                  <a class="mdc-button" @click="showTooltipJobsInterest = false"><mdc-icon style="height: 24px;" icon="clear"/> CLOSE</a>
                </div>
              </template>
            </v-popover>

          </mdc-body>

          <mdc-layout-grid>
            <template v-for="jobrole in jobsInterest">
              <mdc-layout-cell v-if="jobrole.featured && !profile.experienceCompleted" :key="jobrole.id" span=4 class="jobroles">
                <div @click="showCompleteProfileNotification" class="featured-card-link">
                  <featured-card-mobile @click="showCompleteProfileNotification" class="mobileonly" :title="jobrole.title" :text="jobrole.text" :detailTitle="jobrole.detailTitle" :detailText="jobrole.detailText"/>
                  <featured-card class="desktoponly" :title="jobrole.title" :text="jobrole.text" :detailTitle="jobrole.detailTitle" :detailText="jobrole.detailText"/>
                </div>
              </mdc-layout-cell>
              <mdc-layout-cell v-else-if="!jobrole.featured" :key="jobrole.id" span=4 class="jobroles">
                <router-link class="job-card-link" :to="{ name: 'JobDetail', params: {id: jobrole.id} }">
                  <job-role-card-mobile :likelyFlexFilter="filterFlex === 'Very Likely' || filterFlex === 'Likely'" hoverHigh showDesc hideShare class="mobileonly" @blocked="showCompleteProfileNotification" :jobrole="jobrole"></job-role-card-mobile>
                  <job-role-card :likelyFlexFilter="filterFlex === 'Very Likely' || filterFlex === 'Likely'" hoverHigh showDesc class="desktoponly" @blocked="showCompleteProfileNotification" :jobrole="jobrole"></job-role-card>
                </router-link>
              </mdc-layout-cell>
            </template>
          </mdc-layout-grid>
          <div style="display:none" class="additional">
            <span>See </span>
            <round-button class="inline-button" hasSlot @click="seeMoreJobs">More</round-button>
            <span>Job Vacancies... </span>
          </div>

        </section>
        -->

      </section>

      <section v-if="section === 'team'">

        <div class="status-row">
          <mdc-title class="section-title" style="margin-right:6px; white-space:nowrap">Today I'm</mdc-title>
          <div>
            <mdc-button @click="toggleStatusOptions" class="status-button" :class="statusClasses[profile.currentStatus]">
            <span slot="leading-icon">
              <i tabindex="-1" class="material-icons mdc-button__icon" aria-hidden="true">{{statusIcons[profile.currentStatus]}}</i>
            </span>
            <span class="status-button-content">{{statusText(profile.currentStatus)}}</span>
            <span slot="trailing-icon">
              <i tabindex="-1" class="material-icons mdc-button__icon" :class="{'rotate': statusOptionsOpen}" aria-hidden="true">arrow_drop_down</i>
            </span>
            </mdc-button>
            <div :class="{'hidden': !statusOptionsOpen }" class="status-button-dropdown-wrapper">
              <div :class="{'hidden': !statusOptionsOpen }" class="status-button-dropdown">
                <mdc-list  outlined interactive>
                  <mdc-list-item @click="statusSelect('Not Set')">
                    <span slot="leading-icon" class="not-set-icon material-icons mdc-list-item__graphic">close</span>
                    Not Set
                  </mdc-list-item>
                  <mdc-list-item @click="statusSelect('Remote')">
                    <span slot="leading-icon" class="remote-icon material-icons mdc-list-item__graphic">home_work</span>
                    Working Remotely
                  </mdc-list-item>
                  <mdc-list-item @click="statusSelect('Office')">
                    <span slot="leading-icon" class="office-icon material-icons mdc-list-item__graphic">business</span>
                    In the Office
                  </mdc-list-item>
                  <mdc-list-item @click="statusSelect('Parental Leave')">
                    <span slot="leading-icon" class="parental-leave-icon material-icons mdc-list-item__graphic">emoji_people</span>
                    Parental Leave
                  </mdc-list-item>
                  <mdc-list-item @click="statusSelect('Sabbatical')">
                    <span slot="leading-icon" class="sabbatical-icon material-icons mdc-list-item__graphic">emoji_people</span>
                    Sabbatical
                  </mdc-list-item>
                </mdc-list>
              </div>
            </div>
          </div>
        </div>

        <div class="social-interest-section">
          <mdc-body style="margin-top:16px;" typo="body2"> Are you Interested in:</mdc-body>

          <div class="interest-row">
            <div style="flex:1;position:relative;cursor:pointer" @click="addSocialGroup('Community')">
              <img class="highlight-img" style="max-height:100px;max-width:100px; width:100%" src="/static/img/highlights/community-alt.svg"/>
              <mdc-icon v-if="profile.socialGroups.includes('Community')" class="icon social-tick" icon="done"></mdc-icon>
              <mdc-body tag="span" typo="body2">Community Chat</mdc-body>
            </div>
            <div style="flex:1;position:relative;cursor:pointer" @click="addSocialGroup('Lunch & Learn')">
              <img class="highlight-img" style="max-height:100px;max-width:100px; width:100%" src="/static/img/highlights/webinar-alt.svg"/>
              <mdc-icon v-if="profile.socialGroups.includes('Lunch & Learn')" class="icon social-tick" icon="done"></mdc-icon>
              <mdc-body tag="span" typo="body2">Lunch &amp; Learn</mdc-body>
            </div>
            <div style="flex:1;position:relative;cursor:pointer" @click="addSocialGroup('Networking')">
              <img class="highlight-img" style="max-height:100px;max-width:100px; width:100%" src="/static/img/highlights/resource.svg"/>
              <mdc-icon v-if="profile.socialGroups.includes('Networking')" class="icon social-tick" icon="done"></mdc-icon>
              <mdc-body tag="span" typo="body2">Networking 1 to 1</mdc-body>
            </div>
          </div>
        </div>

        <mdc-title class="section-title" style="margin-bottom:0">Team Members</mdc-title>

        <mdc-body class="section-sub-title" style="margin-top:16px;" typo="body2" v-if="profilesFound === 0">No Team members found</mdc-body>

        <mdc-layout-grid>
          <template v-for="prof in teamProfiles">
            <mdc-layout-cell v-if="prof.id !== profile.id" :key="prof.id" span=4 class="profiles">
              <router-link class="profile-card-link" :to="{ name: 'User', params: {id: prof.id} }">
                <profile-card-mobile hoverHigh :showMore="false" showStatus hideShare class="mobileonly" @blocked="showCompleteProfileNotification" :profile="prof"></profile-card-mobile>
                <profile-card hoverHigh :showMore="false" showStatus :medium="true" hideShare class="desktoponly" @blocked="showCompleteProfileNotification" :profile="prof"></profile-card>
              </router-link>
            </mdc-layout-cell>
          </template>
        </mdc-layout-grid>
        <div v-if="loading">
          <div class="spinner" style="left: 0;margin-top: 0;">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
        <div v-else class="additional">
          <span v-if="loadProfilesVisible">
            <span>Load </span>
            <round-button class="inline-button" hasSlot @click="loadMoreProfiles">More</round-button>
          </span>
        </div>

        <mdc-title class="section-title" style="margin-right:12px; white-space:nowrap">Today I'm working on</mdc-title>
        <mdc-layout-grid>
          <mdc-layout-cell span=12>
            <message-section
                v-if="teamProfiles"
                :scrollBottom="false"
                :connectionId="connectionId"
                :connection="connection"
                :profile="profile"
                :profiles="teamProfiles"
                :user="user"
                :messages="messages">
              </message-section>
          </mdc-layout-cell>
        </mdc-layout-grid>

        <mdc-layout-grid>
          <mdc-layout-cell span=12>
            <app-section
              class="team-section"
              :editable="false"
              title="Team Schedule">
              <mdc-title>Plan the location and hours of the team for the next week.</mdc-title>
              <div style="overflow:auto; width:100%;">
                <img style="width:100%; max-width:1000px; min-width:750px;" src="/static/img/screen-mocks/remote-team.png">
              </div>
            </app-section>
          </mdc-layout-cell>
        </mdc-layout-grid>

      </section>

      <section v-if="section === 'activities'">

        <div v-for="(group, index) in socialGroups" :key="index">
          <mdc-title class="section-title" style="margin-bottom:0">Team Members interested in {{index}}</mdc-title>
          <mdc-layout-grid>
          <template v-for="event in events[index]">
            <mdc-layout-cell :key="event.id" span=4 class="profiles">
              <event-card :event="event"></event-card>
            </mdc-layout-cell>
          </template>
          <template v-for="prof in group">
            <mdc-layout-cell v-if="prof.id !== profile.id" :key="prof.id" span=4 class="profiles">
              <router-link class="profile-card-link" :to="{ name: 'User', params: {id: prof.id} }">
                <profile-card-mobile hoverHigh :showMore="false" showStatus hideShare class="mobileonly" @blocked="showCompleteProfileNotification" :profile="prof"></profile-card-mobile>
                <profile-card hoverHigh :showMore="false" showStatus :medium="true" hideShare class="desktoponly" @blocked="showCompleteProfileNotification" :profile="prof"></profile-card>
              </router-link>
            </mdc-layout-cell>
          </template>
        </mdc-layout-grid>
        </div>

      </section>

      <section v-if="section === 'partners' && company.assignedCompany && companyProfiles && (!company.assignedCompany.private || publicSubCompanies)">
        <mdc-title class="section-title" v-if="company.assignedSubCompany" style="margin-bottom:0">
          <span v-if="company.assignedSubCompany.private">{{company.assignedSubCompany.name}} Partner Profiles</span>
          <span v-else>{{company.assignedCompany.displayName}} Partner Profiles</span>
        </mdc-title>
        <mdc-title class="section-title" v-else-if="company.assignedCompany" style="margin-bottom:0">{{company.assignedCompany.displayName}} Partner Profiles</mdc-title>
        <mdc-body class="section-sub-title">People interested or working flexibly</mdc-body>

        <mdc-body class="section-sub-title" style="margin-top:16px;" typo="body2" v-if="companyProfilesFound === 0">Whoops, this search has no results, try something different.</mdc-body>

        <mdc-layout-grid>
          <mdc-layout-cell v-for="prof in companyProfiles" :key="prof.id" span=4 class="profiles">
            <router-link class="profile-card-link" :to="{ name: 'User', params: {id: prof.id} }">
              <profile-card-mobile hoverHigh hideShare class="mobileonly" @blocked="showCompleteProfileNotification" :profile="prof"></profile-card-mobile>
              <profile-card hoverHigh :medium="true" hideShare class="desktoponly" @blocked="showCompleteProfileNotification" :profile="prof"></profile-card>
            </router-link>
          </mdc-layout-cell>
        </mdc-layout-grid>
        <div v-if="loading">
          <div class="spinner" style="left: 0;margin-top: 0;">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
        <div v-else-if="company.assignedCompany" class="additional">
          <span>Load </span>
          <round-button class="inline-button" hasSlot @click="loadMoreCompanyProfiles">More</round-button>
        </div>
      </section>

      <section class="public-interested-section" v-if="section === 'partners' && companyProfilesOnly !== true">
        <mdc-title class="section-title" style="margin-bottom:0">Partner Profiles</mdc-title>
        <mdc-body style="display:flex;" class="section-sub-title">

        <span style="margin-right:6px;">Public users interested or working flexibly</span>
        <span style="width: 25px;height: 25px;">
          <hotspot
            class="partners-hotspot"
            @shown="updateHotspot('partners')"
            :visible="!profile.hotspotClicked.partners && (profile.hotspotSeen.partners <= 5 || !profile.hotspotSeen.partners)" :diameter="20">
            <round-button
              background="transparent"
              @mouseover.native="mouseOverTooltip"
              @click="showTooltip = true"
              noHover
              class="inline-button tooltip-icon"
              style="padding:0;color:#828282;height:32px;width:20px;min-width:20px;margin:0;"
              icon="help_outline"/>
          </hotspot>
        </span>
        <v-popover
          offset="1"
          delay="100"
          container='.public-interested-section'
          style="margin-left:-16px;"
          placement='bottom-start'
          :disabled="false"
          trigger="manual"
          :show="showTooltip"
          autoHide
          @show="trackDiscoverPartnerHelp"
          @hide="showTooltip = false"
        >

          <template slot="popover">
            <mdc-title>Partner Profiles</mdc-title>
            <div>
              <p>Finding someone to Job Share with before you apply for a role significantly increases the chances of success. Check out the potential partners below and connect to send them a message</p>
            </div>
            <div style="width:100%;text-align:right">
              <a class="mdc-button" @click="showTooltip = false"><mdc-icon style="height: 24px;" icon="clear"/> CLOSE</a>
            </div>
          </template>
        </v-popover>

        </mdc-body>

        <mdc-body class="section-sub-title" style="margin-top:16px;" typo="body2" v-if="publicProfilesFound === 0">Whoops, this search has no results, try something different.</mdc-body>

        <mdc-layout-grid>
          <template v-for="prof in publicProfiles">
            <mdc-layout-cell v-if="prof.featured && !profile.experienceCompleted" :key="prof.id" span=4 class="profiles">
              <div @click="showCompleteProfileNotification" class="featured-card-link">
                <featured-card-mobile @click="showCompleteProfileNotification" class="mobileonly" :title="prof.title" :text="prof.text" :detailTitle="prof.detailTitle" :detailText="prof.detailText"/>
                <featured-card class="desktoponly" :title="prof.title" :text="prof.text" :detailTitle="prof.detailTitle" :detailText="prof.detailText"/>
              </div>
            </mdc-layout-cell>
            <mdc-layout-cell v-else-if="!prof.featured" :key="prof.id" span=4 class="profiles">
              <router-link class="profile-card-link" :to="{ name: 'User', params: {id: prof.id} }">
                <profile-card-mobile hoverHigh hideShare class="mobileonly" @blocked="showCompleteProfileNotification" :profile="prof"></profile-card-mobile>
                <profile-card hoverHigh :medium="true" hideShare class="desktoponly" @blocked="showCompleteProfileNotification" :profile="prof"></profile-card>
              </router-link>
            </mdc-layout-cell>
          </template>
        </mdc-layout-grid>
        <div v-if="loading">
          <div class="spinner" style="left: 0;margin-top: 0;">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
        <div v-else class="additional">
          <span v-if="loadProfilesVisible">
            <span>Load </span>
            <round-button class="inline-button" hasSlot @click="loadMoreProfiles">More</round-button>
          </span>
        </div>
      </section>

      <section class="job-section" v-if="section === 'jobs' && profile && !jobsDisabled">
        <div style="display:flex">
          <mdc-title style="flex:1;margin-bottom:0;display:flex"  class="section-title">
            <span>Job Vacancies</span>
            <span style="display:flex;padding-left:6px" v-if="filterCompany">
              <span style="display:flex;" v-if="filterCompany.name">- {{filterCompany.name}}
              <round-button hasSlot style="margin-top:-2px;display:none;" high icon="add" v-if="!hasInterestCompany(filterCompany.name)"  @click="addInterestCompany(filterCompany.name)">Add Interest</round-button>
              <round-button hasSlot style="margin-top:-2px;display:none;" icon="done" v-else disabled>I'm Interested</round-button>
              </span>
            </span>
          </mdc-title>
          <round-button hasSlot
          style="padding-left:16px;padding-right:6px;margin-bottom:6px;margin-top:6px;"
          trailingIcon="filter_list"
          @click="filtersOpen = true"
          >
            Filters
          </round-button>
        </div>
        <mdc-body style="display:flex;" class="section-sub-title">
          <span style="margin-right:6px;">Look for positions to work flexibly</span>
            <span style="width: 25px;height: 25px;">
              <hotspot
                class="jobs-hotspot"
                @shown="updateHotspot('jobs')"
                :visible="!profile.hotspotClicked.jobs && (profile.hotspotSeen.jobs <= 5 || !profile.hotspotSeen.jobs)" :diameter="20">
                <round-button
                  background="transparent"
                  @mouseover.native="mouseOverTooltipJobs"
                  @click="showTooltipJobs = true"
                  noHover
                  class="inline-button tooltip-icon"
                  style="padding:0;color:#828282;height:32px;width:20px;min-width:20px;margin:0;"
                  icon="help_outline"/>
              </hotspot>
            </span>
            <v-popover
              offset="1"
              delay="100"
              container='.job-section'
              style="margin-left:-16px;"
              placement='bottom-end'
              :disabled="false"
              trigger="manual"
              :open="showTooltipJobs"
              @show="trackDiscoverJobsHelp"
              @hide="showTooltipJobs = false"
              autoHide
            >
              <template slot="popover">
                <mdc-title>Work Flexibly</mdc-title>
                <div>
                  <p>Jobs supporting flexibility offer more control over where, when and how you work. We highlight the section of jobs that mentions flexibility.</p>
                </div>
                <div style="width:100%;text-align:right">
                  <a class="mdc-button" @click="showTooltipJobs = false"><mdc-icon style="height: 24px;" icon="clear"/> CLOSE</a>
                </div>
              </template>
            </v-popover>
        </mdc-body>

          <app-dialog
            class="filter-modal fullsize-mobile"
            style="z-index:11"
            accept="Ok"
            @accept="filterJobs"
            cancel=""
            v-model="filtersOpen"
            title="Filters"
            >

            <div style="padding-top:16px;">

              <div class="profile-section">
                <h4>Flex Job Filter</h4>
                <mdc-body style="font-style:italic;margin-top:0;margin-bottom:0" typo="body2">
                  <span>Filter the jobs for how likely they are to offer flexibility as part of the role</span>
                </mdc-body>
                <option-slider modal :value="filterFlex" @change="setFlexFilter" style="margin-bottom:48px;" :options="['No Filter','Likely','Very Likely']" :optionLabels="{'No Filter': 'All Jobs','Likely': 'Less Jobs', 'Very Likely': 'Least Jobs'}" />
              </div>

              <div class="profile-section">
                <div class="toggle-row">
                  <h4>Interested Partners</h4>
                  <mdc-switch v-model="filterInterested" style="z-index:9;display:flex">
                    <span style="padding-left:24px;" v-if="filterInterested">
                      On
                    </span>
                    <span style="padding-left:24px;" v-else>
                      Off
                    </span>
                  </mdc-switch>
                </div>
                <mdc-body style="font-style:italic;margin-top:0;margin-bottom:24px;" typo="body2">Only show jobs with interested partners</mdc-body>
              </div>

              <div class="profile-section">
                <h4>Location</h4>
                <mdc-body style="font-style:italic;margin-top:0;margin-bottom:6px;" typo="body2">Show jobs that are near these city locations</mdc-body>

                <auto-complete-outline
                  autocompleteId="'search'"
                  class="searchbar_field"
                  v-model="filterLocation"
                  field="name"
                  placeholder="Nearest City"
                  :items="locations"
                  :min="1"
                  @set="onSetLocation"
                  dense>
                  <img style="height: 26px; padding-top: 16px; margin-left: 6px;" slot="leading-icon" src="/static/img/icons/location_outline_light.svg"/>
                </auto-complete-outline>
              </div>

              <round-button noHover @click="clearAllFilters" hasSlot style="text-transform:none;margin-left:-16px;padding-left:16px;">Clear all filters</round-button>

            </div>
          </app-dialog>

        <div class="section-sub-title sub-label" v-if="jobsfound">
          <mdc-body typo="body2" style="margin-top:0" v-if="jobsfound >= 1"><span class="not-mobile">Showing</span> {{jobs.length}} of {{jobsfound}} results for
            <span v-if="!filterCompany && query">" {{query}} "</span>
            <span v-else-if="!filterCompany">" {{defaultJobsQueryDisplay}} "</span>
            <span v-else>" {{filterCompany.name}} {{this.query}} "</span>
          </mdc-body>
        </div>

        <mdc-body class="section-sub-title" style="margin-top:16px;" typo="body2" v-if="jobsfound === 0">Whoops, this search
          <span v-if="!filterCompany && query">" {{query}} "</span>
          <span v-else-if="!filterCompany">" {{defaultJobsQueryDisplay}} "</span>
          <span v-else>" {{filterCompany.name}} {{this.query}} "</span>
           has no results, try something different.
          <span v-if="extraMessage" style="margin-left:16px;" class="sub-label"> ({{extraMessage}})</span>
        </mdc-body>

        <mdc-layout-grid>
          <template v-for="jobrole in jobs">
            <mdc-layout-cell v-if="jobrole.featured && !profile.experienceCompleted" :key="jobrole.id" span=4 class="jobroles">
              <div @click="showCompleteProfileNotification" class="featured-card-link">
                <featured-card-mobile @click="showCompleteProfileNotification" class="mobileonly" :title="jobrole.title" :text="jobrole.text" :detailTitle="jobrole.detailTitle" :detailText="jobrole.detailText"/>
                <featured-card class="desktoponly" :title="jobrole.title" :text="jobrole.text" :detailTitle="jobrole.detailTitle" :detailText="jobrole.detailText"/>
              </div>
            </mdc-layout-cell>
            <mdc-layout-cell v-else-if="!jobrole.featured" :key="jobrole.id" span=4 class="jobroles">
              <router-link class="job-card-link" :to="{ name: 'JobDetail', params: {id: jobrole.id} }">
                <job-role-card-mobile :likelyFlexFilter="filterFlex === 'Very Likely' || filterFlex === 'Likely'" hoverHigh showDesc hideShare class="mobileonly" @blocked="showCompleteProfileNotification" :jobrole="jobrole"></job-role-card-mobile>
                <job-role-card :likelyFlexFilter="filterFlex === 'Very Likely' || filterFlex === 'Likely'" hoverHigh hideShare showDesc class="desktoponly" @blocked="showCompleteProfileNotification" :jobrole="jobrole"></job-role-card>
              </router-link>
            </mdc-layout-cell>
          </template>
        </mdc-layout-grid>
        <div v-if="loading">
          <div class="spinner" style="left: 0;margin-top: 0;">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
        <div v-else class="additional">
          <span v-if="loadJobsVisible && jobsfound > jobs.length">
            <span>Load </span>
            <round-button class="inline-button" hasSlot @click="loadMoreJobs">More</round-button>
          </span>
        </div>
        <!--
        <div v-if="!filterCompany && jobs.length <= rows" class="section-interest">
        <mdc-title class="section-title" style="margin-bottom:0"> Jobs with Interest</mdc-title>
        <mdc-body style="display:flex;" class="section-sub-title">
          <span style="margin-right:6px;">Connect to Job Share partners via roles you like</span>
          <span style="width: 25px;height: 25px;">
            <hotspot
              class="jobs-hotspot"
              @shown="updateHotspot('jobsInterest')"
              :visible="!profile.hotspotClicked.jobsInterest && (profile.hotspotSeen.jobsInterest <= 5 || !profile.hotspotSeen.jobsInterest)" :diameter="20">
              <round-button
                background="transparent"
                @mouseover.native="mouseOverTooltipJobsInterest"
                @click="showTooltipJobsInterest = true"
                noHover
                class="inline-button tooltip-icon"
                style="padding:0;color:#828282;height:32px;width:20px;min-width:20px;margin:0;"
                icon="help_outline"/>
            </hotspot>
          </span>
          <v-popover
            offset="1"
            delay="100"
            container='.section-interest'
            style="margin-left:-16px;"
            placement='bottom-end'
            :disabled="false"
            trigger="manual"
            :open="showTooltipJobsInterest"
            @show="markDiscoverJobsInterest"
            @hide="showTooltipJobsInterest = false"
            autoHide
          >
            <template slot="popover">
              <mdc-title>Jobs with Interested Partners</mdc-title>
              <div>
                <p>Mark your interest in these jobs to reveal other interested partners and get notified when more join.</p>
              </div>
              <div style="width:100%;text-align:right">
                <a class="mdc-button" @click="showTooltipJobsInterest = false"><mdc-icon style="height: 24px;" icon="clear"/> CLOSE</a>
              </div>
            </template>
          </v-popover>

        </mdc-body>

        <mdc-layout-grid>
          <template v-for="jobrole in jobsInterest">
            <mdc-layout-cell v-if="jobrole.featured && !profile.experienceCompleted" :key="jobrole.id" span=4 class="jobroles">
              <div @click="showCompleteProfileNotification" class="featured-card-link">
                <featured-card-mobile @click="showCompleteProfileNotification" class="mobileonly" :title="jobrole.title" :text="jobrole.text" :detailTitle="jobrole.detailTitle" :detailText="jobrole.detailText"/>
                <featured-card class="desktoponly" :title="jobrole.title" :text="jobrole.text" :detailTitle="jobrole.detailTitle" :detailText="jobrole.detailText"/>
              </div>
            </mdc-layout-cell>
            <mdc-layout-cell v-else-if="!jobrole.featured" :key="jobrole.id" span=4 class="jobroles">
              <router-link class="job-card-link" :to="{ name: 'JobDetail', params: {id: jobrole.id} }">
                <job-role-card-mobile :likelyFlexFilter="filterFlex === 'Very Likely' || filterFlex === 'Likely'" hoverHigh showDesc hideShare class="mobileonly" @blocked="showCompleteProfileNotification" :jobrole="jobrole"></job-role-card-mobile>
                <job-role-card :likelyFlexFilter="filterFlex === 'Very Likely' || filterFlex === 'Likely'" hoverHigh showDesc class="desktoponly" @blocked="showCompleteProfileNotification" :jobrole="jobrole"></job-role-card>
              </router-link>
            </mdc-layout-cell>
          </template>
        </mdc-layout-grid>
        </div>
        -->
      </section>

      <section v-if="section === 'bookmarked'">
        <mdc-title class="section-title" style="margin-bottom:0">Bookmarks</mdc-title>
        <mdc-body typo="body2" class="section-sub-title" v-if="bookmarksfound === 0"><span>Wow this is empty, click the star </span><mdc-icon style="top: 6px;position: relative;margin-right: 4px;" outlined icon="star_outline"/><span>on any card to save it here</span></mdc-body>
        <mdc-layout-grid>
          <mdc-layout-cell v-for="bookmark in bookmarks" :key="bookmark.id" span=4 class="profiles">
            <div v-if="bookmark.type === 'profile'" >
              <router-link class="profile-card-link" :to="{ name: 'User', params: {id: bookmark.id} }">
                <profile-card-mobile hoverHigh hideShare class="mobileonly" @blocked="showCompleteProfileNotification" :profile="bookmark"></profile-card-mobile>
                <profile-card hoverHigh :medium="true" hideShare class="desktoponly" @blocked="showCompleteProfileNotification" :profile="bookmark"></profile-card>
              </router-link>
            </div>
            <div v-if="bookmark.type === 'job'">
              <router-link class="job-card-link" :to="{ name: 'JobDetail', params: {id: bookmark.id} }">
                <job-role-card-mobile hoverHigh showDesc hideShare class="mobileonly" @blocked="showCompleteProfileNotification" :jobrole="bookmark"></job-role-card-mobile>
                <job-role-card hoverHigh hideShare showDesc class="desktoponly" @blocked="showCompleteProfileNotification" :jobrole="bookmark"></job-role-card>
              </router-link>
            </div>
          </mdc-layout-cell>
        </mdc-layout-grid>
        <div v-if="loading">
          <div class="spinner" style="left: 0;margin-top: 0;">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
      </section>
      <first-login-teams v-if="teamMode" @close="loadPageItems" required :firstModalOpen="firstOpen"></first-login-teams>
      <first-login-remote v-else-if="remote" @close="loadPageItems" required :firstModalOpen="firstOpen"></first-login-remote>
      <first-login v-else @close="loadPageItems" required :firstModalOpen="firstOpen"></first-login>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ProfileCardMobile from '../components/profile-v2/ProfileCardMobile.vue'
import ProfileCard from '../components/profile-v2/ProfileCard.vue'
import JobRoleCardMobile from '../components/profile-v2/JobRoleCardMobile.vue'
import JobRoleCard from '../components/profile-v2/JobRoleCard.vue'
import EventCard from '../components/profile-v2/EventCard.vue'
import FeaturedCard from '../components/profile-v2/FeaturedCard.vue'
import FeaturedCardMobile from '../components/profile-v2/FeaturedCardMobile.vue'
import OptionSlider from '../components/OptionSlider.vue'
import HighlightSection from '../components/profile-v2/HighlightSection.vue'
import CompanyFeatureSection from '../components/CompanyFeatureSection.vue'
import FirstLogin from '../components/FirstLogin.vue'
import FirstLoginRemote from '../components/FirstLoginRemote.vue'
import FirstLoginTeams from '../components/FirstLoginTeams.vue'
import AppDialog from '../components/AppDialog'
import Hotspot from '../components/Hotspot'
import AutoCompleteOutline from '../components/AutoCompleteOutline.vue'
import MessageSection from '../components/MessageSection.vue'
import AppSection from '../components/profile-v2/AppSection.vue'
import * as backend from '../utils/backend'
import { flexFilter, strongFlexFilter } from '../utils/filters'

export default {
  name: 'Discover',
  components: {
    ProfileCard,
    ProfileCardMobile,
    JobRoleCard,
    JobRoleCardMobile,
    EventCard,
    FeaturedCard,
    FeaturedCardMobile,
    HighlightSection,
    FirstLogin,
    FirstLoginRemote,
    FirstLoginTeams,
    AppDialog,
    AutoCompleteOutline,
    OptionSlider,
    CompanyFeatureSection,
    Hotspot,
    MessageSection,
    AppSection
  },
  props: {
    query: {
      type: String
    },
    search: {
      type: String
    },
    isMobile: {
      type: Boolean
    },
    isTablet: {
      type: Boolean
    },
    isDesktopLarge: {
      type: Boolean
    },
    isTabletLarge: {
      type: Boolean
    }
  },
  data () {
    return {
      listSize: 8,
      title: 'Discover',
      section: 'aggregated',
      showTooltip: false,
      showTooltipJobs: false,
      showTooltipJobsInterest: false,
      pageActions: ['all', 'team', 'activities', 'partners', 'jobs', 'bookmarked'],
      links: [
        {
          title: 'All',
          icon: 'done_all',
          action: 'aggregated',
          scrollTop: true
        },
        {
          title: 'Partners',
          svgicon: 'account',
          action: 'partners',
          scrollTop: true
        },
        {
          title: 'Jobs',
          svgicon: 'work',
          action: 'jobs',
          scrollTop: true,
          filter: 'jobsEnabled'
        },
        {
          title: 'Bookmarked',
          icon: 'star_outline',
          action: 'bookmarked',
          scrollTop: true
        }
      ],
      statusOptionsOpen: false,
      statusIcons: {
        'Not Set': 'close',
        Remote: 'home_work',
        Office: 'business',
        'Parental Leave': 'emoji_people',
        Sabbatical: 'emoji_people'
      },
      statusClasses: {
        'Not Set': 'not-set',
        Remote: 'remote',
        Office: 'office',
        'Parental Leave': 'parental-leave',
        Sabbatical: 'sabbatical'
      },
      statusOrder: {
        'Not Set': 5,
        Office: 2,
        Remote: 1,
        Sabbatical: 3,
        'Parental Leave': 4
      },
      events: {
        Community: [
        ],
        Networking: [
          {
            bannerText: 'Networking',
            title: 'Speed Networking',
            location: 'London',
            date: 'Tomorrow 12pm',
            interest: '17 people interested',
            description: 'Speed Networking event where you get 2 minutes each to introduce yourself and what you do.'
          }
        ],
        'Lunch & Learn': [
          {
            bannerText: 'Lunch Event',
            title: 'Remote Communication',
            location: 'Remote',
            date: 'Today 7pm',
            interest: '12 people interested',
            description: 'Lunch video session - learn more about remote communication'
          }
        ]
      },
      completed: 1,
      profileNotification: false,
      firstOpen: false,
      jobsfound: null,
      profilesFound: null,
      teamProfiles: [],
      publicProfilesFound: null,
      companyProfilesFound: null,
      jobs: [],
      jobsInterest: [],
      publicProfiles: [],
      companyProfiles: [],
      bookmarks: [],
      bookmarksfound: null,
      jobFacets: {},
      itemsQuery: '',
      companyPartnersDefaultQuery: '',
      companyPartnerQuery: '',
      publicPartnersDefaultQuery: '',
      publicPartnerQuery: '',
      companyDefaultQuery: '',
      jobsDefaultQuery: '',
      jobsDefaultQueryWithoutInterest: '',
      jobsDefaultQueryLocCat: '',
      jobsQuery: '',
      rows: 16,
      startProfiles: 0,
      startCompanyProfiles: 0,
      startJobs: 0,
      loadJobsVisible: true,
      loadProfilesVisible: true,
      loadCompanyProfilesVisible: true,
      loading: false,
      postSearchDisabled: true,
      filtersOpen: false,
      filterInterested: false,
      filterFlex: 'Very Likely',
      filterLocation: '',
      filterLocationLat: null,
      filterLocationLon: null,
      filterCompany: null,
      defaultJobsQueryDisplay: '',
      extraMessage: '',
      connectionId: 'dummy001',
      connection: {
        id: 'dummy001'
      },
      messages: [
      ],
      socialGroups: {
      }
    }
  },
  methods: {
    ...mapActions([
      'setAction',
      'updateProfile',
      'setPageLoad',
      'setFilters'
    ]),
    statusSelect (val) {
      this.statusOptionsOpen = false
      this.profile.currentStatus = val
      this.updateProfile({ profile: { currentStatus: this.profile.currentStatus }, noSnack: true })
      const self = this
      setTimeout(function () {
        self.loadPageItems()
      }, 1000)
    },
    addSocialGroup (val) {
      if (!this.profile.socialGroups) {
        this.profile.socialGroups = []
      }
      if (!this.profile.socialGroups.includes(val)) {
        this.profile.socialGroups.push(val)
        this.updateProfile({ profile: { socialGroups: this.profile.socialGroups }, noSnack: true })
        const self = this
        setTimeout(function () {
          self.loadPageItems()
        }, 1000)
      }
    },
    toggleStatusOptions () {
      this.statusOptionsOpen = !this.statusOptionsOpen
    },
    statusText (status) {
      if (status === 'Not Set') {
        return 'Not Set'
      } else if (status === 'Remote') {
        return 'working remotely'
      } else if (status === 'Office') {
        return 'in the office'
      } else if (status === 'Parental Leave') {
        return 'on parental leave'
      } else if (status === 'Sabbatical') {
        return 'on sabbatical'
      }
      return ''
    },
    hasInterestCompany (company) {
      if (this.profile.interest) {
        return this.profile.interest.includes(company)
      }
      return false
    },
    addInterestCompany (company) {
      if (!this.profile.interest) {
        this.profile.interest = []
      }
      if (!this.profile.interest.includes(company)) {
        this.profile.interest.push(company)
        this.updateProfile({ profile: { interest: this.profile.interest }, noSnack: true })
      }
    },
    loadMoreCheck (items) {
      if (this.assignedCompany) {
        if (this.assignedCompany.skipProfileCompleteCheck) {
          return true
        }
      }
      if (this.completed < 3) {
        // default > rows (allow one load more)
        if (items.length > this.rows) {
          return false
        }
        // search (on first load more)
        if (this.query) {
          return false
        }
      }
      return true
    },
    calcCompleted () {
      if (this.profile) {
        this.completed = 1
        if (this.profile.basicCompleted) {
          this.basic = true
          this.completed++
        }
        if (this.profile.experienceCompleted) {
          this.experience = true
          this.completed++
        }
      }
    },
    setFlexFilter (val) {
      // console.log('setFlexFilter', val)
      this.filterFlex = val
    },
    onSetLocation (val, result) {
      if (val) {
        if (result) {
          this.filterLocation = val.name
          this.filterLocationLat = result.lat
          this.filterLocationLon = result.lon
        }
      }
    },
    applyCompanyFilter (company) {
      this.filterCompany = company
      this.setFilters({
        filterInterested: this.filterInterested,
        filterFlex: this.filterFlex,
        filterLocation: this.filterLocation,
        filterLocationLat: this.filterLocationLat,
        filterLocationLon: this.filterLocationLon,
        filterCompany: this.filterCompany
      })
      this.$root.$emit('setSearchQuery', '')
      this.$root.$emit('setSearchFilter', this.filterCompany.name)
      // console.log('V2 Dash.applyCompanyFilter loadPageItems')
      this.loadPageItems()
    },
    clearFilter (filter) {
      if (filter === 'jobs') {
        this.filterCompany = null
        // console.log('V2 Dash.clearFilter loadPageItems')
        this.loadPageItems('jobs')
      }
    },
    clearAllFilters () {
      this.filterInterested = false
      this.filterFlex = 'Very Likely'
      this.filterLocation = ''
      this.filterLocationLat = null
      this.filterLocationLon = null
    },
    filterJobs () {
      this.filtersOpen = false
      this.setFilters({
        filterInterested: this.filterInterested,
        filterFlex: this.filterFlex,
        filterLocation: this.filterLocation,
        filterLocationLat: this.filterLocationLat,
        filterLocationLon: this.filterLocationLon,
        filterCompany: this.filterCompany
      })
      // console.log('V2 Dash.filterJobs loadPageItems')
      this.loadPageItems('jobs')
    },
    seeMoreJobs () {
      this.setAction('jobs')
      this.loadMoreJobs()
    },
    seeMorePartners () {
      this.setAction('partners')
      this.loadMoreProfiles()
    },
    loadMore (itemType) {
      // console.log('V2 Dash.loadMore', itemType)
      if (itemType === 'profiles') {
        this.loadMoreProfiles()
      } else if (itemType === 'jobs') {
        this.loadMoreJobs()
      }
    },
    async setSection (section) {
      // console.log('V2 Dash.setSection', section)
      this.section = section
      if (section === 'bookmarked') {
        this.loadBookmarks()
      }
    },
    async showSection (section) {
      // console.log('V2 Dash.showSection', section)
      this.$emit('filterSearch', section)
      this.section = section
      if (section !== 'jobs') {
        this.$root.$emit('setSearchFilter', '')
        if (this.filterCompany) {
          if (this.jobs) {
            this.clearFilter('jobs')
          }
        }
      }

      if (section === 'bookmarked') {
        this.loadBookmarks()
      }

      if (section === 'jobs') {
        // console.log('V2 Dash.showSection.jobs - query:', this.query, ' default:', this.defaultJobsQueryDisplay)
        if (!this.query || this.query === '') {
          if (!this.filterCompany) {
            // this.$root.$emit('setSearchQuery', this.defaultJobsQueryDisplay)
          } else {
            // this.$root.$emit('setSearchQuery', '')
          }
        }
      } else {
        if (!this.query || this.query === '') {
          this.$root.$emit('setSearchQuery', '')
        }
      }
    },
    gotoProfile: function () {
      this.$router.push('/user/' + this.profile.id)
    },
    showCompleteProfileNotification: function () {
      this.$emit('openProfileTasks')
    },
    async getJobs (ids) {
      const idsStr = ids.join(' ')
      // console.log('V2 Dash.getJobs', `q=id:(${idsStr})`)
      const job = await this.jobsSearch(`q=id:(${idsStr})`).then(results => {
        return results
      })
      return job
    },
    async getProfiles (ids) {
      const idsStr = '"' + ids.join('" "') + '"'
      // console.log('V2 Dash.getProfiles', `q=id:(${idsStr})`)
      const prof = await this.profilesSearch(`q=id:(${idsStr})`).then(results => {
        return results
      })
      return prof
    },
    getProfile (id) {
      return this.profiles.filter((profile) => profile.id === id)[0]
    },
    handleResize () {
      if (window.innerWidth < 450) {
        this.listSize = 4
      } else {
        this.listSize = 8
      }
    },
    handleScroll () {
      if (this.isMobile) {
        if (this.showTooltip) {
          this.showTooltip = false
        }
      }
    },
    async jobsSearch (query) {
      const res = await backend.search('jobs', {
        query: { query }
      })
      if (res.error) {
        // console.log('V2 Dash.jobsSearch.error', query, res, res.error)
        return
      }
      const result = res.result.response
      if (res.result.facet_counts) {
        result.facets = {}
        for (const facetField in res.result.facet_counts.facet_fields) {
          let facetsArray = res.result.facet_counts.facet_fields[facetField]
          const facetsObj = {}
          facetsArray = facetsArray.map((x, i) => i % 2 === 0 && facetsArray.slice(i, i + 2)).filter(x => x)
          facetsArray.forEach(function (data) {
            facetsObj[data[0]] = data[1]
          })
          result.facets[facetField] = facetsObj
        }
      }
      // console.log('V2 Dash.jobsSearch', query, res)
      return result
    },
    async profilesSearch (query, fq) {
      const res = await backend.search('profiles', {
        query: { query, fq }
      })
      if (res.error) {
        // console.log('V2 Dash.profilesSearch.error', query, res, res.error)
        return
      }
      const result = res.result.response
      // console.log('V2 Dash.profilesSearch', query, res)
      return result
    },
    finishLoad () {
      setTimeout(() => {
        this.setPageLoad(false)
      }, 300)
    },
    loadMoreJobs () {
      const self = this
      this.loading = true
      if (this.loadMoreCheck(this.jobs)) {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer)
        }
        this.searchTimer = setTimeout(() => {
          const jobSearch = async () => {
            self.startJobs = self.startJobs + self.rows
            var query = `start=${self.startJobs}&${this.jobsQuery}`
            // console.log('V2 Dash.loadMoreJobs', query)
            const res = await backend.search('jobs', {
              query: { query }
            })
            if (res.error) {
              // console.log('V2 Dash.loadMoreJobs.error', res.error)
              return
            }
            const result = res.result
            const featured2 = {
              id: 'featured2',
              featured: true,
              title: 'Work Flexibly',
              detailTitle: 'Find Flexible Jobs',
              detailText: 'Complete your profile to add interest in flexible jobs'
            }
            self.jobs = [...self.jobs, ...result.response.docs]

            this.jobsfound = result.response.numFound
            const jobslength = self.jobs ? self.jobs.length : 0
            // console.log('loadPageItems.jobs', result, this.jobsfound, jobslength)
            if (this.jobsfound > jobslength) {
              self.loadJobsVisible = true
            } else {
              self.loadJobsVisible = false
            }

            if (this.completed < 3) {
              if (this.jobs.length < 23) {
                this.jobs = [...this.jobs, featured2]
              } else {
                this.jobs.splice(22, 1, featured2)
              }
            }
          }
          jobSearch().then(() => {
            self.loading = false
          })
        }, 100)
      } else {
        this.showCompleteProfileNotification()
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    },
    loadMoreProfiles () {
      const self = this
      this.loading = true
      if (this.loadMoreCheck(this.publicProfiles)) {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer)
        }
        this.searchTimer = setTimeout(() => {
          const profilesSearch = async () => {
            self.startProfiles = self.startProfiles + self.rows
            var query = `start=${self.startProfiles}&${this.publicPartnerQuery}`
            const res = await backend.search('profiles', {
              query: { query }
            })
            if (res.error) {
              // console.log('V2 Dash.loadMoreProfiles.error', res.error)
              return
            }
            const result = res.result
            const featured2 = {
              id: 'featured2',
              featured: true,
              title: 'Work Flexibly',
              detailTitle: 'Find Job Share Partners',
              detailText: 'Complete your profile to connect with others'
            }
            self.publicProfiles = [...self.publicProfiles, ...result.response.docs]

            if (result.response.numFound <= self.publicProfiles.length) {
              self.loadProfilesVisible = false
            } else {
              self.loadProfilesVisible = true
            }

            // console.log('loadMoreProfiles:', this.completed, this.publicProfiles.length)
            if (this.completed < 3) {
              if (this.publicProfiles.length < 23) {
                this.publicProfiles = [...this.publicProfiles, featured2]
              } else {
                this.publicProfiles.splice(22, 1, featured2)
              }
            }
          }
          profilesSearch().then(() => {
            self.loading = false
          })
        }, 100)
      } else {
        this.showCompleteProfileNotification()
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    },
    loadMoreCompanyProfiles () {
      const self = this
      this.loading = true
      if (this.loadMoreCheck(this.companyProfiles)) {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer)
        }
        this.searchTimer = setTimeout(() => {
          const profilesSearch = async () => {
            self.startCompanyProfiles = self.startCompanyProfiles + self.rows
            var query = `start=${self.startCompanyProfiles}&${this.companyPartnerQuery}`
            const res = await backend.search('profiles', {
              query: { query }
            })
            if (res.error) {
              // console.log('V2 Dash.loadMoreCompanyProfiles.error', res.error)
              return
            }
            const result = res.result
            self.companyProfiles = [...self.companyProfiles, ...result.response.docs]
            if (result.response.numFound <= self.companyProfiles.length) {
              self.loadCompanyProfilesVisible = false
            } else {
              self.loadCompanyProfilesVisible = true
            }
          }
          profilesSearch().then(() => {
            self.loading = false
          })
        }, 100)
      } else {
        this.showCompleteProfileNotification()
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    },
    async loadBookmarks () {
      this.loading = true
      const bookmarks = []
      this.bookmarksfound = null
      if (this.profile.bookmarks) {
        const jobIds = []
        const profileIds = []
        for (const [id, bookmark] of Object.entries(this.profile.bookmarks)) {
          if (bookmark.type === 'profile') {
            profileIds.push(id)
          } else if (bookmark.type === 'job') {
            jobIds.push(id)
          }
        }
        const promises = []
        if (profileIds.length > 0) {
          promises.push(await this.getProfiles(profileIds).then((profiles) => {
            if (profiles.docs) {
              for (const prof of profiles.docs) {
                prof.type = 'profile'
                bookmarks.push(prof)
              }
            }
          }))
        }
        if (jobIds.length > 0) {
          promises.push(await this.getJobs(jobIds).then((jobs) => {
            if (jobs.docs) {
              for (const job of jobs.docs) {
                job.type = 'job'
                bookmarks.push(job)
              }
            }
          }))
        }
        await Promise.all(promises).then(() => {
          this.bookmarks = bookmarks
          this.bookmarksfound = this.bookmarks.length
          this.loading = false
        })
      }
    },
    getDistanceQuery () {
      if (this.filterLocationLat && this.filterLocationLon) {
        return `&fq={!geofilt}&sfield=latlong&pt=${this.filterLocationLat},${this.filterLocationLon}&d=2500&bf=recip(geodist(),2,200,20)&sort=has_interest+desc,geodist()+asc,date+desc,score+desc`
      } else if (this.profile.locationLat && this.profile.locationLon) {
        return `&fq={!geofilt}&sfield=latlong&pt=${this.profile.locationLat},${this.profile.locationLon}&d=2500&bf=recip(geodist(),2,200,20)&sort=has_interest+desc,geodist()+asc,date+desc,score+desc`
      }
      return ''
    },
    getJobsSort () {
      return '&sort=has_interest+desc,date+desc,score+desc'
    },
    getDistanceQueryPartners () {
      if (this.filterLocationLat && this.filterLocationLon) {
        return `&fq={!geofilt}&sfield=latlong&pt=${this.filterLocationLat},${this.filterLocationLon}&d=2500&bf=recip(geodist(),2,200,20)&sort=geodist()+asc,score+desc`
      } else if (this.profile.locationLat && this.profile.locationLon) {
        return `&fq={!geofilt}&sfield=latlong&pt=${this.profile.locationLat},${this.profile.locationLon}&d=2500&bf=recip(geodist(),2,200,20)&sort=geodist()+asc,score+desc`
      }
      return ''
    },
    getFilterQuery (withoutInterest) {
      let query = ''
      if (this.filterFlex === 'Very Likely') {
        const flexFilterStrongQuery = '"' + strongFlexFilter.join('" "') + '"'
        query += ` AND (${flexFilterStrongQuery})`
      } else if (this.filterFlex === 'Likely') {
        const flexFilterQuery = '"' + flexFilter.join('" "') + '"'
        query += ` AND (${flexFilterQuery})`
      }

      if (this.filterInterested && !withoutInterest) {
        query += ' AND (has_interest:1)'
      }

      query += this.getDistanceQuery()

      return query
    },
    getJobsQuery () {
      var defaultFilters = ''
      var dynamicFilters = ''
      var defaultJobsQueryDisplay = ''
      this.jobsDefaultQueryLocCat = ''
      const filterQuery = this.getFilterQuery()

      if (this.profile.industry) {
        const industry = this.profile.industry.replace('/', ' ')
        this.jobsDefaultQueryLocCat = `rows=${this.rows}&q=(_category:"${industry}")`
      }
      this.jobsDefaultQueryLocCat += this.getDistanceQuery()

      if (this.internalJobsOnly) {
        this.filterCompany = this.assignedCompany.name
      }

      if (this.filterCompany) {
        defaultFilters += '*'
        dynamicFilters = `AND (company:"${this.filterCompany.name}") ` + this.getJobsSort()
        this.jobsDefaultQueryWithoutInterest = `${defaultFilters} ${dynamicFilters} `
      } else {
        if (this.profile.industry) {
          const industry = this.profile.industry.replace('/', ' ')
          defaultFilters = `(_category:"${industry}")`
          // defaultJobsQueryDisplay += `${industry}`
        }
        if (this.profile.role) {
          if (defaultFilters !== '') {
            defaultFilters += ' OR '
          }
          defaultFilters += `(_title:"${this.profile.role.replace('&', '')}")^3`
          defaultJobsQueryDisplay = `${this.profile.role.replace('&', '')} ` + defaultJobsQueryDisplay
        }
        if (!this.profile.role && !this.profile.industry) {
          defaultFilters += '*'
        }
        this.defaultJobsQueryDisplay = defaultJobsQueryDisplay
        dynamicFilters = filterQuery
        const withoutInterest = true
        this.jobsDefaultQueryWithoutInterest = `${defaultFilters} ${this.getFilterQuery(withoutInterest)} `
      }

      this.jobsDefaultQuery = `${defaultFilters} ${dynamicFilters} `
      var jobSearchQuery = `rows=${this.rows}&q=${this.jobsDefaultQuery}`

      if (this.query) {
        jobSearchQuery = `rows=${this.rows}&q=(_title:"${this.query}")^3 ${dynamicFilters}`
      }
      return jobSearchQuery
    },
    loadJobsFacets () {
      this.jobsSearch('facet.field=company&facet=on&q=*%20AND%20-(company%3A"CV-Library")').then(result => {
        if (result) {
          this.jobFacets = result.facets.company
          for (const company in this.featuredCompanies) {
            if (this.jobFacets[company]) {
              this.$set(this.featuredCompanies[company], 'jobsOpen', this.jobFacets[company])
            }
          }
        }
      })
    },
    loadRemoteItems (section) {
      // console.log('V2 Dash.loadRemoteItems', section)

      this.teamProfiles = []
      this.socialGroups = {}
      this.messages = []

      this.calcCompleted()

      const teamSearchQuery = `rows=${this.rows}&q=managerEmail:${this.profile.managerEmail}`
      // const fq = `-id:${this.profile.id}`
      const fq = ''

      this.profilesSearch(teamSearchQuery, fq).then(result => {
        if (result) {
          this.teamProfiles = result.docs.sort((a, b) => (this.statusOrder[a.currentStatus] > this.statusOrder[b.currentStatus]) ? 1 : -1)
          this.connection.users = this.teamProfiles

          for (const [index, member] of this.teamProfiles.entries()) {
            if (member.socialGroups) {
              for (const group of member.socialGroups) {
                if (this.socialGroups[group]) {
                  this.socialGroups[group].push(member)
                } else {
                  this.socialGroups[group] = [member]
                }
              }
            }
            this.messages.push(
              {
                id: `msg${index}`,
                created: {
                  toDate () { return new Date() }
                },
                text: ' - my daily task A \n - my daily task B \n -  my daily task C',
                fromId: member.id,
                origin: member.id
              }
            )
          }

          this.profilesFound = result.numFound
          if (result.numFound <= this.teamProfiles.length) {
            this.loadProfilesVisible = false
          } else {
            this.loadProfilesVisible = true
          }
        }
      })
    },
    loadPageItems (section) {
      // console.log('V2 Dash.loadPageItems', section)
      this.setPageLoad(true)
      this.itemsQuery = this.query
      this.extraMessage = ''

      this.calcCompleted()

      if (!this.jobsDisabled) {
        this.loadJobsFacets()
      }
      const jobSearchQuery = this.getJobsQuery()
      var publicPartnerQuery = ''

      if (this.profile.role) {
        publicPartnerQuery = `${this.profile.role.replace('&', '')}`
      }

      let customPartnerFilter = ''
      let companyPartnerFilter = ''
      // console.log('V2 Dash.loadPageItems:assignedCompany', this.assignedCompany)
      if (this.assignedCompany) {
        if (this.assignedCompany.customPartnerFilter) {
          customPartnerFilter = this.assignedCompany.customPartnerFilter
        }
        if (this.assignedSubCompany) {
          if (this.assignedSubCompany.private) {
            // Private Sub Company - assign sub company filter
            companyPartnerFilter += ` AND subCompanyId:"${this.company.assignedSubCompany.id}"`
          } else {
            // Public Sub Company - filter by all public
            if (this.publicSubCompanies) {
              const publicSubCompanyIds = this.publicSubCompanies.map(sc => sc.id).join(' ')
              companyPartnerFilter += ` AND subCompanyId:(${publicSubCompanyIds})`
            } else {
              companyPartnerFilter += ' AND -subCompanyId:*'
            }
          }
        } else {
          companyPartnerFilter += ' AND -subCompanyId:*'
        }
      }

      this.publicPartnersDefaultQuery = `${publicPartnerQuery} -id:${this.profile.id} -companyId:* AND visible:true ${customPartnerFilter}`
      this.companyDefaultQuery = `visible:true -id:${this.profile.id} AND (companyId:"${this.profile.companyId}" ${companyPartnerFilter})`

      // console.log('V2 Dash.loadPageItems:publicPartnersDefaultQuery', this.publicPartnersDefaultQuery)
      // console.log('V2 Dash.loadPageItems:companyDefaultQuery', this.companyDefaultQuery)

      var partnerSearchQuery = `rows=${this.rows}&q=${this.publicPartnersDefaultQuery}${this.getDistanceQueryPartners()}`
      var companySearchQuery = `rows=${this.rows}&q=${this.companyDefaultQuery}`

      if (this.query) {
        partnerSearchQuery = `rows=${this.rows}&q=${this.query} AND (${this.publicPartnersDefaultQuery})${this.getDistanceQueryPartners()}`
        companySearchQuery = `rows=${this.rows}&q=${this.query} AND (${this.companyDefaultQuery})`
      }

      var action = this.action
      if (!this.pageActions.includes(this.action) && this.action !== 'aggregated') {
        action = 'aggregated'
        this.setAction('aggregated')
      } else {
        this.setSection(action)
      }

      if (action === 'jobs') {
        if (!this.query || this.query === '') {
          if (!this.filterCompany) {
            // this.$root.$emit('setSearchQuery', this.defaultJobsQueryDisplay)
          } else {
            // this.$root.$emit('setSearchQuery', '')
          }
        }
      } else {
        if (!this.query || this.query === '') {
          this.$root.$emit('setSearchQuery', '')
        }
      }

      // Company Partner Search
      if (!section || section === 'partners') {
        this.companyPartnerQuery = companySearchQuery
        console.log('V2 Dash.loadPageItems.companySearchQuery', companySearchQuery)
        if (this.assignedCompany && (!this.assignedCompany.private || this.publicSubCompanies)) {
          this.profilesSearch(companySearchQuery).then(result => {
            console.log('V2 Dash.companySearchQuery', result)
            this.companyProfiles = result.docs
            this.companyProfilesFound = result.numFound
            if (result.numFound <= this.companyProfiles.length) {
              this.loadProfilesVisible = false
            } else {
              this.loadProfilesVisible = true
            }

            const partnerlength = this.companyProfiles ? this.companyProfiles.length : 0
            if (partnerlength === 0) {
            // show default query results if no query results
              var defaultPartnerSearchQuery = `rows=${this.rows}&q=${this.companyDefaultQuery}`
              this.profilesSearch(defaultPartnerSearchQuery).then(result => {
                // console.log('V2 Dash.loadPageItems.companySearchQuery.defaultPartnerSearchQuery', defaultPartnerSearchQuery, result)
                if (result) {
                  this.companyProfiles = result.docs
                }
              })
            }
          })
        }
      }

      // Public Partner Search
      if (!section || section === 'partners') {
        if (!this.companyProfilesOnly) {
          this.publicPartnerQuery = partnerSearchQuery
          // console.log(partnerSearchQuery)
          this.profilesSearch(partnerSearchQuery).then(result => {
            this.publicProfiles = result.docs
            const featured1 = {
              id: 'featured1',
              featured: true,
              title: 'Work Flexibly',
              detailTitle: 'Update Your Profile',
              detailText: 'Get personalised results just for you'
            }

            this.publicProfilesFound = result.numFound
            if (result.numFound <= this.publicProfiles.length) {
              this.loadProfilesVisible = false
            } else {
              this.loadProfilesVisible = true
            }

            // console.log('publicpartnersearch:', this.completed, this.publicProfiles.length)
            if (this.completed < 3) {
              if (this.publicProfiles.length < 8) {
                this.publicProfiles = [...this.publicProfiles, featured1]
              } else {
                this.publicProfiles.splice(8, 1, featured1)
              }
            }

            const partnerlength = this.publicProfiles ? this.publicProfiles.length : 0
            if (partnerlength === 0) {
              // show default query results if no query results
              var defaultPartnerSearchQuery = `rows=${this.rows}&q=${this.publicPartnersDefaultQuery}`
              this.profilesSearch(defaultPartnerSearchQuery).then(result => {
                if (result) {
                  this.publicProfiles = result.docs
                }
              })
            }
          })
        }
        if (section === 'partners') {
          this.finishLoad()
        }
      }

      // Jobs Interest
      if (!this.jobsDisabled) {
        const jobInterestSearchQuery = `rows=${this.rows}&q=has_interest:1&sort=date+desc`
        if (!section) {
          this.jobsSearch(jobInterestSearchQuery).then(result => {
            // console.log('V2 Dash.loadPageItems.jobsSearch.interestSearchQuery', jobInterestSearchQuery, result)
            if (result) {
              this.jobsInterestfound = result.numFound
              this.jobsInterest = result.docs
            }
          })
        }

        // Jobs Search
        if (!section || section === 'jobs') {
          this.jobsQuery = jobSearchQuery
          this.startJobs = 0
          // console.log('V2 Dash.loadPageItems.jobSearch level 1', jobSearchQuery)
          this.jobsSearch(jobSearchQuery).then(result => {
            if (result) {
              this.jobsfound = result.numFound
              this.jobs = result.docs

              const jobslength = this.jobs ? this.jobs.length : 0
              // console.log('loadPageItems.jobs', result, this.jobsfound, jobslength)
              if (jobslength < this.jobsfound) {
                this.loadJobsVisible = true
              } else {
                this.loadJobsVisible = false
              }

              if (jobslength === 0) {
                // show default query results if no search query results
                var backupJobSearchQuery = ''
                if (this.query && this.filterInterested) {
                  // has a search query thats filtered by interest - remove filter
                  const withoutInterest = true
                  this.extraMessage = 'Showing Jobs without Partner Interest'
                  backupJobSearchQuery = `rows=${this.rows}&q=(_title:"${this.query}")^3 ${this.getFilterQuery(withoutInterest)}`
                } else {
                  // use default query
                  this.extraMessage = 'Showing other Jobs'
                  backupJobSearchQuery = `rows=${this.rows}&q=${this.jobsDefaultQueryWithoutInterest}`
                }
                // console.log('job search level 2', backupJobSearchQuery)
                // 2nd level search - Remove interest filter if set or if not remove search from query
                this.jobsSearch(backupJobSearchQuery).then(result => {
                  if (result) {
                    let backupJobsfound = result.numFound
                    if (backupJobsfound === 0) {
                      // 3rd level search - Interest already removed and again no results, remove search query
                      this.extraMessage = 'Showing other Jobs'
                      backupJobSearchQuery = `rows=${this.rows}&q=${this.jobsDefaultQueryWithoutInterest}`
                      // console.log('job search level 3', backupJobSearchQuery)
                      this.jobsSearch(backupJobSearchQuery).then(result => {
                        if (result) {
                          backupJobsfound = result.numFound
                          if (backupJobsfound === 0) {
                            backupJobSearchQuery = this.jobsDefaultQueryLocCat
                            // console.log('job search level 4', backupJobSearchQuery)
                            // 4th level search - Search by location & industry (category)
                            this.jobsSearch(backupJobSearchQuery).then(result => {
                              if (result) {
                                backupJobsfound = result.numFound
                                if (backupJobsfound === 0) {
                                  // 5th level search - search all by date
                                  backupJobSearchQuery = `rows=${this.rows}&q=*&sort=date+desc`
                                  // console.log('job search level 5', backupJobSearchQuery)
                                  this.jobsSearch(backupJobSearchQuery).then(result => {
                                    if (result) {
                                      this.jobs = result.docs
                                    }
                                  })
                                } else {
                                  this.jobs = result.docs
                                }
                              }
                            })
                          } else {
                            this.jobs = result.docs
                          }
                        }
                      })
                    }
                    this.jobs = result.docs
                  }
                })
              }

              // Job Features
              if (!jobSearchQuery.includes('company:')) {
                const featured1 = {
                  id: 'featured1',
                  featured: true,
                  title: 'Work Flexibly',
                  detailTitle: 'Update Your Profile',
                  detailText: 'Get flexible jobs specific to you'
                }
                if (this.completed < 3) {
                  if (this.jobs.length < 8) {
                    this.jobs = [...this.jobs, featured1]
                  } else {
                    this.jobs.splice(8, 1, featured1)
                  }
                }
              }
            } else {
              this.jobs = []
            }
            this.finishLoad()
          })
          this.loadRemoteItems(this.section)
        }
      } else {
        this.finishLoad()
      }
    },
    mouseOverTooltip () {
      if (!this.profile.hotspotClicked.partners) {
        this.showTooltip = true
      }
    },
    mouseOverTooltipJobs () {
      if (!this.profile.hotspotClicked.jobs) {
        this.showTooltipJobs = true
      }
    },
    mouseOverTooltipJobsInterest () {
      if (!this.profile.hotspotClicked.jobsInterest) {
        this.showTooltipJobsInterest = true
      }
    },
    updateHotspot (hotspot) {
      if (!this.profile.hotspotSeen[hotspot]) {
        this.profile.hotspotSeen[hotspot] = 1
      } else {
        this.profile.hotspotSeen[hotspot] = this.profile.hotspotSeen[hotspot] + 1
      }
      this.updateProfile({ profile: { hotspotSeen: this.profile.hotspotSeen }, noSnack: true })
    },
    markDiscoverJobsInterest () {
      this.showTooltipJobsInterest = true
      this.profile.hotspotClicked.jobsInterest = true
      this.updateProfile({ profile: { hotspotClicked: this.profile.hotspotClicked }, noSnack: true })
    },
    trackDiscoverJobsHelp () {
      this.showTooltipJobs = true
      this.profile.hotspotClicked.jobs = true
      this.updateProfile({ profile: { hotspotClicked: this.profile.hotspotClicked }, noSnack: true })
      // eslint-disable-next-line no-undef
      analytics.track('trackDiscoverJobsHelp', {
        id: this.profile.id,
        journeyStage: this.profile.journeyStage,
        role: this.profile.role,
        locationFull: this.profile.locationFull,
        flexschedule: this.profile.flexschedule,
        company: this.profile.company
      })
    },
    trackDiscoverPartnerHelp () {
      this.showTooltip = true
      this.profile.hotspotClicked.partners = true
      this.updateProfile({ profile: { hotspotClicked: this.profile.hotspotClicked }, noSnack: true })
      // eslint-disable-next-line no-undef
      analytics.track('trackDiscoverPartnerHelp', {
        id: this.profile.id,
        journeyStage: this.profile.journeyStage,
        role: this.profile.role,
        locationFull: this.profile.locationFull,
        flexschedule: this.profile.flexschedule,
        company: this.profile.company
      })
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'company',
      'action',
      'filters',
      'locations',
      'isBranded',
      'remote',
      'teamMode'
    ]),
    ...mapState({
      featuredCompanies: state => state.company.featuredCompanies,
      assignedCompany: state => state.company.assignedCompany,
      assignedSubCompany: state => state.company.assignedSubCompany,
      publicSubCompanies: state => state.company.publicSubCompanies
    }),
    hasfeaturedCompanies () {
      if (this.featuredCompanies) {
        return Object.keys(this.featuredCompanies).length
      }
      return false
    },
    internalJobsOnly () {
      if (this.assignedCompany) {
        if (this.assignedCompany.internalJobs) return true
      }
      return false
    },
    companyProfilesOnly () {
      if (this.assignedCompany) {
        if (this.assignedCompany.publicProfilesDisabled) return true
      }
      return false
    },
    publicProfilesOnly () {
      if (this.assignedSubCompany) {
        if (this.assignedSubCompany.private) return true
      }
      return false
    },
    jobsDisabled () {
      if (this.assignedSubCompany) {
        return this.assignedSubCompany.jobsDisabled
      } else if (this.assignedCompany) {
        return this.assignedCompany.jobsDisabled
      }
      return false
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$root.$emit('enableSearch', false)
  },
  async beforeRouteUpdate (to, from, next) {
    next()
    // console.log('V2 Dash.beforeRouteUpdate')
    this.setPageLoad(true)
    this.$emit('setLinks', this.links)
    // setTimeout(() => {
    //   console.log('V2 Dash.beforeRouteUpdate.LoadPageItems:query', this.query)
    //   this.loadPageItems()
    // }, 300)
  },
  mounted () {
    // console.log('V2 Dash.mounted:query', this.query)
    this.$emit('setLinks', this.links)
  },
  activated () {
    // console.log('V2 Dash.activated:query', this.query, this.search, this.itemsQuery)
    if (this.itemsQuery !== this.query) {
      if (!this.isBranded && this.profile) {
        this.loadPageItems()
      }
    }
    this.$emit('setLinks', this.links)
    this.$root.$emit('enableSearch', true)
    this.$root.$emit('setSearchQuery', this.query)
    // console.log('SetSearch in activated Done')
    if (!this.pageActions.includes(this.action) && this.action !== 'aggregated') {
      this.setAction('aggregated')
    }
    if (this.profile) {
      if (!this.profile.seenIntro) {
        this.firstOpen = true
      }
    }
    this.calcCompleted()
    if (this.$refs.featuredCompanies) {
      this.$refs.featuredCompanies.refresh()
    }
    setTimeout(() => {
      this.loading = false
    }, 500)
  },
  created () {
    // console.log('v2 Dash.created', this.query)
    this.setPageLoad(true)
    // set Filters
    this.filterLocation = this.filters.filterLocation || ''
    this.filterLocationLat = this.filters.filterLocationLat
    this.filterLocationLon = this.filters.filterLocationLon
    this.filterFlex = this.filters.filterFlex || 'Very Likely'
    this.filterCompany = this.filters.filterCompany
    if (this.filters.filterInterested) {
      this.filterInterested = this.filters.filterInterested
    } else {
      this.filterInterested = false
    }

    // this.$root.$emit('enableSearch', true)
    // this.$root.$emit('setSearchQuery', this.query)

    if (this.profile) {
      if (!this.isBranded || this.assignedCompany) {
        // console.log('v2 Dash.created loadPageItems')
        this.loadPageItems()
      }
      if (!this.profile.seenIntro) {
        this.firstOpen = true
      }
    }
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('scroll', this.handleScroll)
    document.addEventListener('touchmove', this.handleScroll, false)
    document.addEventListener('touchstart', this.handleScroll, false)
    this.handleResize()
  },
  destroyed () {
    // console.log('V2 Dash.destroyed', this.query)
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
    document.removeEventListener('touchmove', this.handleScroll)
    document.removeEventListener('touchstart', this.handleScroll)
  },
  watch: {
    filterCompany: {
      handler (newf, oldf) {
        if (this.filterCompany) {
          this.$root.$emit('enableSearch', true, { back: true, backAction: 'clearFilter' })
        } else {
          this.$root.$emit('enableSearch', true)
        }
      },
      immediate: true
    },
    query: function (newQ, oldQ) {
      // console.log('V2 Dash.watch:query', newQ)
      this.loadPageItems()
    },
    action: function (newA, oldA) {
      // console.log('V2 Dash.watch:action', newA)
      if (newA !== '') {
        this.showSection(newA)
      }
    },
    profile: function (newP, oldP) {
      // console.log('V2 Dash.watch:profile', newP)
      if (this.profile) {
        if (!this.profile.seenIntro) {
          this.firstOpen = true
        }
        if (!oldP) {
          // console.log('V2 Dash.watch profile - loadPageItems', this.isBranded, this.assignedCompany)
          if (!this.isBranded || this.assignedCompany) {
            // console.log('V2 Dash.watch:profile loadPageItems')
            this.loadPageItems()
          }
          this.loadBookmarks()
        } else if (newP.bookmarks !== oldP.bookmarks) {
          if (this.action === 'bookmarked') {
            this.loadBookmarks()
          }
        }
      }
    },
    publicSubCompanies: function (newPC, oldPC) {
      // console.log('V2 Dash.watch:publicSubCompanies')
      if (!oldPC) {
        this.loadPageItems('partners')
      }
    },
    assignedCompany: function (newAC, oldAC) {
      if (this.assignedCompany && !oldAC) {
        if (this.profile) {
          // console.log('V2 Dash.watch assignedCompany - loadPageItems', this.assignedCompany.name)
          this.loadPageItems()
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.public-partner-section, .jobs-section, .job-section, .jobs-interested-section .section-interest, .public-interested-section, .jobs-interest-section {
  position:relative;
}

.social-interest-section {
  margin:12px;
  width:100%;
  max-width: 800px;
}
.social-interest-section .social-tick {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 0 !important;
    margin-left: -16px;
    font-size: 32px;
}
.social-interest-section .interest-row {
  display:flex;
}
.social-interest-section .interest-row > div {
  display:flex;
  flex-flow: row wrap;
  align-items: center;
}
.social-interest-section .interest-row > div > span {
  padding: 12px;
  white-space:normal;
}

.additional {
  color:rgba(0, 0, 0, 0.54);
  display:flex;
  margin-bottom:32px;
  padding-left:var(--mdc-layout-grid-margin-desktop, 24px);
  padding-right:var(--mdc-layout-grid-margin-desktop, 24px);
}
.additional > span {
  display:flex;
}

.filter-modal .toggle-row {
  display:flex;
  width:100%;
  justify-content: center;
}
.filter-modal h4 {
  flex:1;
  margin-bottom:12px;
  margin-top:0px;
}

.filter-modal .autocomplete.mdc-text-field.outlined .mdc-text-field__input.autocomplete-input::placeholder {
  color: #979797 !important;
  opacity:1 !important;
}

.mobile-links-wrapper {
  margin-left:-16px;
  margin-right:-16px;
  overflow:hidden;
  height:50px;
}
.mobile-links {
  display: flex;
  justify-content:space-between;
  flex-wrap: nowrap;
  margin-top:8px;
  padding-bottom: 10px;
  height:90px;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.mobile-links .mobile-link {
  padding-left:16px;
  flex-grow:1;
  flex-shrink:0;
  margin-left:16px;
  margin-right:16px;
  margin-bottom:2px;
  margin-top:2px;
}

.section-title {
  padding-left:var(--mdc-layout-grid-margin-desktop, 24px);
  padding-right:var(--mdc-layout-grid-margin-desktop, 24px);
}

.section-sub-title {
  padding-left:var(--mdc-layout-grid-margin-desktop, 24px);
  padding-right:var(--mdc-layout-grid-margin-desktop, 24px);
  margin-bottom:0;
  margin-top:0;
}

.featured-card-link {
  text-decoration: none;
}

.profile-card-link {
  text-decoration: none;
  color: #333;
}
.job-card-link {
  text-decoration: none;
  color: #333;
  margin-top: 0;
  width: 100%;
}

@media (min-width: 481px) and (max-width:569px) {
  .mobile-links-wrapper .mobile-links .mobile-link:first-child {
    margin-left:32px;
  }
}

@media (max-width:840px) {
  .additional {
    padding-left:var(--mdc-layout-grid-margin-tablet, 16px);
    padding-right:var(--mdc-layout-grid-margin-tablet, 16px);
  }
  .section-title {
    padding-left:var(--mdc-layout-grid-margin-tablet, 16px);
    padding-right:var(--mdc-layout-grid-margin-tablet, 16px);
  }
  .section-sub-title {
    padding-left:var(--mdc-layout-grid-margin-tablet, 16px);
    padding-right:var(--mdc-layout-grid-margin-tablet, 16px);
  }
  .section > .mdc-layout-grid {
    padding-left:var(--mdc-layout-grid-margin-tablet, 16px);
    padding-right:var(--mdc-layout-grid-margin-tablet, 16px);
  }
}

@media (max-width: 480px) {
  .section-title {
    padding-left:0px !important;
    padding-right:0px !important;
  }
  .section-sub-title {
    padding-left:0px !important;
    padding-right:0px !important;
  }
  .additional {
    padding-left:0px !important;
    padding-right:0px !important;
  }
  .mobile-links {
    padding-left:0px;
    padding-right:0px;
  }
  section > .mdc-layout-grid {
    padding-left:0;
    padding-right:0;
  }
  .not-mobile {
    display:none;
  }
}

/* Override grid media queries for cards
 * Built in screen sizes are not granular enough so need custom size
 * to allow profile content to file while maintaining aspect ratio.
 */

@media (min-width: 481px) and (max-width: 595px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 8;
  }
}
@media (min-width: 595px) and (max-width: 671px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 12;
  }
}
@media (min-width: 840px) and (max-width: 920px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 6;
  }
}
@media (min-width: 920px) and (max-width:   1180px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 6;
  }
}
@media (min-width: 1180px) and (max-width:   1380px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 4;
  }
}
@media (min-width: 1480px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 3;
  }
}
</style>
<style>
.dash .status-row {
  display:flex;
  flex-flow: row;
  align-items: flex-start;
}
.dash .status-button .material-icons {
  transition: transform 0.6s ease-in-out;
  transform: rotate(0deg);
}

.dash .status-button .material-icons.rotate {
  transform: rotate(180deg);
  transition: transform 0.6s ease-in-out;
}

.dash .status-button {
  color:white;
  width:100%;
  display:flex;
}

.dash .status-button .material-icons.mdc-button__icon {
  outline: none !important;
}

.dash .status-button-content {
  flex:1;
  text-align: left
}
.dash .status-button-dropdown {
  margin-left: 2px;
  margin-right: 2px;
}
.dash .status-button-dropdown {
  margin-left: 2px;
  margin-right: 2px;
  transition: max-height 1s;
  transition-delay: 100ms;
  overflow: hidden;
  max-height:400px;
  background:white;
}

.dash .status-button-dropdown-wrapper {
  transition: height 1s;
  transition-delay: 0;
  height: 260px;
  overflow: hidden;
  margin: 2px;
  position: absolute;
  z-index: 9;
}

.dash .status-button-dropdown.hidden {
  max-height: 0;
  transition-delay: 0;
}
.dash .status-button-dropdown-wrapper.hidden {
  height: 15px;
  transition-delay: 100ms;

}

.dash .status-button-dropdown .mdc-list-item__secondary-text span {
  display: inline-block;
  margin: 0;
  font-size: 12px;
}
.dash .status-button-dropdown .mdc-list-item__secondary-text::before {
  display:none;
}
.dash .mdc-list .mdc-list-item__graphic {
  margin-top: 8px;
  margin-bottom: auto;
}
.dash .not-set {
  background-color: #880E4F;
}
.dash .remote {
  background-color: #3560AF;
}
.dash .office {
  background-color: #008080;
}
.dash .parental-leave {
  background-color: #FF6C00;
}
.dash .sabbatical {
  background-color: #FFD12B;
}

.dash .not-set-icon {
  color: #880E4F;
}
.dash .remote-icon {
  color: #3560AF;
}
.dash .office-icon {
  color: #008080;
}
.dash .parental-leave-icon {
  color: #FF6C00;
}
.dash .sabbatical-icon {
  color: #FFD12B;
}

.dash .jobs-hotspot .wrapper {
  margin:0;
}
.dash .partners-hotspot .wrapper {
  margin:0;
}
.dash .jobs-hotspot .wrapper i, .dash .partners-hotspot .wrapper i{
  margin-top: -0.01px;
  margin-left: 0.36px;
}
.dash .filter-modal .autocomplete-input {
  margin-left:26px !important;
  padding-left: 26px;
}
.dash .filter-modal .autocomplete-results {
  margin-top:26px !important;
}
.dash .filter-modal .toggle-row label {
  padding-right:16px;
  order:1;
  display:none;
}
.dash .filter-modal .toggle-row .mdc-switch {
  margin-bottom:0px;
  margin-top:3px;
  order:2;
  margin-right:16px;
}

.dash .additional .inline-button {
    margin: 0px !important;
    margin-top:-1px !important;
    padding-left: 6px !important;
    text-transform: lowercase;
    font-weight: 400;
    font-size: 1em;
    line-height: 1em;
    height: 20px !important;
    padding-right: 4px !important;
    text-decoration: underline;
}
.dash .additional .inline-button:hover {
  box-shadow:none !important;
  text-decoration: underline;
}
.dash .highlight-padding.mobileonly {
  height:0px;
  margin-top:12px;
}
@media (any-hover: hover) {
  .dash .mobile-links .mobile-link:hover {
    box-shadow: 0 0 0 1.5px #979797;
  }
}

.dash .fade-in-enter-active, .fade-in-leave-active {
  transition: opacity 0.5s;
  transition-timing-function: ease-in;
}
.dash .fade-in-enter, .fade-in-leave-to {
  opacity: 0.3;
}
</style>
