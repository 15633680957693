<template>
  <div class="main-content">
    <mdc-linear-progress indeterminate></mdc-linear-progress>
    <div class="main-wrapper">
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/auth'
export default {
  name: 'LinkVerify',
  data () {
    return {
      title: 'Link Verify'
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  mounted () {
    if (window.location.href.includes('apiKey')) {
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn')
        if (!email) {
          email = window.prompt('Please provide your email for confirmation')
        }
        firebase.auth().signInWithEmailLink(email, window.location.href)
          .then(async result => {
            this.$router.push('/dash')
          })
          .catch(err => {
            this.showAlertMessage(err)
            this.$router.push('/signin')
          })
      }
    }
  },
  methods: {
    ...mapActions([
      'setSnackMessage',
      'setPageLoad'
    ]),
    userFriendlyMessage (err) {
      if (err.code === 'auth/user-not-found') {
        return 'We don\'t recognise that email, have you got another email or do you need to sign up for the first time?'
      } else if (err.code === 'auth/wrong-password') {
        return 'The password is invalid'
      }
      return (err.message) ? err.message : err
    },
    showAlertMessage (err) {
      this.isValid = false
      this.helptext = this.userFriendlyMessage(err)
      this.setSnackMessage(this.helptext)
    }
  }
}
</script>

<style scoped>
</style>
