<template>
  <!-- Education -->
  <app-section class="education"
  v-if="!other || educationHistory.length > 0"
  :editable="editable"
  animationTime="0ms"
  :closeOnAccept="false"
  @change="setEditing"
  @save="updateProfileEducation"
  title="Education"
  ref="educationSection"
  >

    <mdc-body typo="body2" style="color:#b00020" v-if="edValiationMessage">{{edValiationMessage}}</mdc-body>
    <div v-show="editable && editingEducation">

    <div style="display:flex">
      <mdc-body typo="body2">Providing Information about your education history by adding qualifications</mdc-body>
      <round-button high title="Add Entry" icon="add" style="margin-left:auto;margin-right: 4px;" @click="addEducationHistory"></round-button>
    </div>

    <mdc-list two-line>
      <div v-for="(ed, index) in educationHistory" class="mdc-list--non-interactive" :key="index">

        <mdc-list-item v-bind:class="{active: edExpanded === index}" @click="setEdExpanded(index)" >

          <span slot="leading-icon">
            <img v-if="ed.educationLevel === 'High school, diploma or the equivalent (GCSE, A-level, etc)'" class="ed-image" src="/static/img/education/Highschool.svg">
            <img v-else-if="ed.educationLevel === 'Trade/technical/professional qualification'" class="ed-image" src="/static/img/education/Trade-Technical-Professional.svg">
            <img v-else-if="ed.educationLevel === 'Bachelor\'s degree'" class="ed-image"  src="/static/img/education/Degree.svg">
            <img v-else-if="ed.educationLevel === 'Master\'s degree'" class="ed-image"  src="/static/img/education/Masters.svg">
            <img v-else-if="ed.educationLevel === 'Doctorate degree'" class="ed-image"  src="/static/img/education/PHD.svg">
            <img v-else-if="ed.educationLevel === 'Self Taught'" class="ed-image"  src="/static/img/education/Self-Taught.svg">
            <img v-else class="ed-image" src="/static/img/education.png">
          </span>

          <mdc-subheading v-if="ed.educationLevel || ed.place">{{ed.educationLevel}} - {{ed.place}}</mdc-subheading>
          <mdc-body v-if="ed.areaOfStudy || ed.award" style="margin:0;margin-bottom:6px;" typo="body2">{{ed.areaOfStudy}}, {{ed.award}}</mdc-body>

          <div slot="secondary">
            <mdc-body typo="body2" v-if="ed.fromYear || ed.toYear" class="subinfo">
              {{ed.fromYear}} - {{ed.toYear}}
            </mdc-body>
          </div>

          <span class="end-detail" slot="end-detail">
            <div class="end-detail-row">
              <round-button @click.prevent.stop="setEdExpanded(index)" :icon="getIcon(index)"></round-button>
              <round-button class="desktoponly" v-if="index !== 0" @click.prevent.stop="moveEdUp(index)" icon="arrow_upward"></round-button>
              <round-button class="desktoponly" v-if="index !== educationHistory.length - 1" @click.prevent.stop="moveEdDown(index)" icon="arrow_downward"></round-button>
              <round-button @click.prevent.stop="deleteEd(index)" iconOutline icon="delete"></round-button>
            </div>
            <div class="end-detail-row mobileonly">
              <round-button v-if="index !== 0" @click.prevent.stop="moveEdUp(index)" icon="arrow_upward"></round-button>
              <round-button v-if="index !== educationHistory.length - 1" @click.prevent.stop="moveEdDown(index)" icon="arrow_downward"></round-button>
            </div>
          </span>
        </mdc-list-item>

        <mdc-list-item style="overflow: visible;" class="sub-expanded" v-if="edExpanded === index">
            <div style="padding-top:16px;max-width:100%;">

              <mdc-select style="width:100%"
              required
              @change="updateEducationalLevel"
              v-model="ed.educationLevel"
              outlined label="Education Type">
                <select-item value="High school, diploma or the equivalent (GCSE, A-level, etc)" />
                <select-item value="Trade/technical/professional qualification" />
                <select-item value="Bachelor's degree" />
                <select-item value="Master's degree" />
                <select-item value="Doctorate degree" />
              </mdc-select>

              <mdc-textfield style="width:100%" type="text"
                  outlined
                  :required="ifDegreeLevel('required', false)"
                  helptext="- E.g. Oxford University"
                  label="Place of Education"
                  v-model="ed.place"
                  maxlength="120">
                <mdc-text-field-counter slot="counter">0 / 120</mdc-text-field-counter>
              </mdc-textfield>

              <mdc-textfield style="width:100%" type="text"
                  outlined
                  :disabled="!degreeLevel"
                  helptext="- E.g. Business"
                  label="Area of Study"
                  v-model="ed.areaOfStudy"
                  maxlength="120">
                <mdc-text-field-counter slot="counter">0 / 120</mdc-text-field-counter>
              </mdc-textfield>

              <mdc-textfield style="width:100%" type="text"
                  outlined
                  :disabled="!degreeLevel"
                  helptext="- E.g. 1st Class (Hons)"
                  label="Award Level"
                  v-model="ed.award"
                  maxlength="120">
                <mdc-text-field-counter slot="counter">0 / 120</mdc-text-field-counter>
              </mdc-textfield>

              <div class="flex-group">
                  <mdc-select outlined class="dateSelect" style="flex:1; margin-right:16px;" v-model="ed.fromYear" label="Year From">
                    <select-item v-for="i in getYearsBefore(ed.toYear)" :key="'edfy'+i" :value="i" />
                  </mdc-select>

                  <mdc-select outlined class="dateSelect" style="flex:1" v-model="ed.toYear" label="Year To">
                    <select-item v-for="i in getYearsAfter(ed.fromYear)" :key="'edty'+i" :value="i" />
                  </mdc-select>
              </div>

              <mdc-text-area-autosize label="Description" v-model="ed.description" outlined maxlength="2000">
                <mdc-text-field-counter slot="counter">0 / 2000</mdc-text-field-counter>
              </mdc-text-area-autosize>

            </div>
        </mdc-list-item>
      </div>
    </mdc-list>
    </div>
    <div v-show="!editable || !editingEducation" style="display:flex" >

      <mdc-list style="flex:1;width: 100%;" two-line>
        <mdc-list-item  v-for="(ed, index) in profile.educationHistory" :key="index" v-bind:class="{active: edExpanded === index}" @click="setEdExpanded(index)">

          <span slot="leading-icon">
            <img v-if="ed.educationLevel === 'High school, diploma or the equivalent (GCSE, A-level, etc)'" class="ed-image" src="/static/img/education/Highschool.svg">
            <img v-else-if="ed.educationLevel === 'Trade/technical/professional qualification'" class="ed-image" src="/static/img/education/Trade-Technical-Professional.svg">
            <img v-else-if="ed.educationLevel === 'Bachelor\'s degree'" class="ed-image"  src="/static/img/education/Degree.svg">
            <img v-else-if="ed.educationLevel === 'Master\'s degree'" class="ed-image"  src="/static/img/education/Masters.svg">
            <img v-else-if="ed.educationLevel === 'Doctorate degree'" class="ed-image"  src="/static/img/education/PHD.svg">
            <img v-else-if="ed.educationLevel === 'Self Taught'" class="ed-image"  src="/static/img/education/Self-Taught.svg">
            <img v-else class="ed-image" src="/static/img/education.png">
          </span>

          <mdc-subheading v-if="ed.educationLevel || ed.place">{{ed.educationLevel}} - {{ed.place}}</mdc-subheading>
          <mdc-body v-if="ed.areaOfStudy || ed.award" style="margin:0;margin-bottom:6px;" typo="body2">{{ed.areaOfStudy}}, {{ed.award}}</mdc-body>

          <div slot="secondary" >
            <mdc-body typo="body2" v-if="ed.fromYear || ed.toYear" class="subinfo">
              {{ed.fromYear}} - {{ed.toYear}}
            </mdc-body>
            <mdc-body v-if="edExpanded === index" v-html="ed.description" typo="body2"></mdc-body>
          </div>

          <span class="end-detail" slot="end-detail">
            <round-button v-if="!other || ed.description" @click.prevent.stop="setEdExpanded(index)" :icon="getIcon(index)"></round-button>
          </span>

        </mdc-list-item>
      </mdc-list>
    </div>

  </app-section>
</template>

<script>

import { getYearArray, getMonthArray, differenceYears, differenceYearsMonths } from '../../utils/time'
import AppSection from '../../components/profile-v2/AppSection.vue'

export default {
  name: 'EducationSection',
  components: {
    AppSection
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    profile: Object,
    other: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editingEducation: false,
      edExpanded: -1,
      edValiationMessage: null,
      edDescUpdates: {},
      degreeLevel: false,
      educationHistory: [
      ],
      educationLevels: [
        'High school, diploma or the equivalent (GCSE, A-level, etc)',
        'Trade/technical/professional qualification',
        'Bachelor\'s degree',
        'Master\'s degree',
        'Doctorate degree'
      ]
    }
  },
  mounted () {
    if (this.profile.educationLevel) {
      this.degreeLevel = this.profile.educationLevel.indexOf('degree') > -1
    }
  },
  methods: {
    getIcon (index) {
      if (this.edExpanded === index) {
        return 'keyboard_arrow_up'
      }
      return 'keyboard_arrow_down'
    },
    ifDegreeLevel (ifTrue, ifFalse) {
      if (this.degreeLevel) {
        return ifTrue
      }
      return ifFalse
    },
    isLineDegreeLevel (line) {
      return line.educationLevel.indexOf('degree') > -1
    },
    updateEducationalLevel: function (level) {
      this.degreeLevel = level.indexOf('degree') > -1
    },
    editProfileEducation: function () {
    },
    updateProfileEducation: function () {
      console.log('updateProfileEducation', this.educationValid())
      if (this.educationValid()) {
        this.editingEducation = false
        for (const descUpdateKey in this.edDescUpdates) {
          const index = parseInt(descUpdateKey.replace('idx', ''))
          this.educationHistory[index].description = this.edDescUpdates[descUpdateKey]
        }

        let highestLevel = this.educationLevels.indexOf(this.profile.educationLevel)
        if (this.educationHistory) {
          let historyHighestLevel = -1
          if (this.educationHistory.length > 0) {
            for (const ed of this.educationHistory) {
              const histoyLevel = this.educationLevels.indexOf(ed.educationLevel)
              if (histoyLevel > historyHighestLevel) {
                historyHighestLevel = histoyLevel
              }
            }
          }
          if (historyHighestLevel >= 0) {
            highestLevel = historyHighestLevel
          }
        }
        this.$emit('updateProfileSection', { educationHistory: this.educationHistory, educationLevel: this.educationLevels[highestLevel] })
        this.editingEducation = false
        this.$refs.educationSection.close()
      } else {
        this.editingEducation = true
      }
    },
    getMonths: function () {
      return getMonthArray()
    },
    getYears: function () {
      return getYearArray()
    },
    getYearDiff: function (exp) {
      if (exp) {
        if (exp.fromYear && exp.toYear) {
          return differenceYears(exp.fromYear, exp.toYear, exp.fromMonth, exp.toMonth)
        }
      }
      return 0
    },
    getYearMonthDiff: function (exp) {
      if (exp) {
        if (exp.fromYear && exp.toYear) {
          return differenceYearsMonths(exp.fromYear, exp.toYear, exp.fromMonth, exp.toMonth)
        }
      }
      return ''
    },
    getYearsAfter: function (after) {
      const years = getYearArray()
      if (after) {
        const afterInt = parseInt(after)
        const index = years.indexOf(afterInt)
        if (index === -1) {
          return ['', ...years]
        }
        return ['', ...years.slice(0, index)]
      }
      return ['', ...years]
    },
    getYearsBefore: function (before) {
      const years = getYearArray()
      if (before) {
        const beforeInt = parseInt(before)
        const index = years.indexOf(beforeInt)
        if (index === -1) {
          return ['', ...years]
        }
        return ['', ...years.slice(index)]
      }
      return ['', ...years]
    },
    onEdDescriptionUpdate: function (text, contentKey) {
      this.edDescUpdates[contentKey] = text
    },
    moveEdDown: function (index) {
      if (index < this.educationHistory.length - 1) {
        const item = this.educationHistory[index]
        this.educationHistory.splice(index, 1)
        this.educationHistory.splice(index + 1, 0, item)
        if (this.edExpanded !== -1) {
          this.edExpanded = index + 1
        }
      }
    },
    moveEdUp: function (index) {
      if (index > 0) {
        const item = this.educationHistory[index]
        this.educationHistory.splice(index, 1)
        this.educationHistory.splice(index - 1, 0, item)
        if (this.edExpanded !== -1) {
          this.edExpanded = index - 1
        }
      }
    },
    deleteEd: function (index) {
      this.educationHistory.splice(index, 1)
      this.edExpanded = -1
    },
    addEducationHistory: function () {
      if (!this.educationHistory) {
        this.educationHistory = []
      }
      if (this.educationValid()) {
        this.educationHistory.push({
          educationLevel: '',
          place: '',
          areaOfStudy: '',
          award: '',
          from: '',
          to: ''
        })
        this.setEdExpanded(this.educationHistory.length - 1)
      }
    },
    setEdExpanded: function (indx) {
      const ed = this.profile.educationHistory[indx]
      console.log(this.profile.educationHistory, indx, ed)
      if (!this.other || ed.description) {
        if (this.edExpanded === indx) {
          this.edExpanded = -1
        } else {
          this.edExpanded = indx
        }
      }
    },
    educationValid: function () {
      let valid = true
      this.edValiationMessage = null
      this.educationHistory.forEach((ed, i) => {
        if (ed.place === '' && this.isLineDegreeLevel(ed)) {
          valid = false
          if (this.edExpanded !== i) {
            this.setEdExpanded(i)
          }
          this.edValiationMessage = '* Please fill out the Place of Education'
        }
      })
      return valid
    },
    setEditing (val) {
      if (!val) {
        this.$emit('editing', false)
      } else {
        this.$emit('editing', 'education')
      }
      this.editingEducation = val
      if (val) {
        this.editProfileEducation()
      }
    }
  },
  watch: {
    profile: {
      handler (newp, oldp) {
        if (this.profile) {
          this.educationHistory = []
          if (this.profile.educationHistory) {
            for (const history of this.profile.educationHistory) {
              if (history.educationLevel) {
                this.educationHistory.push(history)
              }
            }
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.education .mdc-list-item .mdc-subheading {
  margin-top:0;
  margin-bottom: 6px;
  white-space: normal;
}
.education .mdc-list--non-interactive .mdc-list-item {
  padding-left: 2px;
  padding-right: 2px;
}
.education .end-detail {
  display:flex;
  flex-flow:column;
}
.education .end-detail .end-detail-row {
  display:flex;
  flex-flow:row;
  justify-content: flex-end;
}
.education .end-detail .round-button {
  margin:3px;
}
.education .flex-group {
  display:flex;
}
.education .ed-image {
  width:60px;
  margin-right:16px;
}
.education .subinfo {
  margin:0;
}
.education .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
  margin-right:12px;
}
.education .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
  height:36px;
}
.education {
  margin-bottom:16px;
}
.education .tiptap-wrap {
  margin-top:16px;
  width:98%;
}
</style>

<style>
.education .mdc-list--two-line .mdc-list-item__text {
  align-self: stretch;
}
.education .mdc-list-item__secondary-text::before {
  display:none;
}
.education .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control {
  padding-left: 16px;
}
.education .mdc-select {
  margin-bottom:34px;
}
.education .mdc-textfield.mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box):not(.mdc-text-field--textarea):not(.mdc-text-field--outlined) {
  height:46px;
  margin-top:0px;
  margin-bottom:0px;
}
.education .mdc-text-field-helper-text {
    margin-bottom: 12px !important;
}
.education .mdc-list-item__meta {
  border-bottom: 1px solid rgba(0,0,0,.12);
}
.education .mdc-list-item__text {
  border-bottom: 1px solid rgba(0,0,0,.12);
}
</style>
<style>
.education .sub-label {
  font-size:0.8em;
  margin-top:6px;
  margin-bottom:16px;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
}
</style>
