<template>
  <div class="main-content">
    <div class="main-wrapper" style="margin-top: 90px;">

      <mdc-text tag="h1" typo="headline4">{{title}}</mdc-text>
      <span v-html="content"></span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as ghost from '../utils/ghost'
export default {
  name: 'Content',
  data () {
    return {
      title: '',
      links: [
        { title: 'Terms of Service', action: 'terms-of-service' },
        { title: 'Privacy Policy', action: 'privacy-policy' },
        { title: 'Subprocessors', action: 'subprocessors' }
      ],
      content: '',
      filter: null
    }
  },
  computed: {
    ...mapState([
      'action'
    ])
  },
  methods: {
    ...mapActions([
      'setAction',
      'setPageLoad'
    ]),
    fitlerStatus: function (status) {
      this.filter = status
    },
    async getContent (slug) {
      const result = await ghost.pageBySlug(slug)
      if (result.pages) {
        const pages = result.pages
        this.title = pages[0].title
        this.content = pages[0].html
      }
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$root.$emit('enableSearch', false)
  },
  async mounted () {
    await this.setAction('')
    this.$emit('setLinks', this.links)
    this.setAction('terms-of-service')
    this.$root.$emit('enableSearch', true, { context: 'legal' }, { filterStatus: this.filterStatus })
  },
  watch: {
    action: function (newA, oldA) {
      if (newA !== '') {
        this.getContent(newA)
      }
    }
  }
}
</script>
