<template>
  <div>
    <mdc-body typo="body2" style="color:#b00020;text-align:left;margin-left:0;width:100%;" v-if="!valid">{{validationMessage}}</mdc-body>
    <div v-bind:class="{ready: (ready && modal), notready: (!ready && modal)}" class="slider-wrapper day-slider">
      <input class="focus-ancor" style="opacity:0;z-index:-1;" :tabindex="tabindex" @blur="blurSlider" @focus="focusSlider" >
      <vue-slider :class="sliderClass" :enable-cross="false" :useKeyboard="keyboardfoucs" ref="slider" v-model="valueRange" :data="data" :marks="marks" :tooltip="'always'" :tooltip-formatter="tooltip">
        <template v-slot:step="{ label, active }">
          <div :aria-label="label" :class="['custom-step', { active }]"></div>
        </template>
      </vue-slider>
    </div>
  </div>
</template>

<script>
import 'vue-slider-component/theme/material.css'

export default {
  name: 'DaySliderRange',
  model: {
    prop: 'value',
    event: 'change'
  },
  components: {
    VueSlider: () => import('vue-slider-component')
  },
  props: {
    value: {
      required: false
    },
    modal: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    show: {
      type: Boolean,
      default: false
    },
    tabindex: {
    },
    valid: {
      type: Boolean,
      default: true
    },
    validationMessage: {
      type: String,
      default: ''
    }
  },
  methods: {
    focusSlider () {
      this.keyboardfoucs = true
      this.$refs.slider.focus()
    },
    blurSlider () {
      this.keyboardfoucs = false
      this.$refs.slider.blur()
    },
    onChangeRange (val) {
      this.formatRange(val)
      this.$emit('change', this.valueDisplay)
    },
    onChange (val) {
      this.formatDays(val)
      this.$emit('change', this.valueDisplay)
    },
    formatRange (value) {
      if (value === '') {
        value = '0 Days'
        this.valueDisplay = `${value} Days`
        this.valueRange = ['0', '0']
        this.sliderClass = {}
        this.sliderClass[`val1-${this.valueRange[0]}`] = true
        this.sliderClass[`val2-${this.valueRange[1]}`] = true
        this.sliderClass['val1-hide'] = true
      }
      if (value !== null) {
        if (typeof value === 'string') {
          const value1 = ('' + value).split(' ')[0]
          let val1Float = parseFloat(value1)
          if (isNaN(val1Float)) {
            value = '0 Days'
            val1Float = 0
          }
          this.valueRange = ['0', `${val1Float}`]
          this.valueDisplay = `${val1Float} Days`

          const parts = ('' + value).split('-')
          if (parts.length > 1) {
            const value2 = parts[1].trim().split(' ')[0]
            const val2Float = parseFloat(value2)
            this.valueRange = [`${val1Float}`, `${val2Float}`]
            this.valueDisplay = `${val1Float} - ${val2Float} Days`
          }
        }

        this.sliderClass = {}
        this.sliderClass[`val1-${this.valueRange[0]}`] = true
        this.sliderClass[`val2-${this.valueRange[1]}`] = true
        if (this.valueRange[0] === this.valueRange[1] || this.valueRange[0] === '0') {
          this.valueDisplay = `${this.valueRange[1]} Days`
          this.sliderClass['val1-hide'] = true
        } else {
          this.valueDisplay = `${this.valueRange[0]} - ${this.valueRange[1]} Days`
        }
      }
    },
    formatDays (value) {
      if (value === '') {
        value = '0 Days'
        this.valueDisplay = `${value} Days`
        this.valueRange = [0, 0]
        this.sliderClass = {}
        this.sliderClass[`val1-${this.valueRange[0]}`] = true
        this.sliderClass[`val2-${this.valueRange[1]}`] = true
        this.sliderClass['val1-hide'] = true
      }
      if (value !== null) {
        value = ('' + value).split(' ')[0]
        if (this.valueNum !== value) {
          let valFloat = parseFloat(value)
          if (isNaN(valFloat)) {
            value = '0 Days'
            valFloat = 0
          }
          this.valueRange = [0, valFloat]
          this.sliderClass = {}
          this.sliderClass[`val1-${this.valueRange[0]}`] = true
          this.sliderClass[`val2-${this.valueRange[1]}`] = true
          if (this.valueRange[0] === this.valueRange[1] || this.valueRange[0] === '0') {
            this.sliderClass['val1-hide'] = true
          }
        }
        this.valueDisplay = `${value} Days`
      }
    },
    tooltip (val) {
      return `${val} Days`
    }
  },
  data () {
    return {
      keyboardfoucs: false,
      valueNum: 0,
      valueRange: ['0', '1'],
      sliderClass: {},
      valueDisplay: '1 Day',
      ready: false,
      active: true,
      data: ['0', '0.5', '1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5'],
      marks: (val) => {
        if (val % 1 === 0) {
          return `${val}`
        } else {
          return '.'
        }
      }
    }
  },
  mounted () {
    const self = this
    this.formatRange(this.value)
    setTimeout(function () {
      self.ready = true
    }, 100)
  },
  watch: {
    value (newv, oldv) {
      this.formatRange(this.value)
    },
    valueRange (newv, oldv) {
      console.log('DaySliderRange.watch valueRange', newv, oldv)
      this.onChangeRange(this.valueRange)
    },
    show (newr, oldr) {
      if (newr) {
        this.formatRange(this.value)
      }
    }
  }
}
</script>

<style>
.vue-slider-rail .vue-slider-dot-tooltip-show {
  transition: opacity 300ms;
}
.val1-hide .vue-slider-rail div:nth-of-type(3) .vue-slider-dot-tooltip-show {
  opacity: 0;
}
.vue-slider-dot {
  position: absolute;
  will-change: transform;
  -webkit-transition: all 0s;
  transition: all 0s;
  z-index: 9 !important;
}

.vue-slider-dot-handle::after {
  background-color: rgba(52, 152, 219, 0.38);
}

.vue-slider-dot-handle {
  background-color: white;
  box-shadow: 0 1px 2px rgba(0,0,0,.25);
  border: 0.25px solid #BCBDBC;
}

.vue-slider-dot-handle.active {
box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.vue-slider-dot-handle:hover {
box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
}

.vue-slider-rail {
  background-color: #BCBDBC;
}

.vue-slider-process {
  background-color: #2D9BDB;
  z-index: 8;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.vue-slider-dot-tooltip-inner-top {
  border-radius: 3px;
  transform: translate(0, 20%) !important;
  background-color: #2D9BDB;
  color: white;
}

.vue-slider-marks {
  z-index: 8;
  overflow: visible;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.vue-slider-marks .custom-step{
  border-color: #BCBDBC;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  background-color:white;
  width:6px;
  height:6px;
  margin-top:-3px;
  margin-left:-3px;
}

.vue-slider-marks .custom-step.active {
  border-color: #2D9BDB;
}

.vue-slider-marks .vue-slider-mark-label {
  z-index:9 !important;
  left:-70% !important;
  transform: none !important;
}

.vue-slider-mark:nth-child(even) .custom-step {
  border-radius:0;
  width:0px;
  height:2px;
}

.vue-slider-mark:nth-child(even) .vue-slider-mark-label {
  display:none
}

.vue-slider-ltr .vue-slider-mark {
  z-index:9;
  top:0 !important;
  overflow:visible !important;
  transform: none !important;
  margin-left: -2px;
}

.vue-slider-dot-tooltip-inner-top .vue-slider-dot-tooltip-text {
  width:80px;
  font-size:0.9em;
  transform: none !important;
}

.day-slider.slider-wrapper {
  position: relative;
  width:calc(100% - 64px);
  height:50px;
  padding-top: 6px;
  padding-bottom: 26px;
  margin-right:32px;
  margin-left:32px;
}
</style>
