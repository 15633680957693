<template>
  <div v-if="!hasFeedback" class="feedback-bar-container">
    <div class="feedback-bar">
      <div class="feedback-bar-background"></div>
      <span class="feedback-bar-text">{{message}}</span>
      <round-button @click="open = true" style="padding-left:16px;margin:6px;margin-left:16px;" hasSlot>{{feedbackType}} Feedback</round-button>
    </div>

    <app-dialog
        v-model="open"
        @click.native.prevent.stop
        class="connection-modal fullsize-mobile"
        :title="feedbackType"
        accept="Send feedback"
        cancel="Cancel"
        @accept="onAccept"
        @close="onDecline">

        <mdc-layout-grid>
          <mdc-layout-cell desktop="12" tablet="8" phone="4">
            <mdc-body typo="body2">Any and all feedback, we'd love to hear it!</mdc-body>
          </mdc-layout-cell>
          <mdc-layout-cell desktop="12" tablet="8" phone="4">
            <div style="width:100%;" class="mdc-textfield-wrapper message-wrap">
                <mdc-text-area-autosize
                  :minHeight="82"
                  :maxHeight="300"
                  style="min-height:92px;"
                  label="Feedback"
                  ref="messageInput"
                  v-model="feedback"
                  outlined
                  maxlength="1200">
                  <mdc-text-field-counter slot="counter">0 / 1200</mdc-text-field-counter>
                </mdc-text-area-autosize>
            </div>
          </mdc-layout-cell>
        </mdc-layout-grid>
      </app-dialog>

  </div>

</template>

<script>
import { mapState, mapActions } from 'vuex'
import AppDialog from '../components/AppDialog'
import RoundButton from '../components/RoundButton.vue'
import { mail } from '../utils/backend'

export default {
  name: 'FeedbackBar',
  components: {
    AppDialog,
    RoundButton
  },
  props: {
    message: {
      default: ''
    },
    feedbackType: {
      default: ''
    }
  },
  data () {
    return {
      open: false,
      feedback: ''
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      profile: state => state.profile
    }),
    hasFeedback () {
      if (this.profile.feedback) {
        return this.profile.feedback[this.feedbackType]
      }
      return false
    }
  },
  watch: {
  },
  methods: {
    ...mapActions([
      'updateProfile',
      'setSnackMessage'
    ]),
    onAccept () {
      this.mail()
    },
    onDecline () {
      this.feedback = ''
    },
    mail () {
      const mailMessage = {
        email: this.user.email,
        name: this.profile.displayName,
        profileId: this.profile.id,
        section: this.feedbackType,
        feedback: this.feedback
      }
      mail('send-feedback', mailMessage)
      if (!this.profile.feedback) {
        this.profile.feedback = {}
      }
      this.profile.feedback[this.feedbackType] = true
      this.updateProfile({ profile: { feedback: this.profile.feedback }, noSnack: true })
      this.setSnackMessage('Feedback sent')
    }
  }

}
</script>

<style>
.feedback-bar-container {
  position: absolute;
  overflow: hidden;
  top:110px;
  z-index:9;
  width:calc(100%);
}
.mdc-dialog-scroll-lock .feedback-bar-container {
  z-index:99;
}
.feedback-bar-background {
  background-color: #C7EDED;
  opacity:0.6;
  width:100%;
  height:100%;
  position:absolute;
  z-index:-1;
}
.feedback-bar {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width:100%;
  height: auto;
}
.feedback-bar .message-wrap {
  width:100%;
}
.feedback-bar-text {
  font-size: 14px;
  color: #fff;
  margin:6px;
}
.feedback-bar-action {
  margin-left: auto;
  padding-left: 48px;
}
.feedback-bar-action button {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  text-transform: uppercase;
  color: #2D9CDB;
}
.feedback-bar-toggle-transition {
  transition: transform 0.3s ease;
}
.feedback-bar-toggle-transition .feedback-bar-text,
.feedback-bar-toggle-transition .feedback-bar-action {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.feedback-bar-toggle-enter,
.feedback-bar-toggle-leave {
  transform: translateY(60px);
}
.feedback-bar-toggle-enter .feedback-bar-text,
.feedback-bar-toggle-leave .feedback-bar-text,
.feedback-bar-toggle-enter .feedback-bar-action,
.feedback-bar-toggle-leave .feedback-bar-action {
  opacity: 0;
}
</style>
