import fb from './firebase'

const state = {
  duo: null,
  duos: null
}

const duosRef = fb.firestore.collection('duos')
const connectionRef = fb.firestore.collection('connections')

let duoListener
let duosListener

const resetDuoListener = () => {
  if (duoListener) { duoListener() }
}
const resetDuosListener = () => {
  if (duosListener) { duosListener() }
}

const getShareAgreement = async (duo, connectionId) => {
  if (!duo.shareAgreementId) {
    const saSnap = await connectionRef.doc(connectionId).collection('shareAgreement').get()
    const saDoc = saSnap.docs[0]
    if (saDoc) {
      return saDoc.id
    }
  }
  return null
}

const checkShareAgreementSet = async (duo, connectionId) => {
  if (!duo.shareAgreementId) {
    const shareAgreementId = await getShareAgreement(duo, connectionId)
    if (shareAgreementId) {
      return duosRef.doc(duo.id).update({ shareAgreementId })
    }
  }
  return duo
}

const addDuoForConnection = async (connectionId, connection) => {
  const newDuoTemplate = {
    connection: connectionId,
    viewed: 0,
    users: connection.users,
    public: false,
    coverText: '',
    covers: [],
    shareEmails: [],
    agreement: {},
    agreementDates: {},
    status: 'Not Active'
  }
  newDuoTemplate.users = connection.users
  const shareAgreementId = await getShareAgreement(newDuoTemplate, connectionId)
  newDuoTemplate.shareAgreementId = shareAgreementId
  return duosRef.add(newDuoTemplate)
}

const getDuoByConnection = async (commit, connectionId, userId) => {
  const conDoc = await connectionRef.doc(connectionId).get()
  if (!conDoc.exists) {
    // no such connection
    return null
  }
  const connection = conDoc.data()

  // Try get Duo
  const snapshot = await duosRef
    .where(`users.${userId}`, '==', true)
    .where('connection', '==', connectionId)
    .get()
  if (snapshot.docs.length === 0) {
    // Add if none exitsing
    addDuoForConnection(connectionId, connection)
  }

  // Setup Listener
  resetDuoListener()
  duoListener = duosRef
    .where(`users.${userId}`, '==', true)
    .where('connection', '==', connectionId)
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(sdoc => {
        const duo = { ...sdoc.data(), id: sdoc.id }
        checkShareAgreementSet(duo, connectionId)
        commit('setDuo', duo)
      })
    })
}

const loadDuos = (commit, userId) => {
  resetDuosListener()
  duosListener = duosRef
    .where(`users.${userId}`, '==', true)
    .onSnapshot(querySnapshot => {
      const duos = []
      querySnapshot.forEach(sdoc => {
        duos.push({ ...sdoc.data(), id: sdoc.id })
      })
      commit('setDuos', duos)
    })
}

const getDuo = async (duoId) => {
  const doc = await duosRef
    .doc(duoId)
    .get()
  if (!doc.exists) {
    return null
  }
  return doc.data()
}

const setDuo = (duoId, data) => {
  return duosRef
    .doc(duoId)
    .set(data, { merge: true })
}

const mutations = {
  setDuo: (state, duo) => {
    state.duo = duo
  },
  setDuos: (state, duos) => {
    state.duos = duos
  }
}

const actions = {
  loadDuos ({ commit }) {
    const userId = this.state.user.uid
    return loadDuos(commit, userId)
  },
  getDuoByConnection ({ commit }, { connectionId, userId }) {
    return getDuoByConnection(commit, connectionId, userId)
  },
  getDuoConnection ({ commit }, { duoId }) {
    return getDuo(duoId)
  },
  setDuoConnection ({ commit }, { duoId, duo }) {
    return setDuo(duoId, duo)
  }
}

export default { state, actions, mutations, resetDuoListener, resetDuosListener }
