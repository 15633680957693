<template>
  <div class="jobrole-card">
    <div style="display:flex;" class="jobrole-card-wrapper">
      <mdc-card class="jobrole-wrapper">
        <div class="jobrole"
        v-bind:class="{ active: isActive }"
        @click="isActive = !isActive"
        >
          <div class="jobrole-image">
            <div class="company-logo-wrapper">
              <img v-if="jobrole.image" onerror='this.onerror = null; this.src="/static/img/icons/missing.png"' :src="jobrole.image" class="company-logo"/>
            </div>
          </div>

          <div style="position: absolute; top:2px; right:2px;" v-if="bookmarked">
            <round-button ripple style="margin:0;box-shadow:none !important" background="transparent !important" class="bookmark" @click.prevent.stop="unbookmark()" label="unbookmark" title="unbookmark">
                <img slot="leading-icon" class="icon bookmark-icon" src="/static/img/icons/bookmark-on.svg">
            </round-button>
          </div>

          <div style="position: absolute; top:2px; right:2px;" v-else>
            <round-button ripple style="margin:0;box-shadow:none !important" background="transparent !important" class="bookmark" @click.prevent.stop="bookmark()" label="bookmark" title="bookmark">
                <img slot="leading-icon" class="icon bookmark-icon" src="/static/img/icons/bookmark-off.svg">
            </round-button>
          </div>

        </div>
      </mdc-card>

      <div class="jobrole-text">
        <div>
          <mdc-subheading style="font-size:14px;margin-bottom:0px;" class="jobrole-headline" v-html="jobrole.title"></mdc-subheading>
          <mdc-body tag="div" class="value jobrole-location truncate-overflow" v-html="jobrole.location"></mdc-body>
          <mdc-body tag="div" class="value">Posted {{diffDays(jobrole.date)}}</mdc-body>
        </div>
      </div>

    </div>

    <div class="expand-btn" style="display:flex;align-items:center;">
      <div v-if="jobCategory" style="margin-left:2px;width: 120px;display: flex;align-items: center;" class="value" :class="{padded: interestCount > 0}">
        <img style="height:20px;" :src="`/static/img/icons/${jobCategory.toLowerCase().replace(/ /g, '_').replace('+', '')}.svg`"/>
        <mdc-body v-if="jobCategory==='Supports Flexible Working'" class="jobrole-category">Supports Flex</mdc-body>
        <mdc-body v-else class="jobrole-category">{{jobCategory}}</mdc-body>
      </div>
      <mdc-body v-if="interestCount > 0" style="height:36px;line-height:36px;display:flex;width:124px;" class="value">
        <img style="margin-right:2px;margin-top:5px;height:20px" src="/static/img/icons/people_shares_outline_light.svg"/>
        <span class="notification-dot"></span>
        {{interestCount}} Interested
      </mdc-body>
      <mdc-button v-else style="margin-left:10px;">
        MORE
      </mdc-button>
    </div>

  </div>
</template>

<script>
import { diffNowDays } from '../../utils/time'
import { mapState, mapActions, mapGetters } from 'vuex'
import { jobShareFilter, remoteWorkFilter, flexHoursFilter } from '../../utils/filters'
import RoundButton from '../../components/RoundButton.vue'

export default {
  name: 'JobRoleCardMobile',
  computed: {
    ...mapState([
      'profile'
    ]),
    ...mapState({
      featuredCompanies: state => state.company.featuredCompanies
    }),
    ...mapGetters([
      'getJobData'
    ]),
    jobCategory () {
      if (this.jobrole) {
        const description = `${this.jobrole.title} ${this.jobrole.category} ${this.jobrole.description}`.toLowerCase()
        const includesShare = jobShareFilter.some(substring => description.includes(substring.toLowerCase()))
        const includesRemote = remoteWorkFilter.some(substring => description.includes(substring.toLowerCase()))
        const includesFlex = flexHoursFilter.some(substring => description.includes(substring.toLowerCase()))
        if (this.jobrole.company) {
          if (this.featuredCompanies) {
            const featuredCompany = this.featuredCompanies[this.jobrole.company]
            if (featuredCompany) {
              if (featuredCompany.overwriteJobCategory) {
                return featuredCompany.overwriteJobCategory
              }
            }
          }
        }
        if (includesShare) {
          if (includesRemote || includesFlex) {
            return 'Job Share+'
          }
          return 'Job Share'
        } else if (includesRemote) {
          if (includesFlex) {
            return 'Remote Work+'
          }
          return 'Remote Work'
        } else if (includesFlex) {
          return 'Flex Time'
        }
      }
      if (this.likelyFlexFilter) {
        return 'Likely Flexible'
      }
      return ''
    }
  },
  data () {
    return {
      bookmarked: false,
      isActive: false,
      interest: [],
      interestCount: 0,
      highlightElement: 0
    }
  },
  mounted () {
    this.bookmarked = this.isBookmarked()
  },
  methods: {
    ...mapActions([
      'updateProfile'
    ]),
    isBookmarked () {
      return this.jobrole.id in this.profile.bookmarks
    },
    bookmark () {
      this.profile.bookmarks[this.jobrole.id] = { type: 'job' }
      this.bookmarked = true
      this.updateProfile({ profile: { bookmarks: this.profile.bookmarks }, noSnack: true })
    },
    unbookmark () {
      if (this.profile.booksmarks) {
        delete this.profile.bookmarks[this.jobrole.id]
      }
      this.bookmarked = false
      this.updateProfile({ profile: { bookmarks: this.profile.bookmarks }, noSnack: true })
    },
    diffDays (created) {
      var daysago = 0
      if (created) {
        if (created.toDate) {
          created = created.toDate()
        }
        daysago = diffNowDays(created)
      }
      if (daysago === 0) {
        return 'Today'
      }
      if (daysago === 1) {
        return 'Yesterday'
      }
      return `${daysago} Days ago`
    },
    setInterestCount () {
      this.interestCount = 0
      if (this.jobrole.id) {
        this.getJobData(this.jobrole.id).then((jobData) => {
          if (jobData) {
            this.interest = jobData.interest || {}
            this.interestCount = jobData.interest ? Object.keys(this.interest).length : 0
          }
        })
      }
    }
  },
  watch: {
    jobrole: {
      handler () {
        if (this.jobrole) {
          if (this.jobrole.id) {
            this.setInterestCount()
          }
        }
      },
      immediate: true
    }
  },
  components: {
    RoundButton
  },
  props: {
    jobrole: {
      type: Object,
      default: function () {
        return {}
      }
    },
    active: {
      type: Boolean,
      default: false
    },
    likelyFlexFilter: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
em.text-highlight {
  font-style: normal;
  border-radius: 1em 0 1em 0;
  text-shadow: 1px 1px 1px #fff;
  background-image: linear-gradient(-100deg, rgba(255,250,150,0.15), rgba(248, 181, 23, 0.65) 100%, rgba(255,250,150,0.25) );
  padding:2px;
}
</style>
<style scoped>
.value.padded {
  padding-right:20px;
}
.dot-spacer {
  position: relative;
  top: 2px;
}
.jobrole-card-mobile .bookmark-icon {
  min-width:20px;
  width:20px;
  min-height:20px;
  height:20px;
}
.jobrole-card-mobile {
  margin-left: auto;
  margin-right: auto;
}

.jobrole-wrapper {
  overflow: hidden;
  background-color: white;
  position: relative;
  border-radius: 13px;
  width: 100%;
  overflow: hidden;
  height: 82px;
  width:124px;
  min-width:124px;
  position: relative;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}
.jobrole-card {
  padding-left: 16px;
  padding-right: 16px;
  min-width: 255px;
  max-width: unset;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 570px) {
    .jobrole-card {
        padding-left: 0px;
        padding-right: 0px;
    }
}
.jobrole {
  display:flex;
  flex-flow: row;
  position: absolute;
  top: 0;
  left: 0;
  width: 101%;
  height: 100%;
  align-items: center;
}
.jobrole .icon {
  color: #828282;
}

.truncate-overflow {
  line-height: 1em;
  position: relative;
  max-height: 2em;
  overflow: hidden;
  word-break: break-word;
  padding-right: 1rem; /* space for ellipsis */
}
.truncate-overflow::before {
  position: absolute;
  content: "...";
  right: 0;
  bottom: 0;
  height: 1rem;
  width: 1rem;
  inset-block-end: 0; /* "bottom" */
  inset-inline-end: 0; /* "right" */
}
.truncate-overflow::after {
  content: "";
  position: absolute;
  inset-inline-end: 0; /* "right" */
  right:0;
  width: 1rem;
  height: 1rem;
  background: white;
}

.jobrole-location {
  max-height: 28px;
  overflow: hidden;
}

.notification-dot {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 100%;
  background-color: #FFAB40;
  font-size: 9px;
  z-index: 1;
  text-align: center;
  position: absolute;
  margin-left:14px;
  margin-top:20px;
}
.jobrole-image {
  max-width: 100%;
  max-height: 100%;
  flex:1;
}
.expand-btn .mdc-button{
  color:rgb(130, 130, 130);
}
.jobrole-headline {
  overflow: hidden;
  max-height: 40px;
  overflow: hidden;
  line-height: 20px;
}
.jobrole-text {
  text-align: left;
  margin-left:16px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.link-row {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.link-row > div {
  flex: 1;
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
}
.link-row-top > div {
  flex: 0;
}
.link-row .mdc-icon.duome-icon {
    margin-right: 3px;
}
.link-row-bottom > div:nth-of-type(2),
.link-row-bottom > div:nth-of-type(3) {
    flex: 0 0 20%;
    min-width: 30px;
    justify-content: flex-end;
}
.jobrole-stat {
    display: flex;
    flex-direction: column;
    line-height: 0;
    flex: 1;
}
.jobrole-card .mdc-subheading {
  margin:0;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.jobrole-card .mdc-body {
  margin:0;
  font-size: 12px;
  line-height: 1.3;
}
span[class^="days-"] {
  margin-top: 3px;
  white-space: nowrap;
}
span[class^="days-"] .day {
  height: 2px;
  background: #E0E0E0;
  display: inline-block;
  width: 13%;
  margin: 0 0.1%;
}

span.days-1 .day:nth-of-type(-n+1) {
  background: #AD1457;
}
span.days-2 .day:nth-of-type(-n+2) {
  background: #AD1457;
}
span.days-3 .day:nth-of-type(-n+3) {
  background: #AD1457;
}
span.days-4 .day:nth-of-type(-n+4) {
  background: #AD1457;
}
span.days-5 .day:nth-of-type(-n+5) {
  background: #AD1457;
}
.jobrole-status-bar {
  display:flex;
  width:100%
}
.jobrole-status-bar {
  display:flex;
  width:100%
}
.jobrole-details {
  padding: 5% 2% 0 2%;
  font-family: 'Roboto', sans-serif;
  background: none;
  box-shadow: none;
}
.jobrole-details .value {
    display: block;
    line-height: 1;
    color: #333333;
    min-height: 9px;
}
.jobrole-details .field {
  font-style: italic;
  margin-top: 3px;
}
.jobrole-details .duome-icon img {
  width:20px;
  height: 20px;
}

.company-logo-wrapper,
.company-logo-wrapper img {
    max-width: 100%;
    max-height: 44px;
}
.company-logo-wrapper {
    padding: 0 10%;
    display:flex;
    justify-content: center;
}
.bookmark {
  cursor: pointer;
  color: #333333;
}
span[class^="days-"] {
  margin-top: 3px;
  position:relative;
}
span[class^="days-"] .day {
  height: 2px;
  background: #E0E0E0;
  display: inline-block;
  width: 13%;
  margin: 0 0.1%;
}
span[class^="days-"] .halfday {
  position:absolute;
  height: 2px;
  background: #E0E0E0;
  display: inline-block;
  width: 7%;
  margin: 0 0.1%;
  z-index:-1;
  bottom:0px;
}
span.days-0-5 .halfday:nth-of-type(-n+1) {
  background: #AD1457;
  z-index: 1;
}
span.days-1 .day:nth-of-type(-n+2) {
  background: #AD1457;
  z-index: 1;
}
span.days-1-5 .day:nth-of-type(-n+2) {
  background: #AD1457;
  z-index: 1;
}
span.days-1-5 .halfday:nth-of-type(-n+3) {
  background: #AD1457;
  z-index: 1;
}
span.days-2 .day:nth-of-type(-n+4) {
  background: #AD1457;
  z-index: 1;
}
span.days-2-5 .day:nth-of-type(-n+4) {
  background: #AD1457;
  z-index: 1;
}
span.days-2-5 .halfday:nth-of-type(-n+5) {
  background: #AD1457;
  z-index: 1;
}
span.days-3 .day:nth-of-type(-n+6) {
  background: #AD1457;
  z-index: 1;
}
span.days-3-5 .day:nth-of-type(-n+6) {
  background: #AD1457;
  z-index: 1;
}
span.days-3-5 .halfday:nth-of-type(-n+7) {
  background: #AD1457;
  z-index: 1;
}
span.days-4 .day:nth-of-type(-n+8) {
  background: #AD1457;
  z-index: 1;
}
span.days-4-5 .day:nth-of-type(-n+8) {
  background: #AD1457;
  z-index: 1;
}
span.days-4-5 .halfday:nth-of-type(-n+9) {
  background: #AD1457;
  z-index: 1;
}
span.days-5 .day:nth-of-type(-n+10) {
  background: #AD1457;
  z-index: 1;
}
</style>
