<template>
  <div >
    <div class="action-button" v-if="connection && connection.isWelcome !== true && companyChemistryDisabled !== true">
      <v-popover
        v-if="connection.nextAction.tooltip && tooltipConditional(connection.nextAction.tooltipAction, connection.nextAction.tooltipIf)"
        offset="1"
        delay="100"
        container='body'
        popoverClass="next-action"
        placement='bottom-end'
        :disabled="false"
        trigger="hover focus"
      >
        <mdc-button
          outlined
          class="action-button"
          v-bind:class="{active: true}"
          :disabled="connection.nextAction.disabled || chemistryDisabled"
          >
          <i v-if="nextActionIcon" class="material-icons mdc-button__icon">{{nextActionIcon}}</i>
          {{connection.nextAction.text}}
        </mdc-button>

        <template slot="popover">
          <mdc-title v-if="connection.nextAction.tooltipTitle">{{connection.nextAction.tooltipTitle}}</mdc-title>
          <div>
            <p>{{connection.nextAction.tooltipText}}</p>
          </div>
          <div style="width:100%;text-align:center">
            <round-button
              style="display: inline-flex;"
              hasSlot
              high
              class="tooltip-btn"
              v-if="connection.nextAction.tooltipAction"
              :disabled="connection.nextAction.tooltipActionDisabled"
              @click="tooltipAction(connection.nextAction.tooltipAction)"
              :icon="connection.nextAction.tooltipActionIcon">
              <img v-if="connection.nextAction.tooltipActionIconSvg" style="margin-left:6px;margin-right:8px;" slot="leading-icon" class="icon" :src="'/static/img/icons/' + connection.nextAction.tooltipActionIconSvg">
              <span v-if="connection.nextAction.tooltipActionDisabled">{{connection.nextAction.tooltipActionDisabledText}}</span>
              <span v-else>{{connection.nextAction.tooltipActionText}}</span>
            </round-button>
            <a v-if="connection.nextAction.tooltipClose" class="mdc-button" v-close-popover><mdc-icon style="height: 24px;" icon="clear"/> CLOSE</a>
          </div>
        </template>

      </v-popover>
      <mdc-button
        v-else
        outlined
        class="action-button"
        v-bind:class="{active: true}"
        @click="buttonAction(connection.nextAction)"
        :disabled="connection.nextAction.disabled || chemistryDisabled"
        >
        <i v-if="nextActionIcon" class="material-icons mdc-button__icon">{{nextActionIcon}}</i>
        {{connection.nextAction.text}}
        </mdc-button>

        <app-dialog
        v-model="requestSentOpen"
        :title="'Access Requested'"
        accept="OK"
        >
          <div style="padding:24px;white-space:normal">
            A member of the DuoMe team will be in touch with you shortly to provide access
          </div>
        </app-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RoundButton from '../components/RoundButton'
import AppDialog from '../components/AppDialog'
import { mail } from '../utils/backend'

export default {
  name: 'NextActionButton',
  data () {
    return {
      icons: {
        'Start Chemistry': 'supervisor_account'
      },
      chemistryDisabled: true,
      requestSentOpen: false
    }
  },
  components: {
    AppDialog,
    RoundButton
  },
  props: {
    connection: Object
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'profiles',
      'company'
    ]),
    nextActionIcon () {
      return this.icons[this.connection.nextAction.text]
    },
    companyChemistryDisabled () {
      if (this.company.assignedCompany && this.company.assignedCompany.chemistryDisabled) {
        return true
      }
      return false
    }
  },
  mounted () {
    this.isChemistryDisabled()
  },
  watch: {
    connection: function (newc, oldc) {
      this.isChemistryDisabled()
    },
    profile: function (newp, oldp) {
      this.isChemistryDisabled()
    },
    profiles: function (newp, oldp) {
      this.isChemistryDisabled()
    }
  },
  methods: {
    ...mapActions([
      'setSnackMessage',
      'updateConnection',
      'updateProfile'
    ]),
    isChemistryDisabled () {
      this.chemistryDisabled = true
      if (this.connection) {
        this.chemistryDisabled = !(this.otherUser().profileChemistry && this.profile.profileChemistry)
      }
    },
    tooltipConditional (action, field) {
      if (action === 'requestChemistry') {
        if (this.profile) {
          if (this.profile[field]) {
            return false
          }
        }
      }
      return true
    },
    mailChemistryNotification () {
      this.requestSentOpen = true
      const mailMessage = {
        email: this.user.email,
        name: this.profile.displayName,
        profileId: this.profile.id
      }
      mail('chemistry-request', mailMessage)
    },
    tooltipAction (action) {
      if (!this.connection.nextAction.tooltipActionDisabled) {
        if (action === 'requestChemistry') {
          this.mailChemistryNotification()
          this.connection.nextAction.tooltipActionIconSvg = null
          this.connection.nextAction.tooltipActionIconSvg = null
          this.connection.nextAction.tooltipActionIcon = 'done'
          this.connection.nextAction.tooltipActionDisabled = true
          this.connection.nextAction.tooltipActionDisabledText = 'Requested'
          this.updateConnection({ connectionId: this.connection.id, connection: { nextAction: this.connection.nextAction } })
        }
      }
    },
    buttonAction (btn) {
      // console.log('NextActionButton.buttonAction', btn)
      const [command, param] = btn.action.split(':')
      if (command === 'goto') {
        // Go to page
        this.$router.push(`/${param}/${this.connection.id}`)
      } else if (command === 'setStatus') {
        // Set the connection status directly without messages
        this.updateConnection({ connectionId: this.connection.id, connection: { status: param } })
      } else if (command === 'action') {
        // Emit the param to the root component
        this.$root.$emit('action', param)
        if (param === 'ChemistryRequest') {
          // eslint-disable-next-line no-undef
          analytics.track('startChemistry', {
            id: this.user.uid,
            journeyStage: this.profile.journeyStage,
            role: this.profile.role,
            locationFull: this.profile.locationFull,
            flexschedule: this.profile.flexschedule,
            company: this.profile.company
          })
        }
      } else {
        // Emit the whole action to the root component
        this.$root.$emit('action', btn.action)
      }
      if (btn.once) {
        btn.disabled = true
        this.updateConnection({ connectionId: this.connection.id, connection: { nextAction: btn } })
      }
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      if (profileId === 'bot') {
        return {
          displayName: 'DuoMe Bot',
          photoURL: '/static/img/chat-bubble-circle.svg'
        }
      }
      const profileMap = new Map(this.profiles.map(i => [i.id, i]))
      return profileMap.get(profileId)
    },
    otherUser: function () {
      if (this.connection) {
        if (this.profile) {
          const usersCopy = Object.assign({}, this.connection.users)
          delete usersCopy[this.profile.id]
          if (Object.keys(usersCopy).length === 1) {
            const profileId = Object.keys(usersCopy)[0]
            return this.getProfile(profileId)
          }
        }
      }
      return {}
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.action-button {
  text-transform: none;
  font-size: 14x;
  white-space: nowrap;
  height:28px;
  color: #666666;
  border-color: #666666;
}
</style>
<style>
.next-action.tooltip.popover .popover-inner {
  max-width: 250px !important;
}
.next-action.tooltip.popover .popover-inner .mdc-button.tooltip-btn.disabled {
  background: transparent !important;
}
.next-action.tooltip.popover {
  margin-top: 0 !important;
}
</style>
