<script>
import { Doughnut } from 'vue-chartjs'

export default {
  name: 'Doughnut',
  extends: Doughnut,
  props: {
    chartData: {},
    chartOptions: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      htmlLegend: null
    }
  },
  mounted () {
    console.log('DoughnutChart.mounted', this)
    this.renderChart(this.chartData, this.chartOptions)
    this.htmlLegend = this.generateLegend()
    console.log(this.$el)
    this.$emit('legend', this.htmlLegend)
  },
  watch: {
    chartData () {
      console.log('DoughnutChart.chartData - watch', this)
      // this.$data._chart.update()
    }
  }
}
</script>

<style>
.legend {
  list-style: none;
  display:flex;
  flex-flow:row wrap;
  margin-left:auto;
  margin-right:auto;
  align-items: center;
}

.legend .legend-title {
  font-weight:500;
  margin-right: 16px;
  width: 100%;
  margin-bottom: 6px;
}

.legend li:not(.legend-title) {
  margin-right: 10px;
  align-items: center;
  flex:0;
  white-space:nowrap;
  display: flex;
  font-size: 0.9em;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
  margin-top: 0;
}

.legend span
{
  border: 1px solid #ccc;
  width: 30px;
  height: 12px;
  margin: 2px;
  margin-right: 6px;
}
</style>
