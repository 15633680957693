<template>
  <div class="schedule">
    <template v-if="schedule && profile">
        <div class="button-row weekly-buttons" v-if="schedule">
        <round-button style="padding-left:16px;border:1px solid" :medium="schedule.peerPlan" @click="togglePeer" hasSlot>Peer Plan</round-button>
        <round-button style="padding-left:16px;border:1px solid" :medium="schedule.flashChat" @click="toggleFlash" hasSlot>Flash Chat</round-button>
        <round-button style="padding-left:16px;border:1px solid" :medium="schedule.coffeeChat" @click="toggleCoffee" hasSlot>Coffee Chat</round-button>
        </div>
        <div class="button-row mood-row">
          <mdc-body typo="body2">I have everything I need to be productive whilst remote</mdc-body>
          <round-button background="transparent" :class="{selected: schedule.productivityPos}" @click="togglePositive" class="mood" icon="mood">
          </round-button>
          <round-button background="transparent" :class="{selected: schedule.productivityNeg}" @click="toggleNegative" class="mood-bad" icon="mood_bad">
          </round-button>
        </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'RemoteConnect',
  props: {
    teamId: {},
    scheduleId: {}
  },
  data () {
    return {
      scheduleSubmitted: false,
      schedule: false
    }
  },
  computed: {
    ...mapState([
      'profile'
    ])
  },
  methods: {
    ...mapActions([
      'getScheduleByRequest',
      'saveSchedule'
    ]),
    togglePositive () {
      this.schedule.productivityNeg = false
      this.schedule.productivityPos = !this.schedule.productivityPos
      this.schedule = { ...this.schedule }
      this.submitSchedule()
    },
    toggleNegative () {
      this.schedule.productivityPos = false
      this.schedule.productivityNeg = !this.schedule.productivityNeg
      this.schedule = { ...this.schedule }
      this.submitSchedule()
    },
    togglePeer () {
      this.schedule.peerPlan = !this.schedule.peerPlan
      this.schedule = { ...this.schedule }
      this.submitSchedule()
    },
    toggleFlash () {
      this.schedule.flashChat = !this.schedule.flashChat
      this.schedule = { ...this.schedule }
      this.submitSchedule()
    },
    toggleCoffee () {
      this.schedule.coffeeChat = !this.schedule.coffeeChat
      this.schedule = { ...this.schedule }
      this.submitSchedule()
    },
    submitSchedule () {
      this.saveSchedule({ teamId: this.teamId, schedule: this.schedule })
    },
    async getSchedule () {
      this.schedule = await this.getScheduleByRequest({ teamId: this.teamId, requestId: this.scheduleId })
    },
    initialise () {
      if (this.teamId && this.scheduleId) {
        this.getSchedule()
      }
    }
  },
  mounted () {
    this.initialise()
  },
  watch: {
    teamId () {
      this.initialise()
    }
  }
}
</script>
<style>
.schedule .button-row {
  display:flex;
}
.mood.selected {
  color: #24c104 !important;
}
.mood-bad.selected {
  color: #C10457 !important;
}
.mood-row {
  margin-top:6px;
  border-top:1px solid silver;
  display:flex;
  align-items: center;
}
.mood-row, .mood-row .mdc-button, .mood-row .mdc-body {
  color:#999;
}
.mood-row .mdc-button, .mood-row .mdc-body {
  margin-bottom:0 !important;
  margin-top:6px !important;
}
.mood-row .mdc-button {
  height: 21px;
  width:21px !important;
  min-width:21px !important;
}
.button-row.weekly-buttons .mdc-button {
    min-width: 120px;
    padding-left: 6px !important;
    padding-right: 6px !important;
}
@media (max-width: 440px) {
  .button-row.weekly-buttons {
    flex-flow: row wrap !important;
  }
}
</style>
