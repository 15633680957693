<template>
  <span class="share">
    <div class="button-row">
      <round-button style="margin-left:0"  @click.prevent.stop="openMenu" background="transparent" class="share" title="Share" iconOutline icon="share" label="Share"/>
      <span>Share your Profile</span>
    </div>

    <div class="mdc-menu-surface--anchor" style="">
      <mdc-menu class="sharemenu" v-model="open">
          <ShareNetwork class="mdc-menu-item mdc-list-item" v-for="network in networks" :title="sharingDetails.title" :network="network.network" :key="network.network" @open="analytics"  :url="sharingDetails.url" tag="li">
          <font-awesome-icon :icon="network.icon" />
           {{network.name}}
          </ShareNetwork>
      </mdc-menu>
    </div>
  </span>
</template>

<script>
import RoundButton from '../../components/RoundButton'
import { publicProfilesUrl } from '../../settings'
import { ShareNetwork } from 'vue-social-sharing'

export default {
  name: 'ShareSection',
  props: {
    shareopen: {
      type: Boolean,
      default: false
    },
    profile: {}
  },
  components: {
    RoundButton,
    ShareNetwork
  },
  computed: {
    sharingDetails () {
      if (this.profile) {
        return {
          url: `${publicProfilesUrl}/me/${this.profile.id}`,
          title: `DuoMe: ${this.profile.displayName}`
        }
      }
      return {}
    }
  },
  data () {
    return {
      open: false,
      publicProfilesUrl: this.publicProfilesUrl,
      networks: [
        { network: 'email', name: 'Email', icon: ['fa', 'envelope'] },
        { network: 'facebook', name: 'Facebook', icon: ['fab', 'facebook'] },
        { network: 'linkedin', name: 'Linkedin', icon: ['fab', 'linkedin'] },
        { network: 'twitter', name: 'Twitter', icon: ['fab', 'twitter'] },
        { network: 'whatsapp', name: 'Whatsapp', icon: ['fab', 'whatsapp'] }
      ]
    }
  },
  methods: {
    analytics (payload) {
      // eslint-disable-next-line no-undef
      analytics.track('shareProfile', {
        id: this.profile.id,
        role: this.profile.role,
        company: this.profile.company,
        journeyStage: this.profile.journeyStage,
        platform: payload
      })
    },
    openMenu () {
      console.log(window.navigator)
      if (window.navigator.share) {
        window.navigator.share({
          title: `DuoMe: ${this.profile.displayName}`,
          url: `${this.publicProfilesUrl}/me/${this.profile.id}`
        }).then(() => {
          console.log('Thanks for sharing!')
        })
          .catch(console.error)
      } else {
        this.open = true
      }
    }
  },
  watch: {
    shareopen (newo, oldo) {
      this.open = this.shareopen
    },
    open (newo, oldo) {
      if (this.open !== this.shareopen) {
        this.$emit('change', this.open)
      }
    }
  }
}
</script>

<style>
.share .button-row {
  display: flex;
  align-items:center;
  overflow: hidden;
  height: 41px;
}
.share .mdc-menu-surface--anchor {
  position: absolute;
  z-index: 999;
}
.share .mdc-menu-surface--anchor .mdc-menu-surface--open {
  min-width:155px;
  max-height: 220px !important;
}
.share .mdc-menu-surface--anchor .mdc-menu-surface--open .mdc-menu-item {
  flex-flow: row;
  flex-wrap: nowrap;
}
</style>
