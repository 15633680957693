<template>
  <div>
    <div v-if="!isMobile" class="feature-row">
      <carousel :per-page="perPage" mouse-drag
          ref="carousel"
          navigationEnabled
          navigationPrevLabel="<i data-mdc-ripple-is-unbounded class='material-icons mdc-ripple-surface demo-surface'>chevron_left</i>"
          navigationNextLabel="<i data-mdc-ripple-is-unbounded class='material-icons mdc-ripple-surface demo-surface'>chevron_right</i>"
          autoplay autoplayHoverPause loop
          :speed="500"
          :autoplayTimeout="10000">
        <slide v-for="company in companiesWithJobs" :key="company.id">
          <div class="feature-wrapper">
            <div class="feature" @click="$emit('filter', company)">
              <span class="feature-link" style="margin:5px;margin-left:24px;">
                <img class="feature-img" :src="company.image">
              </span>
              <span style="margin-top:6px;" class="feature-link-wrapper">
                <div class="mdc-subheading mdc-typography--subtitle2">{{company.displayName}}</div>
                <div class="sub-label" v-if="company.jobsOpen">
                  {{company.jobsOpen}}
                  <span v-if="company.jobsOpen===1">Flexible Role Open</span>
                  <span v-else>Flexible Roles Open</span>
                 </div>
                <div class="sub-label" v-if="company.supportJobShares"> Supports Job Shares</div>
              </span>
            </div>
          </div>
        </slide>

      </carousel>
    </div>
    <div v-else class="feature-row">
      <carousel :per-page="1" mouse-drag
          ref="carousel"
          navigationEnabled
          navigationPrevLabel="<i data-mdc-ripple-is-unbounded class='material-icons mdc-ripple-surface demo-surface'>chevron_left</i>"
          navigationNextLabel="<i data-mdc-ripple-is-unbounded class='material-icons mdc-ripple-surface demo-surface'>chevron_right</i>"
          autoplay autoplayHoverPause loop
          :speed="500"
          :autoplayTimeout="10000">
        <slide v-for="company in companiesWithJobs" :key="company.id">
          <div class="feature-wrapper">
            <div class="feature" @click="$emit('filter', company)">
              <span class="feature-link" style="margin:5px;margin-left:24px;">
                <img class="feature-img company-image" :src="company.image">
              </span>
              <span style="margin-top:6px;" class="feature-link-wrapper">
                <div class="mdc-subheading mdc-typography--subtitle2">{{company.displayName}}</div>
                <div class="sub-label" v-if="company.jobsOpen">
                  {{company.jobsOpen}}
                  <span v-if="company.jobsOpen===1">Flexible Role Open</span>
                  <span v-else>Flexible Roles Open</span>
                </div>
                <div class="sub-label" v-if="company.supportJobShares"> Supports Job Shares</div>
              </span>
            </div>
          </div>
        </slide>

      </carousel>
    </div>

  </div>
</template>

<script>

export default {
  name: 'CompanyFeatureSection',
  components: {
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isTablet: {
      type: Boolean,
      default: false
    },
    isTabletLarge: {
      type: Boolean,
      default: false
    },
    isDesktopLarge: {
      type: Boolean,
      default: false
    },
    companies: {
      type: Object,
      default: () => {}
    }
  },
  mounted () {
  },
  methods: {
    refresh () {
      console.log('CompanyFeatureSection.refesh', this.$refs.carousel)
      setTimeout(this.$refs.carousel.computeCarouselWidth, 300)
    }
  },
  computed: {
    perPage () {
      if (this.isMobile) {
        return 1
      } else if (this.isTablet) {
        return 1
      } else if (this.isTabletLarge) {
        return 2
      } else if (this.isDesktopLarge) {
        return 4
      } else {
        return 3
      }
    },
    companiesWithJobs () {
      if (this.companies) {
        return Object.keys(this.companies)
          .filter(key => this.companies[key].jobsOpen)
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: this.companies[key]
            }
          }, {})
      }
      return []
    }
  }
}
</script>

<style scoped>
.section-title {
  padding-left:var(--mdc-layout-grid-margin-desktop, 24px);
  padding-right:var(--mdc-layout-grid-margin-desktop, 24px);
}

.section-sub-title {
  padding-left:var(--mdc-layout-grid-margin-desktop, 24px);
  padding-right:var(--mdc-layout-grid-margin-desktop, 24px);
  margin-bottom:0;
  margin-top:0;
}
.feature-link {
  cursor:pointer;
  width: 124px;
  height:82px;
}
.feature-link .feature-img {
  flex:0;
  width:100px;
  max-height:80%;
}
.feature-row {
  display:flex;
  margin-top:10px;
}
.feature-row.desktoponly {
  margin-left:var(--mdc-layout-grid-margin-desktop, 24px);
  margin-right:var(--mdc-layout-grid-margin-desktop, 24px);
}
.feature-row.mobileonly {
  margin-left:var(--mdc-layout-grid-margin-tablet, 16px);
  margin-right:var(--mdc-layout-grid-margin-tablet, 16px);
}
.feature-wrapper {
  width:33%;
}
.VueCarousel .feature-wrapper {
  width:100%;
}
.feature {
  display:flex;
  flex-flow:row wrap;
  align-items:center;
}
.feature img {
  flex:1;
}
.feature-link-wrapper {
  padding:6px;
  flex:1;
  text-align:left;
  padding-left:12px;
}
.feature-padding.mobileonly {
  margin-top:26px;
}
.feature-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color:inherit;
  text-decoration:none;
  border-radius: 13px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}
.feature-link-wrapper p {
  margin-bottom:0;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54))
}
@media (max-width: 480px) {
  .feature-row.mobileonly {
    margin-left:0;
    margin-right:0;
  }
}
</style>

<style>
.feature-row .VueCarousel {
  width:100%;
}
.feature-row .VueCarousel .feature  .feature-link-wrapper {
  margin-right: 0px;
  margin-left: 0px;
}

@media (min-width: 480px) {
  .feature-row.mobileonly {
    margin-left:0;
    margin-right:0;
  }
  .feature-row .VueCarousel .feature  .feature-link-wrapper {
    margin-left: 0px;
  }
}

.feature-row .VueCarousel .feature .feature-link-wrapper  p {
  font-size: 12px;
}

.feature-row .VueCarousel-navigation-button:focus {
  outline: none !important;
}
.feature-row .VueCarousel-navigation-prev {
  margin:0;
  left:40px !important;
  margin-top:-15px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.feature-row .VueCarousel-navigation-next {
  margin:0;
  right:40px !important;
  margin-top:-15px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.feature-row .VueCarousel-navigation-button:focus{
  outline: none !important;
}
.feature-row .VueCarousel-dot-container {
  margin-top:0 !important;
}
.feature-row .VueCarousel-dot-container .VueCarousel-dot {
  margin-top:0 !important;
  outline: none !important;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.feature-row .mdc-ripple-surface::before, .mdc-ripple-surface::after {
    top: calc(50% - 100%);
    left: calc(50% - 100%);
    width: 200%;
    height: 200%;
}
.feature-row .mdc-ripple-surface[data-mdc-ripple-is-unbounded]::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded]::after {
    top: calc(50% - 50%);
    left: calc(50% - 50%);
    width: 100%;
    height: 100%;
}

.feature-row .mdc-ripple-surface::before, .mdc-ripple-surface::after {
    background-color: #000;
}
.feature-row .mdc-ripple-surface::before {
    transition: opacity 15ms linear;
    z-index: 1;
}
.feature-row .mdc-ripple-surface::before, .mdc-ripple-surface::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: "";
}
.feature-row .VueCarousel-dot::before, .feature-row .VueCarousel-dot::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: "";
    background-color: #000;
    top: calc(50% - 50%);
    left: calc(50% - 50%);
    width: 100%;
    height: 100%;
}
.feature-row .VueCarousel-dot:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: .12;
}
.feature-row .demo-surface {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 100px;
    padding: 1rem;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
}
.feature-row .demo-surface[data-mdc-ripple-is-unbounded] {
    width: 22px;
    height: 22px;
    padding: 10px;
}
.feature-row .mdc-ripple-surface[data-mdc-ripple-is-unbounded], .feature-row .VueCarousel-dot {
    overflow: visible;
}
.feature-link-wrapper .sub-label {
    font-style: italic;
    margin-bottom: 0px;
    line-height: 18px;
    margin-top: 2px;
}
.feature-image {
  flex: 0 !important;
  margin-left: 0 !important;
}
</style>
