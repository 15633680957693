<template>
  <div class="main-content">
    <mdc-textfield v-model="query"/>
    <mdc-button @click="loopSolrToTalent">Add to Talent</mdc-button>
    <br><hr>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import fb from '../store/firebase'
import * as backend from '../utils/backend'
const ID = function () {
  const rand = Math.random().toString(36)
  return rand.substr(2, 4) + '-' + rand.substr(5, 4)
}
// const comps = {
//   'HSBC': '61f7680e-3cd8-462f-8da0-48a8cb666a1e',
//   'JP': 'bc9f15e2-fc4c-4ce4-8e30-c5603b60d200',
//   'PWC': '9960ec78-b3fa-4590-8f3f-c2834bb2c378'
// }
export default {
  name: 'help',
  data () {
    return {
      msg: 'Help Page',
      company: '',
      jobTitle: '',
      jobDescription: '',
      query: 'category:"Sales"',
      open: false,
      openmenu: false,
      jobResults: []
    }
  },
  computed: {
    ...mapState([
      'user',
      'role',
      'profile'
    ])
  },
  methods: {
    ...mapActions([
      'setPageLoad'
    ]),
    testId () {
      console.log(ID())
    },
    async testAddCompanyToSearch () {
      const addCompanyToSearch = fb.functions.httpsCallable('addCompanyToSearch')
      const res = await addCompanyToSearch({
        company: {
          displayName: 'CV-Library',
          externalId: '22328'
        }
      })
      if (res.data.error) {
        console.log(res.data.error)
        return
      }
      const result = res.data.result
      console.log(result)
    },
    async testPatchJob () {
      const doJobSearch = fb.functions.httpsCallable('doJobSearch')
      const res = await doJobSearch({
        query: {
          query: 'JP Morgan'
        }
      })
      if (res.data.error) {
        console.log(res.data.error)
        return
      }
      const result = res.data.result
      console.log(result)

      const patchJob = fb.functions.httpsCallable('patchJob')
      result.matchingJobs.forEach(async function (value) {
        const job = value.job
        job.addresses = [
          '1 Knightsbridge, Belgravia, London SW1X 7LX'
        ]
        const res = await patchJob({
          job
        })
        if (res.data.error) {
          console.error(res.data.error)
        } else {
          const result = res.data.result
          console.log(result)
        }
      })
    },
    async testMessageSearch () {
      const res = await backend.search('messages', {
        query: {
          query: 'empir'
        }
      })
      if (res.error) {
        console.log(res.error)
        return
      }
      const result = res.result
      console.log(result)
    },
    async testSolrToTalent () {
      console.time('talent')
      const query = `q=${this.query}&rows=200&start=3201`
      const res = await backend.search('solr-to-talent', {
        query: { query }
      })
      console.log(res, query)
      console.timeEnd('talent')
    },
    async loopSolrToTalent () {
      console.log(this.query, 'start')
      let n = 1
      while (n < 3002) {
        const query = `q=${this.query}&rows=200&start=${n}`
        const res = await backend.search('solr-to-talent', {
          query: { query }
        })
        console.log(res, query)
        n = n + 200
      }
      console.log(this.query, 'finished')
    },
    async jobsSearch () {
      const res = await backend.search('jobs', {
        query: { query: `q=${this.query}&rows=100&start=1401` }
      })
      if (res.error) {
        console.log(res.error)
        return
      }
      const result = res.result.response.docs
      console.log(this.query)
      this.jobResults = result
    }
  },
  mounted () {
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-group-toggle > .btn input[type="checkbox"], .btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"] {
    position: static;
    clip: auto;
    pointer-events: none;
}
</style>
