<template>
  <div class="main-content search">
    <div class="main-wrapper">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Search',
  beforeDestroy () {
    this.$root.$emit('enableSearch', false)
  },
  mounted () {
    this.$root.$emit('enableSearch', true)
  }
}
</script>
