const randomColour = () => {
  const cardColours = ['cyan', 'magenta', 'orange', 'yellow']
  const rnd = Math.floor(Math.random() * (4))
  return cardColours[rnd]
}

export const initializeProfile = (profile, user) => {
  let updated = false
  if (!profile.emailVerified && user.emailVerified) {
    profile.emailVerified = true
    updated = true
  }
  if (!profile.id) {
    profile.id = user.uid
    updated = true
  }
  if (!profile.email) {
    profile.email = user.email
    updated = true
  }
  if (!profile.industry) {
    profile.industry = ''
    updated = true
  }
  if (!profile.displayName) {
    profile.displayName = user.displayName
    updated = true
  }
  if (!profile.firstName) {
    const names = profile.displayName.split(' ')
    profile.firstName = names[0]
    if (names.length > 1) profile.lastName = names[1]
    updated = true
  }
  if (!profile.photoURL) {
    if (user.photoURL) {
      profile.photoURL = user.photoURL
    }
    updated = true
  }
  if (!profile.cardDesign) {
    profile.cardDesign = 'profile-colour-' + randomColour()
    updated = true
  }
  if (!profile.cardDesignBackground) {
    profile.cardDesignBackground = 'profile-bg-bottom'
    updated = true
  }
  if (!profile.view) {
    profile.view = 'public'
    updated = true
  }
  if (!profile.title) {
    profile.title = { role: '' }
    updated = true
  }
  if (!profile.skills) {
    profile.skills = []
    updated = true
  }
  if (!profile.roles) {
    profile.roles = []
    updated = true
  }
  if (!profile.notes) {
    profile.notes = []
    updated = true
  }
  if (!profile.bookmarks) {
    profile.bookmarks = {}
    updated = true
  }
  if (!profile.coverImage) {
    profile.coverImage = '/static/img/cover-image/pattern4.jpg'
    updated = true
  }
  if (typeof profile.profileChemistry === 'undefined') {
    profile.profileChemistry = true
    updated = true
  }
  if (typeof profile.experienceCompleted === 'undefined') {
    profile.experienceCompleted = false
    updated = true
  }
  if (typeof profile.basicCompleted === 'undefined') {
    profile.basicCompleted = false
    updated = true
  }
  if (typeof profile.hotspotClicked === 'undefined') {
    profile.hotspotClicked = {}
    updated = true
  }
  if (typeof profile.hotspotSeen === 'undefined') {
    profile.hotspotSeen = {}
    updated = true
  }
  if (!profile.lastActive) {
    profile.lastActive = new Date()
    updated = true
  }
  if (typeof profile.interest === 'undefined') {
    profile.interest = []
    updated = true
  }
  if (typeof profile.profileAlerts === 'undefined') {
    profile.profileAlerts = true
    updated = true
  }
  if (typeof profile.managerEmail === 'undefined') {
    profile.managerEmail = ''
    updated = true
  }
  if (typeof profile.socialGroups === 'undefined') {
    profile.socialGroups = []
    updated = true
  }
  if (!profile.currentStatus) {
    profile.currentStatus = 'Not Set'
    updated = true
  }
  return updated
}
