<template>
  <div class="main-content">
    <div class="main-wrapper">
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ConnectMenu from '../components/ConnectMenu.vue'
export default {
  name: 'starred',
  data () {
    return {
      title: 'Starred',
      links: [
        { component: ConnectMenu }
      ]
    }
  },
  methods: {
    ...mapActions([
      'setPageLoad'
    ])
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
  },
  mounted () {
    this.$emit('setLinks', this.links)
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
