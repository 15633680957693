<script>
import { Bar } from 'vue-chartjs'

export default {
  name: 'Bar',
  extends: Bar,
  props: {
    chartData: {},
    chartOptions: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.chartOptions)
  },
  watch: {
    chartData () {
      this.$data._chart.update()
    }
  }
}
</script>

<style>
</style>
