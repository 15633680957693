<template>
  <div v-if="myProfile" class="myprofile">

    <div v-if="appName === 'duome-demo'" style="margin-bottom: 22px;flex-flow:row wrap;display:flex;justfy-contents:flex-start;">
      <mdc-button class="profile-modal-btn" v-if="!myProfile.basicCompleted" raised @click="openBasic">Complete Basic</mdc-button>
      <mdc-button class="profile-modal-btn" v-else @click="openBasic">Basic Completed</mdc-button>

      <mdc-button class="profile-modal-btn" v-if="!myProfile.basicCompleted" raised disabled >Complete Experience</mdc-button>
      <mdc-button class="profile-modal-btn" v-else-if="!myProfile.experienceCompleted" raised @click="openExp">Complete Experience</mdc-button>
      <mdc-button class="profile-modal-btn" v-else @click="openExp">Experience Completed</mdc-button>
    </div>

    <app-dialog
      v-model="basic"
      @accept="onBasicAccept"
      @close="onBasicDecline"
      @disabled-accept="saveFailed = true"
      :pauseFocus="isMobile"
      :acceptDisabled="!isBasicValid"
      class="profile-modal fullsize-mobile"
      :disabledMessage ="validationMsg"
      accept="Save"
      title="The Basics"
      subtitle="Answer these simple questions so we know how best to help you">

      <div class="mdc-dialog__body">
        <input class="fake-focus" tabindex="1" style=""/>
        <div v-if="!simpleView" class="profile-section" style="z-index:9">
          <auto-complete-outline
          label="Role"
          prompt="What type of role best describes what you do?"
          :required="true"
          :value="myProfile.role"
          autocompleteId="'role'"
          tabindex="2"
          :field="'role'"
          :scrollFocus="false"
          @set="setRole"
          @input="setRoleValue"
          @blur="basicValid"
          :items="roles"
          ariaLabelledBy="roleLabel"
          style="z-index:99;"
          maxlength="120"
          :isMobile="isMobile"
          :mobileOverlay="true"
          :next="ifCompany('journeyStage', 'industry')"
          @focusOn="focusOn"
          ref="role"
          helptext = "- E.g. Project Manager"
          :valid="roleValid || !saveFailed"
          >
            <mdc-text-field-counter slot="counter">0 / 120</mdc-text-field-counter>
          </auto-complete-outline>

        </div>

        <div class="profile-section" style="z-index:8">
          <auto-complete-outline
          prompt="What Industry do you work in?"
          label="Industry"
          :required="true"
          :value="myProfile.industry"
          autocompleteId="'industry'"
          tabindex="3"
          :readOnly="industrySetByCompany"
          v-if="!industrySetByCompany"
          :field="'name'"
          @input="setIndustryValue"
          @set="setIndustry"
          @blur="basicValid"
          :items="industries"
          ariaLabelledBy="industryLabel"
          style="z-index:98"
          maxlength="120"
          :isMobile="isMobile"
          :mobileOverlay="true"
          next="working"
          :finish="saveFailed && isBasicValid"
          @finish="closePortalSave"
          :prev="ifNotSimpleView('role')"
          @focusOn="focusOn"
          ref="industry"
          :valid="industryValid || !saveFailed"
          :helptext="helpTextCompany('- E.g. Retail', industrySetByCompany)"
          >
            <mdc-text-field-counter slot="counter">0 / 120</mdc-text-field-counter>
          </auto-complete-outline>
        </div>

        <div v-if="!company" class="profile-section" style="z-index:8">
          <portal to="destination" :disabled="!(focusField === 'working' && isMobile)">

          <round-button style="position:fixed; top:0;left:0" v-if="focusField === 'working' && isMobile" noHover icon="close" background="transparent" @click="closePortal"></round-button>

          <mdc-body style="width:100%">Are you currently working?</mdc-body>
          <mdc-body style="width:100%" class="sub-label">
            This helps us control who sees your profile
          </mdc-body>

          <div style="width:100%;padding:16px;">
          <mdc-switch
            tabindex="4"
            class="app-switch"
            style="padding-left:4px;height:36px;"
            @change="setNotWorking"
            :label="workingLabel"
            ref="working"
            v-model="isWorking"/>
          </div>

          <div v-if="focusField === 'working' && isMobile" style="display:flex; width:100%; position:fixed; bottom:0;left:0">
            <round-button class="prev" noHover style="margin-right:auto"  @click="triggerPrev('industry')" hasSlot icon="arrow_back">
              <span>Previous</span>
            </round-button>
            <round-button class="next" noHover style="margin-left:auto"  @click="triggerNext(ifNotWorking('journeyStage' ,ifHasCompany('companyBrand', 'title')))" hasSlot trailingIcon="arrow_forward">
              <span>Next</span>
            </round-button>
            <round-button v-if="saveFailed && isBasicValid" class="next" noHover style="margin-left:auto"  @click="closePortalSave" hasSlot icon="done">
              <span>Done</span>
            </round-button>
          </div>

          </portal>
        </div>

        <div class="profile-section" style="margin-top: 34px;z-index:8" v-if="!myProfile.companyId">
          <div>
            <mdc-body>Where do you currently work?</mdc-body>
            <mdc-body class="sub-label">
              This helps us understand your industry sector and ensures your company will never see you are looking for a Job
            </mdc-body>

            <mdc-layout-grid>
                <mdc-layout-cell desktop=6 tablet=4 phone=4>
                  <app-text-field
                  type="text"
                  label="Job Title"
                  promptMobileOverlay="Where do you currently work?"
                  helptextMobileOverlay="This helps us understand your industry sector and ensures your company will never see you are looking for a Job"
                  outlined
                  v-model="myProfile.title.role"
                  :required="true"
                  :disabled="notWorking"
                  autocomplete="new-password"
                  tabindex="5"
                  autofill="off"
                  @change="basicValid"
                  @blur="basicValid"
                  ariaLabelledBy="titleLabel"
                  maxlength="100"
                  :isMobile="isMobile"
                  :mobileOverlay="true"
                  :prev="ifCompany('industry', 'working')"
                  next="company"
                  :finish="saveFailed && isBasicValid"
                  @finish="closePortalSave"
                  @focusOn="focusOn"
                  ref="title"
                  :valid="jobValid || !saveFailed"
                  >
                    <mdc-text-field-counter slot="counter">0 / 100</mdc-text-field-counter>
                  </app-text-field>

                </mdc-layout-cell>
                <mdc-layout-cell desktop=6 tablet=4 phone=4>
                  <auto-complete-outline
                  label="Company"
                  :promptMobileOverlay="'Where do you currently work?'"
                  :helptextMobileOverlay="'This helps us understand your industry sector and ensures your company will never see you are looking for a Job'"
                  :value="myProfile.company"
                  :required="true"
                  autocompleteId="'company'"
                  tabindex="6"
                  :field="'name'"
                  @input="setCompanyValue"
                  @set="setCompany"
                  :items="companies"
                  :disabled="notWorking"
                  @blur="basicValid"
                  ariaLabelledBy="companyLabel"
                  :min="1"
                  style="z-index:97"
                  maxlength="100"
                  :isMobile="isMobile"
                  :mobileOverlay="true"
                  prev="title"
                  next="journeyStage"
                  :finish="saveFailed && isBasicValid"
                  @finish="closePortalSave"
                  @focusOn="focusOn"
                  ref="company"
                  :valid="companyValid || !saveFailed"
                  >
                    <mdc-text-field-counter slot="counter">0 / 100</mdc-text-field-counter>
                  </auto-complete-outline>
                </mdc-layout-cell>
            </mdc-layout-grid>
          </div>
        </div>
        <div class="profile-section" style="z-index:8" v-else>
          <div v-if="!company">
            <mdc-body>Where do you currently work?</mdc-body>
            <mdc-body class="sub-label">
              This helps us understand your industry sector and ensures your company will never see you are looking for a Job
            </mdc-body>
            <div >
              <mdc-layout-grid>
                <mdc-layout-cell desktop=6 tablet=4 phone=4>
                  <auto-complete-outline
                  label="Company"
                  :promptMobileOverlay="'Where do you currently work?'"
                  :helptextMobileOverlay="'This helps us understand your industry sector and ensures your company will never see you are looking for a Job'"
                  :value="companyName"
                  readonly
                  :required="true"
                  autocompleteId="'company'"
                  tabindex="5"
                  :field="'name'"
                  :items="companyChoices"
                  :disabled="notWorking"
                  @blur="basicValid"
                  ariaLabelledBy="companyLabel"
                  :min="1"
                  style="z-index:97"
                  maxlength="100"
                  :isMobile="isMobile"
                  :mobileOverlay="true"
                  prev="title"
                  next="journeyStage"
                  :finish="saveFailed && isBasicValid"
                  @finish="closePortalSave"
                  @focusOn="focusOn"
                  ref="companyBrand"
                  :valid="companyValid || !saveFailed"
                  :helptext="helpTextCompany('- E.g. Company Name', !!company)"
                  >
                  </auto-complete-outline>
                </mdc-layout-cell>
              </mdc-layout-grid>
            </div>
          </div>
        </div>

        <div class="profile-section">

          <portal to="destination" :disabled="!(focusField === 'journeyStage' && isMobile)">

          <round-button style="position:fixed; top:0;left:0" v-if="focusField === 'journeyStage' && isMobile" noHover icon="close" background="transparent" @click="closePortal"></round-button>
          <mdc-body style="width:100%">Where are you in your flexible working journey? <i class="required"></i></mdc-body>
          <mdc-body class="sub-label">
              This helps us know if we should be actively helping you find a job or partner
          </mdc-body>

          <mdc-body v-if="!myProfile.journeyStage && saveFailed" typo="body2" style="color:#b00020;margin-left:8px;margin-bottom:12px;">
              You must select a journey stage to proceed
          </mdc-body>
          <mdc-layout-grid class="journey-stage" style="padding-left:0;padding-right;0;">
              <mdc-layout-cell desktop="3" tablet="3" phone="2">
                <mdc-radio ref="journeyStage" class="radio-button" tabindex="7" v-model="myProfile.journeyStage" @change="basicValid" name="radios" label="Learning More"  />
                <div class="mdc-form-field stageinfo">
                  <div class="label">This means</div>
                  <div>You're here to learn more about flexible working but not open to offers.</div>
                </div>

              </mdc-layout-cell>
              <mdc-layout-cell desktop="3" tablet="3" phone="2">
                <mdc-radio class="radio-button" tabindex="8" v-model="myProfile.journeyStage" name="radios" @change="basicValid" label="Starting to Look"  />
                <div class="mdc-form-field stageinfo">
                  <div class="label">This means</div>
                  <div>You're actively looking for new work and ready to interview.</div>
                </div>

              </mdc-layout-cell>
              <mdc-layout-cell desktop="3" tablet="3" phone="2">
                <mdc-radio class="radio-button" tabindex="9" v-model="myProfile.journeyStage" name="radios" @change="basicValid" label="Open to Offers"  />
                <div class="mdc-form-field stageinfo">
                  <div class="label">This means</div>
                  <div>You're not looking but open to hear about new opportunities.</div>
                </div>

              </mdc-layout-cell>
              <mdc-layout-cell desktop="3" tablet="3" phone="2">
                <mdc-radio class="radio-button" tabindex="10" v-model="myProfile.journeyStage" name="radios" @change="basicValid" label="Working Flexibly"  />
                <div class="mdc-form-field stageinfo">
                  <div class="label">This means</div>
                  <div>You're currently in a flexible arrangement and interested in the community.</div>
                </div>

              </mdc-layout-cell>
          </mdc-layout-grid>
          <div style="width:100%" v-if="focusField === 'journeyStage' && isMobile">
            <div class="sub-label" v-if="myProfile.journeyStage === 'Learning More'"><b>This means: </b>You're here to learn more about flexible working but not open to offers.</div>
            <div class="sub-label" v-if="myProfile.journeyStage === 'Starting to Look'"><b>This means: </b>You're actively looking for new work and ready to interview.</div>
            <div class="sub-label" v-if="myProfile.journeyStage === 'Open to Offers'"><b>This means: </b>You're not looking but open to hear about new opportunities.</div>
            <div class="sub-label" v-if="myProfile.journeyStage === 'Working Flexibly'"><b>This means: </b>You're currently in a flexible arrangement and interested in the community.</div>
          </div>
          <div v-if="focusField === 'journeyStage' && isMobile" style="display:flex; width:100%; position:fixed; bottom:0;left:0">
            <round-button v-if="!company" class="prev" noHover style="margin-right:auto"  @click="triggerPrev(ifNotWorking('working', ifHasCompany('companyBrand', 'company')))" hasSlot icon="arrow_back">
              <span>Previous</span>
            </round-button>
            <round-button v-if="!company" class="next" noHover style="margin-left:auto"  @click="triggerNext(ifNotSimpleView('flexschedule', 'profileVisible'))" hasSlot trailingIcon="arrow_forward">
              <span>Next</span>
            </round-button>
            <round-button v-if="saveFailed && isBasicValid || !!company" class="next" noHover style="margin-left:auto"  @click="closePortalSave" hasSlot icon="done">
              <span>Done</span>
            </round-button>
          </div>
          </portal>
        </div>

        <div v-if="!simpleView" class="profile-section">
            <mdc-body>How many days are you looking to work? <i class="required"></i></mdc-body>
            <mdc-body class="sub-label nomargin">
              This helps us match opportunities offering similar hours
            </mdc-body>
            <mdc-body v-if="myProfile.flexschedule === '0 Days' && saveFailed" typo="body2" style="color:#b00020;margin-left:8px;">
              You must select more than zero days to proceed
            </mdc-body>
            <div class="input-wrapper">
              <day-slider-range tabindex="11" modal :show="basic" @change="setFlexSchedule" v-model="myProfile.flexschedule"/>
            </div>
        </div>

        <div v-if="!simpleView" class="profile-section">
          <auto-complete-location
            label="Location"
            prompt="Where do you live?"
            tabindex="12"
            helptext="- E.g. London, this helps us match opportunities with a reasonable commute"
            :mobileOverlay="true"
            :value="myProfile.location"
            :isMobile="isMobile"
            @updateAddress="updateAddress"
            @blur="basicValid"
            scrollIntoView
            validationMessage="Select a location option from list"
            :valid="locationValid || !saveFailed">
          </auto-complete-location>

          <mdc-checkbox tabindex="13" style="padding-left:8px;" @change="setRemote"  label="I’m only interested in remote working positions" v-model="remoteOnly"/>
        </div>

        <div v-if="!company" class="profile-section">
          <portal to="destination" :disabled="!(focusField === 'profileVisible' && isMobile)">

          <round-button style="position:fixed; top:0;left:0" v-if="focusField === 'profileVisible' && isMobile" noHover icon="close" background="transparent" @click="closePortal"></round-button>

          <mdc-body style="width:100%">Do you want companies and recruiters to see your profile?</mdc-body>

          <div style="width:100%;padding:16px;">
          <mdc-switch
            tabindex="14"
            class="app-switch"
            style="padding-left:4px;height:36px;"
            @change="setProfileVisible"
            :label="profileVisibleLabel"
            ref="profileVisible"
            v-model="profileVisible"/>
          </div>

          <div v-if="focusField === 'profileVisible' && isMobile" style="display:flex; width:100%; position:fixed; bottom:0;left:0">
            <round-button class="prev" noHover style="margin-right:auto"  @click="triggerPrev(ifNotSimpleView('location', 'journeyStage'))" hasSlot icon="arrow_back">
              <span>Previous</span>
            </round-button>
            <round-button class="next" noHover style="margin-left:auto"  @click="closePortalSave" hasSlot icon="done">
              <span>Done</span>
            </round-button>
          </div>

          </portal>
        </div>
      </div>
    </app-dialog>

    <app-dialog
      v-model="experience"
      @accept="onExperienceAccept"
      @close="onExperienceDecline"
      @disabled-accept="saveFailed = true"
      :pauseFocus="isMobile"
      :acceptDisabled="!isExpValid"
      class="profile-modal fullsize-mobile"
      :disabledMessage ="validationMsg"
      accept="Save"
      title="Experience"
      subtitle="To get the most help finding flexible working, tell us about your experience">

      <div class="mdc-dialog__body">
        <div class="profile-section">
          <mdc-body id="specialitiesLabel">What roles or specialities do you have experience in? <i class="required"></i></mdc-body>
          <mdc-body class="sub-label nomargin">
            Adding multiple roles will enhance your matches and profile
          </mdc-body>

          <!-- Experience -->
          <experience-section
            ref="experience"
            :isMobile="isMobile"
            class="negative-margin"
            :reset="reset"
            @updateNewRoleName="updateNewRoleName"
            @updateNewRoleExp="updateNewRoleExp"
            :newRoleNameVal="newRoleName"
            :newRoleExpVal="newRoleExp"
            :defaultRole="myProfile.role"
            @updateExperience="updateExperience"
            @updateProfileSection.prevent.stop="updateExperinceSection"
            :basic="true"
            :roleNameValid="roleNameValid || !saveFailed"
            :roleExpValid="roleExpValid || !saveFailed"
            :profile="myProfile"
            :editonly="true"
            :editable="true">
            </experience-section>
        </div>

        <div class="profile-section">
          <mdc-body id="skillsLabel nomargin">Tell us about your skills? <i class="required"></i></mdc-body>
          <mdc-body class="sub-label nomargin">
            If your skill is not on our list just type it and click add
          </mdc-body>
          <!-- Skills -->
          <skill-section
            ref="skills"
            :isMobile="isMobile"
            class="negative-margin"
            :reset="reset"
            :skillValid="skillValid || !saveFailed"
            :skillRatingValid="skillRatingValid || !saveFailed"
            :profile="myProfile"
            @updateNewSkill="updateNewSkill"
            @updateNewSkillRating="updateNewSkillRating"
            :newSkillVal="newSkill"
            :newSkillRatingVal="newSkillRating"
            @updateSkills="updateSkills"
            @updateProfileSection.prevent.stop="updateSkillsSection"
            :editonly="true"
            :editable="true">
          </skill-section>

        </div>

        <div class="profile-section">
          <mdc-body class="padded-label" id="educationLabel">What's your highest level of Education? <i class="required"></i></mdc-body>
          <div>
            <app-select
              outlined
              style="width:100%;margin-bottom:12px;"
              required
              @change="updateEducationalLevel"
              v-model="myProfile.educationLevel"
              :valid="educationValid || !saveFailed"
              promptMobileOverlay="What's your highest level of Education?"
              :isMobile="isMobile"
              :mobileOverlay="true"
              :next="ifDegreeLevel('educationPlace', 'aboutme')"
              @focusOn="focusOn"
              ref="educationLevel"
              label="Education Level">
              <select-item value="" />
              <select-item value="High school, diploma or the equivalent (GCSE, A-level, etc)" />
              <select-item value="Trade/technical/professional qualification" />
              <select-item value="Bachelor's degree" />
              <select-item value="Master's degree" />
              <select-item value="Doctorate degree" />
              <select-item value="Self Taught" />
            </app-select>
          </div>
          <mdc-layout-grid>
            <mdc-layout-cell desktop=6 tablet=8 phone=4>
              <app-text-field
                type="text"
                v-show="degreeLevel"
                label="Name of University or College"
                promptMobileOverlay="Where did you study?"
                outlined
                v-model="myProfile.educationPlaceName"
                autocomplete="new-password"
                autofill="off"
                :isMobile="isMobile"
                @blur="expValid"
                @model="expValid"
                ref="educationPlace"
                prev="educationLevel"
                next="areaOfStudy"
                @focusOn="focusOn"
                :valid="educationValid || myProfile.educationPlaceName !== '' || !saveFailed"
                :mobileOverlay="true"
                helptext="- E.g. Nottingham University"
                >
              </app-text-field>
            </mdc-layout-cell>

            <mdc-layout-cell desktop=6 tablet=8 phone=4>
              <app-text-field
                type="text"
                v-show="degreeLevel"
                label="Area of Study"
                promptMobileOverlay="What's was your area of study / qualification?"
                outlined
                v-model="myProfile.areaOfStudy"
                autocomplete="new-password"
                autofill="off"
                :isMobile="isMobile"
                :mobileOverlay="true"
                @blur="expValid"
                @model="expValid"
                prev="educationPlace"
                next="aboutme"
                ref="areaOfStudy"
                @focusOn="focusOn"
                :valid="educationValid || myProfile.areaOfStudy !== '' || !saveFailed"
                helptext="- E.g. BA (hons) Economics"
                >
              </app-text-field>
            </mdc-layout-cell>
          </mdc-layout-grid>
        </div>

        <div class="profile-section">
          <mdc-body class="nomargin">Tell people about yourself:</mdc-body>
          <mdc-body class="sub-label">Think elevator pitch or copy the summary from your CV or resume</mdc-body>
          <mdc-text-area-autosize
            :focusedOn="focusField === 'aboutme'"
            @focusOn="focusOn"
            @focus="$emit('hasFocused')"
            ref="aboutme"
            label="About Me"
            promptMobileOverlay="Tell people what you do"
            :value="aboutMe"
            @input="aboutMe = $event"
            :isMobile="isMobile"
            :mobileOverlay="true"
            style="margin-bottom:16px;"
            :prev="ifDegreeLevel('areaOfStudy', 'educationLevel')"
            :finish="true"
            @finish="closePortalSave"
            helptextMobileOverlay="- E.g. Experienced Project Manager with a degree in Economics"
            outlined
            :maxHeight="ifMobile(150, 200)"
            maxlength="2000"
            helptext="- E.g. Experienced Project Manager with a degree in Economics"
            >
            <mdc-text-field-counter slot="counter">0 / 2000</mdc-text-field-counter>
          </mdc-text-area-autosize>
        </div>

      </div>
    </app-dialog>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AutoCompleteLocation from '../../components/AutoCompleteLocation'
import AutoCompleteOutline from '../../components/AutoCompleteOutline.vue'
import AppTextField from '../../components/AppTextField'
import AppSelect from '../../components/AppSelect'
import ExperienceSection from '../../components/ExperienceSection.vue'
import SkillSection from '../../components/profile-v2/SkillSection.vue'
import DaySliderRange from '../../components/DaySliderRange'
import AppDialog from '../../components/AppDialog'
import { appName } from '../../settings'
import has from 'lodash/has'
import omitBy from 'lodash/omitBy'
import isEqual from 'lodash/isEqual'
const _ = { omitBy, isEqual, has }
let timer = false

export default {
  name: 'ProfileModal',
  components: {
    AutoCompleteOutline,
    AppTextField,
    AppSelect,
    DaySliderRange,
    ExperienceSection,
    SkillSection,
    AutoCompleteLocation,
    AppDialog
  },
  props: {
    triggerOpen: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      simpleView: true,
      focusField: null,
      profileUpdatesTask: false,
      aboutMe: '',
      appName: appName,
      address: '',
      msg: 'Profile Page',
      basic: false,
      experience: false,
      profileVisible: true,
      notWorking: false,
      isWorking: true,
      newSkill: '',
      newSkillRating: '',
      newRoleName: '',
      newRoleExp: '',
      reset: false,
      myProfile: null,
      degreeLevel: false,
      skills: [],
      saveFailed: false,
      isBasicValid: false,
      isExpValid: false,
      roleNameValid: true,
      roleExpValid: true,
      skillValid: true,
      skillRatingValid: true,
      educationValid: true,
      roleValid: true,
      industryValid: true,
      companyValid: true,
      jobValid: true,
      locationValid: true,
      validationMsg: '*Complete required fields to save',
      ratingText: {
        '': '',
        '1★': 'Basics - You have a common knowledge or an understanding of basic techniques and concepts.',
        '2★': 'Novice - You have the level of experience gained in a classroom and/or experimental scenarios or as a trainee on-the-job. You are expected to need help when performing this skill.',
        '3★': 'Intermediate - You are able to successfully complete tasks in this competency as requested. Help from an expert may be required from time to time, but you can usually perform the skill independently.',
        '4★': 'Advanced - You can perform the actions associated with this skill without assistance. You are certainly recognised within your immediate organisation as "a person to ask" when difficult questions arise regarding this skill.',
        '5★': 'Expert - You are known as an expert in this area. You can provide guidance, troubleshoot and answer questions related to this area of expertise and the field where the skill is used.'
      }
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'profileUpdates',
      'roles',
      'industries'
    ]),
    ...mapState({
      companies: (state) => state.companies,
      company: (state) => state.company.assignedCompany,
      subCompany: (state) => state.company.assignedSubCompany,
      skillList: (state) => state.skills
    }),
    industrySetByCompany () {
      if (this.subCompany) {
        if (this.subCompany.industry) {
          return true
        }
      }
      if (this.company) {
        if (this.company.industry) {
          return true
        }
      }
      return false
    },
    companyIndustry () {
      if (this.subCompany) {
        if (this.subCompany.industry) {
          return this.subCompany.industry
        }
      }
      if (this.company) {
        if (this.company.industry) {
          return this.company.industry
        }
      }
      if (this.profile.industry) {
        return this.profile.industry
      }
      return ''
    },
    companyName () {
      if (this.subCompany) {
        if (this.subCompany.name) {
          return this.subCompany.name
        }
      }
      if (this.company) {
        if (this.company.name) {
          return this.company.name
        }
      }
      if (this.profile.company) {
        return this.profile.company
      }
      return ''
    },
    companyChoices () {
      if (this.company) {
        if (this.company.members) {
          return this.company.members
        }
      }
      return [this.company]
    },
    workingLabel () {
      if (!this.notWorking) {
        return ' Yes'
      }
      return ' No'
    },
    profileVisibleLabel () {
      if (this.profileVisible) {
        return ' Yes'
      }
      return ' No'
    },
    defaultProfileValue: function () {
      return {
        active: true,
        headline: '',
        aboutMe: '',
        flexschedule: '',
        workstyle: '',
        experience: [],
        displayName: '',
        photoURL: '',
        role: '',
        title: {
          role: ''
        },
        company: '',
        newRoleName: '',
        newRoleExp: '',
        roles: [],
        skills: [],
        newSkill: '',
        newSkillRating: '',
        location: '',
        daysperweek: 0,
        profileVisible: true,
        educationHistory: [],
        educationPlaceName: '',
        areaOfStudy: '',
        notWorking: false,
        remoteOnly: false,
        basicCompleted: false,
        experienceCompleted: false
      }
    }
  },
  mounted () {
    this.initializeProfile()
    if (!this.myProfile.basicCompleted) {
      this.openBasic()
    } else if (!this.myProfile.experienceCompleted) {
      this.openExp()
    }
  },
  watch: {
    myProfile: {
      deep: true,
      handler (newP, oldP) {
        // console.log('profileModals.watch:myProfile', newP)
        if (newP && oldP) {
          if (timer) {
            clearTimeout(timer)
          }
          timer = setTimeout(() => {
            // updated values
            const updatedValues = _.omitBy(newP, (value, key) => _.isEqual(this.profile[key], value))
            console.log('ProfileModal.watch:myProfile', updatedValues)
            // deleted values (This shouldnt happen in modals but might be useful elsewhere )
            // var deletedValues = _.omitBy(this.profile, (value, key) => _.has(newP, key))
            // console.log('ProfileModals.watch:myProfile', updatedValues)
            this.setProfileUpdates(updatedValues)
            this.basicValid()
          }, 300)
        }
      }
    },
    triggerOpen: {
      handler: function (val, oldval) {
        console.log('ProfileModals.watch:triggerOpen', val, this.myProfile)
        if (val) {
          if (this.profile) {
            if (!this.profile.basicCompleted) {
              this.openBasic()
            } else if (!this.profile.experienceCompleted) {
              this.openExp()
            }
          }
        }
        this.$emit('triggered')
      },
      immediate: true
    },
    profile: function (val, oldValue) {
      if (!oldValue) {
        if (val) {
          this.initializeProfile()
          if (!this.profile.basicCompleted) {
            this.openBasic()
          } else if (!this.profile.experienceCompleted) {
            this.openExp()
          }
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'setProfileUpdates',
      'applyProfileUpdates'
    ]),
    initializeProfile () {
      // console.log('ProfileModals.initializeProfile')
      var myProfileObj = Object.assign({}, this.defaultProfileValue)
      if (this.profile) {
        myProfileObj = Object.assign(myProfileObj, this.profile)
        myProfileObj.skills = [...this.profile.skills]
        myProfileObj.roles = [...this.profile.roles]
      }

      if (this.profileUpdates) {
        myProfileObj = Object.assign(myProfileObj, this.profileUpdates)
      }

      this.notWorking = myProfileObj.notWorking === true
      this.isWorking = !this.notWorking
      this.remoteOnly = myProfileObj.remoteOnly === true
      if (myProfileObj.educationLevel) {
        this.degreeLevel = myProfileObj.educationLevel.indexOf('degree') > -1
      } else {
        this.degreeLevel = false
      }

      this.newRoleName = myProfileObj.newRoleName
      this.newRoleExp = myProfileObj.newRoleExp

      this.newSkill = myProfileObj.newSkill
      this.newSkillRating = myProfileObj.newSkillRating

      if (this.subCompany) {
        myProfileObj.industry = this.subCompany.industry
        myProfileObj.company = this.subCompany.name
        myProfileObj.notWorking = false
        myProfileObj.profileVisible = true
      } else if (this.company) {
        myProfileObj.industry = this.company.industry
        myProfileObj.company = this.company.name
        myProfileObj.notWorking = false
        myProfileObj.profileVisible = true
      }

      this.myProfile = myProfileObj
      this.aboutMe = this.myProfile.aboutMe
      this.profileVisible = this.myProfile.profileVisible
      this.skills = this.myProfile.skills
      this.locationInputVal = this.myProfile.location
    },
    ifCompany (pos, neg) {
      if (this.company) {
        return pos
      }
      return neg
    },
    helpTextCompany (helpText, fieldSetByCompany) {
      if (fieldSetByCompany) {
        if (this.company) {
          return '- This field is set by your employer'
        }
      }
      return helpText
    },
    focusOn (target, direction) {
      console.log('focusOn', target, direction, this.$refs[target])
      this.focusField = target
      if (this.$refs[target]) {
        try {
          this.$refs[target].focus(direction)
        } catch (err) {
          if (this.$refs[target].$el) {
            this.$refs[target].$el.focus()
          }
        }
      }
    },
    triggerNext (next) {
      console.log('ProfileModals.TriggerNext', next)
      this.focusOn(next)
    },
    triggerPrev (prev) {
      console.log('ProfileModals.TriggerPrev', prev)
      this.focusOn(prev, 'previous')
    },
    closePortal () {
      this.$root.$children[0].$refs.view.$emit('inputBlurred')
      this.focusField = false
    },
    closePortalSave () {
      this.$root.$children[0].$refs.view.$emit('inputBlurred')
      this.focusField = false
      if (this.basic) {
        if (this.isBasicValid) {
          this.onBasicAccept()
        } else {
          this.saveFailed = true
        }
      } else if (this.experience) {
        if (this.isExpValid) {
          this.onExperienceAccept()
        } else {
          this.saveFailed = true
        }
      }
    },
    ifMobile (ifTrue, ifFalse) {
      if (this.isMobile) {
        return ifTrue
      }
      if (ifFalse) {
        return ifFalse
      }
      return false
    },
    ifNotSimpleView (ifTrue, ifFalse) {
      if (!this.simpleView) {
        return ifTrue
      }
      if (ifFalse) {
        return ifFalse
      }
      return false
    },
    ifNotWorking (ifTrue, ifFalse) {
      if (this.notWorking) {
        return ifTrue
      }
      return ifFalse
    },
    ifHasCompany (ifTrue, ifFalse) {
      if (this.myProfile.companyId) {
        return ifTrue
      }
      return ifFalse
    },
    ifDegreeLevel (ifTrue, ifFalse) {
      if (this.degreeLevel) {
        return ifTrue
      }
      return ifFalse
    },
    setNotWorking (val) {
      console.log('profileModals.setNotWorking', this.notWorking)
      this.notWorking = !val
      this.myProfile.notWorking = !val
      this.basicValid()
    },
    setRemote () {
      // console.log('profileModals.setRemote', this.remoteOnly)
      this.myProfile.remoteOnly = this.remoteOnly
      this.basicValid()
    },
    setProfileVisible () {
      this.myProfile.profileVisible = this.profileVisible
      this.basicValid()
    },
    basicValid () {
      setTimeout(() => {
        let valid = true
        this.roleValid = true
        this.industryValid = true
        this.companyValid = true
        this.jobValid = true
        this.locationValid = true

        console.log('========BASIC VALID=======')
        // Role
        // console.log('Role', this.myProfile.role)
        if (!this.simpleView) {
          if (!this.myProfile.role || this.myProfile.role === '') {
            valid = false
            this.roleValid = false
          }
        }
        // Industry
        console.log('Industry', this.myProfile.industry)
        if (!this.myProfile.industry || this.myProfile.industry === '') {
          valid = false
          this.industryValid = false
        }
        // Current position
        console.log('Current position', this.notWorking, this.myProfile.companyId, this.myProfile.company, this.myProfile.title.role)
        if (!this.notWorking && (!this.myProfile.companyId && (this.myProfile.company === '' || this.myProfile.company.name !== '')) && (this.myProfile.company === '' || !this.myProfile.company || this.myProfile.title.role === '' || !this.myProfile.title.role)) {
          valid = false
          if (!this.myProfile.company) {
            this.companyValid = false
          }
          if (!this.myProfile.title.role) {
            this.jobValid = false
          }
        }
        // Journey Stage
        console.log('Journey Stage', this.myProfile.journeyStage)
        if (this.myProfile.journeyStage === '' || !this.myProfile.journeyStage) {
          valid = false
        }
        // Flex Schedule
        // console.log('Flex Schedule', this.myProfile.flexschedule)
        if (!this.simpleView) {
          if (!this.myProfile.flexschedule || this.myProfile.flexschedule === '0 Days') {
            valid = false
          }
        }
        // Location
        // console.log('ProfileModals.basicValid:location', this.remoteOnly, this.myProfile.location.length, typeof this.myProfile.location, this.myProfile.location, this.myProfile.locationFull)
        if (!this.simpleView) {
          if (!this.remoteOnly && (this.myProfile.location.length === 0 || typeof this.myProfile.location !== 'string' || !this.myProfile.locationFull)) {
            valid = false
            this.locationValid = false
          }
        }
        console.log(`======== ${valid} =======`)
        this.isBasicValid = valid
        this.myProfile.profilePublic = valid
      }, 300)
    },
    expValid () {
      let valid = true
      this.roleNameValid = true
      this.roleExpValid = true
      this.skillValid = true
      this.skillRatingValid = true
      this.educationValid = true

      // Experience Level
      // console.log('ProfileModals.expValid:Experience', this.myProfile.roles, this.newRoleName, this.roleExpValid)
      if (this.myProfile) {
        if (this.myProfile.roles.length < 1) {
          if (!this.newRoleName) {
            valid = false
            this.roleNameValid = false
          }
          if (!this.newRoleExp) {
            valid = false
            this.roleExpValid = false
          }
        }
      }

      // skills Level
      // console.log('ProfileModals.expValid:Skills', this.myProfile.skills)
      if (this.myProfile) {
        if (this.myProfile.skills.length < 1) {
          if (!this.myProfile.newSkill) {
            valid = false
            this.skillValid = false
          }
          if (!this.myProfile.newSkillRating) {
            valid = false
            this.skillRatingValid = false
          }
          if (this.myProfile.newSkillRating && this.myProfile.newSkill) {
            // Add Skill
            // this.$refs.skills.addSkill()
          }
        }
      }

      // Education Level
      // console.log('ProfileModals.expValid:Education', this.myProfile.educationLevel, this.myProfile.educationPlaceName, this.myProfile.areaOfStudy)
      if (this.myProfile) {
        if (!this.myProfile.educationLevel) {
          valid = false
          this.educationValid = false
        }
        if (this.degreeLevel && (!this.myProfile.educationPlaceName || !this.myProfile.areaOfStudy)) {
          valid = false
          this.educationValid = false
        }
      }
      this.isExpValid = valid
      return valid
    },
    openBasic () {
      this.basic = true
      this.basicValid()
      this.updateAnalytics()
    },
    openExp () {
      this.saveFailed = false
      this.experience = true
      this.reset = false
      this.expValid()
    },
    updateEducationalLevel: function (level) {
      this.degreeLevel = level.indexOf('degree') > -1
      // console.log('ProfileModals.updateEducationalLevel', level, this.degreeLevel)
      this.expValid()
    },
    updateExperience: function (profileUpdates) {
      // console.log('ProfileModal.updateExperience', profileUpdates)
      for (const update in profileUpdates) {
        this.myProfile[update] = profileUpdates[update]
      }
      this.expValid()
    },
    updateExperinceSection: function (profileUpdates) {
      // console.log('ProfileModal.updateExperienceSection', profileUpdates)
      for (const update in profileUpdates) {
        this.myProfile[update] = profileUpdates[update]
      }
      this.expValid()
    },
    updateSkills: function (profileUpdates) {
      // console.log('ProfileModal.updateSkills', profileUpdates)
      for (const update in profileUpdates) {
        this.myProfile[update] = profileUpdates[update]
      }
      this.expValid()
    },
    updateSkillsSection: function (profileUpdates) {
      // console.log('ProfileModal.updateSkillsSection', profileUpdates)
      for (const update in profileUpdates) {
        this.myProfile[update] = profileUpdates[update]
      }
      this.expValid()
    },
    getRatingText: function (rating) {
      return this.ratingText[rating]
    },
    updateNewRoleName (val) {
      // console.log('ProfileModals.updateNewRoleName', val)
      this.newRoleName = val
      this.$set(this.myProfile, 'newRoleName', val)
      this.expValid()
    },
    updateNewRoleExp (val) {
      // console.log('ProfileModals.updateNewRoleExp', val)
      this.newRoleExp = val
      this.$set(this.myProfile, 'newRoleExp', val)
      this.expValid()
    },
    updateNewSkill (val) {
      // console.log('ProfileModals.updateNewSkill', val)
      this.newSkill = val
      this.$set(this.myProfile, 'newSkill', val)
      this.expValid()
    },
    updateNewSkillRating (val) {
      // console.log('ProfileModals.updateNewSkillRating', val)
      this.newSkillRating = val
      this.$set(this.myProfile, 'newSkillRating', val)
      this.expValid()
    },
    setRoleValue: function (val) {
      // console.log('ProfileModals.setRoleValue', val)
      this.myProfile.role = val
      this.basicValid()
    },
    setRole: function (role) {
      if (typeof role.role !== 'undefined') {
        this.myProfile.role = role.role
      } else {
        this.myProfile.role = role
      }
      this.basicValid()
    },
    setIndustryValue: function (val) {
      this.myProfile.industry = val
      this.basicValid()
    },
    setIndustry: function (industry, index) {
      this.myProfile.industry = industry.name
      this.basicValid()
    },
    setCompanyValue: function (val) {
      this.myProfile.company = val
      this.basicValid()
    },
    setCompany: function (company) {
      this.myProfile.company = company
      this.basicValid()
    },
    setFlexSchedule: function (flexschedule) {
      this.myProfile.flexschedule = flexschedule
      this.basicValid()
    },
    onBasicAccept: function () {
      // console.log('ProfileModals.onBasicAccept')
      this.updateProfileBasic()
      this.openExp()
      this.basic = false
    },
    onBasicDecline: function () {
      console.log('ProfileModals.onBasicDecline', this.myProfile.basicComplete)
      if (!this.myProfile.basicCompleted) {
        setTimeout(() => {
          // console.log('ProfileModals.onBasicDecline.back')
          this.$router.replace('/dash').catch(err => {
            console.log('ProfileModal.goBack Error', this.backUrl, err)
          })
        }, 200)
      }
    },
    onExperienceAccept: function () {
      // console.log('ProfileModals.onExperienceAccept', '' + this.newSkill, '' + this.newSkillRating)

      if (this.newRoleName !== '' && this.newRoleExp !== '') {
        this.$refs.experience.addRole()
        this.newRoleName = ''
        this.$set(this.myProfile, 'newRoleName', this.newRoleName)
        this.newRoleExp = ''
        this.$set(this.myProfile, 'newRoleExp', this.newRoleExp)
      }
      if (this.newSkill !== '' && this.newSkillRating !== '') {
        this.$refs.skills.addSkill()
        this.newSkill = ''
        this.$set(this.myProfile, 'newSkill', this.newSkill)
        this.newSkillRating = ''
        this.$set(this.myProfile, 'newSkillRating', this.newSkillRating)
      }
      this.$nextTick(() => {
        this.updateProfileExperience()
        this.experience = false
      })
    },
    onExperienceDecline: function () {
      // console.log('ProfileModals.onExperienceDecline', this.myProfile.experienceCompleted)
      if (!this.myProfile.experienceCompleted) {
        setTimeout(() => {
          // console.log('ProfileModals.onExperienceDecline.back')
          this.$router.replace('/dash').catch(err => {
            console.log('ProfileModal.goBack Error', this.backUrl, err)
          })
        }, 200)
      }
    },
    updateProfileBasic: function () {
      this.$set(this.myProfile, 'basicCompleted', true)
      this.$set(this.myProfile, 'profileVisible', this.profileVisible)
      this.$set(this.myProfile, 'notWorking', this.notWorking)
      if (!this.simpleView) {
        this.$set(this.myProfile, 'workstyle', (this.myProfile.remoteOnly) ? 'Remote' : 'Office')
      }

      if (!this.myProfile.headline) {
        if (this.myProfile.role) {
          this.$set(this.myProfile, 'headline', this.myProfile.role)
        }
      }
      this.$nextTick(() => {
        this.setProfileUpdates(this.profileUpdates).then(() => {
          // console.log('ProfileModals.updateProfileBasic')
          setTimeout(() => {
            this.applyProfileUpdates({})
          }, 600)
        })
      })
    },
    updateProfileExperience: function () {
      this.$set(this.myProfile, 'aboutMe', this.aboutMe)
      this.$set(this.myProfile, 'experienceCompleted', true)
      if (!this.myProfile.educationHistory) {
        this.$set(this.myProfile, 'educationHistory', [
          {
            place: this.myProfile.educationPlaceName,
            areaOfStudy: this.myProfile.areaOfStudy,
            educationLevel: this.myProfile.educationLevel || ''
          }
        ])
      } else if (this.myProfile.educationHistory.length < 1) {
        this.$set(this.myProfile, 'educationHistory', [
          {
            place: this.myProfile.educationPlaceName,
            areaOfStudy: this.myProfile.areaOfStudy,
            educationLevel: this.myProfile.educationLevel || ''
          }
        ])
      }
      this.$nextTick(() => {
        this.setProfileUpdates(this.profileUpdates).then(() => {
          console.log('ProfileModals.updateProfileExperience', this.profileUpdates, this.myProfile)
          setTimeout(() => {
            this.applyProfileUpdates({})
          }, 600)
        })
      })
    },
    updateAddress (addressData) {
      // console.log('updateAddress', this.profile, addressData)
      this.myProfile.location = addressData.location
      this.myProfile.locationFull = addressData.locationFull
      this.myProfile.locationLat = addressData.locationLat
      this.myProfile.locationLon = addressData.locationLon
      this.basicValid()
    },
    updateLocation (val) {
      this.locationInputVal = val.newVal
    },
    updateAnalytics () {
      if (this.myProfile) {
        // eslint-disable-next-line no-undef
        analytics.track('openProfileModal', {
          id: this.myProfile.id,
          basicCompleted: this.myProfile.basicCompleted,
          experienceCompleted: this.myProfile.experienceCompleted,
          journeyStage: this.myProfile.journeyStage,
          role: this.myProfile.role,
          locationFull: this.myProfile.locationFull,
          flexschedule: this.myProfile.flexschedule,
          company: this.myProfile.company
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.profile-modal .mdc-dialog__container {
  width: 100%;
}
.profile-modal .fake-focus {
  position: absolute;
  z-index: -1;
  opacity: 0;
  border: none;
  background: transparent;
}
.round-button-wrapper-right {
  margin-bottom: 2px;
  margin-top: 11px;
}
.round-button-wrapper-right .round-button {
  margin-right:0 !important;
}

.profile-modal .round-button.save.disabled {
  color:#C4C4C4;
}

.location-autocomplete {
  position:relative;
  width:100%;
}

.app-switch .mdc-switch {
  margin-right:12px;
}

.location-autocomplete .autocomplete.mdc-text-field.mdc-text-field--upgraded.outlined {
  margin-bottom:2px;
  height:56px;
}

.location-autocomplete input::placeholder {
  opacity: 1 !important;
}

.location-autocomplete .mdc-text-field.outlined .mdc-text-field__input {
    border: none!important;
    background-color: transparent;
    margin:12px;
    margin-top:20px;
    height:24px;
    padding:0px;
    padding-left:36px;
}

.pac-container {
  z-index: 9999;
  margin-top: 1px;
}

.pac-container::after {
  display:none;
}

.location-autocomplete .mdc-text-field.outlined .mdc-text-field__input:not(.background) {
    z-index:1;
    position: absolute;
    top:0;
    width: calc(100% - 32px);
}

.location-autocomplete .mdc-textfield .mdc-notched-outline {
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    height: 100%;
    text-align: left;
    pointer-events: none;
    opacity: 1;
}

.location-autocomplete .mdc-notched-outline {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 1px);
    height: calc(100% - 2px);
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    overflow: visible;
    display: flex;
}

.location-autocomplete .mdc-floating-label {
    bottom: 16px;
    left: 42px;
    background:white;
    padding-left:6px;
    padding-right:6px;
}

.location-autocomplete .mdc-floating-label--float-above {
    bottom: 20px;
    left: 42px;
    background:white;
    padding-left:6px;
    padding-right:6px;
}

.location-autocomplete .mdc-text-field.outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-radius: 4px 0 0 4px;
}
.location-autocomplete .mdc-text-field.outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    border-radius: 0 4px 4px 0;
}

.location-autocomplete .mdc-text-field.outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field.outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field.outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: rgba(0,0,0,.24);
}
.location-autocomplete .mdc-notched-outline__leading {
    border-left: 1px solid;
    border-right: none;
    width: 12px;
}

.location-autocomplete .mdc-text-field.outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field.outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field.outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: rgba(0,0,0,.24);
}
.location-autocomplete .mdc-notched-outline__notch {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: calc(100% - 12px * 2);
}

.location-autocomplete .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    -webkit-transition: border .15s cubic-bezier(.4,0,.2,1);
    -o-transition: border .15s cubic-bezier(.4,0,.2,1);
    transition: border .15s cubic-bezier(.4,0,.2,1);
    border-top: 1px solid;
    border-bottom: 1px solid;
    pointer-events: none;
}

.location-autocomplete .mdc-notched-outline__leading[dir=rtl], .mdc-notched-outline__trailing, [dir=rtl] .mdc-notched-outline__leading {
  border-left: none;
  border-right: 1px solid;
  flex-grow: 1;
}

.location-autocomplete .mdc-floating-label--float-above i.required:hover::after {
  font-size: 18px;
  left: 6px;
  padding:6px;
}

.location-autocomplete .pac-container {
  position:relative !important;
  top:0 !important;
  left:0 !important;
  margin-bottom:24px;
}
.location-autocomplete .pac-logo:after {
  opacity:0 !important;
  height:0px;
}

.myprofile .mdc-text-field--outlined:not(.autocomplete) {
  margin-top:6px;
}

.myprofile .flex-row {
  display: flex;
  flex-flow: row wrap;
  margin-top:16px;
}
.myprofile .flex-left {
  flex: 1;
}

.myprofile .mdc-switch-wrapper .mdc-switch {
  z-index:9;
}
.myprofile .mdc-select--box{
    margin-bottom:24px;
}
.myprofile .mdc-select--box::before {
    opacity: 0.04;
}
.myprofile .skill-chips {
  flex-flow:column;
}
.myprofile .skill-chips .item {
  margin-right: auto;
}
.myprofile .profile-modal-btn {
    min-width: 180px;
    flex:1;
    font-size: 0.7em;
}
.myprofile .flex-row {
  display:flex;
  flex-flow:row wrap;
}
.myprofile .stars.mdc-select {
  height: 36px;
}
.myprofile .stars select.mdc-select__native-control {
  height: 36px;
  line-height: 30px;
  padding-top: 0;
}
.myprofile .experience {
  box-shadow: none;
}
.myprofile .profile-section {
  margin-bottom: 36px;
  margin-top: 24px;
}
.myprofile .mdc-select--box:not(.mdc-select--disabled) {
  background-color:white;
}
.myprofile .handleicon {
  margin-left:0px;
  margin-right:6px;
}
.myprofile .draggable-cancel-icon {
  margin-right:6px;
  margin-left:6px;
}
.myprofile .mdc-layout-grid {
  padding:0;
}
.myprofile .mdc-dialog__body p {
  margin-block-end: 0.2em;
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.myprofile p.sub-label {
  font-size:0.8em;
  margin-top:0;
  margin-bottom:16px;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
}
.myprofile p.sub-label.nomargin {
  margin-bottom:0;
}
.mdc-radio-wrapper.radio-button {
  border:1px solid silver;
  border-radius:20px;
  padding-right:12px;
  white-space: nowrap;
}
.mdc-radio-wrapper.radio-button .mdc-radio {
  padding-right: 6px;
}
.mdc-radio-wrapper.radio-button label {
  padding-left:2px;
}
.myprofile .stageinfo {
  display:block;
  margin-top:6px;
  margin-left:26px;
  border-left:2px solid silver;
  padding-left:6px;
}
.myprofile .stageinfo .label{
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
  margin-bottom:12px;
}
.myprofile .company-name {
  margin-left:24px;
}
.flexrow {
  display:flex;
  flex-flow: row wrap;
}
.flexrow .left{
  min-width:300px;
  flex-grow: 1;
}
.flexrow .right{
  min-width:300px;
  flex: 1;
}
.myprofile .mdc-dialog__surface {
  max-height: calc(100vh - 60px);
  flex: 1;
}
.myprofile .mdc-dialog__body {
  height:100%;
  overflow:visible;
  position:relative;
}
.dotted-line {
  height: 24px;
  width: 1px;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px dashed silver;
}
.myprofile .mdc-dialog__body .mdc-textfield-wrapper {
  width:100%;
  margin-top:4px;
}
.handle {
  cursor:pointer;
  padding-left:4px;
  padding-right:6px;
}
.myprofile .mdc-textfield-wrapper.inline .mdc-text-field {
  height:30px !important;
  margin-top:0;
}
.myprofile .mdc-textfield-wrapper.inline .mdc-text-field .mdc-text-field__input {
  padding:0;
}
.removeskill {
  cursor:pointer;
  position:relative;
  top:5px;
}
.myprofile .mdc-switch-wrapper {
  padding-top: 12px;
}
.myprofile .mdc-dialog__surface {
  padding-left:5px;
  padding-right:5px;
}
.myprofile .mdc-dialog.mdc-dialog--open {
    z-index: 9999;
}
.myprofile .addButton {
  height:56px;
}
.myprofile .mdc-card__supporting-text {
  padding:0;
}
.myprofile .padded-label {
  padding-bottom:16px;
}

.myprofile .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #2D9CDB);
}
.myprofile .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__path {
  stroke: rgba(0,0,0,0.24);
}
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__idle {
    border-color: rgba(0,0,0,0.24) !important;
}
.myprofile .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__path {
  stroke: rgba(0,0,0,0.24);
  stroke-width: 0.5px;
}
.myprofile .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline__idle {
  stroke: rgba(0,0,0,0.24) !important;
}
.myprofile .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.myprofile .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(45,156,219,0.87);
  overflow: visible;
}
.myprofile .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label i.required {
  color: #b00020;
}

.myprofile .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__icon {
 top:16px;
 left:16px;
}

.myprofile .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading,
.myprofile .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch,
.myprofile .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing
{
  border-color: rgba(0, 0, 0, 0.06);
}
@media (max-width: 479px) {
  .flex-row .flex-left {
    min-width:100%;
    margin-right:0 !important;
  }
}
@media (max-width: 410px) {
  .journey-stage .mdc-layout-grid__inner {
      grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .journey-stage .radio-button label {
    text-overflow: ellipsis;
    max-width: calc(100% - 16px);
    overflow: hidden;
  }
}
</style>
