<template>
  <div class="main-content">
    <mdc-card class="signup-card">
      <mdc-card-header>
        <img style="width: 80px; text-align: center;" src="/static/img/duomeU.png" alt="DuoMe" />
      </mdc-card-header>
      <div v-if="signin">
        <mdc-textfield style="width: 300px" type="text" label="Email"
          required
          :helptext="helptext"
          v-model="email" />
        <mdc-button unelevated @click="signIn">Sign In</mdc-button>
        <mdc-text typo="caption" tag="p">By signing up you agree to DuoMe's terms and conditions.</mdc-text>
      </div>
      <div v-else>
        <mdc-textfield style="width: 300px" type="text" label="Full name"
          required
          v-model="fullname" />
        <mdc-textfield style="width: 300px" type="text" label="Email"
          required
          :helptext="helptext"
          v-model="email" />
        <mdc-button unelevated @click="signUp">Sign Up</mdc-button>
        <mdc-text typo="caption" tag="p">By signing up you agree to DuoMe's terms and conditions.</mdc-text>
      </div>
    </mdc-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/auth'
import fb from '../store/firebase'
const profilesRef = fb.firestore.collection('profiles')
export default {
  name: 'home',
  data () {
    return {
      fullname: '',
      email: '',
      password: '',
      msg: 'Home',
      signin: true,
      helptext: '',
      duoId: null,
      coverId: null,
      continueUrl: null
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile'
    ])
  },
  methods: {
    ...mapActions([
      'setProfile',
      'setSnackMessage',
      'setPageLoad'
    ]),
    onSelected (idx) {
      this.signin = (idx === 0)
    },
    signIn () {
      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true
      }
      firebase.auth().sendSignInLinkToEmail(this.email, actionCodeSettings)
        .then(() => {
          this.showAlertMessage('Email with authentication link has been sent')
          // this.$router.push('/dash')
        })
        .catch(err => {
          console.error('signin error: ', err)
          this.showAlertMessage(err.message)
        })
    },
    signUp () {
      firebase.auth().signInWithEmailLink(this.email, window.location.href)
        .then((result) => {
          const user = firebase.auth().currentUser
          user.updateProfile({ displayName: this.fullname })
          fb.setDocument(profilesRef.doc(user.uid), {
            view: 'public',
            displayName: this.fullname,
            email: this.email
          }).then(() => {
            this.setProfile({
              id: user.uid,
              view: 'public',
              displayName: this.fullname,
              email: this.email
            })
          })
        })
        .catch(err => {
          console.error('signup error: ', err)
          this.showAlertMessage(err.message)
        })
    },
    showAlertMessage (message) {
      this.helptext = message
      this.setSnackMessage(message)
    }
  },
  mounted () {
    this.duoId = this.$route.query.duo
    this.coverId = this.$route.query.cover
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      this.signin = false
    }
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  },
  watch: {
    user: function (newUser, oldUser) {
      if (this.user) {
        if (this.duoId) {
          const link = (this.coverId)
            ? `/duo/${this.duoId}/${this.coverId}`
            : `/duo/${this.duoId}`
          this.$router.push(link)
        } else {
          this.$router.push('/dash')
        }
      }
    }
  }
}
</script>

<style scoped>
.main-content {
  text-align: center;
}
.signup-card {
  max-width: 350px;
  top: calc(50% - 300px);
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  position:fixed;
  z-index:999;
}
.mdc-button {
  width: 230px;
  margin-top:16px;
}
</style>
