import questions from '../assets/json/questions.json'

const state = {
  questions: []
}

const getters = {
  getQuestionById: (state) => (id) => {
    return state.questions.find(question => question.id === id)
  }
}

const actions = {
  loadQuestions ({ commit }) {
    commit('setQuestions', questions)
  }
}

const mutations = {
  setQuestions: (state, questions) => {
    state.questions = questions
  }
}

export default { state, getters, actions, mutations }
