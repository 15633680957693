const firebaseAppName = process.env.VUE_APP_NAME || 'duome-testing'
export const appName = firebaseAppName
export const publicProfilesUrl = (firebaseAppName === 'duome-live') ? 'https://public.duome.co' : `https://${firebaseAppName}.appspot.com`
export const functionsUrl = `https://europe-west1-${firebaseAppName}.cloudfunctions.net`
// export const functionsUrl = `http://localhost:5000/${firebaseAppName}/europe-west1`

const appKeys = {
  'duome-testing': {
    apiKey: 'AIzaSyCwoWkMkIGTL3HP9XmuVKmMm3nk7FoJTs8',
    messagingSenderId: '1081343365199',
    appId: '1:1081343365199:web:8ebaa3efbaa2a8f2',
    dataClientId: '1081343365199-m5ea51u0cb7fstdj0k5ruik07c6a62oq.apps.googleusercontent.com'
  },
  'duome-demo': {
    apiKey: 'AIzaSyA5Why6wZ93kKy0TWs01ZXbZ9GcEpSHZYI',
    messagingSenderId: '881571105574',
    appId: '1:881571105574:web:3592e74f8f62cb2f',
    dataClientId: '881571105574-sfi3ch9bqv8jlg94f7j8775jipnctuqo.apps.googleusercontent.com'
  },
  'duome-live': {
    apiKey: 'AIzaSyDsQkqnb4_xYAcMvt75dB6HcS_s24MlYLc',
    messagingSenderId: '1095398283587',
    appId: '1:1095398283587:web:53ae9b1388e6e7ba',
    dataClientId: '1095398283587-4n9f2abjt67krdce79bkfrr356rhoc93.apps.googleusercontent.com'
  }
}

const { apiKey, messagingSenderId, appId, dataClientId } = appKeys[firebaseAppName]

const authDomain = (firebaseAppName === 'duome-live') ? 'jobshare.duome.co' : `${firebaseAppName}.firebaseapp.com`

export const firebaseSettings = {
  apiKey,
  authDomain,
  databaseURL: `https://${firebaseAppName}.firebaseio.com`,
  projectId: firebaseAppName,
  storageBucket: `${firebaseAppName}.appspot.com`,
  messagingSenderId,
  appId,
  dataClientId
}

export const allowedEmails = [
  'jelliott+',
  'rchan+',
  'eric+',
  'graham+',
  'stephen+'
]
