<template>
  <span v-if="user">
    <span v-if="user.uid !== profile.id" class="connection-modal">
      <span v-if="connections && ownProfile">

        <round-button
          :high="cta"
          :medium="medium"
          :hoverHigh="hoverHigh"
          style="padding-right:12px;width:126px;margin-right:0"
          hasSlot
          :class="{ cta: cta, medium: medium }"
          class="connection-modal-btn"
          v-if="ownProfile && !ownProfile.basicCompleted || !ownProfile.experienceCompleted"
          @click.prevent.stop="block">
          <img slot="leading-icon" v-if="cta" class="icon leading-icon" src="/static/img/icons/add_outline_white.svg">
          <img slot="leading-icon" v-else-if="medium" class="icon leading-icon" src="/static/img/icons/add_outline_blue.svg">
          <img slot="leading-icon" v-else class="icon leading-icon" src="/static/img/icons/add_outline.svg">

          <img slot="leading-icon-hover" v-if="medium && hoverHigh" class="icon leading-icon-hover" src="/static/img/icons/add_outline_white.svg">

          <span>Connect</span>
        </round-button>

        <round-button
          :high="cta"
          :medium="medium"
          :hoverHigh="hoverHigh"
          style="padding-right:12px;width:126px;margin-right:0"
          hasSlot
          :class="{ cta: cta, medium: medium  }"
          class="connection-modal-btn"
          v-else-if="!connection"
          @click.prevent.stop="connectionOpen=!connectionOpen">
          <img slot="leading-icon" v-if="cta" class="icon leading-icon" src="/static/img/icons/add_outline_white.svg">
          <img slot="leading-icon" v-else-if="medium" class="icon leading-icon" src="/static/img/icons/add_outline_blue.svg">
          <img slot="leading-icon" v-else class="icon leading-icon" src="/static/img/icons/add_outline.svg">

          <img slot="leading-icon-hover" v-if="medium && hoverHigh" class="icon leading-icon-hover" src="/static/img/icons/add_outline_white.svg">

          <span>Connect</span>
        </round-button>

        <round-button
          :high="cta"
          :medium="medium"
          :hoverHigh="hoverHigh"
          style="padding-right:12px;width:126px;margin-right:0"
          hasSlot
          :class="{ cta: cta, medium: medium  }"
          class="connection-modal-btn"
          v-else
          @click.prevent.stop="openConnection">
          <mdc-icon slot="leading-icon" v-if="cta" class="icon" style="color:white" icon="send"></mdc-icon>
          <mdc-icon slot="leading-icon" v-else-if="medium" class="icon" icon="send"></mdc-icon>
          <mdc-icon slot="leading-icon" v-else class="icon" icon="send"></mdc-icon>
          <span>Message</span>
        </round-button>
      </span>

      <span v-if="!connection && cta" style="width: 28px;height: 16px; margin-left:12px;">
        <hotspot
          @shown="updateHotspot('connection')"
          :visible="!ownProfile.hotspotClicked.connection && (ownProfile.hotspotSeen.connection <= 5 || !ownProfile.hotspotSeen.connection)" :diameter="20">
          <round-button
            background="transparent"
            @mouseover.native="mouseOverTooltip"
            @click="showTooltip = true"
            noHover
            class="inline-button tooltip-icon"
            style="padding:0;color:#828282;margin:0;height:16px;width:16px;min-width:16px;"
            icon="help_outline"/>
        </hotspot>
      </span>
      <v-popover
        offset="1"
        delay="100"
        class="connection-modal-tooltip"
        container='#main-content'
        style="margin-left:-16px;"
        placement='bottom-end'
        :disabled="false"
        trigger="manual"
        :open="showTooltip"
        @show="trackPartnerConnectHelp"
        @hide="showTooltip = false"
        autoHide
      >
        <template slot="popover">
          <mdc-title>Connect to Partners</mdc-title>
          <div>
            <p>Found a profile that looks interesting? Request to connect so you can start to chat and see if you could partner for a Job Share.</p>
          </div>
          <div style="width:100%;text-align:right">
            <a class="mdc-button" @click="showTooltip = false"><mdc-icon style="height: 24px;" icon="clear"/> CLOSE</a>
          </div>
        </template>
      </v-popover>

      <app-dialog
        v-model="connectionOpen"
        @click.native.prevent.stop
        class="connection-modal-dialog fullsize-mobile"
        title="Connect To"
        accept="OK"
        @accept="onAccept"
        @close="onDecline">

        <mdc-layout-grid>
          <mdc-layout-cell desktop="6" tablet="8" phone="4">
            <profile-card-mobile-basic
              hoverHigh
              hideShare
              style="margin-top:16px;"
              :showMore="false"
              :profile="profile">
            </profile-card-mobile-basic>
          </mdc-layout-cell>
          <mdc-layout-cell desktop="6" tablet="8" phone="4">
            <div class="mdc-textfield-wrapper message-wrap">
                <mdc-text-area-autosize
                  :minHeight="82"
                  :maxHeight="300"
                  style="min-height:92px;"
                  label="Message"
                  ref="messageInput"
                  v-model="message"
                  outlined
                  maxlength="300">
                  <mdc-text-field-counter slot="counter">0 / 300</mdc-text-field-counter>
                </mdc-text-area-autosize>
            </div>
          </mdc-layout-cell>
        </mdc-layout-grid>
      </app-dialog>

    </span>
  </span>
</template>

<script>
import AppDialog from '../components/AppDialog'
import RoundButton from '../components/RoundButton.vue'
import Hotspot from '../components/Hotspot'
import ProfileCardMobileBasic from '../components/profile-v2/ProfileCardMobileBasic.vue'
import { mapState, mapActions } from 'vuex'
import * as backend from '../utils/backend'
export default {
  name: 'ConnectionModal',
  components: {
    AppDialog,
    RoundButton,
    ProfileCardMobileBasic,
    Hotspot
  },
  props: {
    profile: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isActive: {
      type: Boolean,
      default: false
    },
    cta: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    },
    hoverHigh: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showTooltip: false,
      connectionOpen: false,
      messageDefault: 'Please accept my invitation to connect',
      message: 'Please accept my invitation to connect'
    }
  },
  computed: {
    ...mapState([
      'user',
      'connections'
    ]),
    ...mapState({
      ownProfile: state => state.profile
    }),
    connection: function () {
      if (this.user) {
        if (this.connections !== null) {
          const self = this
          const connection = this.connections.filter(function (el) {
            return el.users[self.user.uid] && el.users[self.profile.id]
          })
          if (connection) {
            return connection[0]
          }
        }
      }
      return null
    },
    connected: function () {
      return !!this.connection
    }
  },
  methods: {
    ...mapActions([
      'connectWith',
      'updateProfile'
    ]),
    block: function () {
      this.$parent.$parent.$emit('blocked')
    },
    connect: function () {
      if (!this.connected) {
        // eslint-disable-next-line no-undef
        analytics.track('connectUser', {
          connectToId: this.profile.id,
          id: this.ownProfile.id,
          journeyStage: this.ownProfile.journeyStage,
          role: this.ownProfile.role,
          locationFull: this.ownProfile.locationFull,
          flexschedule: this.ownProfile.flexschedule,
          company: this.ownProfile.company
        })
        this.connectWith({ userId: this.profile.id }).then(async (docRef) => {
          if (this.message === '') {
            this.message = this.messageDefault
          }
          await backend.appFunction('botmessage', {
            status: 'Invite',
            fromId: this.user.uid,
            text: this.message,
            connectionId: docRef.id,
            connection: this.connection
          })
          this.$router.push('/connection/' + docRef.id)
        })
      }
    },
    openConnection: function () {
      this.$router.push('/connection/' + this.connection.id)
    },
    onAccept: function () {
      this.connect()
    },
    onDecline: function () {
      this.connectionOpen = false
    },
    onMessageUpdate: function (text, key) {
      this.message = text
    },
    mouseOverTooltip () {
      if (!this.ownProfile.hotspotClicked.connection) {
        this.showTooltip = true
      }
    },
    updateHotspot (hotspot) {
      if (!this.ownProfile.hotspotSeen[hotspot]) {
        this.ownProfile.hotspotSeen[hotspot] = 1
      } else {
        this.ownProfile.hotspotSeen[hotspot] = this.ownProfile.hotspotSeen[hotspot] + 1
      }
      this.updateProfile({ profile: { hotspotSeen: this.ownProfile.hotspotSeen }, noSnack: true })
    },
    trackPartnerConnectHelp () {
      this.showTooltip = true
      this.ownProfile.hotspotClicked.connection = true
      this.updateProfile({ profile: { hotspotClicked: this.ownProfile.hotspotClicked }, noSnack: true })
      // eslint-disable-next-line no-undef
      analytics.track('partnerConnectHelp', {
        connectToId: this.profile.id,
        id: this.ownProfile.id,
        journeyStage: this.ownProfile.journeyStage,
        role: this.ownProfile.role,
        locationFull: this.ownProfile.locationFull,
        flexschedule: this.ownProfile.flexschedule,
        company: this.ownProfile.company
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.connection-modal {
  display:flex;
  align-items: center;
}
.connection-modal .mdc-textfield-wrapper textarea::placeholder {
  opacity: 1 !important;
}

.connection-modal .mdc-dialog__surface {
  padding-left:5px;
  padding-right:5px;
}
.connection-modal .mdc-dialog.mdc-dialog--open {
    z-index: 9999;
}

.connection-modal .mdc-dialog .mdc-layout-grid {
  padding-top:0;
  padding-bottom:0;
}

.connection-modal .message-wrap {
  width:100%;
  margin-top:16px;
  margin-right:6px;
  text-align:left;
}
.connection-modal .message-wrap .mdc-text-field__input{
  border:none;
}
.connection-modal .message-wrap .mdc-text-field-helper-text {
  color:rgba(0, 0, 0, 0.7);
  text-align:left;
}

.connection-modal .profile-image {
  max-width:150px;
  margin-left:auto;
  margin-right:auto;
}
.connection-modal .profile-image .avatar {
  width: 80%;
  margin: 10%;
  padding-top: 80%;
  position: relative;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  display: block;
  overflow: hidden;
}
.connection-modal .profile-image .avatar img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;;
    left: 0;
    object-fit: cover;
}
.connection-modal .connection-modal-btn {
  margin-left: 0;
  color: #333333;
}
.connection-modal .connection-modal-btn.medium {
  color: var(--mdc-theme-primary, #008AD8);
}
.connection-modal .connection-modal-btn.cta {
  color: white;
}
.connection-modal .connection-modal-btn .icon {
  margin-left: 6px;
  margin-right: 6px;
}
</style>
<style>
.connection-modal .btn-pulse .wrapper {
  margin-top: 0px;
  margin-left: 0.3px;
}
.connection-modal .mdc-textfield-wrapper textarea.mdc-text-field__input {
  min-height: 20px;
  line-height: 20px;
  margin-bottom: 12px;
}
.connection-modal .no-swapper {
  padding:0;
}
</style>
