import fb from './firebase'

const companysRef = fb.firestore.collection('companies')
const featuredCompanyRef = fb.firestore.collection('featuredCompanies')

const state = {
  assignedCompany: null,
  assignedSubCompany: null,
  publicSubCompanies: [],
  featuredCompanies: {}
}

let assignedCompanyListener
let assignedSubCompanyListener
let pubicSubCompaniesListener

const resetAssignedCompanyListener = () => {
  if (assignedCompanyListener) { assignedCompanyListener() }
}

const resetAssignedSubCompanyListener = () => {
  if (assignedSubCompanyListener) { assignedSubCompanyListener() }
}

const resetPublicSubCompaniesListener = () => {
  if (pubicSubCompaniesListener) { pubicSubCompaniesListener() }
}

const getters = {
  getAssignedCompany: (state) => {
    return state.assignedCompany
  }
}

const actions = {
  loadAssignedCompany ({ commit }, { companyId }) {
    resetAssignedCompanyListener()
    const ref = companysRef.doc(companyId)
    assignedCompanyListener = ref.onSnapshot(doc => {
      if (doc.exists) {
        const result = doc.data()
        result.id = companyId
        commit('setAssignedCompany', result)
      }
    })
  },
  async loadPublicSubCompanies ({ commit }, { companyId, subCompanyId }) {
    resetPublicSubCompaniesListener()
    console.log('store.company.loadPublicSubCompanies', companyId)
    const snapshot = await fb.firestore.collection('companies').doc(companyId)
      .collection('subCompanies')
      .where('private', '==', false).get()
    const publicCompanies = []
    snapshot.forEach(doc => {
      publicCompanies.push({ id: doc.id })
    })
    commit('setPublicSubCompanies', publicCompanies)
  },
  loadAssignedSubCompany ({ commit }, { companyId, subCompanyId }) {
    resetAssignedSubCompanyListener()
    console.log('store.company.loadAssignedSubCompany', companyId, subCompanyId)
    const ref = companysRef.doc(companyId).collection('subCompanies').doc(subCompanyId)
    assignedSubCompanyListener = ref.onSnapshot(doc => {
      if (doc.exists) {
        const result = doc.data()
        result.id = subCompanyId
        commit('setAssignedSubCompany', result)
      }
    })
  },
  async loadFeaturedCompanies ({ commit }) {
    const companies = {}
    const snapshot = await featuredCompanyRef.get()
    snapshot.forEach(doc => {
      companies[doc.id] = { ...doc.data() }
    })
    commit('setFeaturedCompanies', companies)
  }
}

const mutations = {
  setAssignedCompany: (state, company) => {
    state.assignedCompany = company
  },
  setAssignedSubCompany: (state, company) => {
    state.assignedSubCompany = company
  },
  setFeaturedCompanies: (state, companies) => {
    state.featuredCompanies = companies
  },
  setPublicSubCompanies: (state, companies) => {
    state.publicSubCompanies = companies
  }
}

export default { state, getters, actions, mutations, resetAssignedCompanyListener, resetAssignedSubCompanyListener, resetPublicSubCompaniesListener }
