<template>
  <div class="main-content">

    <div class="main-wrapper main-content-wrapper" style="margin-top: 56px;">

      <header class="post-full-header">
        <h1 class="post-full-title">{{title}}</h1>
      </header>
      <figure class="post-full-image">
        <img :src="feature_image"/>
      </figure>
      <section class="post-full-content">
        <div class="post-content" v-html="content"></div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import * as ghost from '../utils/ghost'
import fb from '../store/firebase'

export default {
  name: 'Page',
  data () {
    return {
      title: 'Page',
      slug: '',
      links: [],
      content: '',
      feature_image: ''
    }
  },
  computed: {
    ...mapState([
      'profile'
    ])
  },
  methods: {
    ...mapActions([
      'setPageLoad'
    ]),
    async countPage () {
      console.log('Page.countPage', this.slug)
      const page = this.slug
      const type = 'learn'
      const companyId = (this.profile.companyId) ? this.profile.companyId : '_default'
      const pageView = fb.functions.httpsCallable('pageView')
      console.log('Page.countPage - calling pageView', page, type, companyId)
      await pageView({ page, type, companyId })
    }
  },
  beforeDestroy () {
    this.$root.$emit('enableSearch', false)
  },
  async mounted () {
    this.slug = this.$route.params.id
    const result = await ghost.postBySlug(this.slug)
    const posts = result.posts
    this.title = posts[0].title
    this.content = posts[0].html
    this.feature_image = posts[0].feature_image
    this.$root.$emit('enableSearch', true, { back: true, context: 'learn' })

    setTimeout(() => {
      this.setPageLoad(false)
      this.countPage()
    }, 600)
  }
}
</script>
<style scoped>
.post-full-header {
  margin: 0 auto;
  padding: 16px 0 2vw;
  max-width: 1040px;
  text-align: center;
}
.post-full-title {
  margin: 0;
}
.post-full-image {
  margin: 0 -10vw -165px;
  background: #c5d2d9 50%;
  border-radius: 5px;
  overflow: hidden;
}
.post-full-content {
  position: relative;
  padding-left: 2%;
  margin: 0 auto;
  min-height: 230px;
  font-size: 1.0rem;
  line-height: 1.6em;
  background: #fff;
  margin-left: var(--mdc-layout-grid-margin-desktop, 24px);
  margin-right: var(--mdc-layout-grid-margin-desktop, 24px);
}
.post-content {
  display: flex;
  flex-direction: column;
  max-width: 920px;
}
img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

@media (max-width: 840px) {
  .post-full-content {
    margin-left: var(--mdc-layout-grid-margin-phone, 16px);
    margin-right: var(--mdc-layout-grid-margin-phone, 16px);
  }
}

</style>

<style>
.post-full-content img {
  display: block;
  height: auto;
}
.post-full-content .kg-image {
  max-width: 100%;
}
.post-full-content figure {
  margin: 1.5em 0 3em;
}
.post-full-content figure img {
  margin: 0;
}
</style>
